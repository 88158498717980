import { GraphQLClient } from 'graphql-request'
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types'
import { gql } from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CNPJ: { input: any; output: any }
  CPF: { input: any; output: any }
  Date: { input: any; output: any }
  IP: { input: any; output: any }
  Json: { input: any; output: any }
  NaiveDateTime: { input: any; output: any }
  Phone: { input: any; output: any }
  UUID: { input: any; output: any }
  Upload: { input: any; output: any }
  VoterRegistration: { input: any; output: any }
}

/** Representação da resposta sobre os itens inclusos na fatura em aberto */
export type ActiveEnrollmentItemsResponse = {
  __typename?: 'ActiveEnrollmentItemsResponse'
  /** Lista de itens inclusos na fatura em aberto */
  enrollmentItems: Array<EnrollmentItem>
  /** Representação do item de fopag (previsibilidade) */
  futureFopagItem?: Maybe<ExtraEnrollmentItem>
  /** Representação do item de mensalidade */
  monthlyFeeItem: ExtraEnrollmentItem
}

/** Um endereço arbitrário de algum recurso do sistema */
export type Address = {
  __typename?: 'Address'
  /** Nome da Cidade */
  city: Scalars['String']['output']
  /** Complemento */
  complement?: Maybe<Scalars['String']['output']>
  /** Identificador único do endereço */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Bairro */
  neighborhood: Scalars['String']['output']
  /** Número da Rua */
  number: Scalars['String']['output']
  /** CEP do endereço */
  postal: Scalars['String']['output']
  /** Estado (Unidade Federativa) Brasileiro */
  state: AddressState
  /** Rua */
  street: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

export type AddressInsertPayload = {
  /** Nome da Cidade */
  city: Scalars['String']['input']
  /** Complemento */
  complement?: InputMaybe<Scalars['String']['input']>
  /** Bairro */
  neighborhood: Scalars['String']['input']
  /** Número da Rua */
  number: Scalars['String']['input']
  /** CEP do endereço (sem máscara) */
  postal: Scalars['String']['input']
  /** Estado (Unidade Federativa) Brasileiro */
  state: AddressState
  /** Rua */
  street: Scalars['String']['input']
}

export enum AddressState {
  /** Acre */
  Ac = 'AC',
  /** Alagoas */
  Al = 'AL',
  /** Amazonas */
  Am = 'AM',
  /** Amapá */
  Ap = 'AP',
  /** Bahia */
  Ba = 'BA',
  /** Ceará */
  Ce = 'CE',
  /** Distrito Federal */
  Df = 'DF',
  /** Espírito Santo */
  Es = 'ES',
  /** Goiás */
  Go = 'GO',
  /** Maranhão */
  Ma = 'MA',
  /** Minas Gerais */
  Mg = 'MG',
  /** Mato Grosso do Sul */
  Ms = 'MS',
  /** Mato Grosso */
  Mt = 'MT',
  /** Pará */
  Pa = 'PA',
  /** Paraíba */
  Pb = 'PB',
  /** Pernambuco */
  Pe = 'PE',
  /** Piauí */
  Pi = 'PI',
  /** Paraná */
  Pr = 'PR',
  /** Rio de Janeiro */
  Rj = 'RJ',
  /** Rio Grande do Norte */
  Rn = 'RN',
  /** Rondônia */
  Ro = 'RO',
  /** Roraima */
  Rr = 'RR',
  /** Rio Grande do Sul */
  Rs = 'RS',
  /** Santa Catarina */
  Sc = 'SC',
  /** Sergipe */
  Se = 'SE',
  /** São Paulo */
  Sp = 'SP',
  /** Tocantins */
  To = 'TO',
}

export type AddressUpdatePayload = {
  /** Nome da Cidade */
  city?: InputMaybe<Scalars['String']['input']>
  /** Complemento */
  complement?: InputMaybe<Scalars['String']['input']>
  /** Bairro */
  neighborhood?: InputMaybe<Scalars['String']['input']>
  /** Número da Rua */
  number?: InputMaybe<Scalars['String']['input']>
  /** CEP do endereço (sem máscara) */
  postal?: InputMaybe<Scalars['String']['input']>
  /** Estado (Unidade Federativa) Brasileiro */
  state?: InputMaybe<AddressState>
  /** Rua */
  street?: InputMaybe<Scalars['String']['input']>
}

/** Uma alteração no log de registros realizada por um Usuário de Backoffice do sistema */
export type AuditLogBackofficeUserChange = AuditLogChange & {
  __typename?: 'AuditLogBackofficeUserChange'
  /** Ação da mudança realizada */
  action: Scalars['String']['output']
  /** E-mail do autor da mudança (vazio se for do sistema) */
  authorEmail?: Maybe<Scalars['String']['output']>
  /** Usuário de backoffice autor da mudança (nulo se for do sistema ou realizada por um Usuário) */
  backofficeUser: BackofficeUserAccount
  /** ID do usuário de backoffice autor da mudança (nulo se for do sistema ou realizada por um Usuário) */
  backofficeUserId: Scalars['UUID']['output']
  /** Interface pela qual a mudança foi realizada */
  consumer?: Maybe<Scalars['String']['output']>
  /** Contato associado a mudança */
  contact?: Maybe<Contact>
  /** ID do Contato associado a mudança */
  contactId?: Maybe<Scalars['UUID']['output']>
  /** Empresa associada a mudança */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada a mudança */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Identificador do registro de log */
  id: Scalars['UUID']['output']
  /** Data da mudança realizada */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** IP que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  ipAddress?: Maybe<Scalars['IP']['output']>
  /** Declaração de IRPF associado a mudança */
  irpfStatement?: Maybe<IrpfStatement>
  /** ID da Declaração de IRPF associado a mudança */
  irpfStatementId?: Maybe<Scalars['UUID']['output']>
  /** Metadados dos parâmetros da mudança realizada (Acessível apenas por usuários de Backoffice) */
  params?: Maybe<Scalars['Json']['output']>
  /** Metadados de recurso quando a ação está associada a visualização de recursos de Empresa */
  resource?: Maybe<EnterpriseResource>
  /** Venda associada a mudança */
  sale?: Maybe<Sale>
  /** ID da Venda associada a mudança */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Dados do dispositivo que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  userAgent?: Maybe<Scalars['String']['output']>
}

/** Interface da Representação de um Registro de Mudança de Log */
export type AuditLogChange = {
  /** Ação da mudança realizada */
  action: Scalars['String']['output']
  /** E-mail do autor da mudança (vazio se for do sistema) */
  authorEmail?: Maybe<Scalars['String']['output']>
  /** Interface pela qual a mudança foi realizada */
  consumer?: Maybe<Scalars['String']['output']>
  /** Contato associado a mudança */
  contact?: Maybe<Contact>
  /** ID do Contato associado a mudança */
  contactId?: Maybe<Scalars['UUID']['output']>
  /** Empresa associada a mudança */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada a mudança */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Identificador do registro de log */
  id: Scalars['UUID']['output']
  /** Data da mudança realizada */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** IP que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  ipAddress?: Maybe<Scalars['IP']['output']>
  /** Declaração de IRPF associado a mudança */
  irpfStatement?: Maybe<IrpfStatement>
  /** ID da Declaração de IRPF associado a mudança */
  irpfStatementId?: Maybe<Scalars['UUID']['output']>
  /** Metadados dos parâmetros da mudança realizada (Acessível apenas por usuários de Backoffice) */
  params?: Maybe<Scalars['Json']['output']>
  /** Metadados de recurso quando a ação está associada a visualização de recursos de Empresa */
  resource?: Maybe<EnterpriseResource>
  /** Venda associada a mudança */
  sale?: Maybe<Sale>
  /** ID da Venda associada a mudança */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Dados do dispositivo que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  userAgent?: Maybe<Scalars['String']['output']>
}

/** Payload para filtragem de Registros de Logs */
export type AuditLogChangesFilterPayload = {
  /** Filtros para a ação do Registro de Mudança de Log */
  action?: InputMaybe<StringFilterPayload>
  /** Filtros para a interface do Registro de Mudança de Log */
  consumer?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Contato de Mudança de Log */
  contactId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Empresa de Mudança de Log */
  enterpriseeId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Registro de Mudança de Log */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data do Registro de Mudança de Log */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o IP do Registro de Mudança de Log */
  ipAddress?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da Declaração de IRPF de Mudança de Log */
  irpfStatementId?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<AuditLogChangesFilterPayload>>
  /** Filtros para o ID da Venda de Mudança de Log */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Usuário de Backoffice de Mudança de Log */
  userId?: InputMaybe<UuidFilterPayload>
}

/** Lista de Registros de Mudanças de Log e seus metadados de paginação */
export type AuditLogChangesPaginatedList = {
  __typename?: 'AuditLogChangesPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<AuditLogChange>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Uma alteração no log de registros realizada pelo sistema */
export type AuditLogSystemChange = AuditLogChange & {
  __typename?: 'AuditLogSystemChange'
  /** Ação da mudança realizada */
  action: Scalars['String']['output']
  /** E-mail do autor da mudança (vazio se for do sistema) */
  authorEmail?: Maybe<Scalars['String']['output']>
  /** Interface pela qual a mudança foi realizada */
  consumer?: Maybe<Scalars['String']['output']>
  /** Contato associado a mudança */
  contact?: Maybe<Contact>
  /** ID do Contato associado a mudança */
  contactId?: Maybe<Scalars['UUID']['output']>
  /** Empresa associada a mudança */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada a mudança */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Identificador do registro de log */
  id: Scalars['UUID']['output']
  /** Data da mudança realizada */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** IP que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  ipAddress?: Maybe<Scalars['IP']['output']>
  /** Declaração de IRPF associado a mudança */
  irpfStatement?: Maybe<IrpfStatement>
  /** ID da Declaração de IRPF associado a mudança */
  irpfStatementId?: Maybe<Scalars['UUID']['output']>
  /** Metadados dos parâmetros da mudança realizada (Acessível apenas por usuários de Backoffice) */
  params?: Maybe<Scalars['Json']['output']>
  /** Metadados de recurso quando a ação está associada a visualização de recursos de Empresa */
  resource?: Maybe<EnterpriseResource>
  /** Venda associada a mudança */
  sale?: Maybe<Sale>
  /** ID da Venda associada a mudança */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Dados do dispositivo que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  userAgent?: Maybe<Scalars['String']['output']>
}

/** Uma alteração no log de registros realizada por um Usuário do sistema */
export type AuditLogUserChange = AuditLogChange & {
  __typename?: 'AuditLogUserChange'
  /** Ação da mudança realizada */
  action: Scalars['String']['output']
  /** E-mail do autor da mudança (vazio se for do sistema) */
  authorEmail?: Maybe<Scalars['String']['output']>
  /** Interface pela qual a mudança foi realizada */
  consumer?: Maybe<Scalars['String']['output']>
  /** Contato associado a mudança */
  contact?: Maybe<Contact>
  /** ID do Contato associado a mudança */
  contactId?: Maybe<Scalars['UUID']['output']>
  /** Empresa associada a mudança */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada a mudança */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Identificador do registro de log */
  id: Scalars['UUID']['output']
  /** Data da mudança realizada */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** IP que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  ipAddress?: Maybe<Scalars['IP']['output']>
  /** Declaração de IRPF associado a mudança */
  irpfStatement?: Maybe<IrpfStatement>
  /** ID da Declaração de IRPF associado a mudança */
  irpfStatementId?: Maybe<Scalars['UUID']['output']>
  /** Metadados dos parâmetros da mudança realizada (Acessível apenas por usuários de Backoffice) */
  params?: Maybe<Scalars['Json']['output']>
  /** Metadados de recurso quando a ação está associada a visualização de recursos de Empresa */
  resource?: Maybe<EnterpriseResource>
  /** Venda associada a mudança */
  sale?: Maybe<Sale>
  /** ID da Venda associada a mudança */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Usuário autor da mudança (nulo se for do sistema ou realizada por um Usuário do Backoffice) */
  user: UserAccount
  /** Dados do dispositivo que originou a mudança realizada (Acessível apenas por usuários de Backoffice) */
  userAgent?: Maybe<Scalars['String']['output']>
  /** ID do usuário autor da mudança (nulo se for do sistema ou realizada por um Usuário do Backoffice) */
  userId?: Maybe<Scalars['UUID']['output']>
}

/** Unidades Federativas disponível no sistema com seu valor e nome */
export type AvailableState = {
  __typename?: 'AvailableState'
  /** Nome amigável da UF */
  name: Scalars['String']['output']
  /** Valor da UF */
  state: AddressState
}

/** Um registro do Funcionários de Backoffice */
export type BackofficeEmployee = {
  __typename?: 'BackofficeEmployee'
  /** Email de contato do funcionário de backoffice */
  email: Scalars['String']['output']
  /** Identificador único do funcionário de backoffice */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome completo do funcionário de backoffice */
  name: Scalars['String']['output']
  /** URL de caminho da imagem de perfil do funcionário de backoffice */
  picture?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para filtragem de Funcionários de Backoffice */
export type BackofficeEmployeesFilterPayload = {
  /** Filtros para o email do Funcionário de Backoffice */
  email?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Funcionário de Backoffice */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome do Funcionário de Backoffice */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<BackofficeEmployeesFilterPayload>>
}

/** Lista de Funcionários de Backoffice e seus metadados de paginação */
export type BackofficeEmployeesPaginatedList = {
  __typename?: 'BackofficeEmployeesPaginatedList'
  /** Dados recuperados */
  entries: Array<BackofficeEmployee>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Dados da conta de um usuário de backoffice */
export type BackofficeUserAccount = {
  __typename?: 'BackofficeUserAccount'
  /** Email de contato do usuário de backoffice */
  email: Scalars['String']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Data de inserção do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Log de alterações realizadas no usuário de backoffice */
  log: AuditLogChangesPaginatedList
  /** Nome completo do usuário de backoffice */
  name: Scalars['String']['output']
  /** URL de caminho da imagem de perfil do usuário de backoffice */
  picture?: Maybe<Scalars['String']['output']>
  /** Dados do Grupo de Permissão que o usuário de backoffice pertence */
  role: Role
  /** Id do Grupo de Permissão que o usuário de backoffice pertence */
  roleId: Scalars['UUID']['output']
  /** Data de atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Dados da conta de um usuário de backoffice */
export type BackofficeUserAccountLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Payload para filtragem de usuário de backoffices */
export type BackofficeUserAccountsFilterPayload = {
  /** Filtros para o código do usuário de backoffice */
  email?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do recurso */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome amigável do usuário de backoffice */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<BackofficeUserAccountsFilterPayload>>
  /** Filtros para o ID do grupo de permissão do usuário de backoffice */
  roleId?: InputMaybe<UuidFilterPayload>
}

/** Payload para ordenação de usuários de backoffice */
export type BackofficeUserAccountsSortPayload = {
  /** Configuração de ordenação para o e-mail do usuário de backoffice */
  email?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do usuário de backoffice */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação do resultado de uma autenticação de usuário de backoffice na plataforma */
export type BackofficeUserAuthResponse = {
  __typename?: 'BackofficeUserAuthResponse'
  /** JWT de acesso que permite consumir a API pelo cliente (short-lived) */
  accessToken: Scalars['String']['output']
  /** Recurso de usuário de backoffice associado as credenciais enviadas */
  backofficeUser: BackofficeUserAccount
}

/** Payload para inserçao ou atualização de dados da contabilidade atual no Onboarding de Migração pelo Usuário de Backoffice */
export type BackofficeUserEnterpriseOnboardingMigrationAccountingDataUpsertPayload = {
  /** Timestamp de recebimento de dados da contabilidade atual */
  accountingDataReceivedAt?: InputMaybe<Scalars['Date']['input']>
  /** Timestamp de solicitação de dados da contabilidade atual */
  accountingDataRequestedAt?: InputMaybe<Scalars['Date']['input']>
  /** E-mail da contabilidade atual */
  accountingEmail?: InputMaybe<Scalars['String']['input']>
  /** Nome da contabilidade atual */
  accountingName?: InputMaybe<Scalars['String']['input']>
  /** Telefone da contabilidade atual */
  accountingPhone?: InputMaybe<Scalars['Phone']['input']>
  /** Nome do contato responsável na contabilidade atual */
  accountingResponsibleName?: InputMaybe<Scalars['String']['input']>
  /** Número do CRC */
  crcNumber?: InputMaybe<Scalars['String']['input']>
  /** Detalhes da Empresa recebidos da contabilidade atual */
  details?: InputMaybe<Scalars['String']['input']>
  /** Indica se a Empresa não possui contabilidade atualmente */
  noAccounting: Scalars['Boolean']['input']
}

/** Lista de usuários de backoffice e seus metadados de paginação */
export type BackofficeUsersPaginatedList = {
  __typename?: 'BackofficeUsersPaginatedList'
  /** Dados recuperados */
  entries: Array<BackofficeUserAccount>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para filtragem de campos do tipo Booleano */
export type BooleanFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['Boolean']['input']>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['Boolean']['input']>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<Scalars['Boolean']['input']>>
}

/** Dados referente à um Processamento Massivo */
export type BulkUpload = {
  __typename?: 'BulkUpload'
  /** Descrição do processamento */
  description?: Maybe<Scalars['String']['output']>
  /** Identificador único do processamento massivo */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** IDs inseridos no processamento */
  insertedResourcesIds?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>
  /** Caminho do arquivo enviado para o processamento */
  originalFile: Scalars['String']['output']
  /** Funcionário que solicitou o processamento */
  requestedBy: BackofficeEmployee
  /** Recurso inserido no processamento */
  resource: BulkUploadResource
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Funcionário que executou o processamento */
  uploadedBy: BackofficeEmployee
}

/** Payload de inserção de uma novo Processamento Massivo */
export type BulkUploadInsertPayload = {
  /** Descrição do processamento */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo enviado para o processamento */
  originalFile: Scalars['Upload']['input']
  /** ID do Funcionário que solicitou o processamento */
  requestedById: Scalars['UUID']['input']
  /** Recurso inserido no processamento */
  resource: BulkUploadResource
}

export enum BulkUploadResource {
  /** Boleto */
  Bill = 'BILL',
  /** Nota Fiscal */
  Invoice = 'INVOICE',
  /** Outro */
  Other = 'OTHER',
  /** Relatório */
  Report = 'REPORT',
  /** Guia de Imposto */
  TaxGuide = 'TAX_GUIDE',
}

/** Payload para filtragem de campos do tipo BulkUploadResource */
export type BulkUploadResourceFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<BulkUploadResource>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<BulkUploadResource>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<BulkUploadResource>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<BulkUploadResource>>>
}

/** Payload para filtragem de Processamento Massivos */
export type BulkUploadsFilterPayload = {
  /** Filtros para a descrição do Processamento Massivo */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Processamento Massivo */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do Processamento Massivo */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<BulkUploadsFilterPayload>>
  /** Filtros para o ID do Funcionário Executador */
  requestedById?: InputMaybe<UuidFilterPayload>
  /** Filtros para o recurso do Processamento Massivo */
  resource?: InputMaybe<BulkUploadResourceFilterPayload>
  /** Filtros para o ID do Funcionário Solicitante */
  uploadedById?: InputMaybe<UuidFilterPayload>
}

/** Lista de Processamento Massivos e seus metadados de paginação */
export type BulkUploadsPaginatedList = {
  __typename?: 'BulkUploadsPaginatedList'
  /** Dados recuperados */
  entries: Array<BulkUpload>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Processamento Massivos */
export type BulkUploadsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Funcionário Solicitante */
  requestedById?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o recurso do Processamento Massivo */
  resource?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Funcionário Executador */
  uploadedById?: InputMaybe<SortDirection>
}

export enum BusinessActivity {
  /** Comércio */
  Commerce = 'COMMERCE',
  /** Indústria */
  Industry = 'INDUSTRY',
  /** Outro */
  Other = 'OTHER',
  /** Serviço */
  Service = 'SERVICE',
  /** Serviço & Comércio */
  ServiceAndCommerce = 'SERVICE_AND_COMMERCE',
}

/** Representação de um Formato de Negócio na plataforma */
export type BusinessFormat = {
  __typename?: 'BusinessFormat'
  /** Código do Formato de Negócio */
  code: Scalars['String']['output']
  /** Descrição do Formato de Negócio */
  description: Scalars['String']['output']
  /** Identificador único do Formato de Negócio */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome do Formato de Negócio */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para filtragem de Formato de Negócios */
export type BusinessFormatsFilterPayload = {
  /** Filtros para o código do Formato de Negócio */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição do Formato de Negócio */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Formato de Negócio */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome do Formato de Negócio */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<BusinessFormatsFilterPayload>>
}

/** Lista de Formato de Negócios e seus metadados de paginação */
export type BusinessFormatsPaginatedList = {
  __typename?: 'BusinessFormatsPaginatedList'
  /** Dados recuperados */
  entries: Array<BusinessFormat>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Representação de uma Natureza de Negócio na plataforma */
export type BusinessNature = {
  __typename?: 'BusinessNature'
  /** Código da Natureza de Negócio */
  code: Scalars['String']['output']
  /** Descrição da Natureza de Negócio */
  description: Scalars['String']['output']
  /** Identificador único da Natureza de Negócio */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome da Natureza de Negócio */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para filtragem de Natureza de Negócios */
export type BusinessNaturesFilterPayload = {
  /** Filtros para o código da Natureza de Negócio */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição da Natureza de Negócio */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da Natureza de Negócio */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome da Natureza de Negócio */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<BusinessNaturesFilterPayload>>
}

/** Lista de Natureza de Negócios e seus metadados de paginação */
export type BusinessNaturesPaginatedList = {
  __typename?: 'BusinessNaturesPaginatedList'
  /** Dados recuperados */
  entries: Array<BusinessNature>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Representação de Sócios de Informações Empresariais, extraídos ou não da receita */
export type BusinessPartner = {
  __typename?: 'BusinessPartner'
  /** País do sócio */
  country?: Maybe<Scalars['String']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Nome do sócio */
  name?: Maybe<Scalars['String']['output']>
  /** Qualificação do sócio */
  qualification?: Maybe<BusinessPartnerQualification>
  /** Tipo do sócio */
  type?: Maybe<BusinessPartnerType>
}

/** Payload de inserção de e-CPF de sócios */
export type BusinessPartnerEcpfInsertPayload = {
  /** Identificador do Sócio associado ao e-CPF */
  enterpriseOnboardingBusinessPartnerId: Scalars['UUID']['input']
  /** Arquivo do Certificado Digital */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Senha do Certificado Digital */
  password?: InputMaybe<Scalars['String']['input']>
}

/** Payload para mutação de Sócios de Informações Empresariais */
export type BusinessPartnerPayload = {
  /** País do sócio */
  country?: InputMaybe<Scalars['String']['input']>
  /** Nome do sócio */
  name?: InputMaybe<Scalars['String']['input']>
  /** Qualificação do sócio */
  qualification?: InputMaybe<BusinessPartnerQualification>
  /** Tipo do sócio */
  type?: InputMaybe<BusinessPartnerType>
}

export enum BusinessPartnerQualification {
  /** Administrador */
  Admin = 'ADMIN',
  /** Administrador Residente ou Domiciliado no Exterior */
  AdminAbroad = 'ADMIN_ABROAD',
  /** Sócio-Administrador */
  AdminPartner = 'ADMIN_PARTNER',
  /** Sócio-Administrador Residente ou Domiciliado no Exterior */
  AdminPartnerAbroad = 'ADMIN_PARTNER_ABROAD',
  /** Sociedade Filiada */
  AffiliatedCompany = 'AFFILIATED_COMPANY',
  /** Procurador */
  Attorney = 'ATTORNEY',
  /** Conselheiro de Administração */
  BoardAdmin = 'BOARD_ADMIN',
  /** Conselheiro de Administração Residente ou Domiciliado no Exterior */
  BoardAdminAbroad = 'BOARD_ADMIN_ABROAD',
  /** Sócio Capitalista */
  CapitalistPartner = 'CAPITALIST_PARTNER',
  /** Sociedade Consorciada */
  ConsortiumSociety = 'CONSORTIUM_SOCIETY',
  /** Sócio Pessoa Jurídica Domiciliado no Brasil */
  CorporatePartner = 'CORPORATE_PARTNER',
  /** Sócio Pessoa Jurídica Domiciliado no Exterior */
  CorporatePartnerAbroad = 'CORPORATE_PARTNER_ABROAD',
  /** Diretor */
  Director = 'DIRECTOR',
  /** Diretor Residente ou Domiciliado no Exterior */
  DirectorAbroad = 'DIRECTOR_ABROAD',
  /** Sócio Incapaz ou Relat.Incapaz (exceto menor) */
  DisabledPartner = 'DISABLED_PARTNER',
  /** Fundador */
  Founder = 'FOUNDER',
  /** Fundador Residente ou Domiciliado no Exterior */
  FounderAbroad = 'FOUNDER_ABROAD',
  /** Sócio Comanditado */
  GeneralPartner = 'GENERAL_PARTNER',
  /** Sócio Comanditado Residente no Exterior */
  GeneralPartnerAbroad = 'GENERAL_PARTNER_ABROAD',
  /** Titular Pessoa Jurídica Domiciliada no Brasil */
  HolderLegalPerson = 'HOLDER_LEGAL_PERSON',
  /** Titular Pessoa Jurídica Domiciliada no Exterior */
  HolderLegalPersonAbroad = 'HOLDER_LEGAL_PERSON_ABROAD',
  /** Titular Pessoa Física Residente ou Domiciliado no Brasil */
  HolderNaturalPerson = 'HOLDER_NATURAL_PERSON',
  /** Titular Pessoa Física Residente ou Domiciliado no Exterior */
  HolderNaturalPersonAbroad = 'HOLDER_NATURAL_PERSON_ABROAD',
  /** Titular Pessoa Física Incapaz ou Relativamente Incapaz (exceto menor) */
  HolderNaturalPersonDisabled = 'HOLDER_NATURAL_PERSON_DISABLED',
  /** Titular Pessoa Física Menor (Assistido/Representado) */
  HolderNaturalPersonMinor = 'HOLDER_NATURAL_PERSON_MINOR',
  /** Sócio Pessoa Física Residente no Brasil */
  IndividualPartner = 'INDIVIDUAL_PARTNER',
  /** Sócio Pessoa Física Residente ou Domiciliado no Exterior */
  IndividualPartnerAbroad = 'INDIVIDUAL_PARTNER_ABROAD',
  /** Sócio de Indústria */
  IndustryPartner = 'INDUSTRY_PARTNER',
  /** Sócio Comanditário Incapaz */
  LimitedDisabledPartner = 'LIMITED_DISABLED_PARTNER',
  /** Sócio Comanditário Pessoa Jurídica Domiciliado no Exterior */
  LimitedLegalPartnerAbroad = 'LIMITED_LEGAL_PARTNER_ABROAD',
  /** Sócio Comanditário Pessoa Física Residente no Exterior */
  LimitedNaturalPartnerAbroad = 'LIMITED_NATURAL_PARTNER_ABROAD',
  /** Sócio Comanditário */
  LimitedPartner = 'LIMITED_PARTNER',
  /** Sócio-Gerente */
  ManagingPartner = 'MANAGING_PARTNER',
  /** Sócio Menor (Assistido/Representado) */
  MinorPartner = 'MINOR_PARTNER',
  /** Sócio Ostensivo */
  OnstensivePartner = 'ONSTENSIVE_PARTNER',
  /** Outro */
  Other = 'OTHER',
  /** Sócio */
  Partner = 'PARTNER',
  /** Sócio sem Capital */
  PartnerWithoutCapital = 'PARTNER_WITHOUT_CAPITAL',
  /** Sócio com Capital */
  PartnerWithCapital = 'PARTNER_WITH_CAPITAL',
  /** Presidente */
  President = 'PRESIDENT',
  /** Presidente Residente ou Domiciliado no Exterior */
  PresidentAbroad = 'PRESIDENT_ABROAD',
  /** Protetor */
  Protector = 'PROTECTOR',
  /** Produtor Rural */
  RuralProducer = 'RURAL_PRODUCER',
  /** Secretário */
  Secretary = 'SECRETARY',
  /** Cotas em Tesouraria */
  SharesInTreasury = 'SHARES_IN_TREASURY',
  /** Tesoureiro */
  Treasurer = 'TREASURER',
  /** Vice-Presidente */
  VicePresident = 'VICE_PRESIDENT',
}

export enum BusinessPartnerType {
  /** Sócio Estrangeiro */
  ForeignPartner = 'FOREIGN_PARTNER',
  /** Pessoa Jurídica */
  LegalPerson = 'LEGAL_PERSON',
  /** Pessoa Física */
  NaturalPerson = 'NATURAL_PERSON',
  /** Outro */
  Other = 'OTHER',
}

export enum BusinessService {
  /** Contabilidade */
  Accounting = 'ACCOUNTING',
  /** Fiscal */
  Fiscal = 'FISCAL',
  /** Legalização */
  Legalization = 'LEGALIZATION',
  /** Folha de Pagamento */
  Payroll = 'PAYROLL',
}

/** Payload para filtragem de campos do tipo BusinessService */
export type BusinessServiceFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<BusinessService>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<BusinessService>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<BusinessService>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<BusinessService>>>
}

export enum BusinessStatus {
  /** Ativa */
  Active = 'ACTIVE',
  /** Baixada */
  Closed = 'CLOSED',
  /** Ativa Não Regular */
  Irregular = 'IRREGULAR',
  /** Nula */
  Null = 'NULL',
  /** Outra */
  Other = 'OTHER',
  /** Suspensa */
  Suspended = 'SUSPENDED',
  /** Inapta */
  Unqualified = 'UNQUALIFIED',
}

export enum BusinessType {
  /** Filial */
  Branch = 'BRANCH',
  /** Matriz */
  Headquarters = 'HEADQUARTERS',
  /** Outro */
  Other = 'OTHER',
}

/** Um registro do Código Nacional de Atividade Econômica (CNAE) */
export type Cnae = {
  __typename?: 'Cnae'
  /** Código do CNAE, como na tabela do governo */
  code: Scalars['String']['output']
  /** Descrição do CNAE */
  description: Scalars['String']['output']
  /** Identificador único do CNAE */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo CNAE */
export type CnaeInsertPayload = {
  /** Código do CNAE, como na tabela do governo */
  code: Scalars['String']['input']
  /** Descrição do CNAE */
  description: Scalars['String']['input']
}

/** Payload de atualização de um CNAE */
export type CnaeUpdatePayload = {
  /** Código do CNAE, como na tabela do governo */
  code?: InputMaybe<Scalars['String']['input']>
  /** Descrição do CNAE */
  description?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de grupos */
export type CnaesFilterPayload = {
  /** Filtros para o código do CNAE */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição do CNAE */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do CNAE */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<CnaesFilterPayload>>
}

/** Lista de CNAEs e seus metadados de paginação */
export type CnaesPaginatedList = {
  __typename?: 'CnaesPaginatedList'
  /** Dados recuperados */
  entries: Array<Cnae>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Representação de um Contato */
export type Contact = {
  __typename?: 'Contact'
  /** Data de nascimento do Contato */
  birthdate?: Maybe<Scalars['Date']['output']>
  /** Código do Contato */
  code: Scalars['Int']['output']
  /** CPF do Contato */
  cpf?: Maybe<Scalars['CPF']['output']>
  /** Email do Contato */
  email: Scalars['String']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Lista de declarações de IRPF do contato */
  irpfStatements?: Maybe<IrpfStatementsPaginatedList>
  /** Log de alterações realizadas no contato */
  log: AuditLogChangesPaginatedList
  /** Nome do Contato */
  name: Scalars['String']['output']
  /** Origem do Contato (Página, URL ou detalhes sobre a origem) */
  origin: Scalars['String']['output']
  /** Campanha de marketing de origem do Contato */
  originMarketingCampaign?: Maybe<MarketingCampaign>
  /** ID da Campanha de marketing de origem do Contato */
  originMarketingCampaignId?: Maybe<Scalars['UUID']['output']>
  /** Telefone do Contato */
  phone: Scalars['Phone']['output']
  /** Responsável pelo Contato */
  responsible?: Maybe<BackofficeEmployee>
  /** ID do Responsável pelo Contato */
  responsibleId?: Maybe<Scalars['UUID']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário vinculado ao Contato */
  user?: Maybe<UserAccount>
  /** WhatsApp do Contato */
  whatsapp?: Maybe<Scalars['Phone']['output']>
}

/** Representação de um Contato */
export type ContactIrpfStatementsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<IrpfStatementsFilterPayload>
}

/** Representação de um Contato */
export type ContactLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Payload de inserção de um novo Contato */
export type ContactInsertPayload = {
  /** Data de nascimento do Contato */
  birthdate?: InputMaybe<Scalars['Date']['input']>
  /** CPF do Contato */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** Email do Contato */
  email: Scalars['String']['input']
  /** Nome do Contato */
  name: Scalars['String']['input']
  /** Origem do Contato (Página, URL ou detalhes sobre a origem) */
  origin: Scalars['String']['input']
  /** Campanha de marketing de origem do Contato */
  originMarketingCampaignId?: InputMaybe<Scalars['UUID']['input']>
  /** Telefone do Contato */
  phone: Scalars['Phone']['input']
  /** Responsável pelo Contato */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** WhatsApp do Contato */
  whatsapp?: InputMaybe<Scalars['Phone']['input']>
}

/** Representação de uma Interação com o Contato */
export type ContactInteraction = {
  __typename?: 'ContactInteraction'
  /** Contato associado à Interação */
  contact: Contact
  /** ID do Contato associado à Interação */
  contactId: Scalars['UUID']['output']
  /** Dados do motivo da falha na Interação com o Contato, se houver */
  failureReason?: Maybe<Scalars['String']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Método de comunicação utilizado na Interação com o Contato */
  method?: Maybe<ContactInteractionMethod>
  /** Observação sobre a Interação com o Contato */
  observation?: Maybe<Scalars['String']['output']>
  /** Razão da Interação com o Contato */
  reason?: Maybe<ContactInteractionReason>
  /** Responsável pela Interação com o Contato */
  responsible: BackofficeEmployee
  /** ID do responsável pela Interação com o Contato */
  responsibleId: Scalars['UUID']['output']
  /** Venda associado à Interação */
  sale?: Maybe<Sale>
  /** ID da Venda associado à Interação */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Status da Interação com o Contato */
  status?: Maybe<ContactInteractionStatus>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Interação com o Contato */
export type ContactInteractionInsertPayload = {
  /** Id do Contato da Interação com o Contato */
  contactId: Scalars['UUID']['input']
  /** Dados do motivo da falha na Interação com o Contato, se houver */
  failureReason?: InputMaybe<Scalars['String']['input']>
  /** Método de comunicação utilizado na Interação com o Contato */
  method?: InputMaybe<ContactInteractionMethod>
  /** Observação sobre a Interação com o Contato */
  observation?: InputMaybe<Scalars['String']['input']>
  /** Razão da Interação com o Contato */
  reason?: InputMaybe<ContactInteractionReason>
  /** Responsável pela Interação com o Contato */
  responsibleId: Scalars['UUID']['input']
  /** Id da Venda da Interação com o Contato */
  saleId?: InputMaybe<Scalars['UUID']['input']>
  /** Status da Interação com o Contato */
  status?: InputMaybe<ContactInteractionStatus>
}

export enum ContactInteractionMethod {
  /** E-mail */
  Email = 'EMAIL',
  /** Outro */
  Other = 'OTHER',
  /** Ligação telefônica */
  Phone = 'PHONE',
  /** WhatsApp */
  Whatsapp = 'WHATSAPP',
  /** WhatsApp e Ligação telefônica */
  WhatsappAndPhone = 'WHATSAPP_AND_PHONE',
}

/** Payload para filtragem de campos do tipo ContactInteractionMethod */
export type ContactInteractionMethodFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<ContactInteractionMethod>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<ContactInteractionMethod>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<ContactInteractionMethod>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<ContactInteractionMethod>>>
}

export enum ContactInteractionReason {
  /** Follow-Up */
  FollowUp = 'FOLLOW_UP',
  /** Envio de Informações */
  Information = 'INFORMATION',
  /** Outro */
  Other = 'OTHER',
  /** Retorno solicitado pelo Contato */
  ReturningContact = 'RETURNING_CONTACT',
  /** Venda */
  Sale = 'SALE',
}

/** Payload para filtragem de campos do tipo ContactInteractionReason */
export type ContactInteractionReasonFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<ContactInteractionReason>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<ContactInteractionReason>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<ContactInteractionReason>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<ContactInteractionReason>>>
}

export enum ContactInteractionStatus {
  /** Falha */
  Fail = 'FAIL',
  /** Sucesso */
  Success = 'SUCCESS',
}

/** Payload para filtragem de campos do tipo ContactInteractionStatus */
export type ContactInteractionStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<ContactInteractionStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<ContactInteractionStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<ContactInteractionStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<ContactInteractionStatus>>>
}

/** Payload de atualização de uma Interação com o Contato */
export type ContactInteractionUpdatePayload = {
  /** Id do Contato da Interação com o Contato */
  contactId?: InputMaybe<Scalars['UUID']['input']>
  /** Dados do motivo da falha na Interação com o Contato, se houver */
  failureReason?: InputMaybe<Scalars['String']['input']>
  /** Método de comunicação utilizado na Interação com o Contato */
  method?: InputMaybe<ContactInteractionMethod>
  /** Observação sobre a Interação com o Contato */
  observation?: InputMaybe<Scalars['String']['input']>
  /** Razão da Interação com o Contato */
  reason?: InputMaybe<ContactInteractionReason>
  /** Responsável pela Interação com o Contato */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Id da Venda da Interação com o Contato */
  saleId?: InputMaybe<Scalars['UUID']['input']>
  /** Status da Interação com o Contato */
  status?: InputMaybe<ContactInteractionStatus>
}

/** Payload para filtragem de Interação com Contatos */
export type ContactInteractionsFilterPayload = {
  /** Filtros para o ID do contato do Interação com o Contato */
  contactId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Interação com o Contato */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o método de comunicação utilizado na Interação com o Contato */
  method?: InputMaybe<ContactInteractionMethodFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<ContactInteractionsFilterPayload>>
  /** Filtros para a razão da Interação com o Contato */
  reason?: InputMaybe<ContactInteractionReasonFilterPayload>
  /** Filtros para o ID do responsável pela Interação com o Contato */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da venda do Interação com o Contato */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o status da Interação com o Contato */
  status?: InputMaybe<ContactInteractionStatusFilterPayload>
}

/** Lista de Interação com Contatos e seus metadados de paginação */
export type ContactInteractionsPaginatedList = {
  __typename?: 'ContactInteractionsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<ContactInteraction>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload de atualização de um Contato */
export type ContactUpdatePayload = {
  /** Data de nascimento do Contato */
  birthdate?: InputMaybe<Scalars['Date']['input']>
  /** CPF do Contato */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** Email do Contato */
  email?: InputMaybe<Scalars['String']['input']>
  /** Nome do Contato */
  name?: InputMaybe<Scalars['String']['input']>
  /** Origem do Contato (Página, URL ou detalhes sobre a origem) */
  origin?: InputMaybe<Scalars['String']['input']>
  /** Campanha de marketing de origem do Contato */
  originMarketingCampaignId?: InputMaybe<Scalars['UUID']['input']>
  /** Telefone do Contato */
  phone?: InputMaybe<Scalars['Phone']['input']>
  /** Responsável pelo Contato */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** WhatsApp do Contato */
  whatsapp?: InputMaybe<Scalars['Phone']['input']>
}

/** Payload para filtragem de Contatos */
export type ContactsFilterPayload = {
  /** Filtros para a data de nascimento do Contato */
  birthdate?: InputMaybe<DateFilterPayload>
  /** Filtros para o código do Contato */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o CPF do Contato */
  cpf?: InputMaybe<StringFilterPayload>
  /** Filtros para o email do Contato */
  email?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Contato */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do Contato */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome do Contato */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<ContactsFilterPayload>>
  /** Filtros para a origem do Contato (Página, URL ou detalhes sobre a origem) */
  origin?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da campanha de marketing de origem do Contato */
  originMarketingCampaignId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o telefone do Contato */
  phone?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do responsável pelo Contato */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o WhatsApp do Contato */
  whatsapp?: InputMaybe<StringFilterPayload>
}

/** Lista de Contatos e seus metadados de paginação */
export type ContactsPaginatedList = {
  __typename?: 'ContactsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<Contact>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Contatos */
export type ContactsSortPayload = {
  /** Configuração de ordenação para a data de nascimento do Contato */
  birthdate?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o código do Contato */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o e-mail do Contato */
  email?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do Contato */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a origem do Contato */
  origin?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da campanha de marketing de origem do Contato */
  originMarketingCampaignId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o telefone do Contato */
  phone?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do responsável do Contato */
  responsibleId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de um Contrato */
export type Contract = {
  __typename?: 'Contract'
  /** Empresa associada ao Contrato */
  enterprise?: Maybe<Enterprise>
  /** Identificador da Empresa associada ao Contrato */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Metadados extras do Contrato */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Arquivo do Contrato */
  file?: Maybe<Scalars['String']['output']>
  /** Identificador único da assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Venda associada ao Contrato */
  sale?: Maybe<Sale>
  /** Identificador da Venda associada ao Contrato */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** IP de origem da assinatura do Contrato (Acessível apenas por usuários de Backoffice) */
  signatureIp?: Maybe<Scalars['IP']['output']>
  /** Dados do dispositivo que originou a assinatura do Contrato (Acessível apenas por usuários de Backoffice) */
  signatureUserAgent?: Maybe<Scalars['String']['output']>
  /** Timestamp de assinatura do Contrato */
  signedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Usuário que assinou o Contrato */
  signer: UserAccount
  /** Identificador do Usuário que assinou o Contrato */
  signerId: Scalars['UUID']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Conteúdo de uma seção do contrato do Onboarding */
export type ContractRowContent = ContractRowContentList | ContractRowContentText

export type ContractRowContentList = {
  __typename?: 'ContractRowContentList'
  contentList: Array<Maybe<Scalars['String']['output']>>
}

export type ContractRowContentText = {
  __typename?: 'ContractRowContentText'
  contentText: Scalars['String']['output']
}

/** Representação da resposta de um contrato do Onboarding */
export type ContractTextResponse = {
  __typename?: 'ContractTextResponse'
  /** Conteúdo da seção do contrato */
  rows: Array<Maybe<ContractRowContent>>
  /** Título da seção do contrato */
  title: Scalars['String']['output']
}

/** Payload de inserção de um novo Documento no contexto da empresa atual */
export type CurrentEnterpriseDocumentInsertPayload = {
  /** Descrição do Documento */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo do Documento */
  file: Scalars['Upload']['input']
  /** Data de referência do Documento (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** Título do Documento */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Documento */
  type: EnterpriseDocumentType
}

/** Payload de inserção de um novo Tomador de Serviço no contexto da empresa atual */
export type CurrentEnterpriseServiceTakerInsertPayload = {
  /** Endereço associado */
  address: AddressInsertPayload
  /** CNPJ do Tomador de Serviço */
  cnpj?: InputMaybe<Scalars['CNPJ']['input']>
  /** CPF do Tomador de Serviço */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** Nome do Tomador de Serviço */
  name: Scalars['String']['input']
}

/** Payload para atualização cadastral de um usuário */
export type CurrentUserUpdatePayload = {
  /** Data de nascimento do usuário */
  birthdate?: InputMaybe<Scalars['Date']['input']>
  /** Nome completo do usuário */
  name?: InputMaybe<Scalars['String']['input']>
  /** Número de contato de telefone do usuário */
  phone?: InputMaybe<Scalars['Phone']['input']>
  /** Imagem de Perfil */
  picture?: InputMaybe<Scalars['Upload']['input']>
  /** RG do usuário */
  rg?: InputMaybe<Scalars['String']['input']>
  /** Número de whatsapp do usuário */
  whatsapp?: InputMaybe<Scalars['Phone']['input']>
}

/** Payload para filtragem de campos do tipo Date */
export type DateFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo possui o seu valor maior que o valor enviado */
  gt?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo possui o seu valor maior ou igual que o valor enviado */
  gte?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo possui o seu valor menor que o valor enviado */
  lt?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo possui o seu valor menor ou igual que o valor enviado */
  lte?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo não possui o seu valor maior que o valor enviado */
  ngt?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo não possui o seu valor maior ou igual que o valor enviado */
  ngte?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo não possui o seu valor menor que o valor enviado */
  nlt?: InputMaybe<Scalars['Date']['input']>
  /** Se o campo não possui o seu valor menor ou igual que o valor enviado */
  nlte?: InputMaybe<Scalars['Date']['input']>
}

/** Payload de informações de inativação da Empresa */
export type DeactivateInfoPayload = {
  /** Detalhes sobre a inativação */
  details?: InputMaybe<Scalars['String']['input']>
  /** Motivo de inativação */
  reason: EnterpriseDeactivateReason
}

/** Um registro de Certificado Digital */
export type DigitalCertificate = {
  __typename?: 'DigitalCertificate'
  /** Sócio associado */
  businessPartner?: Maybe<EnterpriseOnboardingBusinessPartner>
  /** Empresa associada */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Onboarding associado */
  enterpriseOnboarding?: Maybe<EnterpriseOnboarding>
  /** ID do Onboarding associado */
  enterpriseOnboardingId?: Maybe<Scalars['UUID']['output']>
  /** Data de expiração do Certificado Digital */
  expiryAt?: Maybe<Scalars['Date']['output']>
  /** Caminho do arquivo do Certificado Digital */
  file?: Maybe<Scalars['String']['output']>
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Senha do Certificado Digital */
  password?: Maybe<Scalars['String']['output']>
  /** Título do Certificado Digital */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo do Certificado Digital */
  type: DigitalCertificateType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Certificado Digital no contexto da empresa atual */
export type DigitalCertificateInsertPayload = {
  /** ID da Empresa associada */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** ID do Onboarding associado */
  enterpriseOnboardingId?: InputMaybe<Scalars['UUID']['input']>
  /** Data de expiração do Certificado Digital */
  expiryAt?: InputMaybe<Scalars['Date']['input']>
  /** Arquivo do Certificado Digital */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Senha do Certificado Digital */
  password?: InputMaybe<Scalars['String']['input']>
  /** Título do Certificado Digital */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Certificado Digital */
  type: DigitalCertificateType
}

export enum DigitalCertificateType {
  /** E-CNPJ */
  Ecnpj = 'ECNPJ',
  /** E-CPF */
  Ecpf = 'ECPF',
  /** Outro */
  Other = 'OTHER',
}

/** Payload para filtragem de campos do tipo DigitalCertificateType */
export type DigitalCertificateTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<DigitalCertificateType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<DigitalCertificateType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<DigitalCertificateType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<DigitalCertificateType>>>
}

/** Payload de atualização de um Certificado Digital */
export type DigitalCertificateUpdatePayload = {
  /** ID da Empresa associada */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** ID do Onboarding associado */
  enterpriseOnboardingId?: InputMaybe<Scalars['UUID']['input']>
  /** Data de expiração do Certificado Digital */
  expiryAt?: InputMaybe<Scalars['Date']['input']>
  /** Arquivo do Certificado Digital */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Senha do Certificado Digital */
  password?: InputMaybe<Scalars['String']['input']>
  /** Título do Certificado Digital */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Certificado Digital */
  type?: InputMaybe<DigitalCertificateType>
}

/** Payload para filtragem de Certificado Digitais */
export type DigitalCertificatesFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Onboarding */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a expiração do Certificado Digital */
  expiryAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do Certificado Digital */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<DigitalCertificatesFilterPayload>>
  /** Filtros para o título do Certificado Digital */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo do Certificado Digital */
  type?: InputMaybe<DigitalCertificateTypeFilterPayload>
}

/** Lista de Certificado Digitais e seus metadados de paginação */
export type DigitalCertificatesPaginatedList = {
  __typename?: 'DigitalCertificatesPaginatedList'
  /** Dados recuperados */
  entries: Array<DigitalCertificate>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Certificado Digitais */
export type DigitalCertificatesSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Onboarding vinculado ao recurso */
  enterpriseOnboardingId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de expiração do Certificado Digital */
  expiryAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Certificado Digital */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Certificado Digital */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Payload de descarte de um Onboarding */
export type DiscardEnterpriseOnboardingPayload = {
  /** Detalhes acerca do descarte do Onboarding */
  details?: InputMaybe<Scalars['String']['input']>
  /** Motivo de descarte do Onboarding */
  reason: EnterpriseOnboardingDiscardReason
}

/** Payload de descarte de uma Venda */
export type DiscardSalePayload = {
  /** Detalhes acerca do descarte da Venda */
  details?: InputMaybe<Scalars['String']['input']>
  /** Motivo de descarte da Venda */
  reason: SaleDiscardReason
}

/** Representação de uma assinatura */
export type Enrollment = {
  __typename?: 'Enrollment'
  /** Código da assinatura */
  code: Scalars['Int']['output']
  /** Cartão associado à assinatura */
  currentWalletCard?: Maybe<WalletCard>
  /** ID do cartão associado à assinatura */
  currentWalletCardId?: Maybe<Scalars['UUID']['output']>
  /** Data de inativação da assinatura */
  deactivatedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Lista de faturas da assinatura */
  enrollmentInvoices: Array<EnrollmentInvoice>
  /** Empresa associada à assinatura */
  enterprise?: Maybe<Enterprise>
  /** Identificador externo do usuário associado à assinatura */
  externalEnrollmentId?: Maybe<Scalars['String']['output']>
  /** Identificador único da assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Valor da mensalidade */
  monthlyFee: Scalars['Int']['output']
  /** Data da próxima cobrança */
  nextBillingAt?: Maybe<Scalars['Date']['output']>
  /** Método de pagamento da assinatura */
  paymentMethod: EnrollmentPaymentMethod
  /** Pedido de setup da assinatura */
  setupOrder?: Maybe<Order>
  /** ID do pedido de setup da assinatura */
  setupOrderId?: Maybe<Scalars['UUID']['output']>
  /** Data de início da assinatura */
  startingAt: Scalars['Date']['output']
  /** Status da assinatura */
  status: EnrollmentStatus
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Representação de um Ajuste na Assinatura */
export type EnrollmentAdjustment = {
  __typename?: 'EnrollmentAdjustment'
  /** Define se o recurso pode ser inativado */
  canDeactivate: Scalars['Boolean']['output']
  /** Data de inativação do Ajuste na Assinatura */
  deactivatedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Descrição do Ajuste na Assinatura */
  description: Scalars['String']['output']
  /** Assinatura associada ao item */
  enrollment: Enrollment
  /** Identificador da assinatura */
  enrollmentId: Scalars['UUID']['output']
  /** Identificador externo do Ajuste na Assinatura */
  externalEnrollmentAdjustmentId?: Maybe<Scalars['String']['output']>
  /** Quantidade de meses/ciclos que o Ajuste na Assinatura será aplicado */
  frequency: Scalars['Int']['output']
  /** Identificador único do Ajuste na Assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Data de referência de fim da aplicação do Ajuste na Assinatura */
  referenceEndingAt: Scalars['Date']['output']
  /** Data de referência de início da aplicação do Ajuste na Assinatura */
  referenceStartingAt: Scalars['Date']['output']
  /** Tipo do Ajuste na Assinatura */
  type: EnrollmentAdjustmentType
  /** Tipo do valor do Ajuste na Assinatura */
  typeValue: EnrollmentAdjustmentTypeValue
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Valor do Ajuste na Assinatura */
  value: Scalars['Int']['output']
}

/** Payload de inserção de um novo Ajuste na Assinatura */
export type EnrollmentAdjustmentInsertPayload = {
  /** Descrição do Ajuste na Assinatura */
  description: Scalars['String']['input']
  /** Identificador da assinatura */
  enrollmentId: Scalars['UUID']['input']
  /** Quantidade de meses/ciclos que o Ajuste na Assinatura será aplicado */
  frequency: Scalars['Int']['input']
  /** Tipo do Ajuste na Assinatura */
  type: EnrollmentAdjustmentType
  /** Tipo do valor do Ajuste na Assinatura */
  typeValue: EnrollmentAdjustmentTypeValue
  /** Valor do Ajuste na Assinatura */
  value: Scalars['Int']['input']
}

export enum EnrollmentAdjustmentType {
  /** Desconto */
  Discount = 'DISCOUNT',
  /** Acréscimo */
  Increment = 'INCREMENT',
}

/** Payload para filtragem de campos do tipo EnrollmentAdjustmentType */
export type EnrollmentAdjustmentTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnrollmentAdjustmentType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnrollmentAdjustmentType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnrollmentAdjustmentType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnrollmentAdjustmentType>>>
}

export enum EnrollmentAdjustmentTypeValue {
  /** Valor Fixo */
  Flat = 'FLAT',
  /** Porcentagem */
  Percentage = 'PERCENTAGE',
}

/** Payload para filtragem de campos do tipo EnrollmentAdjustmentTypeValue */
export type EnrollmentAdjustmentTypeValueFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnrollmentAdjustmentTypeValue>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnrollmentAdjustmentTypeValue>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnrollmentAdjustmentTypeValue>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnrollmentAdjustmentTypeValue>>>
}

/** Payload para filtragem de itens na assinatura */
export type EnrollmentAdjustmentsFilterPayload = {
  /** Filtros para a data de inaticação do Ajuste na Assinatura */
  deactivatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o identificador da assinatura */
  enrollmentId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o identificador externo do Ajuste na Assinatura */
  externalEnrollmentAdjustmentId?: InputMaybe<StringFilterPayload>
  /** Filtros para a quantidade de meses/ciclos que o Ajuste na Assinatura será aplicado */
  frequency?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID do Ajuste na Assinatura */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnrollmentAdjustmentsFilterPayload>>
  /** Filtros para a data de referência de fim do Ajuste na Assinatura */
  referenceEndingAt?: InputMaybe<DateFilterPayload>
  /** Filtros para a data de referência de início do Ajuste na Assinatura */
  referenceStartingAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o tipo do Ajuste na Assinatura */
  type?: InputMaybe<EnrollmentAdjustmentTypeFilterPayload>
  /** Filtros para o tipo do valor do Ajuste na Assinatura */
  typeValue?: InputMaybe<EnrollmentAdjustmentTypeValueFilterPayload>
  /** Filtros para a quantidade do Ajuste na Assinatura */
  value?: InputMaybe<IntegerFilterPayload>
}

/** Lista de ajustes e seus metadados de paginação */
export type EnrollmentAdjustmentsPaginatedList = {
  __typename?: 'EnrollmentAdjustmentsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnrollmentAdjustment>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de itens na assinatura */
export type EnrollmentAdjustmentsSortPayload = {
  /** Configuração de ordenação para a data de inaticação do Ajuste na Assinatura */
  deactivatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o identificador externo do Ajuste na Assinatura */
  externalEnrollmentAdjustmentId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência de fim do Ajuste na Assinatura */
  referenceEndingAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência de início do Ajuste na Assinatura */
  referenceStartingAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor do Ajuste na Assinatura */
  value?: InputMaybe<SortDirection>
}

/** Representação de uma fatura da assinatura */
export type EnrollmentInvoice = {
  __typename?: 'EnrollmentInvoice'
  /** Valor da fatura da assinatura */
  amount: Scalars['Int']['output']
  /** Data de cobrança da fatura da assinatura */
  billingAt: Scalars['Date']['output']
  /** Data de cancelamento da fatura da assinatura */
  canceledAt?: Maybe<Scalars['Date']['output']>
  /** Data de vencimento da fatura da assinatura */
  dueAt: Scalars['Date']['output']
  /** Data de término do ciclo de faturamento da assinatura */
  endingAt: Scalars['Date']['output']
  /** Assinatura associada à fatura */
  enrollment: Enrollment
  /** Identificador da assinatura */
  enrollmentId: Scalars['UUID']['output']
  /** Itens contidos na fatura da assinatura */
  enrollmentInvoiceItems: Array<EnrollmentInvoiceItem>
  /** Ordem de Serviço associada à fatura */
  enrollmentInvoiceServiceOrder?: Maybe<EnrollmentInvoiceServiceOrder>
  /** Boleto associado à fatura da assinatura */
  enterpriseBill?: Maybe<EnterpriseBill>
  /** Identificador do boleto associado à fatura da assinatura */
  enterpriseBillId?: Maybe<Scalars['UUID']['output']>
  /** Identificador externo da fatura da assinatura */
  externalInvoiceId: Scalars['String']['output']
  /** Identificador único da fatura da assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Valor de juros e multa */
  interestAndFineAmount?: Maybe<Scalars['Int']['output']>
  /** Data de pagamento da fatura da assinatura */
  paidAt?: Maybe<Scalars['Date']['output']>
  /** Método de pagamento fatura da assinatura */
  paymentMethod: EnrollmentInvoicePaymentMethod
  /** Data de início do ciclo de faturamento da assinatura */
  startingAt: Scalars['Date']['output']
  /** Status da assinatura */
  status: EnrollmentInvoiceStatus
  /** Valor total da fatura da assinatura */
  totalAmount: Scalars['Int']['output']
  /** Valor total de descontos da fatura da assinatura */
  totalDiscount?: Maybe<Scalars['Int']['output']>
  /** Valor total de acréscimos da fatura da assinatura */
  totalIncrement?: Maybe<Scalars['Int']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Representação de um item na fatura da assinatura */
export type EnrollmentInvoiceItem = {
  __typename?: 'EnrollmentInvoiceItem'
  /** Descrição do item na fatura */
  description?: Maybe<Scalars['String']['output']>
  /** Identificador da fatura da assinatura */
  enrollmentInvoiceId: Scalars['UUID']['output']
  /** Identificador único do item na fatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome do item na fatura */
  name: Scalars['String']['output']
  /** Quantidade do item na fatura */
  quantity: Scalars['Int']['output']
  /** Preço unitário do item na fatura */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

export enum EnrollmentInvoicePaymentMethod {
  /** Boleto */
  Bill = 'BILL',
  /** Cartão de Crédito */
  CreditCard = 'CREDIT_CARD',
}

/** Payload para filtragem de campos do tipo EnrollmentInvoicePaymentMethod */
export type EnrollmentInvoicePaymentMethodFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnrollmentInvoicePaymentMethod>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnrollmentInvoicePaymentMethod>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnrollmentInvoicePaymentMethod>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnrollmentInvoicePaymentMethod>>>
}

/** Dados da Ordem de Serviço vinculado a uma fatura */
export type EnrollmentInvoiceServiceOrder = {
  __typename?: 'EnrollmentInvoiceServiceOrder'
  /** Identificador único da Ordem de Serviço */
  id: Scalars['UUID']['output']
  /** Data da inserção da Ordem de Serviço */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** URL da Nota Fiscal emitida pela Hubs acerca da Fatura */
  invoiceUrl?: Maybe<Scalars['String']['output']>
  /** Data do faturamento da Ordem de Serviço */
  invoicedAt?: Maybe<Scalars['Date']['output']>
  /** Data da baixa da Conta a Receber da Ordem de Serviço */
  transactionIssuedAt?: Maybe<Scalars['Date']['output']>
}

export enum EnrollmentInvoiceStatus {
  /** Cancelado(a) */
  Canceled = 'CANCELED',
  /** Falhou */
  Failed = 'FAILED',
  /** Outro */
  Other = 'OTHER',
  /** Pago(a) */
  Paid = 'PAID',
  /** Pendente */
  Pending = 'PENDING',
  /** Agendado(a) */
  Scheduled = 'SCHEDULED',
}

/** Payload para filtragem de campos do tipo EnrollmentInvoiceStatus */
export type EnrollmentInvoiceStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnrollmentInvoiceStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnrollmentInvoiceStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnrollmentInvoiceStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnrollmentInvoiceStatus>>>
}

/** Payload para filtragem de faturas de assinaturas */
export type EnrollmentInvoicesFilterPayload = {
  /** Filtros para o valor da fatura da assinatura */
  amount?: InputMaybe<IntegerFilterPayload>
  /** Filtros para a data de cobrança da fatura da assinatura */
  billingAt?: InputMaybe<DateFilterPayload>
  /** Filtros para a data de cancelamento da fatura da assinatura */
  canceledAt?: InputMaybe<DateFilterPayload>
  /** Filtros para a data de vencimento da fatura da assinatura */
  dueAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID da assinatura */
  enrollmentId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o identificador externo da fatura da assinatura */
  externalInvoiceId?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da fatura da assinatura */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnrollmentInvoicesFilterPayload>>
  /** Filtros para o método de pagamento da fatura da assinatura */
  paymentMethod?: InputMaybe<EnrollmentInvoicePaymentMethodFilterPayload>
  /** Filtros para o status da fatura da assinatura */
  status?: InputMaybe<EnrollmentInvoiceStatusFilterPayload>
  /** Filtros para a data de atualização do recurso */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
}

/** Lista de faturas de assinaturas e seus metadados de paginação */
export type EnrollmentInvoicesPaginatedList = {
  __typename?: 'EnrollmentInvoicesPaginatedList'
  /** Dados recuperados */
  entries: Array<EnrollmentInvoice>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de faturas de assinaturas */
export type EnrollmentInvoicesSortPayload = {
  /** Configuração de ordenação para o valor da fatura da assinatura */
  amount?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de cobrança da fatura da assinatura */
  billingAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de cancelamento da fatura da assinatura */
  canceledAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de vencimento da fatura da assinatura */
  dueAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o identificador externo da fatura da assinatura */
  externalInvoiceId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o método de pagamento da fatura da assinatura */
  paymentMethod?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o status da fatura da assinatura */
  status?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de um item na assinatura */
export type EnrollmentItem = {
  __typename?: 'EnrollmentItem'
  /** Assinatura associada ao item */
  enrollment: Enrollment
  /** Identificador da assinatura */
  enrollmentId: Scalars['UUID']['output']
  /** Identificador externo do item na assinatura */
  externalEnrollmentItemId?: Maybe<Scalars['String']['output']>
  /** Identificador único do item na assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Quantidade do item na assinatura */
  quantity: Scalars['Int']['output']
  /** Data de referência de uso do item na assinatura */
  reference: Scalars['Date']['output']
  /** Item de serviço de assinatura associado ao item */
  serviceItem?: Maybe<ServiceItem>
  /** Identificador do item de serviço */
  serviceItemId: Scalars['UUID']['output']
  /** Preço unitário do item na assinatura */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo item na assinatura */
export type EnrollmentItemInsertPayload = {
  /** Identificador da empresa que possui a assinatura */
  enterpriseId: Scalars['UUID']['input']
  /** Quantidade do item na assinatura */
  quantity: Scalars['Int']['input']
  /** Identificador do item de serviço de assinatura */
  serviceItemId: Scalars['UUID']['input']
}

/** Payload para filtragem de itens na assinatura */
export type EnrollmentItemsFilterPayload = {
  /** Filtros para o identificador da assinatura */
  enrollmentId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o identificador externo do item na assinatura */
  externalEnrollmentItemId?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do item na assinatura */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnrollmentItemsFilterPayload>>
  /** Filtros para a quantidade do item na assinatura */
  quantity?: InputMaybe<IntegerFilterPayload>
  /** Filtros para a data de referência de uso do item na assinatura */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o identificador do item de serviço de assinatura */
  serviceItemId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o preço unitário do item na assinatura */
  unitPrice?: InputMaybe<IntegerFilterPayload>
}

/** Lista de itens e seus metadados de paginação */
export type EnrollmentItemsPaginatedList = {
  __typename?: 'EnrollmentItemsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnrollmentItem>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de itens na assinatura */
export type EnrollmentItemsSortPayload = {
  /** Configuração de ordenação para o identificador externo do item na assinatura */
  externalEnrollmentItemId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a quantidade do item na assinatura */
  quantity?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência de uso do item na assinatura */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o preço unitário do item na assinatura */
  unitPrice?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

export enum EnrollmentPaymentMethod {
  /** Boleto */
  Bill = 'BILL',
  /** Cartão de Crédito */
  CreditCard = 'CREDIT_CARD',
  /** Cartão de Crédito Externo */
  ExternalCreditCard = 'EXTERNAL_CREDIT_CARD',
  /** Outro */
  Other = 'OTHER',
  /** Parceiro */
  Partner = 'PARTNER',
}

export enum EnrollmentStatus {
  /** Ativa */
  Active = 'ACTIVE',
  /** Inativa */
  Inactive = 'INACTIVE',
}

/** Representação de uma Empresa */
export type Enterprise = {
  __typename?: 'Enterprise'
  /** Responsável Contábil */
  accountingResponsible?: Maybe<BackofficeEmployee>
  /** Id do Responsável Contábil */
  accountingResponsibleId?: Maybe<Scalars['UUID']['output']>
  /** Informações acerca das atividades da Empresa */
  activities?: Maybe<Scalars['String']['output']>
  /** Endereço da Empresa */
  address?: Maybe<Address>
  /** Id do Endereço da Empresa */
  addressId?: Maybe<Scalars['UUID']['output']>
  /** Atividade da Empresa */
  businessActivity?: Maybe<BusinessActivity>
  /** Formato de Negócio da Empresa */
  businessFormat?: Maybe<BusinessFormat>
  /** Id do Formato de Negócio da Empresa */
  businessFormatId?: Maybe<Scalars['UUID']['output']>
  /** Natureza de Negócio da Empresa */
  businessNature?: Maybe<BusinessNature>
  /** Id da Natureza de Negócio da Empresa */
  businessNatureId?: Maybe<Scalars['UUID']['output']>
  /** Sócios da Empresa */
  businessPartners?: Maybe<Array<Maybe<BusinessPartner>>>
  /** Status de negócio da Empresa */
  businessStatus?: Maybe<BusinessStatus>
  /** Data do Status de negócio da Empresa */
  businessStatusDate?: Maybe<Scalars['Date']['output']>
  /** Motivo do status da Empresa */
  businessStatusReason?: Maybe<Scalars['String']['output']>
  /** Tipo da Empresa */
  businessType?: Maybe<BusinessType>
  /** Inscrição Municipal da Empresa */
  cityInscription?: Maybe<Scalars['String']['output']>
  /** CNAES da Empresa */
  cnaes?: Maybe<Array<EnterpriseCnae>>
  /** Razão Social da Empresa */
  cnpj: Scalars['CNPJ']['output']
  /** Código da Empresa */
  code: Scalars['Int']['output']
  /** Comentários na Empresa */
  comments?: Maybe<EnterprisesCommentsPaginatedList>
  /** Nome Fantasia da Empresa */
  commercialName?: Maybe<Scalars['String']['output']>
  /** Informações sobre a inativação da Empresa */
  deactivateInfo?: Maybe<EnterpriseDeactivateInfo>
  /** Timestamp de inativação (ou de agendamento) do recurso */
  deactivatedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Descrição da Empresa */
  description?: Maybe<Scalars['String']['output']>
  /** Certificados Digitais da Empresa */
  digitalCertificates?: Maybe<Array<DigitalCertificate>>
  /** Email de contato vinculado à Empresa */
  email?: Maybe<Scalars['String']['output']>
  /** Número de funcionários da Empresa */
  employeesNumber?: Maybe<Scalars['Int']['output']>
  /** Serviços habilitados da Empresa */
  enabledBusinessServices?: Maybe<Array<Maybe<BusinessService>>>
  /** Sistemas externos habilitados para Empresa */
  enabledExternalSystems?: Maybe<Array<Maybe<EnterpriseExternalSystem>>>
  /** Assinatura da Empresa */
  enrollment: Enrollment
  /** ID da Assinatura da Empresa */
  enrollmentId: Scalars['UUID']['output']
  /** Onboarding associado à Empresa */
  enterpriseOnboarding?: Maybe<EnterpriseOnboarding>
  /** ID do Onboarding da Empresa */
  enterpriseOnboardingId?: Maybe<Scalars['UUID']['output']>
  /** Metadados extra */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Responsável Fiscal */
  fiscalResponsible?: Maybe<BackofficeEmployee>
  /** Id do Responsável Fiscal */
  fiscalResponsibleId?: Maybe<Scalars['UUID']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Capa do IPTU do Endereço da Empresa */
  iptuFile?: Maybe<Scalars['String']['output']>
  /** Indica se a empresa está inadimplente */
  isDelinquent: Scalars['Boolean']['output']
  /** Define se a Inscrição Estadual é obrigatória */
  isMandatoryStateInscription?: Maybe<Scalars['Boolean']['output']>
  /** Cidade de jurisdição da empresa */
  jurisdictionCity?: Maybe<Scalars['String']['output']>
  /** Último Humor registrado para a Empresa */
  lastEnterpriseMood?: Maybe<EnterpriseMood>
  /** ID do último Humor registrado para a Empresa */
  lastEnterpriseMoodId?: Maybe<Scalars['UUID']['output']>
  /** Último nível de humor registrado para a Empresa */
  lastEnterpriseMoodLevel: EnterpriseMoodLevel
  /** Log de alterações realizadas na empresa */
  log: AuditLogChangesPaginatedList
  /** Lista de alvarás obrigatórios */
  mandatoryBusinessPermits?: Maybe<Array<Scalars['String']['output']>>
  /** Razão Social da Empresa */
  name?: Maybe<Scalars['String']['output']>
  /** Data de Abertura da Empresa */
  openedAt?: Maybe<Scalars['Date']['output']>
  /** Campanha de marketing de origem da Empresa */
  originMarketingCampaign?: Maybe<MarketingCampaign>
  /** ID da Campanha de origem da Empresa */
  originMarketingCampaignId?: Maybe<Scalars['UUID']['output']>
  /** Responsável de FOPAG */
  payrollResponsible?: Maybe<BackofficeEmployee>
  /** Id do Responsável de FOPAG */
  payrollResponsibleId?: Maybe<Scalars['UUID']['output']>
  /** Telefones vinculados à receita */
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Capital social da Empresa (em centavos de R$) */
  shareCapital?: Maybe<Scalars['Int']['output']>
  /** Períodos da empresa no simples nacional */
  simplesNacionalPeriods?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Situação Especial da Empresa */
  specialSituation?: Maybe<Scalars['String']['output']>
  /** Data da Situação Especial da Empresa */
  specialSituationDate?: Maybe<Scalars['Date']['output']>
  /** Data de Início da Vigência da Empresa */
  startingAt?: Maybe<Scalars['Date']['output']>
  /** Inscrição Estadual da Empresa */
  stateInscription?: Maybe<Scalars['String']['output']>
  /** status da empresa */
  status: EnterpriseStatus
  /** Regime Tributário da Empresa */
  taxRegime?: Maybe<TaxRegime>
  /** Id do Regime Tributário da Empresa */
  taxRegimeId?: Maybe<Scalars['UUID']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuários da Empresa */
  users?: Maybe<Array<EnterpriseUser>>
  /** Carteira da Empresa */
  wallet?: Maybe<Wallet>
}

/** Representação de uma Empresa */
export type EnterpriseCommentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<EnterpriseCommentsFilterPayload>
}

/** Representação de uma Empresa */
export type EnterpriseLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Um registro de Arquivo associado a uma empresa */
export type EnterpriseArchive = {
  __typename?: 'EnterpriseArchive'
  /** Descrição do Arquivo */
  description?: Maybe<Scalars['String']['output']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo do Arquivo */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Título do Arquivo */
  title: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Arquivo da Empresa */
export type EnterpriseArchiveInsertPayload = {
  /** Descrição do Arquivo */
  description?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Arquivo */
  file: Scalars['Upload']['input']
  /** Título do Arquivo */
  title: Scalars['String']['input']
}

/** Payload de atualização de um Arquivo da Empresa */
export type EnterpriseArchiveUpdatePayload = {
  /** Descrição do Arquivo */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Título do Arquivo */
  title?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de Arquivos */
export type EnterpriseArchivesFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Arquivo */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseArchivesFilterPayload>>
  /** Filtros para o título do Arquivo */
  title?: InputMaybe<StringFilterPayload>
}

/** Lista de Arquivos e seus metadados de paginação */
export type EnterpriseArchivesPaginatedList = {
  __typename?: 'EnterpriseArchivesPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseArchive>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Arquivos */
export type EnterpriseArchivesSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Arquivo */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro de Boleto associado a uma empresa */
export type EnterpriseBill = {
  __typename?: 'EnterpriseBill'
  /** Caminho do arquivo da imagem do código de barras do pagamento do Boleto */
  barcode: Scalars['String']['output']
  /** Descrição do Boleto */
  description?: Maybe<Scalars['String']['output']>
  /** Data de vencimento do Boleto */
  dueAt: Scalars['Date']['output']
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo do Boleto */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Número de código de barras do Boleto */
  paymentCode: Scalars['String']['output']
  /** Data de referência do Boleto (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Título do Boleto */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo do Boleto */
  type: EnterpriseBillType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Valor (em centavos de reais) do Boleto */
  value: Scalars['Int']['output']
}

/** Payload de inserção de uma novo Boleto */
export type EnterpriseBillInsertPayload = {
  /** Descrição do Boleto */
  description?: InputMaybe<Scalars['String']['input']>
  /** Data de vencimento do Boleto */
  dueAt: Scalars['Date']['input']
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Arquivo do Boleto */
  file: Scalars['Upload']['input']
  /** Número de código de barras do Boleto */
  paymentCode: Scalars['String']['input']
  /** Data de referência do Boleto (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** Título do Boleto */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Boleto */
  type: EnterpriseBillType
  /** Valor (em centavos de reais) do Boleto */
  value: Scalars['Int']['input']
}

export enum EnterpriseBillType {
  /** Mensalidade Hubs */
  MonthlyService = 'MONTHLY_SERVICE',
  /** Outras Cobranças */
  Other = 'OTHER',
  /** Multa / Juros */
  Penalty = 'PENALTY',
  /** Serviço Pontual */
  PunctualService = 'PUNCTUAL_SERVICE',
}

/** Payload para filtragem de campos do tipo EnterpriseBillType */
export type EnterpriseBillTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseBillType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseBillType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseBillType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseBillType>>>
}

/** Payload de atualização de umo Boleto */
export type EnterpriseBillUpdatePayload = {
  /** Descrição do Boleto */
  description?: InputMaybe<Scalars['String']['input']>
  /** Data de vencimento do Boleto */
  dueAt?: InputMaybe<Scalars['Date']['input']>
  /** ID da Empresa associada */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** Arquivo do Boleto */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Número de código de barras do Boleto */
  paymentCode?: InputMaybe<Scalars['String']['input']>
  /** Data de referência do Boleto (considerar apenas mês e ano) */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** Título do Boleto */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Boleto */
  type?: InputMaybe<EnterpriseBillType>
  /** Valor (em centavos de reais) do Boleto */
  value?: InputMaybe<Scalars['Int']['input']>
}

/** Payload para filtragem de Boletos */
export type EnterpriseBillsFilterPayload = {
  /** Filtros para o vencimento do Boleto */
  dueAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Boleto */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseBillsFilterPayload>>
  /** Filtros para a referência do Boleto */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o título do Boleto */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo do Boleto */
  type?: InputMaybe<EnterpriseBillTypeFilterPayload>
  /** Filtros para o valor do Boleto */
  value?: InputMaybe<IntegerFilterPayload>
}

/** Lista de Boletos e seus metadados de paginação */
export type EnterpriseBillsPaginatedList = {
  __typename?: 'EnterpriseBillsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseBill>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Boletos */
export type EnterpriseBillsSortPayload = {
  /** Configuração de ordenação para a data de vencimento do Boleto */
  dueAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência do Boleto */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Boleto */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Boleto */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor do Boleto */
  value?: InputMaybe<SortDirection>
}

/** Payload de inserção em massa de Relatórios */
export type EnterpriseBulkReportInsertPayload = {
  /** Descrição do Relatório */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo do Relatório */
  file: Scalars['Upload']['input']
  /** Data de referência do Relatório (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** Título do Relatório */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Relatório */
  type: EnterpriseReportType
}

/** Um registro do CNAE associado a uma empresa */
export type EnterpriseCnae = {
  __typename?: 'EnterpriseCnae'
  /** CNAE associado */
  cnae: Cnae
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['output']
  /** Empresa associada */
  enterprise: Enterprise
  /** ID do Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Flag que indica se é a atividade principal da empresa. Apenas um registro pode ter essa flag por empresa. */
  isPrimary: Scalars['Boolean']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Representação de um Comentário na Empresa */
export type EnterpriseComment = {
  __typename?: 'EnterpriseComment'
  /** Usuário que criou o comentário */
  backofficeUser?: Maybe<BackofficeEmployee>
  /** Id do usuário que criou o comentário */
  backofficeUserId?: Maybe<Scalars['UUID']['output']>
  /** Conteúdo do comentário */
  content: Scalars['String']['output']
  /** Id da Empresa associada ao comentário */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um Comentário na Empresa */
export type EnterpriseCommentInsertPayload = {
  /** Id do usuário que criou o comentário */
  backofficeUserId?: InputMaybe<Scalars['UUID']['input']>
  /** Conteúdo do comentário */
  content: Scalars['String']['input']
  /** Id da Empresa associada ao comentário */
  enterpriseId: Scalars['UUID']['input']
}

/** Payload para filtragem de Comentários em Empresas */
export type EnterpriseCommentsFilterPayload = {
  /** Filtros para o ID do Usuário de Backoffice no Comentário na Empresa */
  backofficeUserId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Empresa no Comentário na Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Comentário na Empresa */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseCommentsFilterPayload>>
}

/** Um registro de Credencial associado a uma empresa */
export type EnterpriseCredential = {
  __typename?: 'EnterpriseCredential'
  /** Código da Credencial */
  code?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** Identificador único da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Link associado à Credencial */
  link?: Maybe<Scalars['String']['output']>
  /** Nome da Credencial */
  name?: Maybe<Scalars['String']['output']>
  /** Senha da Credencial */
  password: Scalars['String']['output']
  /** Tipo da Credencial */
  type: EnterpriseCredentialType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Nome de usuário ou login da Credencial */
  username?: Maybe<Scalars['String']['output']>
}

/** Payload de inserção de uma nova Credencial */
export type EnterpriseCredentialInsertPayload = {
  /** Código da Credencial */
  code?: InputMaybe<Scalars['String']['input']>
  /** Identificador único da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Link associado à Credencial */
  link?: InputMaybe<Scalars['String']['input']>
  /** Nome da Credencial */
  name?: InputMaybe<Scalars['String']['input']>
  /** Senha da Credencial */
  password: Scalars['String']['input']
  /** Tipo da Credencial */
  type: EnterpriseCredentialType
  /** Nome de usuário ou login da Credencial */
  username?: InputMaybe<Scalars['String']['input']>
}

export enum EnterpriseCredentialType {
  /** Prefeitura */
  CityHall = 'CITY_HALL',
  /** DAPI */
  Dapi = 'DAPI',
  /** Empregador Web */
  EmpregadorWeb = 'EMPREGADOR_WEB',
  /** Giss Online */
  GissOnline = 'GISS_ONLINE',
  /** GovBR */
  Govbr = 'GOVBR',
  /** NF-e Osasco */
  NfeOsasco = 'NFE_OSASCO',
  /** Outros */
  Other = 'OTHER',
  /** Prodigi */
  Prodigi = 'PRODIGI',
  /** Simples Nacional */
  SimplesNacional = 'SIMPLES_NACIONAL',
  /** Posto Fiscal Eletrônico */
  TaxOffice = 'TAX_OFFICE',
}

/** Payload para filtragem de campos do tipo EnterpriseCredentialType */
export type EnterpriseCredentialTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseCredentialType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseCredentialType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseCredentialType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseCredentialType>>>
}

/** Payload de atualização de uma Credencial */
export type EnterpriseCredentialUpdatePayload = {
  /** Código da Credencial */
  code?: InputMaybe<Scalars['String']['input']>
  /** Link associado à Credencial */
  link?: InputMaybe<Scalars['String']['input']>
  /** Nome da Credencial */
  name?: InputMaybe<Scalars['String']['input']>
  /** Senha da Credencial */
  password?: InputMaybe<Scalars['String']['input']>
  /** Tipo da Credencial */
  type?: InputMaybe<EnterpriseCredentialType>
  /** Nome de usuário ou login da Credencial */
  username?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de Credenciais */
export type EnterpriseCredentialsFilterPayload = {
  /** Filtros para o código da Credencial */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Credencial */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome da Credencial */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseCredentialsFilterPayload>>
  /** Filtros para o tipo da Credencial */
  type?: InputMaybe<EnterpriseCredentialTypeFilterPayload>
  /** Filtros para a data de última atualização do recurso */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome de usuário ou login da Credencial */
  username?: InputMaybe<StringFilterPayload>
}

/** Lista de Credenciais e seus metadados de paginação */
export type EnterpriseCredentialsPaginatedList = {
  __typename?: 'EnterpriseCredentialsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseCredential>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Credenciais */
export type EnterpriseCredentialsSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome da Credencial */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo da Credencial */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Informações sobre a inativação da empresa */
export type EnterpriseDeactivateInfo = {
  __typename?: 'EnterpriseDeactivateInfo'
  /** Detalhes sobre a inativação */
  details?: Maybe<Scalars['String']['output']>
  /** Motivo de inativação */
  reason: EnterpriseDeactivateReason
}

export enum EnterpriseDeactivateReason {
  /** Alteração para CLT */
  ChangeToClt = 'CHANGE_TO_CLT',
  /** Troca de contabilidade */
  ChangingAccounting = 'CHANGING_ACCOUNTING',
  /** Redução de custo */
  CostReduction = 'COST_REDUCTION',
  /** Insatisfação com os serviços */
  DissatisfactionWithService = 'DISSATISFACTION_WITH_SERVICE',
  /** Encerramento da empresa */
  EnterpriseClosing = 'ENTERPRISE_CLOSING',
  /** Venda da empresa */
  EnterpriseSelling = 'ENTERPRISE_SELLING',
  /** Finalização dos serviços com o parceiro */
  FinishedServicesWithPartner = 'FINISHED_SERVICES_WITH_PARTNER',
  /** Desistência da contratação dos serviços */
  GivingUpContractingServices = 'GIVING_UP_CONTRACTING_SERVICES',
  /** Desistência da abertura da empresa */
  GivingUpEnterpriseOpening = 'GIVING_UP_ENTERPRISE_OPENING',
  /** Possui contabilidade interna */
  HasInternalAccounting = 'HAS_INTERNAL_ACCOUNTING',
  /** Não adaptação ao nosso modelo de negócio */
  NoAdaptingWithBusinessModel = 'NO_ADAPTING_WITH_BUSINESS_MODEL',
  /** Dificuldade de contato com o cliente */
  NoContactReturn = 'NO_CONTACT_RETURN',
  /** Falta de pagamento devido a insatisfação com os serviços */
  NoPaymentDueToDissatisfaciton = 'NO_PAYMENT_DUE_TO_DISSATISFACITON',
  /** Não utilização da MEI aberta */
  OpenedMeiNoUse = 'OPENED_MEI_NO_USE',
  /** Outro */
  Other = 'OTHER',
  /** Cancelamento por inadimplência */
  PaymentOverdue = 'PAYMENT_OVERDUE',
  /** Prolemas com atendimento */
  ProblemsWithService = 'PROBLEMS_WITH_SERVICE',
  /** Venda não concluída */
  UnfinishedSell = 'UNFINISHED_SELL',
}

/** Um registro de Documento associado a uma empresa */
export type EnterpriseDocument = {
  __typename?: 'EnterpriseDocument'
  /** Status de contabilização do documento */
  accounted?: Maybe<Scalars['Boolean']['output']>
  /** Descrição do Documento */
  description?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo do Documento */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Data de referência do Documento (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Título do Documento */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo do Documento */
  type: EnterpriseDocumentType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

export enum EnterpriseDocumentType {
  /** Extrato Bancário */
  BankExtract = 'BANK_EXTRACT',
  /** Conta */
  Bill = 'BILL',
  /** Aplicações Financeiras */
  FinancialInvestment = 'FINANCIAL_INVESTMENT',
  /** Nota Fiscal */
  Invoices = 'INVOICES',
  /** Outro */
  Other = 'OTHER',
  /** Outro Extrato */
  OtherExtract = 'OTHER_EXTRACT',
  /** Comprovante de Pagamento */
  PaymentReceipt = 'PAYMENT_RECEIPT',
  /** Comprovante */
  Receipt = 'RECEIPT',
  /** Comprovante de Aluguel */
  RentReceipt = 'RENT_RECEIPT',
  /** Faturamento */
  Revenue = 'REVENUE',
  /** RH */
  Rh = 'RH',
  /** Cartão de Ponto */
  Timesheet = 'TIMESHEET',
}

/** Payload para filtragem de campos do tipo EnterpriseDocumentType */
export type EnterpriseDocumentTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseDocumentType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseDocumentType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseDocumentType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseDocumentType>>>
}

/** Payload de atualização de um Documento */
export type EnterpriseDocumentUpdatePayload = {
  /** Descrição do Documento */
  description?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** Arquivo do Documento */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Data de referência do Documento (considerar apenas mês e ano) */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** Título do Documento */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Documento */
  type?: InputMaybe<EnterpriseDocumentType>
}

/** Payload para filtragem de Documentos */
export type EnterpriseDocumentsFilterPayload = {
  /** Filtros para a indicação de contabilização do Documento */
  accounted?: InputMaybe<BooleanFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Documento */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseDocumentsFilterPayload>>
  /** Filtros para a referência do Documento */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o título do Documento */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo do Documento */
  type?: InputMaybe<EnterpriseDocumentTypeFilterPayload>
}

/** Lista de Documentos e seus metadados de paginação */
export type EnterpriseDocumentsPaginatedList = {
  __typename?: 'EnterpriseDocumentsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseDocument>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Documentos */
export type EnterpriseDocumentsSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência do Documento */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Documento */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Documento */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de uma associação de uma empresa a um item de serviço na assinatura. (Útil para definir preços específicos para a empresa) */
export type EnterpriseEnrollmentServiceItem = {
  __typename?: 'EnterpriseEnrollmentServiceItem'
  /** Empresa associada ao recurso */
  enterprise: Enterprise
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Item de serviço associado ao recurso */
  serviceItem: ServiceItem
  /** Preço unitário do item de serviço para a empresa */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma nova associação de uma empresa a um item de serviço */
export type EnterpriseEnrollmentServiceItemInsertPayload = {
  /** Identificador da empresa */
  enterpriseId: Scalars['UUID']['input']
  /** Identificador do item de serviço */
  serviceItemId: Scalars['UUID']['input']
  /** Preço unitário do item de serviço para a empresa */
  unitPrice: Scalars['Int']['input']
}

/** Payload para atualiação da associação de uma empresa a um item de serviço */
export type EnterpriseEnrollmentServiceItemUpdatePayload = {
  /** Identificador da empresa */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** Identificador do item de serviço */
  serviceItemId?: InputMaybe<Scalars['UUID']['input']>
  /** Preço unitário do item de serviço para a empresa */
  unitPrice?: InputMaybe<Scalars['Int']['input']>
}

/** Payload para filtragem de associações de empresas a itens de serviço */
export type EnterpriseEnrollmentServiceItemsFilterPayload = {
  /** Filtros para o ID da empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do item de serviço */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseEnrollmentServiceItemsFilterPayload>>
  /** Filtros para o ID do item de serviço */
  serviceItemId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o preço unitário do item de serviço */
  unitPrice?: InputMaybe<IntegerFilterPayload>
}

/** Lista de associações de uma empresa a itens de serviço e seus metadados de paginação */
export type EnterpriseEnrollmentServiceItemsPaginatedList = {
  __typename?: 'EnterpriseEnrollmentServiceItemsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseEnrollmentServiceItem>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de associações de empresas a itens de serviço */
export type EnterpriseEnrollmentServiceItemsSortPayload = {
  /** Configuração de ordenação para o ID da empresa */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do item de serviço */
  serviceItemId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o preço unitário do item de serviço */
  unitPrice?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

export enum EnterpriseExternalSystem {
  /** Captura SIEG Hub */
  CapturaSiegHub = 'CAPTURA_SIEG_HUB',
  /** Domínio */
  Dominio = 'DOMINIO',
  /** Escritório Inteligente */
  EscritorioInteligente = 'ESCRITORIO_INTELIGENTE',
  /** Portal Onvio */
  PortalOnvio = 'PORTAL_ONVIO',
  /** SIEG Hub */
  SiegHub = 'SIEG_HUB',
  /** SIEG Iris */
  SiegIris = 'SIEG_IRIS',
}

/** Representação de Informações Empresariais, extraídos ou não da receita, na plataforma */
export type EnterpriseInfo = {
  __typename?: 'EnterpriseInfo'
  /** Informações acerca das atividades da Empresa */
  activities?: Maybe<Scalars['String']['output']>
  /** Endereço da Empresa */
  address?: Maybe<Address>
  /** Id do Endereço da Empresa */
  addressId?: Maybe<Scalars['UUID']['output']>
  /** Atividade da Empresa */
  businessActivity?: Maybe<BusinessActivity>
  /** Formato de Negócio da Empresa */
  businessFormat?: Maybe<BusinessFormat>
  /** Id do Formato de Negócio da Empresa */
  businessFormatId?: Maybe<Scalars['UUID']['output']>
  /** Natureza de Negócio da Empresa */
  businessNature?: Maybe<BusinessNature>
  /** Id da Natureza de Negócio da Empresa */
  businessNatureId?: Maybe<Scalars['UUID']['output']>
  /** Sócios da Empresa */
  businessPartners?: Maybe<Array<Maybe<BusinessPartner>>>
  /** Status de negócio da Empresa */
  businessStatus?: Maybe<BusinessStatus>
  /** Data do Status de negócio da Empresa */
  businessStatusDate?: Maybe<Scalars['Date']['output']>
  /** Motivo do status da Empresa */
  businessStatusReason?: Maybe<Scalars['String']['output']>
  /** Tipo da Empresa */
  businessType?: Maybe<BusinessType>
  /** Inscrição Municipal da Empresa */
  cityInscription?: Maybe<Scalars['String']['output']>
  /** CNAES da Empresa */
  cnaes?: Maybe<Array<Maybe<EnterpriseInfoCnae>>>
  /** CNPJ da Empresa */
  cnpj?: Maybe<Scalars['CNPJ']['output']>
  /** Nome Fantasia da Empresa */
  commercialName?: Maybe<Scalars['String']['output']>
  /** Descrição da Empresa */
  description?: Maybe<Scalars['String']['output']>
  /** Email de contato vinculado à Empresa */
  email?: Maybe<Scalars['String']['output']>
  /** Número de funcionários da Empresa */
  employeesNumber?: Maybe<Scalars['Int']['output']>
  /** Serviços habilitados da Empresa */
  enabledBusinessServices?: Maybe<Array<Maybe<BusinessService>>>
  /** Metadados extra */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Cidade de jurisdição da empresa */
  jurisdictionCity?: Maybe<Scalars['String']['output']>
  /** Razão Social da Empresa */
  name?: Maybe<Scalars['String']['output']>
  /** Data de Abertura da Empresa */
  openedAt?: Maybe<Scalars['Date']['output']>
  /** Telefones vinculados à receita */
  phones?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Capital social da Empresa (em centavos de R$) */
  shareCapital?: Maybe<Scalars['Int']['output']>
  /** Períodos da empresa no simples nacional */
  simplesNacionalPeriods?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Situação Especial da Empresa */
  specialSituation?: Maybe<Scalars['String']['output']>
  /** Data da Situação Especial da Empresa */
  specialSituationDate?: Maybe<Scalars['Date']['output']>
  /** Inscrição Estadual da Empresa */
  stateInscription?: Maybe<Scalars['String']['output']>
  /** Regime Tributário da Empresa */
  taxRegime?: Maybe<TaxRegime>
  /** Id do Regime Tributário da Empresa */
  taxRegimeId?: Maybe<Scalars['UUID']['output']>
}

/** Um registro do CNAE associado a um registro de informações de empresa */
export type EnterpriseInfoCnae = {
  __typename?: 'EnterpriseInfoCnae'
  /** CNAE associado */
  cnae: Cnae
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['output']
  /** Informação de Empresa associada */
  enterpriseInfo: EnterpriseInfo
  /** ID da informação de Empresa associada */
  enterpriseInfoId: Scalars['UUID']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Flag que indica se é a atividade principal da empresa. Apenas um registro pode ter essa flag por empresa. */
  isPrimary: Scalars['Boolean']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para mutação de Informações Empresariais */
export type EnterpriseInfoPayload = {
  /** Informações acerca das atividades da Empresa */
  activities?: InputMaybe<Scalars['String']['input']>
  /** Endereço da Empresa */
  address?: InputMaybe<AddressUpdatePayload>
  /** Id do Endereço da Empresa */
  addressId?: InputMaybe<Scalars['UUID']['input']>
  /** Atividade da Empresa */
  businessActivity?: InputMaybe<BusinessActivity>
  /** Id do Formato de Negócio da Empresa */
  businessFormatId?: InputMaybe<Scalars['UUID']['input']>
  /** Id da Natureza de Negócio da Empresa */
  businessNatureId?: InputMaybe<Scalars['UUID']['input']>
  /** Sócios da Empresa */
  businessPartners?: InputMaybe<Array<InputMaybe<BusinessPartnerPayload>>>
  /** Status de negócio da Empresa */
  businessStatus?: InputMaybe<BusinessStatus>
  /** Data do Status de negócio da Empresa */
  businessStatusDate?: InputMaybe<Scalars['Date']['input']>
  /** Motivo do status da Empresa */
  businessStatusReason?: InputMaybe<Scalars['String']['input']>
  /** Tipo da Empresa */
  businessType?: InputMaybe<BusinessType>
  /** Inscrição Municipal da Empresa */
  cityInscription?: InputMaybe<Scalars['String']['input']>
  /** CNPJ da Empresa */
  cnpj?: InputMaybe<Scalars['CNPJ']['input']>
  /** Nome Fantasia da Empresa */
  commercialName?: InputMaybe<Scalars['String']['input']>
  /** Descrição da Empresa */
  description?: InputMaybe<Scalars['String']['input']>
  /** Email de contato vinculado à Empresa */
  email?: InputMaybe<Scalars['String']['input']>
  /** Número de funcionários da Empresa */
  employeesNumber?: InputMaybe<Scalars['Int']['input']>
  /** Serviços habilitados da Empresa */
  enabledBusinessServices?: InputMaybe<Array<InputMaybe<BusinessService>>>
  /** Metadados extra */
  extraInfo?: InputMaybe<Scalars['Json']['input']>
  /** Cidade de jurisdição da empresa */
  jurisdictionCity?: InputMaybe<Scalars['String']['input']>
  /** Razão Social da Empresa */
  name?: InputMaybe<Scalars['String']['input']>
  /** Data de Abertura da Empresa */
  openedAt?: InputMaybe<Scalars['Date']['input']>
  /** Telefones vinculados à receita */
  phones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Capital social da Empresa (em centavos de R$) */
  shareCapital?: InputMaybe<Scalars['Int']['input']>
  /** Períodos da empresa no simples nacional */
  simplesNacionalPeriods?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Situação Especial da Empresa */
  specialSituation?: InputMaybe<Scalars['String']['input']>
  /** Data da Situação Especial da Empresa */
  specialSituationDate?: InputMaybe<Scalars['Date']['input']>
  /** Inscrição Estadual da Empresa */
  stateInscription?: InputMaybe<Scalars['String']['input']>
  /** Id do Regime Tributário da Empresa */
  taxRegimeId?: InputMaybe<Scalars['UUID']['input']>
}

/** Um registro de Nota Fiscal associado a uma empresa */
export type EnterpriseInvoice = {
  __typename?: 'EnterpriseInvoice'
  /** CNAE associada */
  cnae: Cnae
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['output']
  /** Valor das Deduções (em centavos de reais) da Nota Fiscal */
  deductionsValue: Scalars['Int']['output']
  /** Descrição da Nota Fiscal */
  description?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo da Nota Fiscal */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Número da Nota Fiscal */
  number: Scalars['String']['output']
  /** Data de referência da Nota Fiscal (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Tomador de Serviço associada */
  serviceTaker?: Maybe<EnterpriseServiceTaker>
  /** ID do Tomador de Serviço associado */
  serviceTakerId?: Maybe<Scalars['UUID']['output']>
  /** Valor de Serviço (em centavos de reais) da Nota Fiscal */
  serviceValue: Scalars['Int']['output']
  /** Título da Nota Fiscal */
  title?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma nova Nota Fiscal */
export type EnterpriseInvoiceInsertPayload = {
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['input']
  /** Valor das Deduções (em centavos de reais) da Nota Fiscal */
  deductionsValue: Scalars['Int']['input']
  /** Descrição da Nota Fiscal */
  description?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Arquivo da Nota Fiscal */
  file: Scalars['Upload']['input']
  /** ID da Solicitação de NF associada */
  invoiceRequestId?: InputMaybe<Scalars['UUID']['input']>
  /** Número da Nota Fiscal */
  number: Scalars['String']['input']
  /** Data de referência da Nota Fiscal (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** ID do Tomador de Serviço associado */
  serviceTakerId?: InputMaybe<Scalars['UUID']['input']>
  /** Valor de Serviço (em centavos de reais) da Nota Fiscal */
  serviceValue: Scalars['Int']['input']
  /** Título da Nota Fiscal */
  title?: InputMaybe<Scalars['String']['input']>
}

/** Um registro de Solicitação de Nota Fiscal associado a uma empresa */
export type EnterpriseInvoiceRequest = {
  __typename?: 'EnterpriseInvoiceRequest'
  /** CNAE associado */
  cnae: Cnae
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['output']
  /** Descrição da Solicitação de Nota Fiscal */
  description?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Arquivo da Solicitação de Nota Fiscal */
  file?: Maybe<Scalars['String']['output']>
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Notas fiscais associadas */
  invoices?: Maybe<Array<EnterpriseInvoice>>
  /** Data de referência da Solicitação de Nota Fiscal (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Usuário Solicitador associado */
  requester: UserAccount
  /** ID do Usuário Solicitador associado */
  requesterId: Scalars['UUID']['output']
  /** Tomador de Serviço associada */
  serviceTaker?: Maybe<EnterpriseServiceTaker>
  /** ID do Tomador de Serviço associado */
  serviceTakerId?: Maybe<Scalars['UUID']['output']>
  /** Valor de Serviço (em centavos de reais) da Solicitação de Nota Fiscal */
  serviceValue: Scalars['Int']['output']
  /** Status da Solicitação */
  status: InvoiceRequestStatus
  /** Título da Solicitação de Nota Fiscal */
  title?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma nova Solicitação de Nota Fiscal */
export type EnterpriseInvoiceRequestInsertPayload = {
  /** ID do CNAE associado */
  cnaeId: Scalars['UUID']['input']
  /** Descrição da Solicitação de Nota Fiscal */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo da Solicitação de Nota Fiscal */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Data de referência da Solicitação de Nota Fiscal (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** ID do Tomador de Serviço associado */
  serviceTakerId?: InputMaybe<Scalars['UUID']['input']>
  /** Valor de Serviço (em centavos de reais) da Solicitação de Nota Fiscal */
  serviceValue: Scalars['Int']['input']
  /** Título da Solicitação de Nota Fiscal */
  title?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de Solicitações de Notas Fiscais */
export type EnterpriseInvoiceRequestsFilterPayload = {
  /** Filtros para o ID do CNAE */
  cnaeId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da NF */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseInvoiceRequestsFilterPayload>>
  /** Filtros para a referência da NF */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do Tomador de Serviço */
  serviceTakerId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o valor de serviço da NF */
  serviceValue?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o status da solicitação de NF */
  status?: InputMaybe<InvoiceRequestStatusFilterPayload>
  /** Filtros para o título da NF */
  title?: InputMaybe<StringFilterPayload>
}

/** Lista de Notas Fiscais e seus metadados de paginação */
export type EnterpriseInvoiceRequestsPaginatedList = {
  __typename?: 'EnterpriseInvoiceRequestsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseInvoiceRequest>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Solicitações de Notas Fiscais */
export type EnterpriseInvoiceRequestsSortPayload = {
  /** Configuração de ordenação para o ID do CNAE vinculado ao recurso */
  cnaeId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência da Solicitação de NF */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Tomador de Serviço vinculado ao recurso */
  serviceTakerId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor de serviço da Solicitação de NF */
  serviceValue?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o status da Solicitação de NF */
  status?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título da Solicitação de NF */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Payload de atualização de uma Nota Fiscal */
export type EnterpriseInvoiceUpdatePayload = {
  /** ID do CNAE associado */
  cnaeId?: InputMaybe<Scalars['UUID']['input']>
  /** Valor das Deduções (em centavos de reais) da Nota Fiscal */
  deductionsValue?: InputMaybe<Scalars['Int']['input']>
  /** Descrição da Nota Fiscal */
  description?: InputMaybe<Scalars['String']['input']>
  /** Arquivo da Nota Fiscal */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** ID da Solicitação de NF associada */
  invoiceRequestId?: InputMaybe<Scalars['UUID']['input']>
  /** Número da Nota Fiscal */
  number?: InputMaybe<Scalars['String']['input']>
  /** Data de referência da Nota Fiscal (considerar apenas mês e ano) */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** ID do Tomador de Serviço associado */
  serviceTakerId?: InputMaybe<Scalars['UUID']['input']>
  /** Valor de Serviço (em centavos de reais) da Nota Fiscal */
  serviceValue?: InputMaybe<Scalars['Int']['input']>
  /** Título da Nota Fiscal */
  title?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de Notas Fiscais */
export type EnterpriseInvoicesFilterPayload = {
  /** Filtros para o ID do CNAE */
  cnaeId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o valor de deduções da NF */
  deductionsValue?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da NF */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o número da NF */
  number?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseInvoicesFilterPayload>>
  /** Filtros para a referência da NF */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do Tomador de Serviço */
  serviceTakerId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o valor de serviço da NF */
  serviceValue?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o título da NF */
  title?: InputMaybe<StringFilterPayload>
}

/** Lista de Notas Fiscais e seus metadados de paginação */
export type EnterpriseInvoicesPaginatedList = {
  __typename?: 'EnterpriseInvoicesPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseInvoice>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Notas Fiscais */
export type EnterpriseInvoicesSortPayload = {
  /** Configuração de ordenação para o ID do CNAE vinculado ao recurso */
  cnaeId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor de deduções da NF */
  deductionsValue?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o número da NF */
  number?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência da NF */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Tomador de Serviço vinculado ao recurso */
  serviceTakerId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor de serviço da NF */
  serviceValue?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título da NF */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Resultado a inserção de Comunicados em massa para empresas */
export type EnterpriseMailboxMessagesResult = {
  __typename?: 'EnterpriseMailboxMessagesResult'
  /** Códigos das empresas que falharam na inserção */
  failure: Array<Scalars['Int']['output']>
  /** Códigos das empresas que tiveram sucesso na inserção */
  success: Array<Scalars['Int']['output']>
}

/** Um registro de Fechamento Mensal associado a uma empresa */
export type EnterpriseMonthlyConsolidation = {
  __typename?: 'EnterpriseMonthlyConsolidation'
  /** Detalhes do Fechamento */
  details?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Finalizado em */
  finishedAt?: Maybe<Scalars['Date']['output']>
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Referência */
  reference: Scalars['Date']['output']
  /** Responsável pela Venda */
  responsible?: Maybe<BackofficeEmployee>
  /** ID do Responsável */
  responsibleId?: Maybe<Scalars['UUID']['output']>
  /** Serviço associado ao fechamento */
  service: BusinessService
  /** Status do fechamento */
  status: EnterpriseMonthlyConsolidationStatus
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Fechamento Mensal */
export type EnterpriseMonthlyConsolidationInsertPayload = {
  /** Detalhes do Fechamento */
  details?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Finalizado em */
  finishedAt?: InputMaybe<Scalars['Date']['input']>
  /** Referência */
  reference: Scalars['Date']['input']
  /** ID do Responsável */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Serviço associado ao fechamento */
  service: BusinessService
  /** Status do fechamento */
  status: EnterpriseMonthlyConsolidationStatus
}

export enum EnterpriseMonthlyConsolidationStatus {
  /** Fechado sem movimento */
  FinishedWithoutTransactions = 'FINISHED_WITHOUT_TRANSACTIONS',
  /** Fechado com movimento */
  FinishedWithTransactions = 'FINISHED_WITH_TRANSACTIONS',
  /** Pendente */
  Pending = 'PENDING',
}

/** Payload para filtragem de campos do tipo EnterpriseMonthlyConsolidationStatus */
export type EnterpriseMonthlyConsolidationStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseMonthlyConsolidationStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseMonthlyConsolidationStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseMonthlyConsolidationStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseMonthlyConsolidationStatus>>>
}

/** Payload de atualização de um Fechamento Mensal */
export type EnterpriseMonthlyConsolidationUpdatePayload = {
  /** Detalhes do Fechamento */
  details?: InputMaybe<Scalars['String']['input']>
  /** Finalizado em */
  finishedAt?: InputMaybe<Scalars['Date']['input']>
  /** Referência */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** ID do Responsável */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Serviço associado ao fechamento */
  service?: InputMaybe<BusinessService>
  /** Status do fechamento */
  status?: InputMaybe<EnterpriseMonthlyConsolidationStatus>
}

/** Payload para filtragem de Fechamentos Mensais */
export type EnterpriseMonthlyConsolidationsFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de finalização do Fechamento */
  finishedAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do Fechamento */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do Fechamento */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseMonthlyConsolidationsFilterPayload>>
  /** Filtros para a data de referência do Fechamento */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do Responsável */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o Serviço do Fechamento */
  service?: InputMaybe<BusinessServiceFilterPayload>
  /** Filtros para o status do Fechamento */
  status?: InputMaybe<EnterpriseMonthlyConsolidationStatusFilterPayload>
}

/** Lista de Fechamentos Mensais e seus metadados de paginação */
export type EnterpriseMonthlyConsolidationsPaginatedList = {
  __typename?: 'EnterpriseMonthlyConsolidationsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseMonthlyConsolidation>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Fechamentos Mensais */
export type EnterpriseMonthlyConsolidationsSortPayload = {
  /** Configuração de ordenação para a data de finalização do Fechamento */
  finishedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o serviço do Fechamento */
  service?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do Fechamento */
  status?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro de Humor associado a uma empresa */
export type EnterpriseMood = {
  __typename?: 'EnterpriseMood'
  /** Detalhes do Humor */
  details?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nível do Humor */
  level: EnterpriseMoodLevel
  /** Motivo do Humor */
  reason?: Maybe<EnterpriseMoodReason>
  /** Registrado em */
  registeredAt: Scalars['Date']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Humor */
export type EnterpriseMoodInsertPayload = {
  /** Detalhes do Humor */
  details?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Nível do Humor */
  level: EnterpriseMoodLevel
  /** Motivo do Humor */
  reason?: InputMaybe<EnterpriseMoodReason>
  /** Registrado em */
  registeredAt: Scalars['Date']['input']
}

export enum EnterpriseMoodLevel {
  /** Alto */
  High = 'HIGH',
  /** Baixo */
  Low = 'LOW',
  /** Médio */
  Medium = 'MEDIUM',
  /** Muito Alto */
  VeryHigh = 'VERY_HIGH',
  /** Muito Baixo */
  VeryLow = 'VERY_LOW',
}

/** Payload para filtragem de campos do tipo EnterpriseMoodLevel */
export type EnterpriseMoodLevelFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseMoodLevel>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseMoodLevel>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseMoodLevel>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseMoodLevel>>>
}

export enum EnterpriseMoodReason {
  /** Problemas de Faturamento */
  BillingProblems = 'BILLING_PROBLEMS',
  /** Problemas de Atendimento Comercial */
  CommercialServiceProblems = 'COMMERCIAL_SERVICE_PROBLEMS',
  /** Problemas de Atendimento Customer Success */
  CustomerSuccessServiceProblems = 'CUSTOMER_SUCCESS_SERVICE_PROBLEMS',
  /** Problemas de Atendimento Financeiro */
  FinancialServiceProblems = 'FINANCIAL_SERVICE_PROBLEMS',
  /** Problemas de Atendimento Legalização */
  LegalizationServiceProblems = 'LEGALIZATION_SERVICE_PROBLEMS',
  /** Problemas de Licenças */
  LicenseProblems = 'LICENSE_PROBLEMS',
  /** Outros */
  Other = 'OTHER',
  /** Problemas de Desenquadramento */
  ReclassificationProblems = 'RECLASSIFICATION_PROBLEMS',
  /** Problemas de Regularização */
  RegularizationProblems = 'REGULARIZATION_PROBLEMS',
  /** Problemas de Guias de Impostos */
  TaxGuideProblemas = 'TAX_GUIDE_PROBLEMAS',
  /** Problemas de Atendimento Fiscal */
  TaxServiceProblems = 'TAX_SERVICE_PROBLEMS',
}

/** Payload para filtragem de campos do tipo EnterpriseMoodReason */
export type EnterpriseMoodReasonFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseMoodReason>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseMoodReason>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseMoodReason>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseMoodReason>>>
}

/** Payload para filtragem de Humores */
export type EnterpriseMoodsFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Humor */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do Humor */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nível do Humor */
  level?: InputMaybe<EnterpriseMoodLevelFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseMoodsFilterPayload>>
  /** Filtros para o motivo do Humor */
  reason?: InputMaybe<EnterpriseMoodReasonFilterPayload>
  /** Filtros para a data de registro do Humor */
  registeredAt?: InputMaybe<DateFilterPayload>
}

/** Lista de Humores e seus metadados de paginação */
export type EnterpriseMoodsPaginatedList = {
  __typename?: 'EnterpriseMoodsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseMood>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Humores */
export type EnterpriseMoodsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nível do Humor */
  level?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de registro do Humor */
  registeredAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboarding = {
  __typename?: 'EnterpriseOnboarding'
  /** Quais as Etapas que o Onboarding possui, independente de conclusão ou início */
  allSteps: Array<EnterpriseOnboardingStep>
  /** Lista de anexos associados ao Onboarding */
  attachments: EnterpriseOnboardingAttachmentsPaginatedList
  /** Lista de sócios associados ao Onboarding */
  businessPartners: EnterpriseOnboardingBusinessPartnersPaginatedList
  /** Código do Onboarding */
  code: Scalars['Int']['output']
  /** Comentários do Onboarding */
  comments: EnterpriseOnboardingCommentsPaginatedList
  /** Etapa atual do Onboarding */
  currentStep: EnterpriseOnboardingStep
  /** Índice (0-based) da Etapa atual do Onboarding */
  currentStepIndex: Scalars['Int']['output']
  /** Lista de certificados digitais associados ao Onboarding */
  digitalCertificates: DigitalCertificatesPaginatedList
  /** Informações de descarte do Onboarding */
  discardInfo?: Maybe<EnterpriseOnboardingDiscardInfo>
  /** Timestamp de descarte do Onboarding */
  discardedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Empresa associada ao Onboarding */
  enterprise?: Maybe<Enterprise>
  /** Contrato de Empresa associado ao Onboarding */
  enterpriseContract?: Maybe<Contract>
  /** Identificador do Contrato de Empresa associado ao Onboarding */
  enterpriseContractId?: Maybe<Scalars['UUID']['output']>
  /** Texto do Contrato de Empresa associado ao Onboarding */
  enterpriseContractText?: Maybe<Array<Maybe<ContractTextResponse>>>
  /** Metadados extras do Onboarding */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Timestamp de finalização do Onboarding */
  finishedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Log de alterações realizadas no Onboarding */
  log: AuditLogChangesPaginatedList
  /** Observações do Onboarding */
  observations?: Maybe<Scalars['String']['output']>
  /** Informações do Onboarding de Migração de Empresa (se for do tipo Migração de Empresa) */
  onboardingInfo?: Maybe<EnterpriseOnboardingInfo>
  /** Parceiro associado ao Onboarding */
  partner?: Maybe<PartnerOnboarding>
  /** Pendências do Onboarding */
  pendencies: EnterpriseOnboardingPendenciesPaginatedList
  /** Prioridade do Onboarding */
  priority: EnterpriseOnboardingPriority
  /** Responsável pelo Onboarding */
  responsible?: Maybe<BackofficeEmployee>
  /** Identificador do Responsável pelo Onboarding */
  responsibleId?: Maybe<Scalars['UUID']['output']>
  /** Venda associada ao Onboarding */
  sale: Sale
  /** Contrato de Venda associado ao Onboarding */
  saleContract?: Maybe<Contract>
  /** Identificador do Contrato de Venda associado ao Onboarding */
  saleContractId?: Maybe<Scalars['UUID']['output']>
  /** Texto do Contrato de Venda associado ao Onboarding */
  saleContractText?: Maybe<Array<Maybe<ContractTextResponse>>>
  /** Identificador da Venda associada ao Onboarding */
  saleId: Scalars['UUID']['output']
  /** Data de início dos serviços */
  servicesStartingAt?: Maybe<Scalars['Date']['output']>
  /** Timestamp de início do Onboarding */
  startedAt: Scalars['NaiveDateTime']['output']
  /** Histórico de Etapas que o Onboarding passou */
  stepHistories: Array<EnterpriseOnboardingStepHistory>
  /** Tipo do Onboarding */
  type: EnterpriseOnboardingType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado ao Onboarding */
  user: UserAccount
  /** Identificador do Usuário associado ao Onboarding */
  userId: Scalars['UUID']['output']
  /** Cartão que será utilizado no Onboarding */
  walletCard?: Maybe<WalletCard>
  /** Identificador do Cartão que será utilizado no Onboarding */
  walletCardId?: Maybe<Scalars['UUID']['output']>
  /** Timestamp da reunião de boas-vindas */
  welcomeMeetingAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Responsável pela reunião de boas-vindas */
  welcomeMeetingResponsible?: Maybe<BackofficeEmployee>
  /** Identificador do Responsável pela reunião de boas-vindas */
  welcomeMeetingResponsibleId?: Maybe<Scalars['UUID']['output']>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingAttachmentsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingAttachmentsFilterPayload>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingBusinessPartnersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingBusinessPartnersSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingBusinessPartnersFilterPayload>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingCommentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingCommentsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingCommentsFilterPayload>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingDigitalCertificatesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<DigitalCertificatesSortPayload>>>
  where?: InputMaybe<DigitalCertificatesFilterPayload>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Representação de um Onboarding de Empresa */
export type EnterpriseOnboardingPendenciesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingPendenciesSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingPendenciesFilterPayload>
}

/** Um registro de Anexo de Onboarding */
export type EnterpriseOnboardingAttachment = {
  __typename?: 'EnterpriseOnboardingAttachment'
  /** Usuário Backoffice que enviou o Anexo */
  backofficeUser?: Maybe<BackofficeEmployee>
  /** Identificador do Usuário Backoffice que enviou o Anexo */
  backofficeUserId?: Maybe<Scalars['UUID']['output']>
  /** Descrição do Anexo */
  description?: Maybe<Scalars['String']['output']>
  /** Onboarding associado ao Anexo */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Identificador do Onboarding */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Informações extras do Anexo */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Arquivo do Anexo */
  file: Scalars['String']['output']
  /** Identificador único da assinatura */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Visibilidade do Anexo para o usuário cliente */
  isVisibleToUser: Scalars['Boolean']['output']
  /** Título do Anexo */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo do Anexo */
  type: EnterpriseOnboardingAttachmentType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário que enviou o Anexo */
  user?: Maybe<UserAccount>
  /** Identificador do Usuário que enviou o Anexo */
  userId?: Maybe<Scalars['UUID']['output']>
}

/** Payload para inserção de Anexos de Onboarding */
export type EnterpriseOnboardingAttachmentInsertPayload = {
  /** Descrição do Anexo */
  description?: InputMaybe<Scalars['String']['input']>
  /** Identificador do Onboarding */
  enterpriseOnboardingId: Scalars['UUID']['input']
  /** Arquivo do Anexo */
  file: Scalars['Upload']['input']
  /** Visibilidade do Anexo para o usuário cliente */
  isVisibleToUser?: InputMaybe<Scalars['Boolean']['input']>
  /** Título do Anexo */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Anexo */
  type: EnterpriseOnboardingAttachmentType
}

export enum EnterpriseOnboardingAttachmentType {
  /** Inscrição Municipal */
  CityInscription = 'CITY_INSCRIPTION',
  /** Documento da Empresa */
  Enterprise = 'ENTERPRISE',
  /** Outro */
  Other = 'OTHER',
  /** Contrato Social */
  SocialContract = 'SOCIAL_CONTRACT',
  /** Inscrição Estadual */
  StateInscription = 'STATE_INSCRIPTION',
}

/** Payload para filtragem de campos do tipo EnterpriseOnboardingAttachmentType */
export type EnterpriseOnboardingAttachmentTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseOnboardingAttachmentType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingAttachmentType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseOnboardingAttachmentType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingAttachmentType>>>
}

/** Payload para filtragem de Anexos de Onboarding */
export type EnterpriseOnboardingAttachmentsFilterPayload = {
  /** Filtro por identificador do Usuário Backoffice que enviou o Anexo */
  backofficeUserId?: InputMaybe<UuidFilterPayload>
  /** Filtro por identificador do Onboarding */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtro por visibilidade do Anexo para o usuário cliente */
  isVisibleToUser?: InputMaybe<BooleanFilterPayload>
  /** Filtro por tipo de Anexo */
  type?: InputMaybe<EnterpriseOnboardingAttachmentTypeFilterPayload>
  /** Filtro por identificador do Usuário que enviou o Anexo */
  userId?: InputMaybe<UuidFilterPayload>
}

/** Lista de onboardings e seus metadados de paginação */
export type EnterpriseOnboardingAttachmentsPaginatedList = {
  __typename?: 'EnterpriseOnboardingAttachmentsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseOnboardingAttachment>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Anexos de Onboarding */
export type EnterpriseOnboardingAttachmentsSortPayload = {
  /** Ordenação por identificador do Usuário Backoffice que enviou o Anexo */
  backofficeUserId?: InputMaybe<SortDirection>
  /** Ordenação por identificador do Onboarding */
  enterpriseOnboardingId?: InputMaybe<SortDirection>
  /** Ordenação por ID */
  id?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de criação */
  insertedAt?: InputMaybe<SortDirection>
  /** Ordenação por visibilidade do Anexo para o usuário cliente */
  isVisibleToUser?: InputMaybe<SortDirection>
  /** Ordenação por tipo de Anexo */
  type?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de atualização */
  updatedAt?: InputMaybe<SortDirection>
  /** Ordenação por identificador do Usuário que enviou o Anexo */
  userId?: InputMaybe<SortDirection>
}

/** Representação de um Sócio no Onboarding */
export type EnterpriseOnboardingBusinessPartner = {
  __typename?: 'EnterpriseOnboardingBusinessPartner'
  /** Endereço do Sócio */
  address: Address
  /** Identificador do Endereço associado ao Sócio */
  addressId: Scalars['UUID']['output']
  /** Data de nascimento do Sócio */
  birthdate: Scalars['Date']['output']
  /** Local de nascimento do Sócio */
  birthplace: Scalars['String']['output']
  /** CPF do Sócio */
  cpf: Scalars['CPF']['output']
  /** Certificado Digital (e-CPF) do Sócio */
  digitalCertificate?: Maybe<DigitalCertificate>
  /** Identificador do Certificado Digital (e-CPF) do Sócio */
  digitalCertificateId?: Maybe<Scalars['UUID']['output']>
  /** E-mail do Sócio */
  email: Scalars['String']['output']
  /** Onboarding associado ao Sócio */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Identificador do Onboarding associado ao Sócio */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Nome do pai do Sócio */
  fatherName: Scalars['String']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Documento de identificação do Sócio (verso) */
  identificationBackFile?: Maybe<Scalars['String']['output']>
  /** Documento de identificação do Sócio (frente) */
  identificationFrontFile?: Maybe<Scalars['String']['output']>
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Estado civil do Sócio */
  maritalStatus: EnterpriseOnboardingBusinessPartnerMaritalStatus
  /** Regime de bens do Sócio */
  matrimonialRegime?: Maybe<EnterpriseOnboardingBusinessPartnerMatrimonialRegime>
  /** Nome da mãe do Sócio */
  motherName: Scalars['String']['output']
  /** Nome do Sócio */
  name: Scalars['String']['output']
  /** Profissão do Sócio */
  occupation: Scalars['String']['output']
  /** Telefone do Sócio */
  phone: Scalars['Phone']['output']
  /** Comprovante de endereço do Sócio */
  proofOfAddressFile?: Maybe<Scalars['String']['output']>
  /** RG do Sócio */
  rg: Scalars['String']['output']
  /** Data de emissão do RG do Sócio */
  rgEmittedAt: Scalars['Date']['output']
  /** Órgão emissor do RG do Sócio */
  rgIssuer: Scalars['String']['output']
  /** Estado emissor do RG do Sócio */
  rgIssuerState: AddressState
  /** Participação do Sócio no capital social */
  shareCapitalParticipation: Scalars['Int']['output']
  /** Número do recibo da declaração do imposto de renda do Sócio */
  statementReceiptNumber?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Número do título de eleitor do Sócio */
  voterRegistration: Scalars['VoterRegistration']['output']
}

/** Payload de inserção de um novo Certificado Digital e-CPF para o Sócio */
export type EnterpriseOnboardingBusinessPartnerEcpfInsertPayload = {
  /** Data de expiração do Certificado Digital */
  expiryAt?: InputMaybe<Scalars['Date']['input']>
  /** Arquivo do Certificado Digital */
  file: Scalars['Upload']['input']
  /** Senha do Certificado Digital */
  password: Scalars['String']['input']
  /** Título do Certificado Digital */
  title?: InputMaybe<Scalars['String']['input']>
}

export enum EnterpriseOnboardingBusinessPartnerMaritalStatus {
  /** Divorciado(a) */
  Divorced = 'DIVORCED',
  /** Separado(a) Judicialmente */
  LegallySeparated = 'LEGALLY_SEPARATED',
  /** Casado(a) */
  Married = 'MARRIED',
  /** Outro */
  Other = 'OTHER',
  /** Solteiro(a) */
  Single = 'SINGLE',
  /** União Estável */
  StableUnion = 'STABLE_UNION',
  /** Viúvo(a) */
  Widower = 'WIDOWER',
}

export enum EnterpriseOnboardingBusinessPartnerMatrimonialRegime {
  /** Comunhão Parcial de Bens */
  CommunityProperty = 'COMMUNITY_PROPERTY',
  /** Participação Final nos Aquestos */
  FinalAcquisition = 'FINAL_ACQUISITION',
  /** Separação Obrigatória de Bens */
  MandatorySeparationOfProperty = 'MANDATORY_SEPARATION_OF_PROPERTY',
  /** Outro */
  Other = 'OTHER',
  /** Separação Total de Bens */
  SeparationOfProperty = 'SEPARATION_OF_PROPERTY',
  /** Comunhão Universal de Bens */
  TotalCommunityProperty = 'TOTAL_COMMUNITY_PROPERTY',
}

/** Payload para inserção ou atualização de Sócios no Onboarding */
export type EnterpriseOnboardingBusinessPartnerUpsertPayload = {
  /** Endereço associado ao Sócio */
  address: AddressInsertPayload
  /** Data de nascimento do Sócio */
  birthdate: Scalars['Date']['input']
  /** Local de nascimento do Sócio */
  birthplace: Scalars['String']['input']
  /** CPF do Sócio */
  cpf: Scalars['CPF']['input']
  /** E-mail do Sócio */
  email: Scalars['String']['input']
  /** Identificador do Onboarding associado ao Sócio */
  enterpriseOnboardingId: Scalars['UUID']['input']
  /** Nome do pai do Sócio */
  fatherName: Scalars['String']['input']
  /** Documento de identificação do Sócio (verso) */
  identificationBackFile?: InputMaybe<Scalars['Upload']['input']>
  /** Documento de identificação do Sócio (frente) */
  identificationFrontFile?: InputMaybe<Scalars['Upload']['input']>
  /** Estado civil do Sócio */
  maritalStatus: EnterpriseOnboardingBusinessPartnerMaritalStatus
  /** Regime de bens do Sócio */
  matrimonialRegime?: InputMaybe<EnterpriseOnboardingBusinessPartnerMatrimonialRegime>
  /** Nome da mãe do Sócio */
  motherName: Scalars['String']['input']
  /** Nome do Sócio */
  name: Scalars['String']['input']
  /** Profissão do Sócio */
  occupation: Scalars['String']['input']
  /** Telefone do Sócio */
  phone: Scalars['Phone']['input']
  /** Comprovante de endereço do Sócio */
  proofOfAddressFile?: InputMaybe<Scalars['Upload']['input']>
  /** RG do Sócio */
  rg: Scalars['String']['input']
  /** Data de emissão do RG do Sócio */
  rgEmittedAt: Scalars['Date']['input']
  /** Órgão emissor do RG do Sócio */
  rgIssuer: Scalars['String']['input']
  /** Estado emissor do RG do Sócio */
  rgIssuerState: AddressState
  /** Participação do Sócio no capital social */
  shareCapitalParticipation: Scalars['Int']['input']
  /** Número do recibo da declaração do imposto de renda do Sócio */
  statementReceiptNumber?: InputMaybe<Scalars['String']['input']>
  /** Número do título de eleitor do Sócio */
  voterRegistration: Scalars['VoterRegistration']['input']
}

/** Payload para filtragem de Sócios no Onboarding */
export type EnterpriseOnboardingBusinessPartnersFilterPayload = {
  /** Filtros para o CPF do Sócio */
  cpf?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Onboarding */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Sócio */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseOnboardingBusinessPartnersFilterPayload>>
  /** Filtros para a data de última atualização do recurso */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
}

/** Lista de Sócios de Onboardings e seus metadados de paginação */
export type EnterpriseOnboardingBusinessPartnersPaginatedList = {
  __typename?: 'EnterpriseOnboardingBusinessPartnersPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseOnboardingBusinessPartner>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Sócios no Onboarding */
export type EnterpriseOnboardingBusinessPartnersSortPayload = {
  /** Ordenação por ID do Onboarding */
  enterpriseOnboardingId?: InputMaybe<SortDirection>
  /** Ordenação por ID */
  id?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de criação */
  insertedAt?: InputMaybe<SortDirection>
  /** Ordenação por nome */
  name?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de atualização */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro de Comentário do Onboarding */
export type EnterpriseOnboardingComment = {
  __typename?: 'EnterpriseOnboardingComment'
  /** Usuário de Backoffice associado */
  backofficeUser?: Maybe<BackofficeEmployee>
  /** Identificador do Usuário de Backoffice associado */
  backofficeUserId?: Maybe<Scalars['UUID']['output']>
  /** Conteúdo do comentário */
  content: Scalars['String']['output']
  /** Onboarding associado */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Identificador do Onboarding associado */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Comentário do Onboarding */
export type EnterpriseOnboardingCommentInsertPayload = {
  /** Conteúdo do Comentário do Onboarding */
  content: Scalars['String']['input']
  /** Identificador do Onboarding associado */
  enterpriseOnboardingId: Scalars['UUID']['input']
}

/** Payload para filtragem de Comentários do Onboarding */
export type EnterpriseOnboardingCommentsFilterPayload = {
  /** Filtros para o ID do Usuário de Backoffice */
  backofficeUserId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Onboarding */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Comentário do Onboarding */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de insercão do Comentário do Onboarding */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseOnboardingCommentsFilterPayload>>
}

/** Lista de Comentários do Onboarding e seus metadados de paginação */
export type EnterpriseOnboardingCommentsPaginatedList = {
  __typename?: 'EnterpriseOnboardingCommentsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseOnboardingComment>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Comentários do Onboarding */
export type EnterpriseOnboardingCommentsSortPayload = {
  /** Configuração de ordenação para o ID do Usuário de Backoffice vinculado ao recurso */
  backofficeUserId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Onboarding vinculado ao recurso */
  enterpriseOnboardingId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
}

/** Tipo de Contrato do Onboarding */
export enum EnterpriseOnboardingContractType {
  /** Contrato de Empresa */
  Enterprise = 'ENTERPRISE',
  /** Contrato de Venda */
  Sale = 'SALE',
}

/** Representação das informações acerca do descarte de um Onboarding */
export type EnterpriseOnboardingDiscardInfo = {
  __typename?: 'EnterpriseOnboardingDiscardInfo'
  /** Detalhes acerca do descarte do Onboarding */
  details?: Maybe<Scalars['String']['output']>
  /** Motivo de descarte do Onboarding */
  reason: EnterpriseOnboardingDiscardReason
}

export enum EnterpriseOnboardingDiscardReason {
  /** Desistência */
  Abandonment = 'ABANDONMENT',
  /** Desqualificado */
  Disqualified = 'DISQUALIFIED',
  /** Contato inválido */
  InvalidContact = 'INVALID_CONTACT',
  /** Sem resposta */
  NoResponse = 'NO_RESPONSE',
  /** Outro */
  Other = 'OTHER',
}

/** The onboarding info for its specific type */
export type EnterpriseOnboardingInfo = EnterpriseOnboardingMigrationInfo | EnterpriseOnboardingOpeningInfo

/** Payload para inserçao ou atualização de dados da contabilidade atual no Onboarding de Migração */
export type EnterpriseOnboardingMigrationAccountingDataUpsertPayload = {
  /** E-mail da contabilidade atual */
  accountingEmail?: InputMaybe<Scalars['String']['input']>
  /** Nome da contabilidade atual */
  accountingName?: InputMaybe<Scalars['String']['input']>
  /** Telefone da contabilidade atual */
  accountingPhone?: InputMaybe<Scalars['Phone']['input']>
  /** Nome do contato responsável na contabilidade atual */
  accountingResponsibleName?: InputMaybe<Scalars['String']['input']>
  /** Número do CRC */
  crcNumber?: InputMaybe<Scalars['String']['input']>
  /** Indica se a Empresa não possui contabilidade atualmente */
  noAccounting: Scalars['Boolean']['input']
}

/** Payload para inserção ou atualização de dados e documentos no Onboarding de Migração */
export type EnterpriseOnboardingMigrationDataAndDocumentsUpsertPayload = {
  /** Senha de acesso da Prefeitura */
  cityHallPassword?: InputMaybe<Scalars['String']['input']>
  /** Inscrição Municipal da Empresa */
  cityInscriptionFile?: InputMaybe<Scalars['Upload']['input']>
  /** Arquivo do certificado digital da Empresa */
  digitalCertificateFile?: InputMaybe<Scalars['Upload']['input']>
  /** Senha do certificado digital da Empresa */
  digitalCertificatePassword?: InputMaybe<Scalars['String']['input']>
  /** Senha GovBr */
  govbrPassword?: InputMaybe<Scalars['String']['input']>
  /** Senha do Simples Nacional */
  simplesNacionalPassword?: InputMaybe<Scalars['String']['input']>
  /** Contrato Social da Empresa */
  socialContractFile?: InputMaybe<Scalars['Upload']['input']>
  /** Inscrição Estadual da Empresa */
  stateInscriptionFile?: InputMaybe<Scalars['Upload']['input']>
  /** Senha do Posto Fiscal Eletrônico */
  taxOfficePassword?: InputMaybe<Scalars['String']['input']>
}

/** Representação das informações do Onboarding de Migração */
export type EnterpriseOnboardingMigrationInfo = {
  __typename?: 'EnterpriseOnboardingMigrationInfo'
  /** Timestamp de recebimento de dados da contabilidade atual */
  accountingDataReceivedAt?: Maybe<Scalars['Date']['output']>
  /** Timestamp de solicitação de dados da contabilidade atual */
  accountingDataRequestedAt?: Maybe<Scalars['Date']['output']>
  /** E-mail da contabilidade atual */
  accountingEmail?: Maybe<Scalars['String']['output']>
  /** Nome da contabilidade atual */
  accountingName?: Maybe<Scalars['String']['output']>
  /** Telefone da contabilidade atual */
  accountingPhone?: Maybe<Scalars['Phone']['output']>
  /** Nome do contato responsável na contabilidade atual */
  accountingResponsibleName?: Maybe<Scalars['String']['output']>
  /** Senha de acesso da Prefeitura */
  cityHallPassword?: Maybe<Scalars['String']['output']>
  /** Inscrição Municipal da Empresa */
  cityInscriptionFile?: Maybe<Scalars['String']['output']>
  /** Número do CRC */
  crcNumber?: Maybe<Scalars['String']['output']>
  /** Detalhes da Empresa recebidos da contabilidade atual */
  details?: Maybe<Scalars['String']['output']>
  /** Dados do certificado digital da Empresa */
  digitalCertificate?: Maybe<DigitalCertificate>
  /** Onboarding associado */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Identificador do Onboarding associado */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Metadados extras do Onboarding de Migração */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Senha GovBr */
  govbrPassword?: Maybe<Scalars['String']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Indica se a Empresa não possui contabilidade atualmente */
  noAccounting?: Maybe<Scalars['Boolean']['output']>
  /** Senha do Simples Nacional */
  simplesNacionalPassword?: Maybe<Scalars['String']['output']>
  /** Contrato Social da Empresa */
  socialContractFile?: Maybe<Scalars['String']['output']>
  /** Inscrição Estadual da Empresa */
  stateInscriptionFile?: Maybe<Scalars['String']['output']>
  /** Senha do Posto Fiscal Eletrônico */
  taxOfficePassword?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para inserção ou atualização de dados e documentos da Empresa no Onboarding de Abertura */
export type EnterpriseOnboardingOpeningDataAndDocumentsUpsertPayload = {
  /** Atividades da Empresa */
  enterpriseActivities: Scalars['String']['input']
  /** Endereço da Empresa */
  enterpriseAddress: AddressInsertPayload
  /** Nome Fantasia da Empresa */
  enterpriseCommercialName: Scalars['String']['input']
  /** Arquivo do IPTU do Imóvel da Empresa */
  enterpriseIptuFile?: InputMaybe<Scalars['Upload']['input']>
  /** Número do IPTU do Imóvel da Empresa */
  enterpriseIptuNumber?: InputMaybe<Scalars['String']['input']>
  /** Representante Legal da Empresa */
  enterpriseLegalRepresentativeName: Scalars['String']['input']
  /** Razão Social da Empresa */
  enterpriseName: Scalars['String']['input']
  /** Capital Social da Empresa */
  enterpriseShareCapital: Scalars['Int']['input']
}

/** Payload para inserção ou atualização de dados de emissão de e-CPF no Onboarding de Abertura */
export type EnterpriseOnboardingOpeningEcpfDataUpsertPayload = {
  /** Define se todos os sócios possuem e-CPF */
  allPartnersHaveEcpf: Scalars['Boolean']['input']
  /** Lista de e-CPF de sócios */
  ecpfs?: InputMaybe<Array<BusinessPartnerEcpfInsertPayload>>
  /** Senha do gov.br */
  govbrPassword?: InputMaybe<Scalars['String']['input']>
  /** Define se o contrato social será assinado pelos sócios */
  isSocialContractSignedByPartner: Scalars['Boolean']['input']
}

/** Representação das informações do Onboarding de Abertura */
export type EnterpriseOnboardingOpeningInfo = {
  __typename?: 'EnterpriseOnboardingOpeningInfo'
  /** Define se todos os sócios possuem e-CPF */
  allPartnersHaveEcpf?: Maybe<Scalars['Boolean']['output']>
  /** Data de análise de viabilidade */
  analyzedAt?: Maybe<Scalars['Date']['output']>
  /** Data de registro do CNPJ */
  cnpjRegisteredAt?: Maybe<Scalars['Date']['output']>
  /** Data de pedido do DBE */
  dbeRequestedAt?: Maybe<Scalars['Date']['output']>
  /** Atividades da Empresa */
  enterpriseActivities?: Maybe<Scalars['String']['output']>
  /** Endereço da Empresa */
  enterpriseAddress?: Maybe<Address>
  /** Nome Fantasia da Empresa */
  enterpriseCommercialName?: Maybe<Scalars['String']['output']>
  /** Informações detalhadas da Empresa */
  enterpriseInfo?: Maybe<EnterpriseInfo>
  /** Arquivo do IPTU do Imóvel da Empresa */
  enterpriseIptuFile?: Maybe<Scalars['String']['output']>
  /** Número do IPTU do Imóvel da Empresa */
  enterpriseIptuNumber?: Maybe<Scalars['String']['output']>
  /** Representante Legal da Empresa */
  enterpriseLegalRepresentativeName?: Maybe<Scalars['String']['output']>
  /** Razão Social da Empresa */
  enterpriseName?: Maybe<Scalars['String']['output']>
  /** Onboarding associado */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Identificador do Onboarding associado */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Capital Social da Empresa */
  enterpriseShareCapital?: Maybe<Scalars['Int']['output']>
  /** Metadados extras do Onboarding de Abertura */
  extraInfo?: Maybe<Scalars['Json']['output']>
  /** Senha do GovBr */
  govbrPassword?: Maybe<Scalars['String']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Define se a Inscrição Estadual é obrigatória */
  isMandatoryStateInscription?: Maybe<Scalars['Boolean']['output']>
  /** Define se o contrato social será assinado pelos sócios */
  isSocialContractSignedByPartner?: Maybe<Scalars['Boolean']['output']>
  /** Lista de alvarás obrigatórios */
  mandatoryBusinessPermits?: Maybe<Array<Scalars['String']['output']>>
  /** Lista de certificados obrigatórios */
  mandatoryCertificates?: Maybe<Array<Scalars['String']['output']>>
  /** Timestamp de Contrato Social confirmado */
  socialContractConfirmedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Arquivo do Contrato Social */
  socialContractFile?: Maybe<Scalars['String']['output']>
  /** Data de registro do Contrato Social */
  socialContractRegisteredAt?: Maybe<Scalars['Date']['output']>
  /** URL de assinatura do Contrato Social */
  socialContractUrl?: Maybe<Scalars['String']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para atualização de dados de abertura de CNPJ no Onboarding de Abertura */
export type EnterpriseOnboardingOpeningInfoUpdatePayload = {
  /** Data de análise de viabilidade */
  analyzedAt?: InputMaybe<Scalars['Date']['input']>
  /** Data de registro do CNPJ */
  cnpjRegisteredAt?: InputMaybe<Scalars['Date']['input']>
  /** Data de pedido do DBE */
  dbeRequestedAt?: InputMaybe<Scalars['Date']['input']>
  /** Informações detalhadas da Empresa */
  enterpriseInfo?: InputMaybe<EnterpriseInfoPayload>
  /** Metadados extras do Onboarding de Abertura */
  extraInfo?: InputMaybe<Scalars['Json']['input']>
  /** Define se a Inscrição Estadual é obrigatória */
  isMandatoryStateInscription?: InputMaybe<Scalars['Boolean']['input']>
  /** Lista de alvarás obrigatórios */
  mandatoryBusinessPermits?: InputMaybe<Array<Scalars['String']['input']>>
  /** Lista de certificados obrigatórios */
  mandatoryCertificates?: InputMaybe<Array<Scalars['String']['input']>>
  /** Arquivo do Contrato Social */
  socialContractFile?: InputMaybe<Scalars['Upload']['input']>
  /** Data de registro do Contrato Social */
  socialContractRegisteredAt?: InputMaybe<Scalars['Date']['input']>
  /** URL de assinatura do Contrato Social */
  socialContractUrl?: InputMaybe<Scalars['String']['input']>
}

/** Payload de inserção de uma nova Cobrança Pontual para um onboarding */
export type EnterpriseOnboardingOrderInsertPayload = {
  /** Identificador do Onboarding associado a Cobrança Pontual */
  enterpriseOnboardingId: Scalars['UUID']['input']
  /** Método de pagamento da Cobrança Pontual */
  paymentMethod: OrderPaymentMethod
  /** Dados da Venda associada a Cobrança Pontual */
  sale: SaleInsertPayload
  /** Itens de Serviço da Cobrança Pontual */
  services: Array<OrderServiceItemInsertPayload>
}

/** Payload de filtro de Pendências do Onboarding */
export type EnterpriseOnboardingPendenciesFilterPayload = {
  /** Filtros para a data fechamento da Pendência da Pendência */
  closedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do Onboarding associada à Pendência */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Pendência */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação da Pendência da Pendência */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseOnboardingPendenciesFilterPayload>>
  /** Filtros para a data de atualização da Pendência da Pendência */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
}

/** Lista de Pendências e seus metadados de paginação */
export type EnterpriseOnboardingPendenciesPaginatedList = {
  __typename?: 'EnterpriseOnboardingPendenciesPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseOnboardingPendency>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Pendências do Onboarding */
export type EnterpriseOnboardingPendenciesSortPayload = {
  /** Configuração de ordenação para a data de fechamento da Pendência da Pendência */
  closedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro da Pendência do Onboarding */
export type EnterpriseOnboardingPendency = {
  __typename?: 'EnterpriseOnboardingPendency'
  /** Timestamp de fechamento da Pendência */
  closedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Declaração de IRPF associada à Pendência */
  enterpriseOnboarding: EnterpriseOnboarding
  /** ID do Onboarding associada à Pendência */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Mensagem da Pendência */
  message: Scalars['String']['output']
  /** Etapa vinculada do Onboarding no cadasto da Pendência */
  step?: Maybe<EnterpriseOnboardingStep>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de Pendências do Onboarding */
export type EnterpriseOnboardingPendencyInsertPayload = {
  /** ID do Onboarding associada à Pendência */
  enterpriseOnboardingId: Scalars['UUID']['input']
  /** Mensagem da Pendência do Onboarding */
  message: Scalars['String']['input']
}

/** Payload de atualização de Pendências do Onboarding */
export type EnterpriseOnboardingPendencyUpdatePayload = {
  /** Mensagem da Pendência do Onboarding */
  message: Scalars['String']['input']
}

export enum EnterpriseOnboardingPriority {
  /** Alta */
  High = 'HIGH',
  /** Baixa */
  Low = 'LOW',
  /** Normal */
  Normal = 'NORMAL',
  /** Urgente */
  Urgent = 'URGENT',
}

/** Payload para filtragem de campos do tipo EnterpriseOnboardingPriority */
export type EnterpriseOnboardingPriorityFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseOnboardingPriority>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingPriority>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseOnboardingPriority>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingPriority>>>
}

export enum EnterpriseOnboardingStep {
  /** Cadastros Municipais */
  CityRegistration = 'CITY_REGISTRATION',
  /** Dados da Contabilidade Atual */
  CurrentAccountingData = 'CURRENT_ACCOUNTING_DATA',
  /** Envio de Dados e Documentos */
  DataAndDocuments = 'DATA_AND_DOCUMENTS',
  /** Emissão do e-CNPJ */
  Ecnpj = 'ECNPJ',
  /** Emissão do e-CPF */
  Ecpf = 'ECPF',
  /** Contrato Empresarial */
  EnterpriseContract = 'ENTERPRISE_CONTRACT',
  /** Abertura do CNPJ */
  OpeningCnpj = 'OPENING_CNPJ',
  /** Contrato de Venda */
  SaleContract = 'SALE_CONTRACT',
  /** Opção pelo Simples Nacional */
  SimplesNacional = 'SIMPLES_NACIONAL',
  /** Cadastros Estaduais */
  StateRegistration = 'STATE_REGISTRATION',
  /** Cadastro do Cartão de Crédito */
  WalletCardRegistration = 'WALLET_CARD_REGISTRATION',
}

/** Payload para filtragem de campos do tipo EnterpriseOnboardingStep */
export type EnterpriseOnboardingStepFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseOnboardingStep>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingStep>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseOnboardingStep>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingStep>>>
}

/** Representação de uma Etapa no Histórico do Onboarding */
export type EnterpriseOnboardingStepHistory = {
  __typename?: 'EnterpriseOnboardingStepHistory'
  /** Timestamp de finalização da Etapa */
  finishedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Timestamp de início da Etapa */
  startedAt: Scalars['NaiveDateTime']['output']
  /** Etapa */
  step: EnterpriseOnboardingStep
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

export enum EnterpriseOnboardingType {
  /** Migração de Empresa */
  EnterpriseMigration = 'ENTERPRISE_MIGRATION',
  /** Abertura de Empresa */
  EnterpriseOpening = 'ENTERPRISE_OPENING',
  /** Outro */
  Other = 'OTHER',
}

/** Payload para filtragem de campos do tipo EnterpriseOnboardingType */
export type EnterpriseOnboardingTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseOnboardingType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseOnboardingType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingType>>>
}

/** Payload para atualização de dados básicos do Onboarding */
export type EnterpriseOnboardingUpdatePayload = {
  /** Metadados extras do Onboarding */
  extraInfo?: InputMaybe<Scalars['Json']['input']>
  /** Observações do Onboarding */
  observations?: InputMaybe<Scalars['String']['input']>
  /** Prioridade do Onboarding */
  priority?: InputMaybe<EnterpriseOnboardingPriority>
  /** Identificador do Responsável pelo Onboarding */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Data de início dos serviços */
  servicesStartingAt?: InputMaybe<Scalars['Date']['input']>
  /** Tipo do Onboarding */
  type?: InputMaybe<EnterpriseOnboardingType>
  /** Timestamp da reunião de boas-vindas */
  welcomeMeetingAt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Identificador do Responsável pela reunião de boas-vindas */
  welcomeMeetingResponsibleId?: InputMaybe<Scalars['UUID']['input']>
}

/** Payload para filtragem de Onboardings de Empresa */
export type EnterpriseOnboardingsFilterPayload = {
  /** Filtros para o código do Onboarding */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para a etapa atual do Onboarding */
  currentStep?: InputMaybe<EnterpriseOnboardingStepFilterPayload>
  /** Filtros para o timestamp de descarte do Onboarding */
  discardedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o timestamp de conclusão do Onboarding */
  finishedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do Onboarding */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o timestamp de criação do Onboarding */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseOnboardingsFilterPayload>>
  /** Filtros para a prioridade do Onboarding */
  priority?: InputMaybe<EnterpriseOnboardingPriorityFilterPayload>
  /** Filtros para o ID do Responsável */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Venda */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de início dos serviços */
  servicesStartingAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o timestamp de início do Onboarding */
  startedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o tipo do Onboarding */
  type?: InputMaybe<EnterpriseOnboardingTypeFilterPayload>
  /** Filtros para o timestamp de atualização do Onboarding */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do Usuário */
  userId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Cartão */
  walletCardId?: InputMaybe<UuidFilterPayload>
}

/** Lista de onboardings e seus metadados de paginação */
export type EnterpriseOnboardingsPaginatedList = {
  __typename?: 'EnterpriseOnboardingsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseOnboarding>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Onboardings de Empresa */
export type EnterpriseOnboardingsSortPayload = {
  /** Ordenação por código */
  code?: InputMaybe<SortDirection>
  /** Ordenação por etapa atual */
  currentStep?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de descarte */
  discardedAt?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de conclusão */
  finishedAt?: InputMaybe<SortDirection>
  /** Ordenação por ID */
  id?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de criação */
  insertedAt?: InputMaybe<SortDirection>
  /** Ordenação por prioridade */
  priority?: InputMaybe<SortDirection>
  /** Ordenação por ID do Responsável */
  responsibleId?: InputMaybe<SortDirection>
  /** Ordenação por ID da Venda */
  saleId?: InputMaybe<SortDirection>
  /** Ordenação por data de início dos serviços */
  servicesStartingAt?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de início */
  startedAt?: InputMaybe<SortDirection>
  /** Ordenação por tipo */
  type?: InputMaybe<SortDirection>
  /** Ordenação por timestamp de atualização */
  updatedAt?: InputMaybe<SortDirection>
  /** Ordenação por ID do Usuário */
  userId?: InputMaybe<SortDirection>
  /** Ordenação por ID do Cartão */
  walletCardId?: InputMaybe<SortDirection>
}

/** Um registro de Relatório associado a uma empresa */
export type EnterpriseReport = {
  __typename?: 'EnterpriseReport'
  /** Descrição do Relatório */
  description?: Maybe<Scalars['String']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo do Relatório */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Data de referência do Relatório (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Título do Relatório */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo do Relatório */
  type: EnterpriseReportType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma novo Relatório */
export type EnterpriseReportInsertPayload = {
  /** Descrição do Relatório */
  description?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Arquivo do Relatório */
  file: Scalars['Upload']['input']
  /** Data de referência do Relatório (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** Título do Relatório */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Relatório */
  type: EnterpriseReportType
}

export enum EnterpriseReportType {
  /** Demonstrações Contábeis */
  AccountingStatement = 'ACCOUNTING_STATEMENT',
  /** Documentação de Admissão */
  AdmissionDocument = 'ADMISSION_DOCUMENT',
  /** Balanço */
  BalanceSheet = 'BALANCE_SHEET',
  /** Certidão Negativa de Débitos Municipal */
  CndCity = 'CND_CITY',
  /** Certidão Negativa de Débitos Federal */
  CndCountry = 'CND_COUNTRY',
  /** Certidão Negativa de Débitos FGTS */
  CndFgts = 'CND_FGTS',
  /** Certidão Negativa de Débitos Estadual */
  CndState = 'CND_STATE',
  /** Documentação Contratual */
  ContractualDocument = 'CONTRACTUAL_DOCUMENT',
  /** Documentação de Demissão */
  DismissalDocument = 'DISMISSAL_DOCUMENT',
  /** Demonstração do Resultado do Exercício */
  Dre = 'DRE',
  /** Recibo de Honorários Contábeis */
  EnrollmentInvoice = 'ENROLLMENT_INVOICE',
  /** Extrato do Simples Nacional */
  ExtractSn = 'EXTRACT_SN',
  /** Relatório Fiscal */
  FiscalReport = 'FISCAL_REPORT',
  /** Informe de Rendimentos */
  IncomeReport = 'INCOME_REPORT',
  /** IRPJ */
  Irpj = 'IRPJ',
  /** Outro */
  Other = 'OTHER',
  /** Folha de Pagamento */
  Payroll = 'PAYROLL',
  /** Folha de Pró-Labore */
  Prolabor = 'PROLABOR',
  /** Documentação de Recisão */
  TerminationDocument = 'TERMINATION_DOCUMENT',
  /** Balancete */
  TrialBalance = 'TRIAL_BALANCE',
  /** Férias */
  Vacation = 'VACATION',
  /** Aviso de Férias */
  VacationNotice = 'VACATION_NOTICE',
  /** Aviso Trabalhado */
  WorkedNotice = 'WORKED_NOTICE',
}

/** Payload para filtragem de campos do tipo EnterpriseReportType */
export type EnterpriseReportTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseReportType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseReportType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseReportType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseReportType>>>
}

/** Payload de atualização de um Relatório */
export type EnterpriseReportUpdatePayload = {
  /** Descrição do Relatório */
  description?: InputMaybe<Scalars['String']['input']>
  /** ID da Empresa associada */
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  /** Arquivo do Relatório */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Data de referência do Relatório (considerar apenas mês e ano) */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** Título do Relatório */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Relatório */
  type?: InputMaybe<EnterpriseReportType>
}

/** Payload para filtragem de Relatórios */
export type EnterpriseReportsFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Relatório */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseReportsFilterPayload>>
  /** Filtros para a referência do Relatório */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o título do Relatório */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo do Relatório */
  type?: InputMaybe<EnterpriseReportTypeFilterPayload>
}

/** Lista de Relatórios e seus metadados de paginação */
export type EnterpriseReportsPaginatedList = {
  __typename?: 'EnterpriseReportsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseReport>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Relatórios */
export type EnterpriseReportsSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de referência do Relatório */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Relatório */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Relatório */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Recurso associado à Empresa */
export type EnterpriseResource =
  | ResourceEnrollmentInvoice
  | ResourceEnterpriseArchive
  | ResourceEnterpriseBill
  | ResourceEnterpriseInvoice
  | ResourceEnterpriseReport
  | ResourceEnterpriseTaxGuide
  | ResourceMailboxMessage
  | ResourceOrder

/** Tipo de Recurso associado à Empresa */
export enum EnterpriseResourceType {
  /** Arquivo */
  Archive = 'ARCHIVE',
  /** Boleto */
  Bill = 'BILL',
  /** Fatura */
  EnrollmentInvoice = 'ENROLLMENT_INVOICE',
  /** Nota Fiscal */
  Invoice = 'INVOICE',
  /** Comunicado */
  MailboxMessage = 'MAILBOX_MESSAGE',
  /** Cobrança Pontual */
  Order = 'ORDER',
  /** Relatório */
  Report = 'REPORT',
  /** Guia de Imposto */
  TaxGuide = 'TAX_GUIDE',
}

/** Um registro de Tomador de Serviço associado a uma empresa */
export type EnterpriseServiceTaker = {
  __typename?: 'EnterpriseServiceTaker'
  /** Endereço associado */
  address: Address
  /** ID do Endereço associado */
  addressId: Scalars['UUID']['output']
  /** CNPJ do Tomador de Serviço */
  cnpj?: Maybe<Scalars['CNPJ']['output']>
  /** CPF do Tomador de Serviço */
  cpf?: Maybe<Scalars['CPF']['output']>
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome do Tomador de Serviço */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo Tomador de Serviço */
export type EnterpriseServiceTakerInsertPayload = {
  /** Endereço associado */
  address: AddressInsertPayload
  /** CNPJ do Tomador de Serviço */
  cnpj?: InputMaybe<Scalars['CNPJ']['input']>
  /** CPF do Tomador de Serviço */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Nome do Tomador de Serviço */
  name: Scalars['String']['input']
}

/** Payload de atualização de um Tomador de Serviço */
export type EnterpriseServiceTakerUpdatePayload = {
  /** Endereço associado */
  address?: InputMaybe<AddressUpdatePayload>
  /** CNPJ do Tomador de Serviço */
  cnpj?: InputMaybe<Scalars['CNPJ']['input']>
  /** CPF do Tomador de Serviço */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** Nome do Tomador de Serviço */
  name?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de Tomadores de Serviços */
export type EnterpriseServiceTakersFilterPayload = {
  /** Filtros para o ID do Endereço */
  addressId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o CNPJ do Tomador */
  cnpj?: InputMaybe<StringFilterPayload>
  /** Filtros para o CPF do Tomador */
  cpf?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Tomador */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do Tomador */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome do Tomador */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseServiceTakersFilterPayload>>
}

/** Lista de Tomadores de Serviços e seus metadados de paginação */
export type EnterpriseServiceTakersPaginatedList = {
  __typename?: 'EnterpriseServiceTakersPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseServiceTaker>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Tomadores de Serviços */
export type EnterpriseServiceTakersSortPayload = {
  /** Configuração de ordenação para o ID do Endereço vinculado ao recurso */
  addressId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o CPF do Tomador */
  cnpj?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do Tomador */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

export enum EnterpriseStatus {
  /** Ativa */
  Active = 'ACTIVE',
  /** Inativa */
  Inactive = 'INACTIVE',
}

/** Payload para filtragem de campos do tipo EnterpriseStatus */
export type EnterpriseStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseStatus>>>
}

/** Um registro de Guia de Imposto associado a uma empresa */
export type EnterpriseTaxGuide = {
  __typename?: 'EnterpriseTaxGuide'
  /** Caminho do arquivo da imagem do código de barras do pagamento da Guia de Imposto */
  barcode?: Maybe<Scalars['String']['output']>
  /** Descrição da Guia de Imposto */
  description?: Maybe<Scalars['String']['output']>
  /** Data de vencimento da Guia de Imposto */
  dueAt: Scalars['Date']['output']
  /** Empresa associada */
  enterprise: Enterprise
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Caminho do arquivo da Guia de Imposto */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Número de código de barras da Guia de Imposto */
  paymentCode?: Maybe<Scalars['String']['output']>
  /** Status de Pagamento da Guia de Imposto */
  paymentStatus: PaymentStatus
  /** Data de referência da Guia de Imposto (considerar apenas mês e ano) */
  reference: Scalars['Date']['output']
  /** Título da Guia de Imposto */
  title?: Maybe<Scalars['String']['output']>
  /** Tipo da Guia de Imposto */
  type: EnterpriseTaxGuideType
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Valor (em centavos de reais) da Guia de Imposto */
  value: Scalars['Int']['output']
}

/** Payload de inserção de uma nova Guia de Imposto */
export type EnterpriseTaxGuideInsertPayload = {
  /** Descrição da Guia de Imposto */
  description?: InputMaybe<Scalars['String']['input']>
  /** Data de vencimento da Guia de Imposto */
  dueAt: Scalars['Date']['input']
  /** ID da Empresa associada */
  enterpriseId: Scalars['UUID']['input']
  /** Arquivo da Guia de Imposto */
  file: Scalars['Upload']['input']
  /** Número de código de barras da Guia de Imposto */
  paymentCode?: InputMaybe<Scalars['String']['input']>
  /** Data de referência da Guia de Imposto (considerar apenas mês e ano) */
  reference: Scalars['Date']['input']
  /** Título da Guia de Imposto */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo da Guia de Imposto */
  type: EnterpriseTaxGuideType
  /** Valor (em centavos de reais) da Guia de Imposto */
  value: Scalars['Int']['input']
}

export enum EnterpriseTaxGuideType {
  /** COFINS */
  Cofins = 'COFINS',
  /** DAE */
  Dae = 'DAE',
  /** DARF */
  Darf = 'DARF',
  /** DAS */
  Das = 'DAS',
  /** FGTS */
  Fgts = 'FGTS',
  /** GPS */
  Gps = 'GPS',
  /** ICMS */
  Icms = 'ICMS',
  /** II */
  Ii = 'II',
  /** INSS */
  Inss = 'INSS',
  /** INSTALLMENT */
  Installment = 'INSTALLMENT',
  /** IPI */
  Ipi = 'IPI',
  /** IRPJ */
  Irpj = 'IRPJ',
  /** IRRF */
  Irrf = 'IRRF',
  /** IR - CS */
  IrCs = 'IR_CS',
  /** ISS */
  Iss = 'ISS',
  /** OUTRO */
  Other = 'OTHER',
  /** PIS */
  Pis = 'PIS',
  /** PIS - COFINS */
  PisCofins = 'PIS_COFINS',
}

/** Payload para filtragem de campos do tipo EnterpriseTaxGuideType */
export type EnterpriseTaxGuideTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseTaxGuideType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseTaxGuideType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseTaxGuideType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseTaxGuideType>>>
}

/** Payload de atualização de uma Guia de Imposto */
export type EnterpriseTaxGuideUpdatePayload = {
  /** Descrição da Guia de Imposto */
  description?: InputMaybe<Scalars['String']['input']>
  /** Data de vencimento da Guia de Imposto */
  dueAt?: InputMaybe<Scalars['Date']['input']>
  /** Arquivo da Guia de Imposto */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** Número de código de barras da Guia de Imposto */
  paymentCode?: InputMaybe<Scalars['String']['input']>
  /** Status de Pagamento da Guia de Imposto */
  paymentStatus?: InputMaybe<PaymentStatus>
  /** Data de referência da Guia de Imposto (considerar apenas mês e ano) */
  reference?: InputMaybe<Scalars['Date']['input']>
  /** Título da Guia de Imposto */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo da Guia de Imposto */
  type?: InputMaybe<EnterpriseTaxGuideType>
  /** Valor (em centavos de reais) da Guia de Imposto */
  value?: InputMaybe<Scalars['Int']['input']>
}

/** Payload para filtragem de Guias de Impostos */
export type EnterpriseTaxGuidesFilterPayload = {
  /** Filtros para o vencimento da Guia */
  dueAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Guia */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterpriseTaxGuidesFilterPayload>>
  /** Filtros para o status de Pagamento da Guia */
  paymentStatus?: InputMaybe<PaymentStatusFilterPayload>
  /** Filtros para a referência da Guia */
  reference?: InputMaybe<DateFilterPayload>
  /** Filtros para o título da Guia */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo da Guia */
  type?: InputMaybe<EnterpriseTaxGuideTypeFilterPayload>
  /** Filtros para o valor da Guia */
  value?: InputMaybe<IntegerFilterPayload>
}

/** Lista de Guias de Impostos e seus metadados de paginação */
export type EnterpriseTaxGuidesPaginatedList = {
  __typename?: 'EnterpriseTaxGuidesPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseTaxGuide>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Guias de Impostos */
export type EnterpriseTaxGuidesSortPayload = {
  /** Configuração de ordenação para o vencimento da Guia */
  dueAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a referência da Guia */
  reference?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título da Guia */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo da Guia */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o valor da Guia */
  value?: InputMaybe<SortDirection>
}

/** Payload para atualização de metadados de Empresas */
export type EnterpriseUpdatePayload = {
  /** Id do Responsável Contábil */
  accountingResponsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Informações acerca das atividades da Empresa */
  activities?: InputMaybe<Scalars['String']['input']>
  /** Endereço da Empresa */
  address?: InputMaybe<AddressUpdatePayload>
  /** Atividade da Empresa */
  businessActivity?: InputMaybe<BusinessActivity>
  /** ID do Formato de Negócio da Empresa */
  businessFormatId?: InputMaybe<Scalars['UUID']['input']>
  /** ID da Natureza de Negócio da Empresa */
  businessNatureId?: InputMaybe<Scalars['UUID']['input']>
  /** Sócios da Empresa */
  businessPartners?: InputMaybe<Array<InputMaybe<BusinessPartnerPayload>>>
  /** Status de negócio da Empresa */
  businessStatus?: InputMaybe<BusinessStatus>
  /** Data do Status de negócio da Empresa */
  businessStatusDate?: InputMaybe<Scalars['Date']['input']>
  /** Motivo do status da Empresa */
  businessStatusReason?: InputMaybe<Scalars['String']['input']>
  /** Tipo da Empresa */
  businessType?: InputMaybe<BusinessType>
  /** Inscrição Municipal da Empresa */
  cityInscription?: InputMaybe<Scalars['String']['input']>
  /** CNPJ da Empresa */
  cnpj?: InputMaybe<Scalars['CNPJ']['input']>
  /** Nome Fantasia da Empresa */
  commercialName?: InputMaybe<Scalars['String']['input']>
  /** Descrição da Empresa */
  description?: InputMaybe<Scalars['String']['input']>
  /** Email de contato vinculado à Empresa */
  email?: InputMaybe<Scalars['String']['input']>
  /** Número de funcionários da Empresa */
  employeesNumber?: InputMaybe<Scalars['Int']['input']>
  /** Serviços habilitados da Empresa */
  enabledBusinessServices?: InputMaybe<Array<InputMaybe<BusinessService>>>
  /** Sistemas externos habilitados para Empresa */
  enabledExternalSystems?: InputMaybe<Array<InputMaybe<EnterpriseExternalSystem>>>
  /** Metadados extra */
  extraInfo?: InputMaybe<Scalars['Json']['input']>
  /** Id do Responsável Fiscal */
  fiscalResponsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Capa do IPTU do Endereço da Empresa */
  iptuFile?: InputMaybe<Scalars['Upload']['input']>
  /** Define se a Inscrição Estadual é obrigatória */
  isMandatoryStateInscription?: InputMaybe<Scalars['Boolean']['input']>
  /** Cidade de jurisdição da empresa */
  jurisdictionCity?: InputMaybe<Scalars['String']['input']>
  /** Lista de alvarás obrigatórios */
  mandatoryBusinessPermits?: InputMaybe<Array<Scalars['String']['input']>>
  /** Razão Social da Empresa */
  name?: InputMaybe<Scalars['String']['input']>
  /** Data de Abertura da Empresa */
  openedAt?: InputMaybe<Scalars['Date']['input']>
  /** ID da Campanha de origem da Empresa */
  originMarketingCampaignId?: InputMaybe<Scalars['UUID']['input']>
  /** Id do Responsável de FOPAG */
  payrollResponsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Telefones vinculados à receita */
  phones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Capital social da Empresa (em centavos de R$) */
  shareCapital?: InputMaybe<Scalars['Int']['input']>
  /** Períodos da empresa no simples nacional */
  simplesNacionalPeriods?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Situação Especial da Empresa */
  specialSituation?: InputMaybe<Scalars['String']['input']>
  /** Data da Situação Especial da Empresa */
  specialSituationDate?: InputMaybe<Scalars['Date']['input']>
  /** Data de Início da Vigência da Empresa */
  startingAt?: InputMaybe<Scalars['Date']['input']>
  /** Inscrição Estadual da Empresa */
  stateInscription?: InputMaybe<Scalars['String']['input']>
  /** ID do Regime Fiscal da Empresa */
  taxRegimeId?: InputMaybe<Scalars['UUID']['input']>
}

/** Um registro do Usuário associado a uma empresa */
export type EnterpriseUser = {
  __typename?: 'EnterpriseUser'
  /** Empresa associada */
  enterprise: Enterprise
  /** ID do Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Flag que indica se é o dono da empresa. Apenas um registro pode ter essa flag por empresa. */
  isOwner: Scalars['Boolean']['output']
  /** Preferências de notificações do Usuário na Empresa associada */
  notificationPreferences: EnterpriseUserNotificationPreferences
  /** Grupo de Permissão associado */
  role: Role
  /** ID do Grupo de Permissão associado */
  roleId: Scalars['UUID']['output']
  /** Quantidade de notificações não visualizadas pelo usuário na empresa associada */
  unseenNotificationsAmount: Scalars['Int']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado */
  user: UserAccount
  /** ID do Usuário associado */
  userId: Scalars['UUID']['output']
}

/** Um registro do Convite à um Usuário associado a uma empresa */
export type EnterpriseUserInvitation = {
  __typename?: 'EnterpriseUserInvitation'
  /** Email destinado ao convite */
  email: Scalars['String']['output']
  /** Empresa associada */
  enterprise: Enterprise
  /** ID do Empresa associada */
  enterpriseId: Scalars['UUID']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Grupo de Permissão associado */
  role: Role
  /** ID do Grupo de Permissão associado */
  roleId: Scalars['UUID']['output']
  /** Status do convite */
  status: EnterpriseUserInvitationStatus
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado */
  user?: Maybe<UserAccount>
  /** ID do Usuário associado */
  userId?: Maybe<Scalars['UUID']['output']>
}

export enum EnterpriseUserInvitationStatus {
  /** Aceito */
  Accepted = 'ACCEPTED',
  /** Pendente */
  Pending = 'PENDING',
  /** Rejeitado */
  Rejected = 'REJECTED',
}

/** Payload para filtragem de campos do tipo EnterpriseUserInvitationStatus */
export type EnterpriseUserInvitationStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EnterpriseUserInvitationStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EnterpriseUserInvitationStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EnterpriseUserInvitationStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EnterpriseUserInvitationStatus>>>
}

/** Payload de filtragem dos convites para usuários associados à uma empresa */
export type EnterpriseUserInvitationsFilterPayload = {
  /** Filtros para o email destinado ao convite */
  email?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do recurso */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para serem recuperadas */
  or?: InputMaybe<Array<EnterpriseUserInvitationsFilterPayload>>
  /** Filtros para o status do recurso */
  status?: InputMaybe<EnterpriseUserInvitationStatusFilterPayload>
}

/** Lista de convites de uma empresa e seus metadados de paginação */
export type EnterpriseUserInvitationsPaginatedList = {
  __typename?: 'EnterpriseUserInvitationsPaginatedList'
  /** Dados recuperados */
  entries: Array<EnterpriseUserInvitation>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação dos convites para usuários associados à uma empresa */
export type EnterpriseUserInvitationsSortPayload = {
  /** Configuração de ordenação para o email destinado ao convite */
  email?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Preferências de notificação de um Usuário para uma Empresa */
export type EnterpriseUserNotificationPreferences = {
  __typename?: 'EnterpriseUserNotificationPreferences'
  /** Quais os eventos que o Usuário está assinado a receber notificações por Email */
  email: Array<UserNotificationKind>
  /** Quais os eventos que o Usuário está assinado a receber notificações por Push Notification */
  push: Array<UserNotificationKind>
  /** Quais os eventos que o Usuário está assinado a receber notificações por WhatsApp */
  whatsapp: Array<UserNotificationKind>
}

/** Payload para atualização das preferências de notificação de um usuário */
export type EnterpriseUserNotificationPreferencesUpdatePayload = {
  /** Quais os eventos que o Usuário está assinado a receber notificações por Email */
  email?: InputMaybe<Array<UserNotificationKind>>
  /** Quais os eventos que o Usuário está assinado a receber notificações por Push Notification */
  push?: InputMaybe<Array<UserNotificationKind>>
  /** Quais os eventos que o Usuário está assinado a receber notificações por WhatsApp */
  whatsapp?: InputMaybe<Array<UserNotificationKind>>
}

/** Lista de Comentários na Empresa e seus metadados de paginação */
export type EnterprisesCommentsPaginatedList = {
  __typename?: 'EnterprisesCommentsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<EnterpriseComment>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para filtragem de Empresas */
export type EnterprisesFilterPayload = {
  /** Filtros para o ID do Responsável Contábil */
  accountingResponsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o CNPJ da Empresa */
  cnpj?: InputMaybe<StringFilterPayload>
  /** Filtros para o código da Empresa */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o Nome Fantasia da Empresa */
  commercialName?: InputMaybe<StringFilterPayload>
  /** Filtros para a data de inativação da Empresa */
  deactivatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do Responsável Fiscal */
  fiscalResponsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Empresa */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção da Empresa */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o tipo da Empresa */
  kind?: InputMaybe<StringFilterPayload>
  /** Filtros para a Razão Social da Empresa */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<EnterprisesFilterPayload>>
  /** Filtros para o ID da Campanha de origem da Empresa */
  originMarketingCampaignId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Responsável de FOPAG */
  payrollResponsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o status da Empresa */
  status?: InputMaybe<EnterpriseStatusFilterPayload>
}

/** Lista de Empresas e seus metadados de paginação */
export type EnterprisesPaginatedList = {
  __typename?: 'EnterprisesPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<Enterprise>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Empresas */
export type EnterprisesSortPayload = {
  /** Configuração de ordenação para o CNPJ da Empresa */
  cnpj?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o código da Empresa */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o Nome Fantasia da Empresa */
  commercialName?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de inativação do recurso */
  deactivatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Empresa */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o último Humor registrado para a Empresa */
  lastEnterpriseMoodLevel?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a Razão Social da Empresa */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da Campanha de origem da Empresa */
  originMarketingCampaignId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

export enum EventType {
  /** Cobrança reprovada por antifraude */
  ChargeAntifraudReproved = 'CHARGE_ANTIFRAUD_REPROVED',
  /** Falha no pagamento da cobrança */
  ChargePaymentFailed = 'CHARGE_PAYMENT_FAILED',
  /** Cobrança estornada */
  ChargeRefunded = 'CHARGE_REFUNDED',
  /** Pedido cancelado */
  OrderCanceled = 'ORDER_CANCELED',
  /** Pedido pago */
  OrderPaid = 'ORDER_PAID',
  /** Falha no pagamento do pedido */
  OrderPaymentFailed = 'ORDER_PAYMENT_FAILED',
  /** Pedido atualizado */
  OrderUpdated = 'ORDER_UPDATED',
}

/** Payload para filtragem de campos do tipo EventType */
export type EventTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<EventType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<EventType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<EventType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<EventType>>>
}

/** Representação de um item na assinatura (extra) */
export type ExtraEnrollmentItem = {
  __typename?: 'ExtraEnrollmentItem'
  /** Identificador único do item na assinatura */
  id: Scalars['UUID']['output']
  /** Nome do item */
  name: Scalars['String']['output']
  /** Quantidade do item na assinatura */
  quantity: Scalars['Int']['output']
  /** Data de referência de uso do item na assinatura */
  reference?: Maybe<Scalars['Date']['output']>
  /** Preço unitário do item na assinatura */
  unitPrice: Scalars['Int']['output']
}

/** Payload para filtragem de campos do tipo Inteiro */
export type IntegerFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo possui o seu valor maior que o valor enviado */
  gt?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo possui o seu valor maior ou igual que o valor enviado */
  gte?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<Scalars['Int']['input']>>
  /** Se o campo possui o seu valor menor que o valor enviado */
  lt?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo possui o seu valor menor ou igual que o valor enviado */
  lte?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo não possui o seu valor maior que o valor enviado */
  ngt?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo não possui o seu valor maior ou igual que o valor enviado */
  ngte?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<Scalars['Int']['input']>>
  /** Se o campo não possui o seu valor menor que o valor enviado */
  nlt?: InputMaybe<Scalars['Int']['input']>
  /** Se o campo não possui o seu valor menor ou igual que o valor enviado */
  nlte?: InputMaybe<Scalars['Int']['input']>
}

export enum InvoiceRequestStatus {
  /** Cancelado */
  Canceled = 'CANCELED',
  /** Finalizado */
  Finished = 'FINISHED',
  /** Outro */
  Other = 'OTHER',
  /** Pendente */
  Pending = 'PENDING',
}

/** Payload para filtragem de campos do tipo InvoiceRequestStatus */
export type InvoiceRequestStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<InvoiceRequestStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<InvoiceRequestStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<InvoiceRequestStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<InvoiceRequestStatus>>>
}

/** Um registro da declaração de IRPF */
export type IrpfStatement = {
  __typename?: 'IrpfStatement'
  /** Endereço do declarante */
  address?: Maybe<Address>
  /** ID do endereço associado à declaração */
  addressId?: Maybe<Scalars['UUID']['output']>
  /** Conta bancária do declarante */
  bankAccount?: Maybe<IrpfStatementBankAccount>
  /** Dependentes do declarante */
  dependents?: Maybe<Array<IrpfStatementDependent>>
  /** Senha de acesso ao Gov.br */
  govbrPassword?: Maybe<Scalars['String']['output']>
  /** Identificador único da declaração de IRPF */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Lista paginada, filtrável e ordenável de anexos da declaração */
  irpfStatementAttachments: IrpfStatementAttachmentsPaginatedList
  /** Lista paginada, filtrável e ordenável de pendências da declaração */
  irpfStatementPendencies: IrpfStatementPendenciesPaginatedList
  /** Data de último contato com o declarante */
  lastContactedAt?: Maybe<Scalars['Date']['output']>
  /** Caminho do arquivo da última declaração de IRPF */
  lastIrpfStatementFile?: Maybe<Scalars['String']['output']>
  /** Log de alterações realizadas na declaração */
  log: AuditLogChangesPaginatedList
  /** Observações gerais da declaração */
  observations?: Maybe<Scalars['String']['output']>
  /** Profissão/Ocupação do declarante */
  occupation?: Maybe<Scalars['String']['output']>
  /** Pedido associado à declaração */
  order?: Maybe<Order>
  /** ID do pedido associado à declaração */
  orderId: Scalars['UUID']['output']
  /** Ano de referência da declaração */
  referenceYear: Scalars['Int']['output']
  /** Data de restituição do lote da declaração */
  refundBatchDate?: Maybe<Scalars['Date']['output']>
  /** Responsável pela declaração */
  responsible?: Maybe<BackofficeEmployee>
  /** ID do Responsável pela declaração */
  responsibleId?: Maybe<Scalars['UUID']['output']>
  /** Venda associada à declaração */
  sale?: Maybe<Sale>
  /** ID da venda associada à declaração */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Informações do cônjuge declarante */
  spouse?: Maybe<IrpfStatementSpouse>
  /** Caminho do arquivo da declaração de IRPF */
  statementFile?: Maybe<Scalars['String']['output']>
  /** Caminho do arquivo do comprovante de entrega da declaração de IRPF */
  statementReceiptFile?: Maybe<Scalars['String']['output']>
  /** Status da declaração */
  status: IrpfStatementStatus
  /** Tipo de declaração */
  type?: Maybe<IrpfStatementType>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado à declaração */
  user?: Maybe<UserAccount>
  /** ID do usuário associado à declaração */
  userId: Scalars['UUID']['output']
}

/** Um registro da declaração de IRPF */
export type IrpfStatementIrpfStatementAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementAttachmentsSortPayload>>>
  where?: InputMaybe<IrpfStatementAttachmentsFilterPayload>
}

/** Um registro da declaração de IRPF */
export type IrpfStatementIrpfStatementPendenciesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementPendenciesSortPayload>>>
  where?: InputMaybe<IrpfStatementPendenciesFilterPayload>
}

/** Um registro da declaração de IRPF */
export type IrpfStatementLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Um registro do anexo da declaração de IRPF */
export type IrpfStatementAttachment = {
  __typename?: 'IrpfStatementAttachment'
  /** Categoria do anexo da declaração de IRPF */
  category: IrpfStatementAttachmentCategory
  /** Detalhes do anexo da declaração de IRPF */
  details?: Maybe<IrpfStatementAttachmentDetails>
  /** Caminho do arquivo do anexo da declaração de IRPF */
  file: Scalars['String']['output']
  /** Identificador único do anexo da declaração de IRPF */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Declaração de IRPF associada ao anexo */
  irpfStatement: IrpfStatement
  /** ID da declaração de IRPF associada ao anexo */
  irpfStatementId: Scalars['UUID']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Representação dos detalhes de um anexo da declaração de IRPF da categoria 'Bens' */
export type IrpfStatementAttachmentAssetDetails = IrpfStatementAttachmentDetails & {
  __typename?: 'IrpfStatementAttachmentAssetDetails'
  /** Data de aquisição do bem */
  acquisitionDate: Scalars['Date']['output']
  /** Descrição do arquivo */
  description?: Maybe<Scalars['String']['output']>
}

export enum IrpfStatementAttachmentCategory {
  /** Bens */
  AssetsAttachment = 'ASSETS_ATTACHMENT',
  /** Informes */
  IncomesAttachment = 'INCOMES_ATTACHMENT',
  /** Gastos */
  SpendingsAttachment = 'SPENDINGS_ATTACHMENT',
}

/** Payload para filtragem de campos do tipo IrpfStatementAttachmentCategory */
export type IrpfStatementAttachmentCategoryFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<IrpfStatementAttachmentCategory>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<IrpfStatementAttachmentCategory>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<IrpfStatementAttachmentCategory>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<IrpfStatementAttachmentCategory>>>
}

/** Representação dos detalhes de um anexo da declaração de IRPF das categorias diferentes de 'Bens' */
export type IrpfStatementAttachmentDefaultDetails = IrpfStatementAttachmentDetails & {
  __typename?: 'IrpfStatementAttachmentDefaultDetails'
  /** Descrição do arquivo */
  description?: Maybe<Scalars['String']['output']>
}

/** Representação dos detalhes de um anexo da declaração de IRPF */
export type IrpfStatementAttachmentDetails = {
  /** Descrição do arquivo */
  description?: Maybe<Scalars['String']['output']>
}

/** Payload de detalhes de um anexo da declaração de IRPF */
export type IrpfStatementAttachmentDetailsPayload = {
  /** Data de aquisição do bem */
  acquisitionDate?: InputMaybe<Scalars['Date']['input']>
  /** Descrição do arquivo */
  description?: InputMaybe<Scalars['String']['input']>
}

/** Payload de inserção de anexos da declaração de IRPF */
export type IrpfStatementAttachmentInsertPayload = {
  /** Categoria do anexo da declaração de IRPF */
  category: IrpfStatementAttachmentCategory
  /** Detalhes do anexo da declaração de IRPF */
  details?: InputMaybe<IrpfStatementAttachmentDetailsPayload>
  /** Arquivo do anexo da declaração de IRPF */
  file: Scalars['Upload']['input']
  /** ID da declaração de IRPF associada ao anexo */
  irpfStatementId: Scalars['UUID']['input']
}

/** Payload de atualização de anexos da declaração de IRPF */
export type IrpfStatementAttachmentUpdatePayload = {
  /** Categoria do anexo da declaração de IRPF */
  category?: InputMaybe<IrpfStatementAttachmentCategory>
  /** Detalhes do anexo da declaração de IRPF */
  details?: InputMaybe<IrpfStatementAttachmentDetailsPayload>
  /** Arquivo do anexo da declaração de IRPF */
  file?: InputMaybe<Scalars['Upload']['input']>
}

/** Payload de filtro de anexos da declaração de IRPF */
export type IrpfStatementAttachmentsFilterPayload = {
  /** Filtros para a categoria do anexo da declaração de IRPF */
  category?: InputMaybe<IrpfStatementAttachmentCategoryFilterPayload>
  /** Filtros para o ID da declaração */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de Criação da declaração */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID da declaração de IRPF associada ao anexo */
  irpfStatementId?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<IrpfStatementAttachmentsFilterPayload>>
}

/** Lista de anexos e seus metadados de paginação */
export type IrpfStatementAttachmentsPaginatedList = {
  __typename?: 'IrpfStatementAttachmentsPaginatedList'
  /** Dados recuperados */
  entries: Array<IrpfStatementAttachment>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de anexos da declarações de IRPF */
export type IrpfStatementAttachmentsSortPayload = {
  /** Configuração de ordenação para a categoria do anexo da declaração */
  category?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Informações de uma conta bancária em uma declaração de IRPF */
export type IrpfStatementBankAccount = {
  __typename?: 'IrpfStatementBankAccount'
  /** Número da conta */
  accountNumber?: Maybe<Scalars['String']['output']>
  /** Número da agência */
  agency?: Maybe<Scalars['String']['output']>
  /** Número do banco */
  bank?: Maybe<Scalars['String']['output']>
  /** Chave PIX */
  pixKey?: Maybe<Scalars['String']['output']>
  /** Tipo de chave PIX */
  pixType?: Maybe<PixType>
}

/** Payload de informações da conta bancária em uma declaração de IRPF */
export type IrpfStatementBankAccountPayload = {
  /** Número da conta */
  accountNumber?: InputMaybe<Scalars['String']['input']>
  /** Número da agência */
  agency?: InputMaybe<Scalars['String']['input']>
  /** Número do banco */
  bank?: InputMaybe<Scalars['String']['input']>
  /** Chave PIX */
  pixKey?: InputMaybe<Scalars['String']['input']>
  /** Tipo de chave PIX */
  pixType?: InputMaybe<PixType>
}

/** Informações de um dependente em uma declaração de IRPF */
export type IrpfStatementDependent = {
  __typename?: 'IrpfStatementDependent'
  /** Data de nascimento do dependente */
  birthdate: Scalars['Date']['output']
  /** CPF do dependente */
  cpf?: Maybe<Scalars['CPF']['output']>
  /** Identificador único do dependente */
  id: Scalars['UUID']['output']
  /** Identificador interno único do dependente, para atualizações e pendências */
  internalId: Scalars['UUID']['output']
  /** Nome do dependente */
  name: Scalars['String']['output']
  /** Parentesco do dependente */
  parentage: Scalars['String']['output']
}

/** Payload de informações de dependentes em uma declaração de IRPF */
export type IrpfStatementDependentPayload = {
  /** Data de nascimento do dependente */
  birthdate: Scalars['Date']['input']
  /** CPF do dependente */
  cpf?: InputMaybe<Scalars['CPF']['input']>
  /** Identificador interno único do dependente, para pendências. Não alterar! */
  internalId?: InputMaybe<Scalars['UUID']['input']>
  /** Nome do dependente */
  name: Scalars['String']['input']
  /** Parentesco do dependente */
  parentage: Scalars['String']['input']
}

/** Payload de finalização de uma declaração de IRPF */
export type IrpfStatementFinishPayload = {
  /** Valor adicional a ser pago pela declaração */
  additiveValue?: InputMaybe<Scalars['Int']['input']>
  /** Arquivo da declaração */
  statementFile: Scalars['Upload']['input']
  /** Arquivo do comprovante de entrega da declaração */
  statementReceiptFile: Scalars['Upload']['input']
}

/** Payload de filtro de pendências da declaração de IRPF */
export type IrpfStatementPendenciesFilterPayload = {
  /** Filtros para a categoria da pendência da declaração de IRPF */
  category?: InputMaybe<IrpfStatementPendencyCategoryFilterPayload>
  /** Filtros para a data fechamento da pendência da declaração */
  closedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID da declaração */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação da pendência da declaração */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID da declaração de IRPF associada à pendência */
  irpfStatementId?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<IrpfStatementPendenciesFilterPayload>>
}

/** Lista de pendências e seus metadados de paginação */
export type IrpfStatementPendenciesPaginatedList = {
  __typename?: 'IrpfStatementPendenciesPaginatedList'
  /** Dados recuperados */
  entries: Array<IrpfStatementPendency>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de pendências da declarações de IRPF */
export type IrpfStatementPendenciesSortPayload = {
  /** Configuração de ordenação para a categoria da pendência da declaração */
  category?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de fechamento da pendência da declaração */
  closedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro da pendência da declaração de IRPF */
export type IrpfStatementPendency = {
  __typename?: 'IrpfStatementPendency'
  /** Categoria da pendência da declaração de IRPF */
  category: IrpfStatementPendencyCategory
  /** Timestamp de fechamento da pendência */
  closedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Identificador único da pendência da declaração de IRPF */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Declaração de IRPF associada à pendência */
  irpfStatement: IrpfStatement
  /** ID da declaração de IRPF associada à pendência */
  irpfStatementId: Scalars['UUID']['output']
  /** Mensagens de erro associadas à pendência */
  pendencies?: Maybe<Array<IrpfStatementPendencyMessage>>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

export enum IrpfStatementPendencyCategory {
  /** Informações do Endereço */
  AddressInformation = 'ADDRESS_INFORMATION',
  /** Bens */
  AssetsAttachment = 'ASSETS_ATTACHMENT',
  /** Informações da Conta Bancária */
  BankAccountInformation = 'BANK_ACCOUNT_INFORMATION',
  /** Informações de Dependentes */
  DependentsInformation = 'DEPENDENTS_INFORMATION',
  /** Observações gerais */
  GeneralObservation = 'GENERAL_OBSERVATION',
  /** Informes */
  IncomesAttachment = 'INCOMES_ATTACHMENT',
  /** Informações cadastrais */
  RegistrationInformation = 'REGISTRATION_INFORMATION',
  /** Gastos */
  SpendingsAttachment = 'SPENDINGS_ATTACHMENT',
  /** Informações do Cônjuge */
  SpouseInformation = 'SPOUSE_INFORMATION',
}

/** Payload para filtragem de campos do tipo IrpfStatementPendencyCategory */
export type IrpfStatementPendencyCategoryFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<IrpfStatementPendencyCategory>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<IrpfStatementPendencyCategory>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<IrpfStatementPendencyCategory>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<IrpfStatementPendencyCategory>>>
}

/** Payload de inserção de pendências da declaração de IRPF */
export type IrpfStatementPendencyInsertPayload = {
  /** Categoria da pendência da declaração de IRPF */
  category: IrpfStatementPendencyCategory
  /** ID da declaração de IRPF associada à pendência */
  irpfStatementId: Scalars['UUID']['input']
  /** Mensagens de erro associadas à pendência */
  pendencies?: InputMaybe<Array<IrpfStatementPendencyMessagePayload>>
}

/** Um registro da mensagem de pendência da declaração de IRPF */
export type IrpfStatementPendencyMessage = {
  __typename?: 'IrpfStatementPendencyMessage'
  /** Descrição do erro encontrado */
  error: Scalars['String']['output']
  /** Campo da declaração de IRPF que gerou a pendência */
  field: Scalars['String']['output']
  /** Identificador único da mensagem de pendência da declaração de IRPF */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Define se a pendência foi corrigida */
  isResolved: Scalars['Boolean']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload da mensagem de pendência da declaração de IRPF */
export type IrpfStatementPendencyMessagePayload = {
  /** Descrição do erro encontrado */
  error: Scalars['String']['input']
  /** Campo da declaração de IRPF que gerou a pendência */
  field: Scalars['String']['input']
}

/** Payload de solicitação de uma declaração de IRPF */
export type IrpfStatementRequestPayload = {
  /** Ano de referência da declaração */
  referenceYear: Scalars['Int']['input']
  /** Identificador do cartão na carteira a ser utilizada na compra */
  walletCardId: Scalars['UUID']['input']
}

/** Payload de atualização de uma solicitação de declaração de IRPF */
export type IrpfStatementRequestUpdatePayload = {
  /** Informações do endereço associado a declaração */
  address?: InputMaybe<AddressInsertPayload>
  /** Informações da conta bancária */
  bankAccount?: InputMaybe<IrpfStatementBankAccountPayload>
  /** Informações de dependentes */
  dependents?: InputMaybe<Array<IrpfStatementDependentPayload>>
  /** Senha de acesso ao Gov.br */
  govbrPassword?: InputMaybe<Scalars['String']['input']>
  /** Caminho do arquivo da última declaração */
  lastIrpfStatementFile?: InputMaybe<Scalars['Upload']['input']>
  /** Observações gerais sobre a declaração */
  observations?: InputMaybe<Scalars['String']['input']>
  /** Profissão/Ocupação do declarante */
  occupation?: InputMaybe<Scalars['String']['input']>
  /** Ano de referência da declaração */
  referenceYear?: InputMaybe<Scalars['Int']['input']>
  /** Informações do cônjuge */
  spouse?: InputMaybe<IrpfStatementSpousePayload>
  /** Caminho do arquivo da declaração */
  statementFile?: InputMaybe<Scalars['Upload']['input']>
  /** Caminho do arquivo do comprovante de entrega da declaração */
  statementReceiptFile?: InputMaybe<Scalars['Upload']['input']>
}

/** Informações de um cônjuge em uma declaração de IRPF */
export type IrpfStatementSpouse = {
  __typename?: 'IrpfStatementSpouse'
  /** Data de nascimento do cônjuge */
  birthdate: Scalars['Date']['output']
  /** CPF do cônjuge */
  cpf: Scalars['CPF']['output']
  /** Nome do cônjuge */
  name: Scalars['String']['output']
}

/** Payload de informações do cônjuge em uma declaração de IRPF */
export type IrpfStatementSpousePayload = {
  /** Data de nascimento do cônjuge */
  birthdate: Scalars['Date']['input']
  /** CPF do cônjuge */
  cpf: Scalars['CPF']['input']
  /** Nome do cônjuge */
  name: Scalars['String']['input']
}

export enum IrpfStatementStatus {
  /** Finalizado */
  Finished = 'FINISHED',
  /** Em análise */
  InReview = 'IN_REVIEW',
  /** Iniciado */
  Started = 'STARTED',
  /** Aguardando correções */
  WaitingPendencies = 'WAITING_PENDENCIES',
}

/** Payload para filtragem de campos do tipo IrpfStatementStatus */
export type IrpfStatementStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<IrpfStatementStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<IrpfStatementStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<IrpfStatementStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<IrpfStatementStatus>>>
}

export enum IrpfStatementType {
  /** Completa */
  Complete = 'COMPLETE',
  /** Simples */
  Simple = 'SIMPLE',
}

/** Payload para filtragem de campos do tipo IrpfStatementType */
export type IrpfStatementTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<IrpfStatementType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<IrpfStatementType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<IrpfStatementType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<IrpfStatementType>>>
}

/** Payload de atualização de uma declaração de IRPF */
export type IrpfStatementUpdatePayload = {
  /** Data de último contato com o declarante */
  lastContactedAt?: InputMaybe<Scalars['Date']['input']>
  /** ID do Responsável pela declaração */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Tipo de declaração */
  type?: InputMaybe<IrpfStatementType>
}

/** Payload para filtragem de declarações de IRPFs */
export type IrpfStatementsFilterPayload = {
  /** Filtros para o ID do endereço da declaração */
  addressId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da declaração */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação da declaração */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para a profissão/ocupação do declarante */
  occupation?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<IrpfStatementsFilterPayload>>
  /** Filtros para o ID do pedido associado à declaração */
  orderId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ano de referência da declaração */
  referenceYear?: InputMaybe<IntegerFilterPayload>
  /** Filtros para a data de restituição do lote da declaração */
  refundBatchDate?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID do responsável associado à declaração */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da venda associada da declaração */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o status da declaração */
  status?: InputMaybe<IrpfStatementStatusFilterPayload>
  /** Filtros para o tipo de declaração */
  type?: InputMaybe<IrpfStatementTypeFilterPayload>
  /** Filtros para a data de atualização da declaração */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do usuário associado à declaração */
  userId?: InputMaybe<UuidFilterPayload>
}

/** Lista de declarações de IRPFs e seus metadados de paginação */
export type IrpfStatementsPaginatedList = {
  __typename?: 'IrpfStatementsPaginatedList'
  /** Dados recuperados */
  entries: Array<IrpfStatement>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de declarações de IRPFs */
export type IrpfStatementsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ano de referência da declaração */
  referenceYear?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o status da declaração */
  status?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Um registro de comunicado */
export type MailboxMessage = {
  __typename?: 'MailboxMessage'
  /** Anexos do comunicado */
  attachments: Array<MailboxMessageAttachment>
  /** Empresa associada */
  enterprise?: Maybe<Enterprise>
  /** ID da Empresa associada */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Tipo do comunicado */
  kind: MailboxMessageKind
  /** Texto do comunicado */
  text: Scalars['String']['output']
  /** Título do comunicado */
  title: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado */
  user?: Maybe<UserAccount>
  /** ID do Usuário associado */
  userId?: Maybe<Scalars['UUID']['output']>
}

/** Um registro de anexo de comunicado */
export type MailboxMessageAttachment = {
  __typename?: 'MailboxMessageAttachment'
  /** Caminho do arquivo anexado */
  file: Scalars['String']['output']
  /** Identificador único do registro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Comunicado associado */
  mailboxMessage?: Maybe<MailboxMessage>
  /** ID do comunicado associado */
  mailboxMessageId: Scalars['UUID']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de arquivo em um comunicado */
export type MailboxMessageAttachmentFilePayload = {
  /** Arquivo */
  file: Scalars['Upload']['input']
}

/** Payload de inserção de um novo comunicado */
export type MailboxMessageInsertPayload = {
  /** Anexos do comunicado */
  attachments?: InputMaybe<Array<MailboxMessageAttachmentFilePayload>>
  /** Tipo do comunicado */
  kind: MailboxMessageKind
  /** Texto do comunicado */
  text: Scalars['String']['input']
  /** Título do comunicado */
  title: Scalars['String']['input']
}

export enum MailboxMessageKind {
  /** Formalização */
  Formalization = 'FORMALIZATION',
  /** Novidade */
  News = 'NEWS',
  /** Outro */
  Other = 'OTHER',
  /** Promoção */
  Promo = 'PROMO',
  /** Aviso */
  Warning = 'WARNING',
}

/** Payload para filtragem de campos do tipo MailboxMessageKind */
export type MailboxMessageKindFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<MailboxMessageKind>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<MailboxMessageKind>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<MailboxMessageKind>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<MailboxMessageKind>>>
}

/** Payload para filtragem de Comunicados */
export type MailboxMessagesFilterPayload = {
  /** Filtros para o ID da Empresa */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Comunicado */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de insercão do Comunicado */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o tipo do Comunicado */
  kind?: InputMaybe<MailboxMessageKindFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<MailboxMessagesFilterPayload>>
  /** Filtros para o texto do Comunicado */
  text?: InputMaybe<StringFilterPayload>
  /** Filtros para o título do Comunicado */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Usuário */
  userId?: InputMaybe<UuidFilterPayload>
}

/** Lista de Comunicados e seus metadados de paginação */
export type MailboxMessagesPaginatedList = {
  __typename?: 'MailboxMessagesPaginatedList'
  /** Dados recuperados */
  entries: Array<MailboxMessage>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Comunicados */
export type MailboxMessagesSortPayload = {
  /** Configuração de ordenação para o ID da Empresa vinculada ao recurso */
  enterpriseId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Comunicado */
  kind?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o texto do Comunicado */
  text?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Comunicado */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do Usuário vinculada ao recurso */
  userId?: InputMaybe<SortDirection>
}

/** Payload de um Recurso visualizado a ser registrado nos Registros de Logs */
export type MarkEnterpriseResourceAsSeenPayload = {
  /** Identificador único do Recurso visualizado */
  resourceId: Scalars['UUID']['input']
  /** Tipo do Recurso visualizado */
  resourceType: EnterpriseResourceType
}

/** Uma campanha de marketing para agrupar recursos à um contexto/parceiro */
export type MarketingCampaign = {
  __typename?: 'MarketingCampaign'
  /** Nome 'slugificado', único no contexto, da campanha */
  code: Scalars['String']['output']
  /** Descrição da campanha */
  description: Scalars['String']['output']
  /** Identificador único da campanha */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Se a campanha está ou não ativo */
  isActive: Scalars['Boolean']['output']
  /** Nome amigável da campanha */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um nova campanha */
export type MarketingCampaignInsertPayload = {
  /** Nome 'slugificado', único no contexto, da campanha */
  code: Scalars['String']['input']
  /** Descrição da campanha */
  description: Scalars['String']['input']
  /** Se a campanha está ou não ativo */
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Nome amigável da campanha */
  name: Scalars['String']['input']
}

/** Payload de atualização de uma campanha */
export type MarketingCampaignUpdatePayload = {
  /** Nome 'slugificado', único no contexto, da campanha */
  code?: InputMaybe<Scalars['String']['input']>
  /** Descrição da campanha */
  description?: InputMaybe<Scalars['String']['input']>
  /** Se a campanha está ou não ativo */
  isActive?: InputMaybe<Scalars['Boolean']['input']>
  /** Nome amigável da campanha */
  name?: InputMaybe<Scalars['String']['input']>
}

/** Payload para filtragem de campanhas */
export type MarketingCampaignsFilterPayload = {
  /** Filtros para o código da campanha */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição da campanha */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID da campanha */
  id?: InputMaybe<UuidFilterPayload>
  /** Se a campanha está ou não ativo */
  isActive?: InputMaybe<BooleanFilterPayload>
  /** Filtros para o nome amigável da campanha */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<MarketingCampaignsFilterPayload>>
}

/** Lista de campanhas e seus metadados de paginação */
export type MarketingCampaignsPaginatedList = {
  __typename?: 'MarketingCampaignsPaginatedList'
  /** Dados recuperados */
  entries: Array<MarketingCampaign>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para filtragem de campos do tipo NaiveDateTime */
export type NaiveDatetimeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo possui o seu valor maior que o valor enviado */
  gt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo possui o seu valor maior ou igual que o valor enviado */
  gte?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo possui o seu valor menor que o valor enviado */
  lt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo possui o seu valor menor ou igual que o valor enviado */
  lte?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo não possui o seu valor maior que o valor enviado */
  ngt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo não possui o seu valor maior ou igual que o valor enviado */
  ngte?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo não possui o seu valor menor que o valor enviado */
  nlt?: InputMaybe<Scalars['NaiveDateTime']['input']>
  /** Se o campo não possui o seu valor menor ou igual que o valor enviado */
  nlte?: InputMaybe<Scalars['NaiveDateTime']['input']>
}

/** Um registro do pedido */
export type Order = {
  __typename?: 'Order'
  /** Valor total do pedido */
  amount?: Maybe<Scalars['Int']['output']>
  /** Código do pedido */
  code: Scalars['Int']['output']
  /** Data de expiração do QR Code do Pix do pedido */
  dueAt?: Maybe<Scalars['Date']['output']>
  /** Empresa associada ao pedido */
  enterprise?: Maybe<Enterprise>
  /** ID da empresa associada ao pedido */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** Onboarding associado ao pedido */
  enterpriseOnboarding?: Maybe<EnterpriseOnboarding>
  /** ID do onboarding associado ao pedido */
  enterpriseOnboardingId?: Maybe<Scalars['UUID']['output']>
  /** Lista paginada, filtrável e ordenável de eventos do pedido */
  events: OrderEventsPaginatedList
  /** Identificador externo do pedido */
  externalOrderId?: Maybe<Scalars['String']['output']>
  /** Identificador único do pedido */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Ordem de Serviço associada ao Pedido */
  orderServiceOrder?: Maybe<OrderServiceOrder>
  /** Data de pagamento do pedido */
  paidAt?: Maybe<Scalars['Date']['output']>
  /** Método de pagamento do pedido */
  paymentMethod?: Maybe<OrderPaymentMethod>
  /** QR Code do Pix do pedido */
  pixQrCode?: Maybe<Scalars['String']['output']>
  /** URL do QR Code do Pix do pedido */
  pixQrCodeUrl?: Maybe<Scalars['String']['output']>
  /** Venda associada ao pedido */
  sale?: Maybe<Sale>
  /** ID da venda associada ao pedido */
  saleId?: Maybe<Scalars['UUID']['output']>
  /** Lista paginada, filtrável e ordenável de itens de serviço do pedido */
  services: OrderServiceItemsPaginatedList
  /** Status do pedido */
  status: OrderStatus
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado ao pedido */
  user?: Maybe<UserAccount>
  /** ID do usuário associado ao pedido */
  userId?: Maybe<Scalars['UUID']['output']>
  /** Cartão associado ao pedido */
  walletCard?: Maybe<WalletCard>
  /** ID do cartão associado ao pedido */
  walletCardId?: Maybe<Scalars['UUID']['output']>
}

/** Um registro do pedido */
export type OrderEventsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrderEventsSortPayload>>>
  where?: InputMaybe<OrderEventsFilterPayload>
}

/** Um registro do pedido */
export type OrderServicesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrderServiceItemsSortPayload>>>
  where?: InputMaybe<OrderServiceItemsFilterPayload>
}

/** Um registro do evento do pedido */
export type OrderEvent = {
  __typename?: 'OrderEvent'
  /** Metadados do evento do pedido */
  data?: Maybe<Scalars['Json']['output']>
  /** Código do evento do pedido */
  event: EventType
  /** Identificador único do evento do pedido */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Empresa associada ao pedido */
  order?: Maybe<Order>
  /** ID do pedido */
  orderId?: Maybe<Scalars['UUID']['output']>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para filtragem de pedidos */
export type OrderEventsFilterPayload = {
  /** Filtros para o evento do pedido */
  event?: InputMaybe<EventTypeFilterPayload>
  /** Filtros para o ID do pedido */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do pedido */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<OrderEventsFilterPayload>>
  /** Filtros para o ID do pedido associado ao evento */
  orderId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de atualização do pedido */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
}

/** Lista de eventos de pedidos e seus metadados de paginação */
export type OrderEventsPaginatedList = {
  __typename?: 'OrderEventsPaginatedList'
  /** Dados recuperados */
  entries: Array<OrderEvent>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de pedidos */
export type OrderEventsSortPayload = {
  /** Configuração de ordenação para o evento do pedido */
  event?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Payload de inserção de um novo Pedido */
export type OrderInsertPayload = {
  /** Identificador da Empresa associada ao Pedido */
  enterpriseId: Scalars['UUID']['input']
  /** Método de pagamento do Pedido */
  paymentMethod: OrderPaymentMethod
  /** Dados da Venda associada ao Pedido */
  sale: SaleInsertPayload
  /** Itens de Serviço do Pedido */
  services: Array<OrderServiceItemInsertPayload>
}

export enum OrderPaymentMethod {
  /** Boleto */
  Boleto = 'BOLETO',
  /** Cartão de crédito */
  CreditCard = 'CREDIT_CARD',
  /** Pix */
  Pix = 'PIX',
}

/** Representação de um Item de Serviço associado a um Pedido */
export type OrderServiceItem = {
  __typename?: 'OrderServiceItem'
  /** Código do item de serviço */
  code: Scalars['String']['output']
  /** Descrição do item de serviço */
  description?: Maybe<Scalars['String']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome do item de serviço */
  name: Scalars['String']['output']
  /** Venda associada */
  order: Order
  /** ID da Venda associada */
  orderId: Scalars['UUID']['output']
  /** Quantidade do Item de Serviço */
  quantity: Scalars['Int']['output']
  /** Item de Serviço associado */
  serviceItem: ServiceItem
  /** ID do Item de Serviço associado */
  serviceItemId: Scalars['UUID']['output']
  /** Preço unitário do Item de Serviço */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma novo Item de Serviço junto ao Pedido */
export type OrderServiceItemInsertPayload = {
  /** Quantidade do Item de Serviço */
  quantity: Scalars['Int']['input']
  /** ID do Item de Serviço associado */
  serviceItemId: Scalars['UUID']['input']
  /** Preço unitário do Item de Serviço */
  unitPrice: Scalars['Int']['input']
}

/** Payload para filtragem de Item de Serviços em Pedidos */
export type OrderServiceItemsFilterPayload = {
  /** Filtros para o ID do Item de Serviço em Pedido */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<OrderServiceItemsFilterPayload>>
  /** Filtros para o ID da Venda */
  orderId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a quantidade do Item de Serviço em Pedido */
  quantity?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID da Venda */
  serviceItemId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o preço unitário do Item de Serviço em Pedido */
  unitPrice?: InputMaybe<IntegerFilterPayload>
}

/** Lista de Itens de Serviço em Pedidos e seus metadados de paginação */
export type OrderServiceItemsPaginatedList = {
  __typename?: 'OrderServiceItemsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<OrderServiceItem>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Item de Serviços em Pedidos */
export type OrderServiceItemsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a quantidade do Item de Serviço em Pedido */
  quantity?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o preço unitário do Item de Serviço em Pedido */
  unitPrice?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Dados da Ordem de Serviço vinculado a um Pedido */
export type OrderServiceOrder = {
  __typename?: 'OrderServiceOrder'
  /** Identificador único da Ordem de Serviço */
  id: Scalars['UUID']['output']
  /** Data da inserção da Ordem de Serviço */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** URL da Nota Fiscal emitida pela Hubs acerca do Pedido */
  invoiceUrl?: Maybe<Scalars['String']['output']>
  /** Data do faturamento da Ordem de Serviço */
  invoicedAt?: Maybe<Scalars['Date']['output']>
  /** Data da baixa da Conta a Receber da Ordem de Serviço */
  transactionIssuedAt?: Maybe<Scalars['Date']['output']>
}

export enum OrderStatus {
  /** Pedido pago */
  Paid = 'PAID',
  /** Pedido pendente */
  Pending = 'PENDING',
}

/** Payload para filtragem de campos do tipo OrderStatus */
export type OrderStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<OrderStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<OrderStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<OrderStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<OrderStatus>>>
}

/** Payload para filtragem de pedidos */
export type OrdersFilterPayload = {
  /** Filtros para o código do pedido */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para a data de vencimento do Pix do pedido */
  dueAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID da empresa associado ao pedido */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do onboarding associado ao pedido */
  enterpriseOnboardingId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o identificador externo do pedido */
  externalOrderId?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do pedido */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de criação do pedido */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<OrdersFilterPayload>>
  /** Filtros para a data de pagamento do pedido */
  paidAt?: InputMaybe<DateFilterPayload>
  /** Filtros para o ID da venda associada do pedido */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o status do pedido */
  status?: InputMaybe<OrderStatusFilterPayload>
  /** Filtros para a data de atualização do pedido */
  updatedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID do usuário associado ao pedido */
  userId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do cartão associado ao pedido */
  walletCardId?: InputMaybe<UuidFilterPayload>
}

/** Lista de pedidos e seus metadados de paginação */
export type OrdersPaginatedList = {
  __typename?: 'OrdersPaginatedList'
  /** Dados recuperados */
  entries: Array<Order>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de pedidos */
export type OrdersSortPayload = {
  /** Configuração de ordenação para o código do pedido */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de vencimento do Pix do pedido */
  dueAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o identificador externo do pedido */
  externalOrderId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de pagamento do pedido */
  paidAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o status do pedido */
  status?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de metadados de paginação de diversos recursos na plataforma */
export type PaginationMetadata = {
  __typename?: 'PaginationMetadata'
  /** Um valor calculado da página atual, necessita do parâmetro includeTotalCount ser enviado */
  pageNumber?: Maybe<Scalars['Int']['output']>
  /** Dado do limite de entidades utilizado para paginar */
  pageSize: Scalars['Int']['output']
  /** Quantidade total de recursos presente na origem de dados */
  totalEntries?: Maybe<Scalars['Int']['output']>
  /** Um valor calculado do total de páginas considerando o limite utilizado, necessita do parâmetro includeTotalCount ser enviado */
  totalPages?: Maybe<Scalars['Int']['output']>
}

/** Payload para paginação de recursos */
export type PaginationPayload = {
  /** Valor de qual página deseja visualizar */
  page?: InputMaybe<Scalars['Int']['input']>
  /** Dado do limite de entidades utilizado para paginar */
  pageSize?: InputMaybe<Scalars['Int']['input']>
}

/** Um parceiro associado ao sistema */
export type Partner = {
  __typename?: 'Partner'
  /** Código 'slugificado', único no contexto, do parceiro */
  code: Scalars['String']['output']
  /** Descrição do parceiro */
  description?: Maybe<Scalars['String']['output']>
  /** Identificador único do parceiro */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome amigável do parceiro */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Dados associtivos de um parceiro e um onboarding */
export type PartnerOnboarding = {
  __typename?: 'PartnerOnboarding'
  /** Onboarding associado */
  enterpriseOnboarding: EnterpriseOnboarding
  /** ID do Onboarding */
  enterpriseOnboardingId: Scalars['UUID']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Onboarding associado */
  partner: Partner
  /** ID do Parceiro */
  partnerId: Scalars['UUID']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Dados associtivos de um parceiro e um usuário */
export type PartnerUser = {
  __typename?: 'PartnerUser'
  /** ID do parceiro que identifica o usuário */
  externalUserId: Scalars['String']['output']
  /** Identificador único do registro de associação */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Onboarding associado */
  partner: Partner
  /** ID do Parceiro */
  partnerId: Scalars['UUID']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado */
  user: UserAccount
  /** ID do Usuário */
  userId: Scalars['UUID']['output']
}

/** Payload para filtragem de parceiros */
export type PartnersFilterPayload = {
  /** Filtros para o código do parceiro */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do parceiro */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome do parceiro */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<PartnersFilterPayload>>
}

/** Lista de parceiros de permissão e seus metadados de paginação */
export type PartnersPaginatedList = {
  __typename?: 'PartnersPaginatedList'
  /** Dados recuperados */
  entries: Array<Partner>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de parceiros */
export type PartnersSortPayload = {
  /** Configuração de ordenação para o código do parceiro */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do parceiro */
  name?: InputMaybe<SortDirection>
}

export enum PaymentMethod {
  /** Boleto */
  Bill = 'BILL',
  /** Cartão de Crédito */
  CreditCard = 'CREDIT_CARD',
}

export enum PaymentStatus {
  /** Pago(a) */
  Paid = 'PAID',
  /** Pendente */
  Pending = 'PENDING',
  /** Desconhecido */
  Unknown = 'UNKNOWN',
}

/** Payload para filtragem de campos do tipo PaymentStatus */
export type PaymentStatusFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<PaymentStatus>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<PaymentStatus>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<PaymentStatus>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<PaymentStatus>>>
}

export enum PersonProfile {
  /** Funcionário */
  Employee = 'EMPLOYEE',
  /** Outro */
  Other = 'OTHER',
  /** Dono */
  Owner = 'OWNER',
  /** Parente */
  Relative = 'RELATIVE',
}

export enum PixType {
  /** CPF */
  Cpf = 'CPF',
  /** E-mail */
  Email = 'EMAIL',
  /** Celular */
  Phone = 'PHONE',
  /** Chave Aleatória */
  Random = 'RANDOM',
}

export type ResourceEnrollmentInvoice = {
  __typename?: 'ResourceEnrollmentInvoice'
  enrollmentInvoice?: Maybe<EnrollmentInvoice>
}

export type ResourceEnterpriseArchive = {
  __typename?: 'ResourceEnterpriseArchive'
  archive?: Maybe<EnterpriseArchive>
}

export type ResourceEnterpriseBill = {
  __typename?: 'ResourceEnterpriseBill'
  bill?: Maybe<EnterpriseBill>
}

export type ResourceEnterpriseInvoice = {
  __typename?: 'ResourceEnterpriseInvoice'
  invoice?: Maybe<EnterpriseInvoice>
}

export type ResourceEnterpriseReport = {
  __typename?: 'ResourceEnterpriseReport'
  report?: Maybe<EnterpriseReport>
}

export type ResourceEnterpriseTaxGuide = {
  __typename?: 'ResourceEnterpriseTaxGuide'
  taxGuide?: Maybe<EnterpriseTaxGuide>
}

export type ResourceMailboxMessage = {
  __typename?: 'ResourceMailboxMessage'
  mailboxMessage?: Maybe<MailboxMessage>
}

export type ResourceOrder = {
  __typename?: 'ResourceOrder'
  order?: Maybe<Order>
}

/** Um grupo de políticas para agrupar usuários em um contexto */
export type Role = {
  __typename?: 'Role'
  /** Descrição do grupo */
  description?: Maybe<Scalars['String']['output']>
  /** Nome amigável do grupo */
  displayName: Scalars['String']['output']
  /** Identificador único do grupo */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome 'slugificado', único no contexto, do grupo */
  name: Scalars['String']['output']
  /** Políticas aplicadas a este grupo de permissão */
  policies: Array<RolePolicy>
  /** Escopo que o grupo de permissão é utilizado */
  scope: RoleScope
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo grupo de permissão */
export type RoleInsertPayload = {
  /** Descrição do grupo */
  description?: InputMaybe<Scalars['String']['input']>
  /** Nome amigável do grupo */
  displayName: Scalars['String']['input']
  /** Nome 'slugificado', único no contexto, do grupo */
  name: Scalars['String']['input']
  /** Políticas aplicadas a este grupo de permissão */
  policies: Array<RolePolicy>
  /** Escopo que o grupo de permissão é utilizado */
  scope: RoleScope
}

export enum RolePolicy {
  /** Inativar Assinaturas */
  DeactivateEnrollments = 'DEACTIVATE_ENROLLMENTS',
  /** Inativar Ajustes em Assinaturas */
  DeactivateEnrollmentAdjustments = 'DEACTIVATE_ENROLLMENT_ADJUSTMENTS',
  /** Excluir CNAEs */
  DeleteCnaes = 'DELETE_CNAES',
  /** Excluir Contatos */
  DeleteContacts = 'DELETE_CONTACTS',
  /** Excluir Certificados Digitais */
  DeleteDigitalCertificates = 'DELETE_DIGITAL_CERTIFICATES',
  /** Deletar Assinaturas */
  DeleteEnrollments = 'DELETE_ENROLLMENTS',
  /** Excluir Ajustes em Assinaturas */
  DeleteEnrollmentAdjustments = 'DELETE_ENROLLMENT_ADJUSTMENTS',
  /** Excluir Itens em Assinaturas */
  DeleteEnrollmentItems = 'DELETE_ENROLLMENT_ITEMS',
  /** Excluir Itens de Serviço de Assinaturas */
  DeleteEnrollmentServiceItems = 'DELETE_ENROLLMENT_SERVICE_ITEMS',
  /** Excluir Arquivos de Empresas */
  DeleteEnterpriseArchives = 'DELETE_ENTERPRISE_ARCHIVES',
  /** Excluir Boletos de Empresas */
  DeleteEnterpriseBills = 'DELETE_ENTERPRISE_BILLS',
  /** Excluir Credenciais de Empresas */
  DeleteEnterpriseCredentials = 'DELETE_ENTERPRISE_CREDENTIALS',
  /** Excluir Documentos de Empresas */
  DeleteEnterpriseDocuments = 'DELETE_ENTERPRISE_DOCUMENTS',
  /** Excluir Associações de Empresas a Itens de Serviço de Assinaturas */
  DeleteEnterpriseEnrollmentServiceItems = 'DELETE_ENTERPRISE_ENROLLMENT_SERVICE_ITEMS',
  /** Excluir Notas Fiscais de Empresas */
  DeleteEnterpriseInvoices = 'DELETE_ENTERPRISE_INVOICES',
  /** Excluir Fechamentos Mensais */
  DeleteEnterpriseMonthlyConsolidations = 'DELETE_ENTERPRISE_MONTHLY_CONSOLIDATIONS',
  /** Excluir Onboardings de Empresas */
  DeleteEnterpriseOnboardings = 'DELETE_ENTERPRISE_ONBOARDINGS',
  /** Excluir Pendências de Onboardings de Empresas */
  DeleteEnterpriseOnboardingPendencies = 'DELETE_ENTERPRISE_ONBOARDING_PENDENCIES',
  /** Excluir Relatórios de Empresas */
  DeleteEnterpriseReports = 'DELETE_ENTERPRISE_REPORTS',
  /** Excluir Tomadores de Serviços de Empresas */
  DeleteEnterpriseServiceTakers = 'DELETE_ENTERPRISE_SERVICE_TAKERS',
  /** Excluir Guias de Impostos de Empresas */
  DeleteEnterpriseTaxGuides = 'DELETE_ENTERPRISE_TAX_GUIDES',
  /** Excluir Cartões na Carteira de Empresas */
  DeleteEnterpriseWalletCards = 'DELETE_ENTERPRISE_WALLET_CARDS',
  /** Excluir Declarações de IRPF */
  DeleteIrpfStatements = 'DELETE_IRPF_STATEMENTS',
  /** Excluir Anexos de Declarações de IRPF */
  DeleteIrpfStatementAttachments = 'DELETE_IRPF_STATEMENT_ATTACHMENTS',
  /** Excluir Pendências de Declarações de IRPF */
  DeleteIrpfStatementPendencies = 'DELETE_IRPF_STATEMENT_PENDENCIES',
  /** Excluir Comunicados */
  DeleteMailboxMessages = 'DELETE_MAILBOX_MESSAGES',
  /** Excluir Campanhas de Marketing */
  DeleteMarketingCampaigns = 'DELETE_MARKETING_CAMPAIGNS',
  /** Excluir Cobranças Pontuais */
  DeleteOrders = 'DELETE_ORDERS',
  /** Excluir Parceiros */
  DeletePartners = 'DELETE_PARTNERS',
  /** Excluir Grupos de Permissão */
  DeleteRoles = 'DELETE_ROLES',
  /** Excluir Vendas */
  DeleteSales = 'DELETE_SALES',
  /** Excluir Anexos de Vendas */
  DeleteSaleAttachments = 'DELETE_SALE_ATTACHMENTS',
  /** Excluir Itens de Serviço em Vendas */
  DeleteSaleServiceItems = 'DELETE_SALE_SERVICE_ITEMS',
  /** Excluir Itens de Serviço */
  DeleteServiceItems = 'DELETE_SERVICE_ITEMS',
  /** Excluir Usuários */
  DeleteUsers = 'DELETE_USERS',
  /** Finalizar Declarações de IRPF */
  FinishIrpfStatements = 'FINISH_IRPF_STATEMENTS',
  /** Cadastrar CNAEs */
  InsertCnaes = 'INSERT_CNAES',
  /** Cadastrar Contatos */
  InsertContacts = 'INSERT_CONTACTS',
  /** Cadastrar Certificados Digitais */
  InsertDigitalCertificates = 'INSERT_DIGITAL_CERTIFICATES',
  /** Cadastrar Ajustes em Assinaturas */
  InsertEnrollmentAdjustments = 'INSERT_ENROLLMENT_ADJUSTMENTS',
  /** Cadastrar Itens em Assinaturas */
  InsertEnrollmentItems = 'INSERT_ENROLLMENT_ITEMS',
  /** Cadastrar Itens de Serviço de Assinaturas */
  InsertEnrollmentServiceItems = 'INSERT_ENROLLMENT_SERVICE_ITEMS',
  /** Cadastrar Arquivo de Empresas */
  InsertEnterpriseArchives = 'INSERT_ENTERPRISE_ARCHIVES',
  /** Cadastrar Boletos de Empresas */
  InsertEnterpriseBills = 'INSERT_ENTERPRISE_BILLS',
  /** Cadastrar Credenciais de Empresas */
  InsertEnterpriseCredentials = 'INSERT_ENTERPRISE_CREDENTIALS',
  /** Cadastrar Documentos de Empresas */
  InsertEnterpriseDocuments = 'INSERT_ENTERPRISE_DOCUMENTS',
  /** Cadastrar Associações de Empresas a Itens de Serviço de Assinaturas */
  InsertEnterpriseEnrollmentServiceItems = 'INSERT_ENTERPRISE_ENROLLMENT_SERVICE_ITEMS',
  /** Cadastrar Notas Fiscais de Empresas */
  InsertEnterpriseInvoices = 'INSERT_ENTERPRISE_INVOICES',
  /** Cadastrar Fechamentos Mensais */
  InsertEnterpriseMonthlyConsolidations = 'INSERT_ENTERPRISE_MONTHLY_CONSOLIDATIONS',
  /** Cadastrar Pendências de Onboardings de Empresas */
  InsertEnterpriseOnboardingPendencies = 'INSERT_ENTERPRISE_ONBOARDING_PENDENCIES',
  /** Cadastrar Relatórios de Empresas */
  InsertEnterpriseReports = 'INSERT_ENTERPRISE_REPORTS',
  /** Cadastrar Tomadores de Serviços de Empresas */
  InsertEnterpriseServiceTakers = 'INSERT_ENTERPRISE_SERVICE_TAKERS',
  /** Cadastrar Guias de Impostos de Empresas */
  InsertEnterpriseTaxGuides = 'INSERT_ENTERPRISE_TAX_GUIDES',
  /** Cadastrar Cartões na Carteira de Empresas */
  InsertEnterpriseWalletCards = 'INSERT_ENTERPRISE_WALLET_CARDS',
  /** Cadastrar Anexos de Declarações de IRPF */
  InsertIrpfStatementAttachments = 'INSERT_IRPF_STATEMENT_ATTACHMENTS',
  /** Cadastrar Pendências de Declarações de IRPF */
  InsertIrpfStatementPendencies = 'INSERT_IRPF_STATEMENT_PENDENCIES',
  /** Cadastrar Comunicados */
  InsertMailboxMessages = 'INSERT_MAILBOX_MESSAGES',
  /** Cadastrar Campanhas de Marketing */
  InsertMarketingCampaigns = 'INSERT_MARKETING_CAMPAIGNS',
  /** Cadastrar Cobranças Pontuais */
  InsertOrders = 'INSERT_ORDERS',
  /** Cadastrar Parceiros */
  InsertPartners = 'INSERT_PARTNERS',
  /** Cadastrar Grupos de Permissão */
  InsertRoles = 'INSERT_ROLES',
  /** Cadastrar Vendas */
  InsertSales = 'INSERT_SALES',
  /** Cadastrar Anexos de Vendas */
  InsertSaleAttachments = 'INSERT_SALE_ATTACHMENTS',
  /** Cadastrar Itens de Serviço em Vendas */
  InsertSaleServiceItems = 'INSERT_SALE_SERVICE_ITEMS',
  /** Cadastrar Itens de Serviço */
  InsertServiceItems = 'INSERT_SERVICE_ITEMS',
  /** Cadastrar Usuários */
  InsertUsers = 'INSERT_USERS',
  /** Gerenciar Humor de Empresas */
  ManageEnterpriseMoods = 'MANAGE_ENTERPRISE_MOODS',
  /** Alterar contabilização de Documentos de Empresas */
  ToggleAccountedEnterpriseDocuments = 'TOGGLE_ACCOUNTED_ENTERPRISE_DOCUMENTS',
  /** Atualizar Funcionários de Backoffice */
  UpdateBackofficeEmployees = 'UPDATE_BACKOFFICE_EMPLOYEES',
  /** Atualizar CNAEs */
  UpdateCnaes = 'UPDATE_CNAES',
  /** Atualizar Contatos */
  UpdateContacts = 'UPDATE_CONTACTS',
  /** Atualizar Certificados Digitais */
  UpdateDigitalCertificates = 'UPDATE_DIGITAL_CERTIFICATES',
  /** Atualizar Assinaturas */
  UpdateEnrollments = 'UPDATE_ENROLLMENTS',
  /** Atualizar Cartão a ser utilizado na Assinatura */
  UpdateEnrollmentCurrentWalletCard = 'UPDATE_ENROLLMENT_CURRENT_WALLET_CARD',
  /** Atualizar Valor Mensal da Assinatura */
  UpdateEnrollmentMonthlyFee = 'UPDATE_ENROLLMENT_MONTHLY_FEE',
  /** Atualizar Método de Pagamento da Assinatura */
  UpdateEnrollmentPaymentMethod = 'UPDATE_ENROLLMENT_PAYMENT_METHOD',
  /** Atualizar Itens de Serviço de Assinaturas */
  UpdateEnrollmentServiceItems = 'UPDATE_ENROLLMENT_SERVICE_ITEMS',
  /** Atualizar Empresas */
  UpdateEnterprises = 'UPDATE_ENTERPRISES',
  /** Atualizar Arquivos de Empresas */
  UpdateEnterpriseArchives = 'UPDATE_ENTERPRISE_ARCHIVES',
  /** Atualizar Boletos de Empresas */
  UpdateEnterpriseBills = 'UPDATE_ENTERPRISE_BILLS',
  /** Atualizar Credenciais de Empresas */
  UpdateEnterpriseCredentials = 'UPDATE_ENTERPRISE_CREDENTIALS',
  /** Atualizar Documentos de Empresas */
  UpdateEnterpriseDocuments = 'UPDATE_ENTERPRISE_DOCUMENTS',
  /** Atualizar Associações de Empresas a Itens de Serviço de Assinaturas */
  UpdateEnterpriseEnrollmentServiceItems = 'UPDATE_ENTERPRISE_ENROLLMENT_SERVICE_ITEMS',
  /** Atualizar Notas Fiscais de Empresas */
  UpdateEnterpriseInvoices = 'UPDATE_ENTERPRISE_INVOICES',
  /** Atualizar Fechamentos Mensais */
  UpdateEnterpriseMonthlyConsolidations = 'UPDATE_ENTERPRISE_MONTHLY_CONSOLIDATIONS',
  /** Atualizar Onboardings de Empresas */
  UpdateEnterpriseOnboardings = 'UPDATE_ENTERPRISE_ONBOARDINGS',
  /** Atualizar Pendências de Onboardings de Empresas */
  UpdateEnterpriseOnboardingPendencies = 'UPDATE_ENTERPRISE_ONBOARDING_PENDENCIES',
  /** Atualizar Relatórios de Empresas */
  UpdateEnterpriseReports = 'UPDATE_ENTERPRISE_REPORTS',
  /** Atualizar Tomadores de Serviços de Empresas */
  UpdateEnterpriseServiceTakers = 'UPDATE_ENTERPRISE_SERVICE_TAKERS',
  /** Alterar o status de ativo de uma empresa */
  UpdateEnterpriseStatus = 'UPDATE_ENTERPRISE_STATUS',
  /** Atualizar Guias de Impostos de Empresas */
  UpdateEnterpriseTaxGuides = 'UPDATE_ENTERPRISE_TAX_GUIDES',
  /** Atualizar Declarações de IRPF */
  UpdateIrpfStatements = 'UPDATE_IRPF_STATEMENTS',
  /** Atualizar Anexos de Declarações de IRPF */
  UpdateIrpfStatementAttachments = 'UPDATE_IRPF_STATEMENT_ATTACHMENTS',
  /** Atualizar Pendências de Declarações de IRPF */
  UpdateIrpfStatementPendencies = 'UPDATE_IRPF_STATEMENT_PENDENCIES',
  /** Atualizar Campanhas de Marketing */
  UpdateMarketingCampaigns = 'UPDATE_MARKETING_CAMPAIGNS',
  /** Atualizar Parceiros */
  UpdatePartners = 'UPDATE_PARTNERS',
  /** Atualizar Grupos de Permissão */
  UpdateRoles = 'UPDATE_ROLES',
  /** Atualizar Vendas */
  UpdateSales = 'UPDATE_SALES',
  /** Atualizar Anexos de Vendas */
  UpdateSaleAttachments = 'UPDATE_SALE_ATTACHMENTS',
  /** Atualizar Itens de Serviço em Vendas */
  UpdateSaleServiceItems = 'UPDATE_SALE_SERVICE_ITEMS',
  /** Atualizar Itens de Serviço */
  UpdateServiceItems = 'UPDATE_SERVICE_ITEMS',
  /** Atualizar Configurações do Sistema */
  UpdateSystemSettings = 'UPDATE_SYSTEM_SETTINGS',
  /** Atualizar Usuários */
  UpdateUsers = 'UPDATE_USERS',
  /** Visualizar Contatos */
  ViewContacts = 'VIEW_CONTACTS',
  /** Visualizar Certificados Digitais */
  ViewDigitalCertificates = 'VIEW_DIGITAL_CERTIFICATES',
  /** Visualizar Assinaturas */
  ViewEnrollments = 'VIEW_ENROLLMENTS',
  /** Visualizar Ajustes em Assinaturas */
  ViewEnrollmentAdjustments = 'VIEW_ENROLLMENT_ADJUSTMENTS',
  /** Visualizar Itens em Assinaturas */
  ViewEnrollmentItems = 'VIEW_ENROLLMENT_ITEMS',
  /** Visualizar Itens de Serviço de Assinaturas */
  ViewEnrollmentServiceItems = 'VIEW_ENROLLMENT_SERVICE_ITEMS',
  /** Visualizar Empresas */
  ViewEnterprises = 'VIEW_ENTERPRISES',
  /** Visualizar Arquivos de Empresas */
  ViewEnterpriseArchives = 'VIEW_ENTERPRISE_ARCHIVES',
  /** Visualizar Boletos de Empresas */
  ViewEnterpriseBills = 'VIEW_ENTERPRISE_BILLS',
  /** Visualizar Credenciais de Empresas */
  ViewEnterpriseCredentials = 'VIEW_ENTERPRISE_CREDENTIALS',
  /** Visualizar Documentos de Empresas */
  ViewEnterpriseDocuments = 'VIEW_ENTERPRISE_DOCUMENTS',
  /** Visualizar Associações de Empresas a Itens de Serviço de Assinaturas */
  ViewEnterpriseEnrollmentServiceItems = 'VIEW_ENTERPRISE_ENROLLMENT_SERVICE_ITEMS',
  /** Visualizar Notas Fiscais de Empresas */
  ViewEnterpriseInvoices = 'VIEW_ENTERPRISE_INVOICES',
  /** Visualizar Fechamentos Mensais */
  ViewEnterpriseMonthlyConsolidations = 'VIEW_ENTERPRISE_MONTHLY_CONSOLIDATIONS',
  /** Visualizar Onboardings de Empresas */
  ViewEnterpriseOnboardings = 'VIEW_ENTERPRISE_ONBOARDINGS',
  /** Visualizar Pendências de Onboardings de Empresas */
  ViewEnterpriseOnboardingPendencies = 'VIEW_ENTERPRISE_ONBOARDING_PENDENCIES',
  /** Visualizar Relatórios de Empresas */
  ViewEnterpriseReports = 'VIEW_ENTERPRISE_REPORTS',
  /** Visualizar Tomadores de Serviços de Empresas */
  ViewEnterpriseServiceTakers = 'VIEW_ENTERPRISE_SERVICE_TAKERS',
  /** Visualizar Guias de Impostos de Empresas */
  ViewEnterpriseTaxGuides = 'VIEW_ENTERPRISE_TAX_GUIDES',
  /** Visualizar Cartões na Carteira de Empresas */
  ViewEnterpriseWalletCards = 'VIEW_ENTERPRISE_WALLET_CARDS',
  /** Visualizar Declarações de IRPF */
  ViewIrpfStatements = 'VIEW_IRPF_STATEMENTS',
  /** Visualizar Anexos de Declarações de IRPF */
  ViewIrpfStatementAttachments = 'VIEW_IRPF_STATEMENT_ATTACHMENTS',
  /** Visualizar Pendências de Declarações de IRPF */
  ViewIrpfStatementPendencies = 'VIEW_IRPF_STATEMENT_PENDENCIES',
  /** Visualizar Comunicados */
  ViewMailboxMessages = 'VIEW_MAILBOX_MESSAGES',
  /** Visualizar Cobranças Pontuais */
  ViewOrders = 'VIEW_ORDERS',
  /** Visualizar Parceiros */
  ViewPartners = 'VIEW_PARTNERS',
  /** Visualizar Vendas */
  ViewSales = 'VIEW_SALES',
  /** Visualizar Anexos de Vendas */
  ViewSaleAttachments = 'VIEW_SALE_ATTACHMENTS',
  /** Visualizar Itens de Serviço em Vendas */
  ViewSaleServiceItems = 'VIEW_SALE_SERVICE_ITEMS',
  /** Visualizar Itens de Serviço */
  ViewServiceItems = 'VIEW_SERVICE_ITEMS',
  /** Visualizar Configurações do Sistema */
  ViewSystemSettings = 'VIEW_SYSTEM_SETTINGS',
  /** Visualizar listagem de Usuários */
  ViewUsers = 'VIEW_USERS',
  /** Visualizar detalhes completo de Usuários */
  ViewUserDetails = 'VIEW_USER_DETAILS',
}

export enum RoleScope {
  /** Usuários de Backoffice */
  BackofficeUser = 'BACKOFFICE_USER',
  /** Usuários */
  User = 'USER',
}

/** Payload para filtragem de campos do tipo RoleScope */
export type RoleScopeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<RoleScope>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<RoleScope>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<RoleScope>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<RoleScope>>>
}

/** Payload de atualização de um grupo de permissão */
export type RoleUpdatePayload = {
  /** Descrição do grupo */
  description?: InputMaybe<Scalars['String']['input']>
  /** Nome amigável do grupo */
  displayName?: InputMaybe<Scalars['String']['input']>
  /** Nome 'slugificado', único no contexto, do grupo */
  name?: InputMaybe<Scalars['String']['input']>
  /** Políticas aplicadas a este grupo de permissão */
  policies?: InputMaybe<Array<RolePolicy>>
  /** Escopo que o grupo de permissão é utilizado */
  scope?: InputMaybe<RoleScope>
}

/** Payload para filtragem de grupos */
export type RolesFilterPayload = {
  /** Filtros para o nome amigável do grupo */
  displayName?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do grupo */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome do grupo */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<RolesFilterPayload>>
  /** Filtros para o escopo do grupo */
  scope?: InputMaybe<RoleScopeFilterPayload>
}

/** Lista de grupos de permissão e seus metadados de paginação */
export type RolesPaginatedList = {
  __typename?: 'RolesPaginatedList'
  /** Dados recuperados */
  entries: Array<Role>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

export type RootMutationType = {
  __typename?: 'RootMutationType'
  /** Marca um contrato como lido e aceito */
  acceptEnterpriseOnboardingContract: EnterpriseOnboarding
  /** Aceita um convite destinado ao usuário atual autenticado */
  acceptEnterpriseUserInvitation: EnterpriseUser
  /** Usuário de Backoffice insere ou atualiza os dados da contabilidade atual no Onboarding de Migração */
  backofficeUserUpsertEnterpriseOnboardingMigrationAccountingData: EnterpriseOnboarding
  /** Cancela uma Solicitação de Nota Fiscal da empresa do contexto do usuário autenticado. Não pode ser feita se a solicitação já está finalizada. */
  cancelEnterpriseInvoiceRequest: EnterpriseInvoiceRequest
  /** Marca Pendência de Onboarding como resolvida */
  closeEnterpriseOnboardingPendency: EnterpriseOnboardingPendency
  /** Marca o contrato social no Onboarding de Abertura como confirmado */
  confirmEnterpriseOnboardingOpeningSocialContract: EnterpriseOnboarding
  /** Confirma o cadastro de um novo usuário, a partir de um token válido de confirmação de e-mail */
  confirmUserAccount: UserAccount
  /** Confirma a alteração de um e-mail de usuário, a partir de um token válido de atualização de e-mail */
  confirmUserUpdateEmail: Scalars['Boolean']['output']
  /** Converte uma Venda */
  convertSale: Sale
  /** Insere um novo Documento */
  currentEnterpriseInsertEnterpriseDocument: EnterpriseDocument
  /** Insere um nova Solicitação de Nota Fiscal na empresa do contexto do usuário autenticado */
  currentEnterpriseInsertEnterpriseInvoiceRequest: EnterpriseInvoiceRequest
  /** Insere um novo Tomador de Serviço da empresa atual do contexto */
  currentEnterpriseInsertServiceTaker: EnterpriseServiceTaker
  /** Insere uma Venda de Migração de Empresa a partir do contexto do usuário atual. Retorna o código da venda. */
  currentUserRequestEnterpriseMigrationSale: Scalars['Int']['output']
  /** Insere uma Venda de Abertura de Empresa a partir do contexto do usuário atual. Retorna o código da venda. */
  currentUserRequestEnterpriseOpeningSale: Scalars['Int']['output']
  /** Atualiza as preferências de notificação do usuário autenticado para o contexto da empresa atual */
  currentUserUpdateEnterpriseNotificationPreferences: EnterpriseUserNotificationPreferences
  /** Inativa uma assinatura, possivelmente a excluindo da Pagarme */
  deactivateEnrollment: Enrollment
  /** Inativa um Ajuste na Assinatura */
  deactivateEnrollmentAdjustment: EnrollmentAdjustment
  /** Inativa uma empresa */
  deactivateEnterprise: Enterprise
  /** Agenda a Inativação de uma empresa */
  deactivateEnterpriseLater: Enterprise
  /** Remove um Contato */
  deleteContact: Contact
  /** Remove uma Interação com o Contato */
  deleteContactInteraction: ContactInteraction
  /** Exclui um cartão de uma carteira da empresa atual do contexto */
  deleteCurrentEnterpriseWalletCard: Scalars['Boolean']['output']
  /** Solicita a exclusão do usuário autenticado (obrigatório por LGPD) */
  deleteCurrentUser: UserAccount
  /** Exclui um cartão de uma carteira do usuário do contexto */
  deleteCurrentUserWalletCard: Scalars['Boolean']['output']
  /** Remove um Certificado Digital */
  deleteDigitalCertificate: DigitalCertificate
  /** Exclui um item na assinatura */
  deleteEnrollmentItem: Scalars['Boolean']['output']
  /** Remove um Arquivo */
  deleteEnterpriseArchive: EnterpriseArchive
  /** Remove um Boleto */
  deleteEnterpriseBill: EnterpriseBill
  /** Remove a associação de um CNAE com uma Empresa */
  deleteEnterpriseCnae: EnterpriseCnae
  /** Remove um Comentário na Empresa */
  deleteEnterpriseComment: EnterpriseComment
  /** Remove um Relatório */
  deleteEnterpriseCredential: EnterpriseCredential
  /** Remove um Documento */
  deleteEnterpriseDocument: EnterpriseDocument
  /** Exclui uma associação de uma empresa a um item de serviço */
  deleteEnterpriseEnrollmentServiceItem: Scalars['Boolean']['output']
  /** Remove uma Nota Fiscal */
  deleteEnterpriseInvoice: EnterpriseInvoice
  /** Remove um Fechamento Mensal */
  deleteEnterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidation
  /** Remove um Humor */
  deleteEnterpriseMood: EnterpriseMood
  /** Remove um Anexo de Onboarding */
  deleteEnterpriseOnboardingAttachment?: Maybe<EnterpriseOnboardingAttachment>
  /** Remove um Sócio do Onboarding */
  deleteEnterpriseOnboardingBusinessPartner?: Maybe<EnterpriseOnboardingBusinessPartner>
  /** Remove um Comentário do Onboarding */
  deleteEnterpriseOnboardingComment: EnterpriseOnboardingComment
  /** Exclui uma Pendência de Onboarding */
  deleteEnterpriseOnboardingPendency: Scalars['Boolean']['output']
  /** Remove um Relatório */
  deleteEnterpriseReport: EnterpriseReport
  /** Remove um Tomador de Serviço */
  deleteEnterpriseServiceTaker: EnterpriseServiceTaker
  /** Remove uma Guia de Imposto */
  deleteEnterpriseTaxGuide: EnterpriseTaxGuide
  /** Exclui o acesso de um usuário à uma empresa. Deve ser da empresa do contexto atual do usuário autenticado, se não estiver credenciado como um usuário de backoffice */
  deleteEnterpriseUser: EnterpriseUser
  /** Exclui um convite de usuário para uma empresa. Deve ser da empresa do contexto atual do usuário autenticado, se não estiver credenciado como um usuário de backoffice */
  deleteEnterpriseUserInvitation: EnterpriseUserInvitation
  /** Exclui uma declaração */
  deleteIrpfStatement: Scalars['Boolean']['output']
  /** Exclui um anexo de declaração de IRPF */
  deleteIrpfStatementAttachment: Scalars['Boolean']['output']
  /** Exclui uma pendência de declaração de IRPF */
  deleteIrpfStatementPendency: Scalars['Boolean']['output']
  /** Remove um Comunicado */
  deleteMailboxMessage: MailboxMessage
  /** Exclui uma campanha */
  deleteMarketingCampaign: Scalars['Boolean']['output']
  /** Remove um Pedido */
  deleteOrder: Order
  /** Remove uma Venda */
  deleteSale: Sale
  /** Remove um Anexo de Venda */
  deleteSaleAttachment: SaleAttachment
  /** Remove um Item de Serviço em Venda */
  deleteSaleServiceItem: SaleServiceItem
  /** Exclui um item de serviço */
  deleteServiceItem: Scalars['Boolean']['output']
  /** Define um token de confirmação de email de um Usuário e envia por email */
  deliverUserConfirmationInstructions: Scalars['Boolean']['output']
  /** Define um token de reset de senha de um Usuário e envia por email */
  deliverUserResetPasswordInstructions: Scalars['Boolean']['output']
  /** Define um token de alteração de email do Usuário autenticado e envia no seu novo email */
  deliverUserUpdateEmailInstructions: Scalars['Boolean']['output']
  /** Descarta um Onboarding */
  discardEnterpriseOnboarding: EnterpriseOnboarding
  /** Descarta uma Venda */
  discardSale: Sale
  /** Busca dados da SERPRO, se ainda não houver, referente à um CNPJ e o preenche no campo de informações empresariais */
  fetchEnterpriseOnboardingEnterpriseInfo: EnterpriseOnboarding
  /** Busca dados da SERPRO, se ainda não houver, referente à um CNPJ e o preenche no campo de informações empresariais */
  fetchSaleEnterpriseInfo: Sale
  /** Finaliza uma etapa do Onboarding */
  finishEnterpriseOnboardingCurrentStep: EnterpriseOnboarding
  /** Finaliza a solicitação de uma declaração de IRPF */
  finishRequestedIrpfStatement: IrpfStatement
  /** Gera um Pedido a partir de uma venda */
  generateOrderFromSale: Order
  /** Insere um novo Processamento Massivo */
  insertBulkUpload: BulkUpload
  /** Insere um novo CNAE */
  insertCnae: Cnae
  /** Insere um novo Contato */
  insertContact: Contact
  /** Insere um novo Interação com o Contato */
  insertContactInteraction: ContactInteraction
  /** Cria um cartão na carteira da empresa atual do contexto */
  insertCurrentEnterpriseWalletCard: WalletCard
  /** Cria um cartão na carteira do usuário do contexto */
  insertCurrentUserWalletCard: WalletCard
  /** Insere um novo Certificado Digital */
  insertDigitalCertificate: DigitalCertificate
  /** Insere um novo Ajuste na Assinatura */
  insertEnrollmentAdjustment: EnrollmentAdjustment
  /** Insere um novo item na assinatura */
  insertEnrollmentItem: EnrollmentItem
  /** Insere um novo Arquivo */
  insertEnterpriseArchive: EnterpriseArchive
  /** Insere um novo Boleto */
  insertEnterpriseBill: EnterpriseBill
  /** Insere relatórios em massa para uma empresa */
  insertEnterpriseBulkReports?: Maybe<Scalars['Boolean']['output']>
  /** Adiciona um CNAE à lista de uma Empresa */
  insertEnterpriseCnae: EnterpriseCnae
  /** Insere um novo Comentário na Empresa */
  insertEnterpriseComment: EnterpriseComment
  /** Insere uma nova Credencial */
  insertEnterpriseCredential: EnterpriseCredential
  /** Insere uma nova associação de uma empresa a um item de serviço */
  insertEnterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItem
  /** Insere um nova Nota Fiscal */
  insertEnterpriseInvoice: EnterpriseInvoice
  /** Insere um Fechamento Mensal */
  insertEnterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidation
  /** Insere um Humor */
  insertEnterpriseMood: EnterpriseMood
  /** Insere um Anexo de Onboarding */
  insertEnterpriseOnboardingAttachment?: Maybe<EnterpriseOnboardingAttachment>
  /** Insere um Certificado Digital e-CPF para um Sócio do Onboarding */
  insertEnterpriseOnboardingBusinessPartnerEcpf: EnterpriseOnboardingBusinessPartner
  /** Insere um novo Comentário do Onboarding */
  insertEnterpriseOnboardingComment: EnterpriseOnboardingComment
  /** Insere uma Cobrança Pontual com seus Itens de serviço para um Onboarding */
  insertEnterpriseOnboardingOrder: Order
  /** Insere uma nova Pendência de Onboarding */
  insertEnterpriseOnboardingPendency: EnterpriseOnboardingPendency
  /** Insere um cartão de crédito no Onboarding */
  insertEnterpriseOnboardingWalletCard: EnterpriseOnboarding
  /** Insere um novo Relatório */
  insertEnterpriseReport: EnterpriseReport
  /** Insere um Tomador de Serviço */
  insertEnterpriseServiceTaker: EnterpriseServiceTaker
  /** Insere um nova Guia de Imposto */
  insertEnterpriseTaxGuide: EnterpriseTaxGuide
  /** Insere um novo Comunicado para empresas */
  insertEnterprisesMailboxMessage: EnterpriseMailboxMessagesResult
  /** Insere um novo anexo de declaração de IRPF */
  insertIrpfStatementAttachment: IrpfStatementAttachment
  /** Insere uma nova pendência de declaração de IRPF */
  insertIrpfStatementPendency: IrpfStatementPendency
  /** Insere um nova campanha */
  insertMarketingCampaign: MarketingCampaign
  /** Insere um Pedido com seus Itens de serviço */
  insertOrder: Order
  /** Insere um novo grupo de permissão */
  insertRole: Role
  /** Insere uma nova Venda */
  insertSale: Sale
  /** Insere um novo Anexo de Venda */
  insertSaleAttachment: SaleAttachment
  /** Insere um novo Comentário na Venda */
  insertSaleComment: SaleComment
  /** Insere um novo Item de Serviço em Venda */
  insertSaleServiceItem: SaleServiceItem
  /** Insere um novo item de serviço */
  insertServiceItem: ServiceItem
  /** Envia um convite por email para que um usuário junte a lista de membros da empresa do contexto atual do usuário autenticado */
  inviteCurrentUserEnterpriseUserByEmail: EnterpriseUserInvitation
  /** Envia um convite por email para que um usuário junte a lista de membros de uma empresa */
  inviteEnterpriseUserByEmail: EnterpriseUserInvitation
  /** Marca a etapa como pronta para análise */
  markCurrentStepAsReady: Scalars['Boolean']['output']
  /** Marca uma fatura pendente como paga de forma manual. Dá a baixa na conta a receber, se existir, e marca o boleto como pago, se existir */
  markEnrollmentInvoiceAsPaid: EnrollmentInvoice
  /** Registra um recurso como visualizado nos Registros de Logs */
  markEnterpriseResourceAsSeen: Scalars['Boolean']['output']
  /** Finaliza uma declaração de IRPF */
  markIrpfStatementAsFinished: IrpfStatement
  /** Marca uma declaração de IRPF como pendente, notificando o usuário que existem alterações solicitadas */
  markIrpfStatementAsWaitingPendencies: IrpfStatement
  /** Marca uma notificação única como lida */
  markUserNotificationAsSeen: UserNotification
  /** Marca todas as notificações não-lidas da empresa atual do contexto do usuário como lidas */
  markUserNotificationsAsSeen: Scalars['Int']['output']
  /** Reativa uma empresa */
  reactivateEnterprise: Enterprise
  /** Gera, a partir de credenciais válidas (refresh token), um novo access token JWT */
  refreshBackofficeUserAccessToken: Scalars['String']['output']
  /** Gera, a partir de credenciais válidas (refresh token), um novo access token JWT */
  refreshUserAccessToken: Scalars['String']['output']
  /** Rejeita um convite destinado ao usuário atual autenticado */
  rejectEnterpriseUserInvitation: EnterpriseUserInvitation
  /** Insere uma Venda e possivelmente um Contato a partir da ação de interesse em uma LP ou Hubinho. Retorna o código da venda. */
  requestContactSale: Scalars['String']['output']
  /** Solicita a geração de uma declaração de IRPF */
  requestIrpfStatement: IrpfStatement
  /** Atualiza a senha de um Usuário que esqueceu sua senha e resgatou corretamente o token enviado por email */
  resetUserPassword: Scalars['Boolean']['output']
  /** Marca mensagens de pendência de declaração de IRPF como resolvidas */
  resolveIrpfStatementPendencyMessages: IrpfStatementPendency
  /** Autentica um usuário de backoffice a partir de um token do Google, o cadastrando, se não tiver cadastro */
  signInBackofficeUser: BackofficeUserAuthResponse
  /** Gera, a partir de credenciais válidas, um par de JWT (acesso e refresh) e seus dados de usuário */
  signInUser: UserAuthResponse
  /** Gera, a partir de credenciais válidas, e através de SSO do parceiro atrelado ao usuário, um par de JWT (acesso e refresh) e seus dados de usuário */
  signInUserWithPartnerSso: UserAuthResponse
  /** Gera, a partir de credenciais de um JWT de autenticação pré-assinado válido, um par de JWT (acesso e refresh) e seus dados de usuário */
  signInUserWithPresignedToken: UserAuthResponse
  /** Invalida, a partir de credenciais válidas, um refresh token de um usuário de backoffice */
  signOutBackofficeUser: Scalars['Boolean']['output']
  /** Invalida, a partir de credenciais válidas, um refresh token de um usuário */
  signOutUser: Scalars['Boolean']['output']
  /** Realiza o cadastro de um usuário */
  signUpUser: UserAuthResponse
  /** Atualiza o status de contabilização de um Documento */
  toggleAccountedDocument: EnterpriseDocument
  /** Atualiza o Grupo de Permissão de um Usuário de Backoffice */
  updateBackofficeUserRole: BackofficeUserAccount
  /** Atualiza um CNAE */
  updateCnae: Cnae
  /** Atualiza um Contato */
  updateContact: Contact
  /** Atualiza uma Interação com o Contato */
  updateContactInteraction: ContactInteraction
  /** Atualiza cartão a ser utilizado na assinatura */
  updateCurrentEnterpriseEnrollmentWalletCard: Enrollment
  /** Atualiza os dados cadastrais do usuário autenticado */
  updateCurrentUser: UserAccount
  /** Atualiza o grupo de permissão de um usuário da empresa do contexto atual do usuário autenticado */
  updateCurrentUserEnterpriseUserRole: EnterpriseUser
  /** Atualiza um Certificado Digital */
  updateDigitalCertificate: DigitalCertificate
  /** Atualiza o método de pagamento da assinatura */
  updateEnrollmentPaymentMethod: Enrollment
  /** Atualiza os dados de uma Empresa */
  updateEnterprise: Enterprise
  /** Atualiza um Arquvio */
  updateEnterpriseArchive: EnterpriseArchive
  /** Atualiza um Boleto */
  updateEnterpriseBill: EnterpriseBill
  /** Atualiza uma Credencial */
  updateEnterpriseCredential: EnterpriseCredential
  /** Atualiza um Documento */
  updateEnterpriseDocument: EnterpriseDocument
  /** Atualiza uma associação de uma empresa a um item de serviço */
  updateEnterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItem
  /** Atualiza uma Nota Fiscal */
  updateEnterpriseInvoice: EnterpriseInvoice
  /** Atualiza o status de uma Solicitação de Nota Fiscal de uma Empresa */
  updateEnterpriseInvoiceRequestStatus: EnterpriseInvoiceRequest
  /** Atualiza um Fechamento Mensal */
  updateEnterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidation
  /** Atualiza os dados básicos do Onboarding */
  updateEnterpriseOnboarding: EnterpriseOnboarding
  /** Atualiza dados de abertura de CNPJ no Onboarding de Abertura */
  updateEnterpriseOnboardingOpeningInfo: EnterpriseOnboarding
  /** Atualiza o Parceiro do Onboarding */
  updateEnterpriseOnboardingPartner: EnterpriseOnboarding
  /** Atualiza os dados de uma Pendência de Onboarding */
  updateEnterpriseOnboardingPendency: EnterpriseOnboardingPendency
  /** Atualiza o CNAE principal de uma Empresa */
  updateEnterprisePrimaryCnae: EnterpriseCnae
  /** Atualiza um Relatório */
  updateEnterpriseReport: EnterpriseReport
  /** Atualiza um Tomador de Serviço */
  updateEnterpriseServiceTaker: EnterpriseServiceTaker
  /** Atualiza uma Guia de Imposto */
  updateEnterpriseTaxGuide: EnterpriseTaxGuide
  /** Atualiza o status de pagamento de ua Guia de Imposto da empresa do contexto do usuário autenticado */
  updateEnterpriseTaxGuidePaymentStatus: EnterpriseTaxGuide
  /** Atualiza o dono de uma empresa. Deve ser da empresa do contexto atual do usuário autenticado, se não estiver credenciado como um usuário de backoffice */
  updateEnterpriseUserOwner: EnterpriseUser
  /** Atualiza o grupo de permissões do usuário de uma empresa */
  updateEnterpriseUserRole: EnterpriseUser
  /** Atualiza uma declaracao de IRPF */
  updateIrpfStatement: IrpfStatement
  /** Atualiza um anexo de declaração de IRPF */
  updateIrpfStatementAttachment: IrpfStatementAttachment
  /** Atualiza uma campanha */
  updateMarketingCampaign: MarketingCampaign
  /** Atualiza uma solicitação de declaração de IRPF */
  updateRequestedIrpfStatement: IrpfStatement
  /** Altera o responsável pela solicitação de uma declaração de IRPF */
  updateRequestedIrpfStatementResponsible: IrpfStatement
  /** Altera o tipo de uma declaração de IRPF */
  updateRequestedIrpfStatementType: IrpfStatement
  /** Atualiza um grupo de permissão */
  updateRole: Role
  /** Atualiza uma Venda */
  updateSale: Sale
  /** Atualiza um Anexo de Venda */
  updateSaleAttachment: SaleAttachment
  /** Atualiza um Item de Serviço em Venda */
  updateSaleServiceItem: SaleServiceItem
  /** Atualiza um item de serviço */
  updateServiceItem: ServiceItem
  /** Atualiza a senha de um Usuário que a deseja atualizar */
  updateUserPassword: Scalars['Boolean']['output']
  /** Insere ou atualiza uma associação de uma empresa a um item de serviço */
  upsertEnterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItem
  /** Insere ou atualiza um Sócio do Onboarding */
  upsertEnterpriseOnboardingBusinessPartner: EnterpriseOnboardingBusinessPartner
  /** Insere ou atualiza os dados da contabilidade atual no Onboarding de Migração */
  upsertEnterpriseOnboardingMigrationAccountingData: EnterpriseOnboarding
  /** Insere ou atualiza os dados e documentos no Onboarding de Migração */
  upsertEnterpriseOnboardingMigrationDataAndDocuments: EnterpriseOnboarding
  /** Insere ou atualiza os dados e documentos no Onboarding de Abertura */
  upsertEnterpriseOnboardingOpeningDataAndDocuments: EnterpriseOnboarding
  /** Insere ou atualiza os dados de emissão de e-CPF no Onboarding de Abertura */
  upsertEnterpriseOnboardingOpeningEcpfData: EnterpriseOnboarding
  /** Insere ou atualiza uma configuração de sistema */
  upsertSystemSetting: SystemSetting
  /** Valida se um token de recuperação de senha é válido para um endereço de e-mail */
  verifyUserWithResetPasswordToken: Scalars['Boolean']['output']
}

export type RootMutationTypeAcceptEnterpriseOnboardingContractArgs = {
  id: Scalars['UUID']['input']
  type: EnterpriseOnboardingContractType
}

export type RootMutationTypeAcceptEnterpriseUserInvitationArgs = {
  invitationId: Scalars['UUID']['input']
}

export type RootMutationTypeBackofficeUserUpsertEnterpriseOnboardingMigrationAccountingDataArgs = {
  accountingData: BackofficeUserEnterpriseOnboardingMigrationAccountingDataUpsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeCancelEnterpriseInvoiceRequestArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeCloseEnterpriseOnboardingPendencyArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeConfirmEnterpriseOnboardingOpeningSocialContractArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeConfirmUserAccountArgs = {
  token: Scalars['String']['input']
}

export type RootMutationTypeConfirmUserUpdateEmailArgs = {
  token: Scalars['String']['input']
}

export type RootMutationTypeConvertSaleArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeCurrentEnterpriseInsertEnterpriseDocumentArgs = {
  enterpriseDocument: CurrentEnterpriseDocumentInsertPayload
}

export type RootMutationTypeCurrentEnterpriseInsertEnterpriseInvoiceRequestArgs = {
  enterpriseInvoiceRequest: EnterpriseInvoiceRequestInsertPayload
}

export type RootMutationTypeCurrentEnterpriseInsertServiceTakerArgs = {
  enterpriseServiceTaker: CurrentEnterpriseServiceTakerInsertPayload
}

export type RootMutationTypeCurrentUserRequestEnterpriseMigrationSaleArgs = {
  cnpj: Scalars['CNPJ']['input']
}

export type RootMutationTypeCurrentUserUpdateEnterpriseNotificationPreferencesArgs = {
  notificationPreferences: EnterpriseUserNotificationPreferencesUpdatePayload
}

export type RootMutationTypeDeactivateEnrollmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeactivateEnrollmentAdjustmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeactivateEnterpriseArgs = {
  deactivateInfo: DeactivateInfoPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeactivateEnterpriseLaterArgs = {
  deactivateInfo: DeactivateInfoPayload
  deactivatedAt: Scalars['Date']['input']
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteContactArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteContactInteractionArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteCurrentEnterpriseWalletCardArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteCurrentUserWalletCardArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteDigitalCertificateArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnrollmentItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseArchiveArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseBillArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseCnaeArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseCommentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseCredentialArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseDocumentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseEnrollmentServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseInvoiceArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseMonthlyConsolidationArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseMoodArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseOnboardingAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseOnboardingBusinessPartnerArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseOnboardingCommentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseOnboardingPendencyArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseReportArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseServiceTakerArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseTaxGuideArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseUserArgs = {
  enterpriseUserId: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteEnterpriseUserInvitationArgs = {
  invitationId: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteIrpfStatementArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteIrpfStatementAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteIrpfStatementPendencyArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteMailboxMessageArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteMarketingCampaignArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteOrderArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteSaleArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteSaleAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteSaleServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeleteServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDeliverUserResetPasswordInstructionsArgs = {
  email: Scalars['String']['input']
}

export type RootMutationTypeDeliverUserUpdateEmailInstructionsArgs = {
  currentPassword: Scalars['String']['input']
  newEmail: Scalars['String']['input']
}

export type RootMutationTypeDiscardEnterpriseOnboardingArgs = {
  discardInfo: DiscardEnterpriseOnboardingPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeDiscardSaleArgs = {
  discardInfo: DiscardSalePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeFetchEnterpriseOnboardingEnterpriseInfoArgs = {
  cnpj: Scalars['CNPJ']['input']
  id: Scalars['UUID']['input']
}

export type RootMutationTypeFetchSaleEnterpriseInfoArgs = {
  cnpj: Scalars['CNPJ']['input']
  id: Scalars['UUID']['input']
}

export type RootMutationTypeFinishEnterpriseOnboardingCurrentStepArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeFinishRequestedIrpfStatementArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeGenerateOrderFromSaleArgs = {
  enterpriseId?: InputMaybe<Scalars['UUID']['input']>
  saleId: Scalars['UUID']['input']
  userId?: InputMaybe<Scalars['UUID']['input']>
}

export type RootMutationTypeInsertBulkUploadArgs = {
  bulkUpload: BulkUploadInsertPayload
}

export type RootMutationTypeInsertCnaeArgs = {
  cnae: CnaeInsertPayload
}

export type RootMutationTypeInsertContactArgs = {
  contact: ContactInsertPayload
}

export type RootMutationTypeInsertContactInteractionArgs = {
  contactInteraction: ContactInteractionInsertPayload
}

export type RootMutationTypeInsertCurrentEnterpriseWalletCardArgs = {
  walletCard: WalletCardInsertPayload
}

export type RootMutationTypeInsertCurrentUserWalletCardArgs = {
  walletCard: WalletCardInsertPayload
}

export type RootMutationTypeInsertDigitalCertificateArgs = {
  digitalCertificate: DigitalCertificateInsertPayload
}

export type RootMutationTypeInsertEnrollmentAdjustmentArgs = {
  enrollmentAdjustment: EnrollmentAdjustmentInsertPayload
}

export type RootMutationTypeInsertEnrollmentItemArgs = {
  enrollmentItem: EnrollmentItemInsertPayload
}

export type RootMutationTypeInsertEnterpriseArchiveArgs = {
  enterpriseArchive: EnterpriseArchiveInsertPayload
}

export type RootMutationTypeInsertEnterpriseBillArgs = {
  enterpriseBill: EnterpriseBillInsertPayload
}

export type RootMutationTypeInsertEnterpriseBulkReportsArgs = {
  enterpriseId: Scalars['UUID']['input']
  enterpriseReports: Array<EnterpriseBulkReportInsertPayload>
}

export type RootMutationTypeInsertEnterpriseCnaeArgs = {
  cnaeId: Scalars['UUID']['input']
  enterpriseId: Scalars['UUID']['input']
}

export type RootMutationTypeInsertEnterpriseCommentArgs = {
  enterpriseComment: EnterpriseCommentInsertPayload
}

export type RootMutationTypeInsertEnterpriseCredentialArgs = {
  enterpriseCredential: EnterpriseCredentialInsertPayload
}

export type RootMutationTypeInsertEnterpriseEnrollmentServiceItemArgs = {
  enterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItemInsertPayload
}

export type RootMutationTypeInsertEnterpriseInvoiceArgs = {
  enterpriseInvoice: EnterpriseInvoiceInsertPayload
}

export type RootMutationTypeInsertEnterpriseMonthlyConsolidationArgs = {
  enterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidationInsertPayload
}

export type RootMutationTypeInsertEnterpriseMoodArgs = {
  enterpriseMood: EnterpriseMoodInsertPayload
}

export type RootMutationTypeInsertEnterpriseOnboardingAttachmentArgs = {
  enterpriseOnboardingAttachment: EnterpriseOnboardingAttachmentInsertPayload
}

export type RootMutationTypeInsertEnterpriseOnboardingBusinessPartnerEcpfArgs = {
  digitalCertificate: EnterpriseOnboardingBusinessPartnerEcpfInsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeInsertEnterpriseOnboardingCommentArgs = {
  enterpriseOnboardingComment: EnterpriseOnboardingCommentInsertPayload
}

export type RootMutationTypeInsertEnterpriseOnboardingOrderArgs = {
  order: EnterpriseOnboardingOrderInsertPayload
}

export type RootMutationTypeInsertEnterpriseOnboardingPendencyArgs = {
  enterpriseOnboardingPendency: EnterpriseOnboardingPendencyInsertPayload
}

export type RootMutationTypeInsertEnterpriseOnboardingWalletCardArgs = {
  id: Scalars['UUID']['input']
  walletCard: WalletCardInsertPayload
}

export type RootMutationTypeInsertEnterpriseReportArgs = {
  enterpriseReport: EnterpriseReportInsertPayload
}

export type RootMutationTypeInsertEnterpriseServiceTakerArgs = {
  enterpriseServiceTaker: EnterpriseServiceTakerInsertPayload
}

export type RootMutationTypeInsertEnterpriseTaxGuideArgs = {
  enterpriseTaxGuide: EnterpriseTaxGuideInsertPayload
}

export type RootMutationTypeInsertEnterprisesMailboxMessageArgs = {
  enterprisesCodes: Array<Scalars['Int']['input']>
  mailboxMessage: MailboxMessageInsertPayload
}

export type RootMutationTypeInsertIrpfStatementAttachmentArgs = {
  irpfStatementAttachment: IrpfStatementAttachmentInsertPayload
}

export type RootMutationTypeInsertIrpfStatementPendencyArgs = {
  irpfStatementPendency: IrpfStatementPendencyInsertPayload
}

export type RootMutationTypeInsertMarketingCampaignArgs = {
  marketingCampaign: MarketingCampaignInsertPayload
}

export type RootMutationTypeInsertOrderArgs = {
  order: OrderInsertPayload
}

export type RootMutationTypeInsertRoleArgs = {
  role: RoleInsertPayload
}

export type RootMutationTypeInsertSaleArgs = {
  sale: SaleInsertPayload
}

export type RootMutationTypeInsertSaleAttachmentArgs = {
  saleAttachment: SaleAttachmentInsertPayload
}

export type RootMutationTypeInsertSaleCommentArgs = {
  saleComment: SaleCommentInsertPayload
}

export type RootMutationTypeInsertSaleServiceItemArgs = {
  saleServiceItem: SaleServiceItemInsertPayload
}

export type RootMutationTypeInsertServiceItemArgs = {
  serviceItem: ServiceItemInsertPayload
}

export type RootMutationTypeInviteCurrentUserEnterpriseUserByEmailArgs = {
  email: Scalars['String']['input']
  roleId: Scalars['UUID']['input']
}

export type RootMutationTypeInviteEnterpriseUserByEmailArgs = {
  email: Scalars['String']['input']
  enterpriseId: Scalars['UUID']['input']
  roleId: Scalars['UUID']['input']
}

export type RootMutationTypeMarkCurrentStepAsReadyArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeMarkEnrollmentInvoiceAsPaidArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeMarkEnterpriseResourceAsSeenArgs = {
  payload: MarkEnterpriseResourceAsSeenPayload
}

export type RootMutationTypeMarkIrpfStatementAsFinishedArgs = {
  id: Scalars['UUID']['input']
  irpfStatement: IrpfStatementFinishPayload
}

export type RootMutationTypeMarkIrpfStatementAsWaitingPendenciesArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeMarkUserNotificationAsSeenArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeReactivateEnterpriseArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeRejectEnterpriseUserInvitationArgs = {
  invitationId: Scalars['UUID']['input']
}

export type RootMutationTypeRequestContactSaleArgs = {
  cnpj?: InputMaybe<Scalars['String']['input']>
  contact: ContactInsertPayload
  marketingCampaignCode?: InputMaybe<Scalars['String']['input']>
}

export type RootMutationTypeRequestIrpfStatementArgs = {
  irpfStatement: IrpfStatementRequestPayload
}

export type RootMutationTypeResetUserPasswordArgs = {
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type RootMutationTypeResolveIrpfStatementPendencyMessagesArgs = {
  id: Scalars['UUID']['input']
  pendencyMessageIds: Array<Scalars['UUID']['input']>
}

export type RootMutationTypeSignInBackofficeUserArgs = {
  googleIdToken: Scalars['String']['input']
}

export type RootMutationTypeSignInUserArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type RootMutationTypeSignInUserWithPartnerSsoArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type RootMutationTypeSignInUserWithPresignedTokenArgs = {
  presignedToken: Scalars['String']['input']
}

export type RootMutationTypeSignUpUserArgs = {
  user: UserSignUpPayload
}

export type RootMutationTypeToggleAccountedDocumentArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateBackofficeUserRoleArgs = {
  id: Scalars['UUID']['input']
  roleId: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateCnaeArgs = {
  cnae: CnaeUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateContactArgs = {
  contact: ContactUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateContactInteractionArgs = {
  contactInteraction: ContactInteractionUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateCurrentEnterpriseEnrollmentWalletCardArgs = {
  walletCardId?: InputMaybe<Scalars['UUID']['input']>
}

export type RootMutationTypeUpdateCurrentUserArgs = {
  user: CurrentUserUpdatePayload
}

export type RootMutationTypeUpdateCurrentUserEnterpriseUserRoleArgs = {
  enterpriseUserId: Scalars['UUID']['input']
  roleId: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateDigitalCertificateArgs = {
  digitalCertificate: DigitalCertificateUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnrollmentPaymentMethodArgs = {
  id: Scalars['UUID']['input']
  paymentMethod: EnrollmentPaymentMethod
  walletCardId?: InputMaybe<Scalars['UUID']['input']>
}

export type RootMutationTypeUpdateEnterpriseArgs = {
  enterprise: EnterpriseUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseArchiveArgs = {
  enterpriseArchive: EnterpriseArchiveUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseBillArgs = {
  enterpriseBill: EnterpriseBillUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseCredentialArgs = {
  enterpriseCredential: EnterpriseCredentialUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseDocumentArgs = {
  enterpriseDocument: EnterpriseDocumentUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseEnrollmentServiceItemArgs = {
  enterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItemUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseInvoiceArgs = {
  enterpriseInvoice: EnterpriseInvoiceUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseInvoiceRequestStatusArgs = {
  id: Scalars['UUID']['input']
  status: InvoiceRequestStatus
}

export type RootMutationTypeUpdateEnterpriseMonthlyConsolidationArgs = {
  enterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidationUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseOnboardingArgs = {
  enterpriseOnboarding: EnterpriseOnboardingUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseOnboardingOpeningInfoArgs = {
  id: Scalars['UUID']['input']
  openingInfo: EnterpriseOnboardingOpeningInfoUpdatePayload
}

export type RootMutationTypeUpdateEnterpriseOnboardingPartnerArgs = {
  id: Scalars['UUID']['input']
  partnerId?: InputMaybe<Scalars['UUID']['input']>
}

export type RootMutationTypeUpdateEnterpriseOnboardingPendencyArgs = {
  enterpriseOnboardingPendency: EnterpriseOnboardingPendencyUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterprisePrimaryCnaeArgs = {
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseReportArgs = {
  enterpriseReport: EnterpriseReportUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseServiceTakerArgs = {
  enterpriseServiceTaker: EnterpriseServiceTakerUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseTaxGuideArgs = {
  enterpriseTaxGuide: EnterpriseTaxGuideUpdatePayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseTaxGuidePaymentStatusArgs = {
  id: Scalars['UUID']['input']
  paymentStatus: PaymentStatus
}

export type RootMutationTypeUpdateEnterpriseUserOwnerArgs = {
  enterpriseUserId: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateEnterpriseUserRoleArgs = {
  enterpriseUserId: Scalars['UUID']['input']
  roleId: Scalars['UUID']['input']
}

export type RootMutationTypeUpdateIrpfStatementArgs = {
  id: Scalars['UUID']['input']
  irpfStatement: IrpfStatementUpdatePayload
}

export type RootMutationTypeUpdateIrpfStatementAttachmentArgs = {
  id: Scalars['UUID']['input']
  irpfStatementAttachment: IrpfStatementAttachmentUpdatePayload
}

export type RootMutationTypeUpdateMarketingCampaignArgs = {
  id: Scalars['UUID']['input']
  marketingCampaign: MarketingCampaignUpdatePayload
}

export type RootMutationTypeUpdateRequestedIrpfStatementArgs = {
  id: Scalars['UUID']['input']
  irpfStatement: IrpfStatementRequestUpdatePayload
}

export type RootMutationTypeUpdateRequestedIrpfStatementResponsibleArgs = {
  id: Scalars['UUID']['input']
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
}

export type RootMutationTypeUpdateRequestedIrpfStatementTypeArgs = {
  id: Scalars['UUID']['input']
  type: IrpfStatementType
}

export type RootMutationTypeUpdateRoleArgs = {
  id: Scalars['UUID']['input']
  role: RoleUpdatePayload
}

export type RootMutationTypeUpdateSaleArgs = {
  id: Scalars['UUID']['input']
  sale: SaleUpdatePayload
}

export type RootMutationTypeUpdateSaleAttachmentArgs = {
  id: Scalars['UUID']['input']
  saleAttachment: SaleAttachmentUpdatePayload
}

export type RootMutationTypeUpdateSaleServiceItemArgs = {
  id: Scalars['UUID']['input']
  saleServiceItem: SaleServiceItemUpdatePayload
}

export type RootMutationTypeUpdateServiceItemArgs = {
  id: Scalars['UUID']['input']
  serviceItem: ServiceItemUpdatePayload
}

export type RootMutationTypeUpdateUserPasswordArgs = {
  currentPassword: Scalars['String']['input']
  password: Scalars['String']['input']
  passwordConfirmation: Scalars['String']['input']
}

export type RootMutationTypeUpsertEnterpriseEnrollmentServiceItemArgs = {
  enterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItemInsertPayload
}

export type RootMutationTypeUpsertEnterpriseOnboardingBusinessPartnerArgs = {
  enterpriseOnboardingBusinessPartner: EnterpriseOnboardingBusinessPartnerUpsertPayload
}

export type RootMutationTypeUpsertEnterpriseOnboardingMigrationAccountingDataArgs = {
  accountingData: EnterpriseOnboardingMigrationAccountingDataUpsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpsertEnterpriseOnboardingMigrationDataAndDocumentsArgs = {
  dataAndDocuments: EnterpriseOnboardingMigrationDataAndDocumentsUpsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpsertEnterpriseOnboardingOpeningDataAndDocumentsArgs = {
  dataAndDocuments: EnterpriseOnboardingOpeningDataAndDocumentsUpsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpsertEnterpriseOnboardingOpeningEcpfDataArgs = {
  ecpfData: EnterpriseOnboardingOpeningEcpfDataUpsertPayload
  id: Scalars['UUID']['input']
}

export type RootMutationTypeUpsertSystemSettingArgs = {
  systemSetting: SystemSettingUpsertPayload
}

export type RootMutationTypeVerifyUserWithResetPasswordTokenArgs = {
  email: Scalars['String']['input']
  token: Scalars['String']['input']
}

export type RootQueryType = {
  __typename?: 'RootQueryType'
  /** Lista de Ajustes ativos para a fatura em aberto de uma assinatura */
  activeEnrollmentAdjustments: Array<EnrollmentAdjustment>
  /** Lista de itens na fatura em aberto de uma assinatura */
  activeEnrollmentItems: ActiveEnrollmentItemsResponse
  /** Recurso associado ao token de credenciais de um Usuário */
  authenticatedBackofficeUser: BackofficeUserAccount
  /** Recurso associado ao token de credenciais de um Usuário */
  authenticatedUser: UserAccount
  /** Lista as Unidades Federativas disponíveis */
  availableStates?: Maybe<Array<AvailableState>>
  /** Busca um Funcionário de Backoffice por seu identificador */
  backofficeEmployee: BackofficeEmployee
  /** Lista paginada, filtrável e ordenável de grupos de permissão */
  backofficeEmployees: BackofficeEmployeesPaginatedList
  /** Busca um usuário de backoffice por seu identificador */
  backofficeUserAccount: BackofficeUserAccount
  /** Lista paginada, filtrável e ordenável de Usuários de Backoffice */
  backofficeUserAccounts: BackofficeUsersPaginatedList
  /** Busca um Processamento Massivo por seu identificador */
  bulkUpload: BulkUpload
  /** Lista paginada, filtrável e ordenável de Processamento Massivos */
  bulkUploads: BulkUploadsPaginatedList
  /** Busca um Formato de Negócio por seu identificador */
  businessFormat: BusinessFormat
  /** Lista paginada, filtrável e ordenável de Formato de Negócios */
  businessFormats: BusinessFormatsPaginatedList
  /** Busca uma Natureza de Negócio por seu identificador */
  businessNature: BusinessNature
  /** Lista paginada, filtrável e ordenável de Natureza de Negócios */
  businessNatures: BusinessNaturesPaginatedList
  /** Busca um CNAE por seu identificador */
  cnae: Cnae
  /** Lista paginada, filtrável e ordenável de CNAEs */
  cnaes: CnaesPaginatedList
  /** Busca um Contato por seu identificador */
  contact: Contact
  /** Busca uma Interação com o Contato por seu identificador */
  contactInteraction: ContactInteraction
  /** Lista paginada, filtrável e ordenável de Interação com Contatos */
  contactInteractions: ContactInteractionsPaginatedList
  /** Lista paginada, filtrável e ordenável de Contatos */
  contacts: ContactsPaginatedList
  /** Lista paginada, filtrável e ordenável de Arquivos da empresa atual do contexto */
  currentEnterpriseArchives: EnterpriseArchivesPaginatedList
  /** Lista paginada, filtrável e ordenável de Boletos da empresa atual do contexto */
  currentEnterpriseBills: EnterpriseBillsPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Boleto */
  currentEnterpriseBillsActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de Documentos da empresa atual do contexto */
  currentEnterpriseDocuments: EnterpriseDocumentsPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Documento */
  currentEnterpriseDocumentsActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de faturas de assinaturas associadas a empresa atual do contexto */
  currentEnterpriseEnrollmentInvoices: EnrollmentInvoicesPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de fatura de assinatura */
  currentEnterpriseEnrollmentInvoicesActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de Notas Fiscais da empresa atual do contexto */
  currentEnterpriseInvoiceRequests: EnterpriseInvoiceRequestsPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Solicitação de Nota Fiscal */
  currentEnterpriseInvoiceRequestsActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de Notas Fiscais da empresa atual do contexto */
  currentEnterpriseInvoices: EnterpriseInvoicesPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Nota Fiscal */
  currentEnterpriseInvoicesActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de Comunicados da empresa atual do contexto */
  currentEnterpriseMailboxMessages: MailboxMessagesPaginatedList
  /** Lista paginada, filtrável e ordenável de pedidos da empresa do contexto */
  currentEnterpriseOrders: OrdersPaginatedList
  /** Lista paginada, filtrável e ordenável de Relatórios da empresa atual do contexto */
  currentEnterpriseReports: EnterpriseReportsPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Relatório */
  currentEnterpriseReportsActiveYears: Array<Scalars['Int']['output']>
  /** Lista paginada, filtrável e ordenável de Tomadores de Serviços da empresa atual do contexto */
  currentEnterpriseServiceTakers: EnterpriseServiceTakersPaginatedList
  /** Lista paginada, filtrável e ordenável de Guias de Impostos da empresa atual do contexto */
  currentEnterpriseTaxGuides: EnterpriseTaxGuidesPaginatedList
  /** Lista de anos que a empresa atual do contexto possui ao menos um registro de Guia de Imposto */
  currentEnterpriseTaxGuidesActiveYears: Array<Scalars['Int']['output']>
  /** Retorna os dados do usuário da empresa do contexto atual do usuário autenticado */
  currentEnterpriseUser: EnterpriseUser
  /** Lista paginada, filtrável e ordenável de notificações de usuários associados à empresa no contexto atual do usuário autenticado */
  currentEnterpriseUserNotifications: UserNotificationsPaginatedList
  /** Recupera um cartão na carteira da empresa atual do contexto */
  currentEnterpriseWalletCard: WalletCard
  /** Lista paginada, filtrável e ordenável de Cartões na carteira da empresa atual do contexto */
  currentEnterpriseWalletCards: WalletCardsPaginatedList
  /** Lista paginada, filtrável e ordenável de Onboardings de Empresa para o usuário atual */
  currentUserEnterpriseOnboardings: EnterpriseOnboardingsPaginatedList
  /** Lista os usuários que pertencem a empresa do contexto atual do usuário autenticado */
  currentUserEnterpriseUsers: Array<EnterpriseUser>
  /** Lista os convites vinculados à empresa do contexto atual do usuário autenticado */
  currentUserEnterpriseUsersInvitations: EnterpriseUserInvitationsPaginatedList
  /** Lista os convites vinculados ao usuário autenticado */
  currentUserInvitations: EnterpriseUserInvitationsPaginatedList
  /** Lista paginada, filtrável e ordenável de declarações de IRPFs do usuário logado */
  currentUserIrpfStatements: IrpfStatementsPaginatedList
  /** Lista paginada, filtrável e ordenável de notificações de usuários no contexto atual do usuário autenticado */
  currentUserNotifications: UserNotificationsPaginatedList
  /** Lista paginada, filtrável e ordenável de pedidos do usuário logado */
  currentUserOrders: OrdersPaginatedList
  /** Busca a quantidade de vendas pendentes de Abertura ou Migração associadas ao Usuário */
  currentUserPendingEnterpriseOnboardingSalesCount: Scalars['Int']['output']
  /** Recupera um cartão na carteira do usuário atual do contexto */
  currentUserWalletCard: WalletCard
  /** Lista paginada, filtrável e ordenável de Cartões na carteira do usuário do contexto */
  currentUserWalletCards: WalletCardsPaginatedList
  /** Busca um Certificado Digital por seu identificador */
  digitalCertificate: DigitalCertificate
  /** Lista paginada, filtrável e ordenável de Certificado Digitais */
  digitalCertificates: DigitalCertificatesPaginatedList
  /** Busca uma Assinatura por seu identificador */
  enrollment: Enrollment
  /** Busca um item por seu identificador */
  enrollmentAdjustment: EnrollmentAdjustment
  /** Lista paginada, filtrável e ordenável de Ajustes na assinatura */
  enrollmentAdjustments: EnrollmentAdjustmentsPaginatedList
  /** Busca uma fatura de assinatura por seu identificador */
  enrollmentInvoice: EnrollmentInvoice
  /** Busca um item por seu identificador */
  enrollmentItem: EnrollmentItem
  /** Lista paginada, filtrável e ordenável de itens na assinatura */
  enrollmentItems: EnrollmentItemsPaginatedList
  /** Busca uma Empresa por seu identificador */
  enterprise: Enterprise
  /** Busca um Arquivo por seu identificador */
  enterpriseArchive: EnterpriseArchive
  /** Lista paginada, filtrável e ordenável de Arquivos */
  enterpriseArchives: EnterpriseArchivesPaginatedList
  /** Busca umo Boleto por seu identificador */
  enterpriseBill: EnterpriseBill
  /** Lista paginada, filtrável e ordenável de Boletos */
  enterpriseBills: EnterpriseBillsPaginatedList
  /** Busca uma Credencial por seu identificador */
  enterpriseCredential: EnterpriseCredential
  /** Lista paginada, filtrável e ordenável de Credenciais */
  enterpriseCredentials: EnterpriseCredentialsPaginatedList
  /** Busca um Documento por seu identificador */
  enterpriseDocument: EnterpriseDocument
  /** Lista paginada, filtrável e ordenável de Documentos */
  enterpriseDocuments: EnterpriseDocumentsPaginatedList
  /** Lista paginada, filtrável e ordenável de faturas de assinaturas associadas a empresa informada */
  enterpriseEnrollmentInvoices: EnrollmentInvoicesPaginatedList
  /** Busca uma associação de empersa a item de serviço por seu identificador */
  enterpriseEnrollmentServiceItem: EnterpriseEnrollmentServiceItem
  /** Lista paginada, filtrável e ordenável de associações de empresas a itens de serviço */
  enterpriseEnrollmentServiceItems: EnterpriseEnrollmentServiceItemsPaginatedList
  /** Busca uma Nota Fiscal por seu identificador */
  enterpriseInvoice: EnterpriseInvoice
  /** Busca uma Solicitação de Nota Fiscal por seu identificador */
  enterpriseInvoiceRequest: EnterpriseInvoiceRequest
  /** Lista paginada, filtrável e ordenável de Notas Fiscais */
  enterpriseInvoiceRequests: EnterpriseInvoiceRequestsPaginatedList
  /** Lista paginada, filtrável e ordenável de Notas Fiscais */
  enterpriseInvoices: EnterpriseInvoicesPaginatedList
  /** Busca um Fechamento Mensal por seu identificador */
  enterpriseMonthlyConsolidation: EnterpriseMonthlyConsolidation
  /** Lista paginada, filtrável e ordenável de Fechamentos Mensais */
  enterpriseMonthlyConsolidations: EnterpriseMonthlyConsolidationsPaginatedList
  /** Busca um Humor por seu identificador */
  enterpriseMood: EnterpriseMood
  /** Lista paginada, filtrável e ordenável de Humores */
  enterpriseMoods: EnterpriseMoodsPaginatedList
  /** Busca um Onboarding de Empresa por seu identificador */
  enterpriseOnboarding: EnterpriseOnboarding
  /** Recupera um Anexo de Onboarding */
  enterpriseOnboardingAttachment: EnterpriseOnboardingAttachment
  /** Lista de Anexos de Onboarding */
  enterpriseOnboardingAttachments: EnterpriseOnboardingAttachmentsPaginatedList
  /** Busca um Sócio de Onboarding de Empresa por seu identificador */
  enterpriseOnboardingBusinessPartner: EnterpriseOnboardingBusinessPartner
  /** Lista paginada, filtrável e ordenável de Sócios de Onboardings de Empresa */
  enterpriseOnboardingBusinessPartners: EnterpriseOnboardingBusinessPartnersPaginatedList
  /** Busca um Comentário do Onboarding por seu identificador */
  enterpriseOnboardingComment: EnterpriseOnboardingComment
  /** Lista paginada, filtrável e ordenável de Comentários do Onboarding */
  enterpriseOnboardingComments: EnterpriseOnboardingCommentsPaginatedList
  /** Lista paginada, filtrável e ordenável de Pendências de Onboardings */
  enterpriseOnboardingPendencies: EnterpriseOnboardingPendenciesPaginatedList
  /** Busca uma Pendência de Onboarding por seu identificador */
  enterpriseOnboardingPendency: EnterpriseOnboardingPendency
  /** Lista paginada, filtrável e ordenável de Onboardings de Empresa */
  enterpriseOnboardings: EnterpriseOnboardingsPaginatedList
  /** Busca um Relatório por seu identificador */
  enterpriseReport: EnterpriseReport
  /** Lista paginada, filtrável e ordenável de Relatórios */
  enterpriseReports: EnterpriseReportsPaginatedList
  /** Busca um Tomador de Serviço por seu identificador */
  enterpriseServiceTaker: EnterpriseServiceTaker
  /** Lista paginada, filtrável e ordenável de Tomadores de Serviços */
  enterpriseServiceTakers: EnterpriseServiceTakersPaginatedList
  /** Busca uma Guia de Imposto por seu identificador */
  enterpriseTaxGuide: EnterpriseTaxGuide
  /** Lista paginada, filtrável e ordenável de Guias de Impostos */
  enterpriseTaxGuides: EnterpriseTaxGuidesPaginatedList
  /** Busca um registro do Usuário associado a uma empresa pelo identificador do registro */
  enterpriseUser: EnterpriseUser
  /** Recupera os dados de um convite */
  enterpriseUserInvitation: EnterpriseUserInvitation
  /** Lista paginada, filtrada e ordenável dos convites vinculados à uma empresa */
  enterpriseUserInvitations: EnterpriseUserInvitationsPaginatedList
  /** Lista os usuários que pertencem a uma empresa */
  enterpriseUsers: Array<EnterpriseUser>
  /** Lista os convites vinculados à uma empresa */
  enterpriseUsersInvitations: EnterpriseUserInvitationsPaginatedList
  /** Lista de cartões da empresa */
  enterpriseWalletCards: WalletCardsPaginatedList
  /** Lista paginada, filtrável e ordenável de Empresas */
  enterprises: EnterprisesPaginatedList
  /** Busca uma declaração de IRPF por seu identificador */
  irpfStatement: IrpfStatement
  /** Busca um anexo de declaração de IRPF por seu identificador */
  irpfStatementAttachment: IrpfStatementAttachment
  /** Lista paginada, filtrável e ordenável de anexos de declarações de IRPFs */
  irpfStatementAttachments: IrpfStatementAttachmentsPaginatedList
  /** Lista paginada, filtrável e ordenável de pendências de declarações de IRPFs */
  irpfStatementPendencies: IrpfStatementPendenciesPaginatedList
  /** Busca uma pendência de declaração de IRPF por seu identificador */
  irpfStatementPendency: IrpfStatementPendency
  /** Lista paginada, filtrável e ordenável de declarações de IRPFs */
  irpfStatements: IrpfStatementsPaginatedList
  /** Busca umo Comunicado por seu identificador */
  mailboxMessage: MailboxMessage
  /** Lista paginada, filtrável e ordenável de Comunicados */
  mailboxMessages: MailboxMessagesPaginatedList
  /** Busca uma campanha por seu identificador */
  marketingCampaign: MarketingCampaign
  /** Lista paginada, filtrável e ordenável de campanhas */
  marketingCampaigns: MarketingCampaignsPaginatedList
  /** Busca um Pedido por seu identificador */
  order: Order
  /** Busca uma pedido por seu identificador */
  orderEvent: OrderEvent
  /** Lista paginada, filtrável e ordenável de pedidos */
  orderEvents: OrderEventsPaginatedList
  /** Lista paginada, filtrável e ordenável de pedidos */
  orders: OrdersPaginatedList
  /** Lista paginada, filtrável e ordenável de parceiros */
  partners: PartnersPaginatedList
  /** Busca um grupo de permissão por seu identificador */
  role: Role
  /** Lista paginada, filtrável e ordenável de grupos de permissão */
  roles: RolesPaginatedList
  /** Busca uma Venda por seu identificador */
  sale: Sale
  /** Busca um Anexo de Venda por seu identificador */
  saleAttachment: SaleAttachment
  /** Lista paginada, filtrável e ordenável de Anexos de Vendas */
  saleAttachments: SaleAttachmentsPaginatedList
  /** Busca um Item de Serviço em Venda por seu identificador */
  saleServiceItem: SaleServiceItem
  /** Lista paginada, filtrável e ordenável de Item de Serviços em Vendas */
  saleServiceItems: SaleServiceItemsPaginatedList
  /** Busca os tipos de vendas categorizadas */
  saleTypesCategorized: SaleTypesCategorized
  /** Lista paginada, filtrável e ordenável de Vendas */
  sales: SalesPaginatedList
  /** Busca um item de serviço por seu identificador */
  serviceItem: ServiceItem
  /** Lista paginada, filtrável e ordenável de itens de serviço */
  serviceItems: ServiceItemsPaginatedList
  /** Metadados sobre o sistema */
  systemInfo: SystemInfo
  /** Busca uma configuração de sistema pelo ID */
  systemSetting: SystemSetting
  /** Busca uma configuração de sistema pela chave */
  systemSettingByKey: SystemSetting
  /** Lista todas as configurações de sistema */
  systemSettings?: Maybe<Array<SystemSetting>>
  /** Busca um Regime Tributário por seu identificador */
  taxRegime: TaxRegime
  /** Lista paginada, filtrável e ordenável de Regime Tributários */
  taxRegimes: TaxRegimesPaginatedList
  /** Busca um usuário por seu identificador */
  userAccount: UserAccount
  /** Lista paginada, filtrada e ordenável de usuários */
  userAccounts: UsersPaginatedList
  /** Lista paginada, filtrável e ordenável de notificações de usuários */
  userNotifications: UserNotificationsPaginatedList
}

export type RootQueryTypeActiveEnrollmentAdjustmentsArgs = {
  enrollmentId: Scalars['UUID']['input']
}

export type RootQueryTypeActiveEnrollmentItemsArgs = {
  enrollmentId: Scalars['UUID']['input']
}

export type RootQueryTypeBackofficeEmployeeArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeBackofficeEmployeesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<BackofficeEmployeesFilterPayload>
}

export type RootQueryTypeBackofficeUserAccountArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeBackofficeUserAccountsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<BackofficeUserAccountsSortPayload>>>
  where?: InputMaybe<BackofficeUserAccountsFilterPayload>
}

export type RootQueryTypeBulkUploadArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeBulkUploadsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<BulkUploadsSortPayload>>>
  where?: InputMaybe<BulkUploadsFilterPayload>
}

export type RootQueryTypeBusinessFormatArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeBusinessFormatsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<BusinessFormatsFilterPayload>
}

export type RootQueryTypeBusinessNatureArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeBusinessNaturesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<BusinessNaturesFilterPayload>
}

export type RootQueryTypeCnaeArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeCnaesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<CnaesFilterPayload>
}

export type RootQueryTypeContactArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeContactInteractionArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeContactInteractionsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<ContactInteractionsFilterPayload>
}

export type RootQueryTypeContactsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<ContactsSortPayload>>>
  where?: InputMaybe<ContactsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseArchivesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseArchivesSortPayload>>>
  where?: InputMaybe<EnterpriseArchivesFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseBillsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseBillsSortPayload>>>
  where?: InputMaybe<EnterpriseBillsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseDocumentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseDocumentsSortPayload>>>
  where?: InputMaybe<EnterpriseDocumentsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseEnrollmentInvoicesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnrollmentInvoicesSortPayload>>>
  where?: InputMaybe<EnrollmentInvoicesFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseInvoiceRequestsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseInvoiceRequestsSortPayload>>>
  where?: InputMaybe<EnterpriseInvoiceRequestsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseInvoicesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseInvoicesSortPayload>>>
  where?: InputMaybe<EnterpriseInvoicesFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseMailboxMessagesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<MailboxMessagesSortPayload>>>
  where?: InputMaybe<MailboxMessagesFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseOrdersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrdersSortPayload>>>
  where?: InputMaybe<OrdersFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseReportsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseReportsSortPayload>>>
  where?: InputMaybe<EnterpriseReportsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseServiceTakersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<EnterpriseServiceTakersFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseTaxGuidesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseTaxGuidesSortPayload>>>
  where?: InputMaybe<EnterpriseTaxGuidesFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseUserNotificationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<UserNotificationsFilterPayload>
}

export type RootQueryTypeCurrentEnterpriseWalletCardArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeCurrentEnterpriseWalletCardsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<WalletCardsSortPayload>>>
  where?: InputMaybe<WalletCardsFilterPayload>
}

export type RootQueryTypeCurrentUserEnterpriseOnboardingsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingsFilterPayload>
}

export type RootQueryTypeCurrentUserEnterpriseUsersInvitationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseUserInvitationsSortPayload>>>
  where?: InputMaybe<EnterpriseUserInvitationsFilterPayload>
}

export type RootQueryTypeCurrentUserInvitationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseUserInvitationsSortPayload>>>
  where?: InputMaybe<EnterpriseUserInvitationsFilterPayload>
}

export type RootQueryTypeCurrentUserIrpfStatementsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementsSortPayload>>>
  where?: InputMaybe<IrpfStatementsFilterPayload>
}

export type RootQueryTypeCurrentUserNotificationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<UserNotificationsFilterPayload>
}

export type RootQueryTypeCurrentUserOrdersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrdersSortPayload>>>
  where?: InputMaybe<OrdersFilterPayload>
}

export type RootQueryTypeCurrentUserWalletCardArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeCurrentUserWalletCardsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<WalletCardsSortPayload>>>
  where?: InputMaybe<WalletCardsFilterPayload>
}

export type RootQueryTypeDigitalCertificateArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeDigitalCertificatesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<DigitalCertificatesSortPayload>>>
  where?: InputMaybe<DigitalCertificatesFilterPayload>
}

export type RootQueryTypeEnrollmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnrollmentAdjustmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnrollmentAdjustmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnrollmentAdjustmentsSortPayload>>>
  where?: InputMaybe<EnrollmentAdjustmentsFilterPayload>
}

export type RootQueryTypeEnrollmentInvoiceArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnrollmentItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnrollmentItemsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnrollmentItemsSortPayload>>>
  where?: InputMaybe<EnrollmentItemsFilterPayload>
}

export type RootQueryTypeEnterpriseArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseArchiveArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseArchivesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseArchivesSortPayload>>>
  where?: InputMaybe<EnterpriseArchivesFilterPayload>
}

export type RootQueryTypeEnterpriseBillArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseBillsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseBillsSortPayload>>>
  where?: InputMaybe<EnterpriseBillsFilterPayload>
}

export type RootQueryTypeEnterpriseCredentialArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseCredentialsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseCredentialsSortPayload>>>
  where?: InputMaybe<EnterpriseCredentialsFilterPayload>
}

export type RootQueryTypeEnterpriseDocumentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseDocumentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseDocumentsSortPayload>>>
  where?: InputMaybe<EnterpriseDocumentsFilterPayload>
}

export type RootQueryTypeEnterpriseEnrollmentInvoicesArgs = {
  enterpriseId: Scalars['UUID']['input']
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnrollmentInvoicesSortPayload>>>
  where?: InputMaybe<EnrollmentInvoicesFilterPayload>
}

export type RootQueryTypeEnterpriseEnrollmentServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseEnrollmentServiceItemsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseEnrollmentServiceItemsSortPayload>>>
  where?: InputMaybe<EnterpriseEnrollmentServiceItemsFilterPayload>
}

export type RootQueryTypeEnterpriseInvoiceArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseInvoiceRequestArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseInvoiceRequestsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseInvoiceRequestsSortPayload>>>
  where?: InputMaybe<EnterpriseInvoiceRequestsFilterPayload>
}

export type RootQueryTypeEnterpriseInvoicesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseInvoicesSortPayload>>>
  where?: InputMaybe<EnterpriseInvoicesFilterPayload>
}

export type RootQueryTypeEnterpriseMonthlyConsolidationArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseMonthlyConsolidationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseMonthlyConsolidationsSortPayload>>>
  where?: InputMaybe<EnterpriseMonthlyConsolidationsFilterPayload>
}

export type RootQueryTypeEnterpriseMoodArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseMoodsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseMoodsSortPayload>>>
  where?: InputMaybe<EnterpriseMoodsFilterPayload>
}

export type RootQueryTypeEnterpriseOnboardingArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseOnboardingAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseOnboardingAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingAttachmentsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingAttachmentsFilterPayload>
}

export type RootQueryTypeEnterpriseOnboardingBusinessPartnerArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseOnboardingBusinessPartnersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingBusinessPartnersSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingBusinessPartnersFilterPayload>
}

export type RootQueryTypeEnterpriseOnboardingCommentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseOnboardingCommentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingCommentsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingCommentsFilterPayload>
}

export type RootQueryTypeEnterpriseOnboardingPendenciesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingPendenciesSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingPendenciesFilterPayload>
}

export type RootQueryTypeEnterpriseOnboardingPendencyArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseOnboardingsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseOnboardingsSortPayload>>>
  where?: InputMaybe<EnterpriseOnboardingsFilterPayload>
}

export type RootQueryTypeEnterpriseReportArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseReportsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseReportsSortPayload>>>
  where?: InputMaybe<EnterpriseReportsFilterPayload>
}

export type RootQueryTypeEnterpriseServiceTakerArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseServiceTakersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseServiceTakersSortPayload>>>
  where?: InputMaybe<EnterpriseServiceTakersFilterPayload>
}

export type RootQueryTypeEnterpriseTaxGuideArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseTaxGuidesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseTaxGuidesSortPayload>>>
  where?: InputMaybe<EnterpriseTaxGuidesFilterPayload>
}

export type RootQueryTypeEnterpriseUserArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseUserInvitationArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseUserInvitationsArgs = {
  enterpriseId: Scalars['UUID']['input']
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterpriseUserInvitationsSortPayload>>>
  where?: InputMaybe<EnterpriseUserInvitationsFilterPayload>
}

export type RootQueryTypeEnterpriseUsersArgs = {
  enterpriseId: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseUsersInvitationsArgs = {
  enterpriseId: Scalars['UUID']['input']
}

export type RootQueryTypeEnterpriseWalletCardsArgs = {
  enterpriseId: Scalars['UUID']['input']
}

export type RootQueryTypeEnterprisesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<EnterprisesSortPayload>>>
  where?: InputMaybe<EnterprisesFilterPayload>
}

export type RootQueryTypeIrpfStatementArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeIrpfStatementAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeIrpfStatementAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementAttachmentsSortPayload>>>
  where?: InputMaybe<IrpfStatementAttachmentsFilterPayload>
}

export type RootQueryTypeIrpfStatementPendenciesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementPendenciesSortPayload>>>
  where?: InputMaybe<IrpfStatementPendenciesFilterPayload>
}

export type RootQueryTypeIrpfStatementPendencyArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeIrpfStatementsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<IrpfStatementsSortPayload>>>
  where?: InputMaybe<IrpfStatementsFilterPayload>
}

export type RootQueryTypeMailboxMessageArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeMailboxMessagesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<MailboxMessagesSortPayload>>>
  where?: InputMaybe<MailboxMessagesFilterPayload>
}

export type RootQueryTypeMarketingCampaignArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeMarketingCampaignsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<MarketingCampaignsFilterPayload>
}

export type RootQueryTypeOrderArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeOrderEventArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeOrderEventsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrderEventsSortPayload>>>
  where?: InputMaybe<OrderEventsFilterPayload>
}

export type RootQueryTypeOrdersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<OrdersSortPayload>>>
  where?: InputMaybe<OrdersFilterPayload>
}

export type RootQueryTypePartnersArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<PartnersSortPayload>>>
  where?: InputMaybe<PartnersFilterPayload>
}

export type RootQueryTypeRoleArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeRolesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<RolesFilterPayload>
}

export type RootQueryTypeSaleArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeSaleAttachmentArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeSaleAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<SaleAttachmentsSortPayload>>>
  where?: InputMaybe<SaleAttachmentsFilterPayload>
}

export type RootQueryTypeSaleServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeSaleServiceItemsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<SaleServiceItemsSortPayload>>>
  where?: InputMaybe<SaleServiceItemsFilterPayload>
}

export type RootQueryTypeSalesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<SalesSortPayload>>>
  where?: InputMaybe<SalesFilterPayload>
}

export type RootQueryTypeServiceItemArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeServiceItemsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<ServiceItemsSortPayload>>>
  where?: InputMaybe<ServiceItemsFilterPayload>
}

export type RootQueryTypeSystemSettingArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeSystemSettingByKeyArgs = {
  key: SystemSettingKey
}

export type RootQueryTypeTaxRegimeArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeTaxRegimesArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<TaxRegimesFilterPayload>
}

export type RootQueryTypeUserAccountArgs = {
  id: Scalars['UUID']['input']
}

export type RootQueryTypeUserAccountsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  sort?: InputMaybe<Array<InputMaybe<UserAccountsSortPayload>>>
  where?: InputMaybe<UserAccountsFilterPayload>
}

export type RootQueryTypeUserNotificationsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<UserNotificationsFilterPayload>
}

/** Representação de uma Venda */
export type Sale = {
  __typename?: 'Sale'
  /** Anexos da Venda */
  attachments?: Maybe<SalesAttachmentsPaginatedList>
  /** Código da Venda */
  code: Scalars['Int']['output']
  /** Comentários da Venda */
  comments?: Maybe<SalesCommentsPaginatedList>
  /** Condições Comerciais da Venda */
  commercialInfo?: Maybe<SaleCommercialInfo>
  /** Contato da Venda */
  contact: Contact
  /** Id do Contato da Venda */
  contactId: Scalars['UUID']['output']
  /** Lista de Interações de Contatos */
  contactInteractions: ContactInteractionsPaginatedList
  /** Timestamp de conversão da Venda */
  convertedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Detalhes da Venda */
  details?: Maybe<Scalars['String']['output']>
  /** Informações acerca do descarte da Venda */
  discardInfo?: Maybe<SaleDiscardInfo>
  /** Timestamp de descarte da Venda */
  discardedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Informações Empresariais associados à Venda */
  enterpriseInfo?: Maybe<EnterpriseInfo>
  /** Id do registro de Informações Empresariais associados à Venda */
  enterpriseInfoId?: Maybe<Scalars['UUID']['output']>
  /** Onboarding da Venda */
  enterpriseOnboarding?: Maybe<EnterpriseOnboarding>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Informações Internas da Venda */
  internalInfo?: Maybe<SaleInternalInfo>
  /** Log de alterações realizadas na Venda */
  log: AuditLogChangesPaginatedList
  /** Campanha de marketing de origem da Venda */
  marketingCampaign?: Maybe<MarketingCampaign>
  /** Id da Campanha de marketing de origem da Venda */
  marketingCampaignId?: Maybe<Scalars['UUID']['output']>
  /** Pedido associado à Venda */
  order?: Maybe<Order>
  /** Informações Pessoais do Contato da Venda */
  personalInfo?: Maybe<SalePersonalInfo>
  /** Prioridade da Venda */
  priority?: Maybe<SalePriority>
  /** Responsável pela Venda */
  responsible?: Maybe<BackofficeEmployee>
  /** Id do Responsável pela Venda */
  responsibleId?: Maybe<Scalars['UUID']['output']>
  /** Data de início de prestação de serviços */
  servicesStartingAt?: Maybe<Scalars['Date']['output']>
  /** Status da Venda (convertido, descartado ou em negociação) */
  status: SaleStatus
  /** Temperatura da Venda */
  temperature: SaleTemperature
  /** Tipo da Venda */
  type?: Maybe<SaleType>
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Representação de uma Venda */
export type SaleAttachmentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<SaleAttachmentsFilterPayload>
}

/** Representação de uma Venda */
export type SaleCommentsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<SaleCommentsFilterPayload>
}

/** Representação de uma Venda */
export type SaleContactInteractionsArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<ContactInteractionsFilterPayload>
}

/** Representação de uma Venda */
export type SaleLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Representação das Condições Comerciais da Venda do Tipo 'Contabilidade' */
export type SaleAccountingCommercialInfo = SaleCommercialInfo & {
  __typename?: 'SaleAccountingCommercialInfo'
  /** Apêndices comerciais definidos na Venda */
  appendices?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Informações extras */
  extra?: Maybe<Scalars['String']['output']>
  /** Número de mensalidades gratuitas */
  freeMonthlyFees?: Maybe<Scalars['Int']['output']>
  /** Número de eventos de Folha de Pagamento gratuitos */
  freePayrollEventFees?: Maybe<Scalars['Int']['output']>
  /** Valor inicial em centavos */
  initialFee?: Maybe<Scalars['Int']['output']>
  /** Valor da mensalidade em centavos */
  monthlyFee?: Maybe<Scalars['Int']['output']>
  /** Método de Pagamento que será utilizado */
  paymentMethod?: Maybe<PaymentMethod>
  /** Valor dos eventos de Folha de Pagamento em centavos */
  payrollEventFee?: Maybe<Scalars['Int']['output']>
}

/** Representação dos anexos associados à Venda */
export type SaleAttachment = {
  __typename?: 'SaleAttachment'
  /** Detalhes do Anexo */
  details?: Maybe<Scalars['String']['output']>
  /** Caminho do arquivo anexado */
  file: Scalars['String']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Venda associada */
  sale: Sale
  /** ID da Venda associada */
  saleId: Scalars['UUID']['output']
  /** Título do Anexo */
  title: Scalars['String']['output']
  /** Tipo do Anexo */
  type: SaleAttachmentType
  /** Timestamp de atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma novo Anexo de Venda */
export type SaleAttachmentInsertPayload = {
  /** Detalhes do Anexo de Venda */
  details?: InputMaybe<Scalars['String']['input']>
  /** Arquivo do Anexo de Venda */
  file: Scalars['Upload']['input']
  /** ID da Venda associada */
  saleId: Scalars['UUID']['input']
  /** Título do Anexo */
  title: Scalars['String']['input']
  /** Tipo do Anexo de Venda */
  type: SaleAttachmentType
}

export enum SaleAttachmentType {
  /** CNH */
  Cnh = 'CNH',
  /** CPF */
  Cpf = 'CPF',
  /** IPTU */
  Iptu = 'IPTU',
  /** Outro */
  Other = 'OTHER',
  /** Comprovante de Pagamento */
  PaymentReceipt = 'PAYMENT_RECEIPT',
  /** RG */
  Rg = 'RG',
  /** Selfie */
  Selfie = 'SELFIE',
  /** Título de Eleitor */
  VoterRegistration = 'VOTER_REGISTRATION',
}

/** Payload para filtragem de campos do tipo SaleAttachmentType */
export type SaleAttachmentTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<SaleAttachmentType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<SaleAttachmentType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<SaleAttachmentType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<SaleAttachmentType>>>
}

/** Payload de atualização de um Anexo de Venda */
export type SaleAttachmentUpdatePayload = {
  /** Detalhes do Anexo de Venda */
  details?: InputMaybe<Scalars['String']['input']>
  /** Arquivo do Anexo de Venda */
  file?: InputMaybe<Scalars['Upload']['input']>
  /** ID da Venda associada */
  saleId?: InputMaybe<Scalars['UUID']['input']>
  /** Título do Anexo */
  title?: InputMaybe<Scalars['String']['input']>
  /** Tipo do Anexo de Venda */
  type?: InputMaybe<SaleAttachmentType>
}

/** Payload para filtragem de Anexos de Vendas */
export type SaleAttachmentsFilterPayload = {
  /** Filtros para detalhes do Anexo de Venda */
  details?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Anexo de Venda */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<SaleAttachmentsFilterPayload>>
  /** Filtros para o ID da Venda */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o título do Anexo de Venda */
  title?: InputMaybe<StringFilterPayload>
  /** Filtros para o tipo do Anexo de Venda */
  type?: InputMaybe<SaleAttachmentTypeFilterPayload>
}

/** Lista de Anexos e seus metadados de paginação */
export type SaleAttachmentsPaginatedList = {
  __typename?: 'SaleAttachmentsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<SaleAttachment>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Anexos de Vendas */
export type SaleAttachmentsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o título do Anexo de Venda */
  title?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o tipo do Anexo de Venda */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de um Comentário na Venda */
export type SaleComment = {
  __typename?: 'SaleComment'
  /** Usuário que criou o comentário */
  backofficeUser?: Maybe<BackofficeEmployee>
  /** Id do usuário que criou o comentário */
  backofficeUserId?: Maybe<Scalars['UUID']['output']>
  /** Conteúdo do comentário */
  content: Scalars['String']['output']
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Id da Venda associada ao comentário */
  saleId: Scalars['UUID']['output']
}

/** Payload de inserção de um Comentário na Venda */
export type SaleCommentInsertPayload = {
  /** Id do usuário que criou o comentário */
  backofficeUserId?: InputMaybe<Scalars['UUID']['input']>
  /** Conteúdo do comentário */
  content: Scalars['String']['input']
  /** Id da Venda associada ao comentário */
  saleId: Scalars['UUID']['input']
}

/** Payload para filtragem de Comentários em Vendas */
export type SaleCommentsFilterPayload = {
  /** Filtros para o ID do Usuário de Backoffice no Comentário da Venda */
  backofficeUserId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID do Comentário da Venda */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<SaleCommentsFilterPayload>>
  /** Filtros para o ID da Venda no Comentário da Venda */
  saleId?: InputMaybe<UuidFilterPayload>
}

/** Representação das Condições Comerciais da Venda */
export type SaleCommercialInfo = {
  /** Apêndices comerciais definidos na Venda */
  appendices?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Informações extras */
  extra?: Maybe<Scalars['String']['output']>
  /** Método de Pagamento que será utilizado */
  paymentMethod?: Maybe<PaymentMethod>
}

/** Payload de informações comerciais da Venda */
export type SaleCommercialInfoPayload = {
  /** Apêndices comerciais definidos na Venda */
  appendices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Informações extras */
  extra?: InputMaybe<Scalars['String']['input']>
  /** Número de mensalidades gratuitas */
  freeMonthlyFees?: InputMaybe<Scalars['Int']['input']>
  /** Número de eventos de Folha de Pagamento gratuitos */
  freePayrollEventFees?: InputMaybe<Scalars['Int']['input']>
  /** Valor inicial em centavos */
  initialFee?: InputMaybe<Scalars['Int']['input']>
  /** Valor da mensalidade em centavos */
  monthlyFee?: InputMaybe<Scalars['Int']['input']>
  /** Método de Pagamento que será utilizado */
  paymentMethod?: InputMaybe<PaymentMethod>
  /** Valor dos eventos de Folha de Pagamento em centavos */
  payrollEventFee?: InputMaybe<Scalars['Int']['input']>
  /** Valor total do serviço em centavos */
  totalFee?: InputMaybe<Scalars['Int']['input']>
}

/** Representação das informações acerca do descarte de uma Venda */
export type SaleDiscardInfo = {
  __typename?: 'SaleDiscardInfo'
  /** Detalhes acerca do descarte da Venda */
  details?: Maybe<Scalars['String']['output']>
  /** Motivo de descarte da Venda */
  reason: SaleDiscardReason
}

export enum SaleDiscardReason {
  /** Concorrência */
  CompetitionVictory = 'COMPETITION_VICTORY',
  /** Desqualificado */
  Disqualified = 'DISQUALIFIED',
  /** Contato inválido */
  InvalidContact = 'INVALID_CONTACT',
  /** Sem resposta */
  NoResponse = 'NO_RESPONSE',
  /** Outro */
  Other = 'OTHER',
}

/** Payload de inserção de uma nova Venda */
export type SaleInsertPayload = {
  /** Informações Comerciais da Venda */
  commercialInfo?: InputMaybe<SaleCommercialInfoPayload>
  /** Contato da Venda */
  contactId: Scalars['UUID']['input']
  /** Detalhes da Venda */
  details?: InputMaybe<Scalars['String']['input']>
  /** Informações Empresariais da Venda */
  enterpriseInfo?: InputMaybe<EnterpriseInfoPayload>
  /** Informações Internas da Venda */
  internalInfo?: InputMaybe<SaleInternalInfoPayload>
  /** Campanha de marketing de origem da Venda */
  marketingCampaignId?: InputMaybe<Scalars['UUID']['input']>
  /** Informações Pessoais do Contato da Venda */
  personalInfo?: InputMaybe<SalePersonalInfoPayload>
  /** Prioridade da Venda */
  priority?: InputMaybe<SalePriority>
  /** Responsável pela Venda */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Data de início de prestação de serviços */
  servicesStartingAt?: InputMaybe<Scalars['Date']['input']>
  /** Tipo da Venda */
  type?: InputMaybe<SaleType>
}

/** Representação das Informações Internas da Venda */
export type SaleInternalInfo = {
  __typename?: 'SaleInternalInfo'
  /** Informações extras */
  extra?: Maybe<Scalars['String']['output']>
  /** Define se há registro do Contato da Venda na plataforma Gestta */
  gesttaRegistered?: Maybe<Scalars['Boolean']['output']>
  /** Define se a Empresa possuia contabilidade anteriormente */
  hadAccounting?: Maybe<Scalars['Boolean']['output']>
  /** Define se há agendamento de reunião com o Contato da Venda */
  meetingScheduled?: Maybe<Scalars['Boolean']['output']>
  /** Define se a mensagem de Boas-Vindas foi enviada ao Contato da Venda */
  welcomeMessageSent?: Maybe<Scalars['Boolean']['output']>
}

/** Payload de informações internas da Venda */
export type SaleInternalInfoPayload = {
  /** Informações extras */
  extra?: InputMaybe<Scalars['String']['input']>
  /** Define se há registro do Contato da Venda na plataforma Gestta */
  gesttaRegistered?: InputMaybe<Scalars['Boolean']['input']>
  /** Define se a Empresa possuia contabilidade anteriormente */
  hadAccounting?: InputMaybe<Scalars['Boolean']['input']>
  /** Define se há agendamento de reunião com o Contato da Venda */
  meetingScheduled?: InputMaybe<Scalars['Boolean']['input']>
  /** Define se a mensagem de Boas-Vindas foi enviada ao Contato da Venda */
  welcomeMessageSent?: InputMaybe<Scalars['Boolean']['input']>
}

/** Representação das Informações Pessoais do Contato na Venda */
export type SalePersonalInfo = {
  __typename?: 'SalePersonalInfo'
  /** Informações extras */
  extra?: Maybe<Scalars['String']['output']>
  /** Perfil da pessoa do Contato */
  personProfile?: Maybe<PersonProfile>
}

/** Payload de informações pessoais do contato da Venda */
export type SalePersonalInfoPayload = {
  /** Informações extras */
  extra?: InputMaybe<Scalars['String']['input']>
  /** Perfil da pessoa do Contato */
  personProfile?: InputMaybe<PersonProfile>
}

export enum SalePriority {
  /** Alta */
  High = 'HIGH',
  /** Baixa */
  Low = 'LOW',
  /** Normal */
  Normal = 'NORMAL',
  /** Urgente */
  Urgent = 'URGENT',
}

/** Payload para filtragem de campos do tipo SalePriority */
export type SalePriorityFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<SalePriority>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<SalePriority>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<SalePriority>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<SalePriority>>>
}

/** Representação das Condições Comerciais da Venda do Tipo 'Serviço' */
export type SaleServiceCommercialInfo = SaleCommercialInfo & {
  __typename?: 'SaleServiceCommercialInfo'
  /** Apêndices comerciais definidos na Venda */
  appendices?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  /** Informações extras */
  extra?: Maybe<Scalars['String']['output']>
  /** Método de Pagamento que será utilizado */
  paymentMethod?: Maybe<PaymentMethod>
  /** Valor total do serviço em centavos */
  totalFee?: Maybe<Scalars['Int']['output']>
}

/** Representação de um Item de Serviço associados à Venda */
export type SaleServiceItem = {
  __typename?: 'SaleServiceItem'
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Quantidade do Item de Serviço */
  quantity: Scalars['Int']['output']
  /** Venda associada */
  sale: Sale
  /** ID da Venda associada */
  saleId: Scalars['UUID']['output']
  /** Item de Serviço associado */
  serviceItem: ServiceItem
  /** ID do Item de Serviço associado */
  serviceItemId: Scalars['UUID']['output']
  /** Preço unitário do Item de Serviço */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de uma novo Item de Serviço em Venda */
export type SaleServiceItemInsertPayload = {
  /** Quantidade do Item de Serviço */
  quantity: Scalars['Int']['input']
  /** ID da Venda associada */
  saleId: Scalars['UUID']['input']
  /** ID do Item de Serviço associado */
  serviceItemId: Scalars['UUID']['input']
  /** Preço unitário do Item de Serviço */
  unitPrice: Scalars['Int']['input']
}

/** Payload de atualização de um Item de Serviço em Venda */
export type SaleServiceItemUpdatePayload = {
  /** Quantidade do Item de Serviço */
  quantity: Scalars['Int']['input']
  /** ID da Venda associada */
  saleId: Scalars['UUID']['input']
  /** ID do Item de Serviço associado */
  serviceItemId: Scalars['UUID']['input']
  /** Preço unitário do Item de Serviço */
  unitPrice: Scalars['Int']['input']
}

/** Payload para filtragem de Item de Serviços em Vendas */
export type SaleServiceItemsFilterPayload = {
  /** Filtros para o ID do Item de Serviço em Venda */
  id?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<SaleServiceItemsFilterPayload>>
  /** Filtros para a quantidade do Item de Serviço em Venda */
  quantity?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID da Venda */
  saleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da Venda */
  serviceItemId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o preço unitário do Item de Serviço em Venda */
  unitPrice?: InputMaybe<IntegerFilterPayload>
}

/** Lista de Itens de Serviço em Vendas e seus metadados de paginação */
export type SaleServiceItemsPaginatedList = {
  __typename?: 'SaleServiceItemsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<SaleServiceItem>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Item de Serviços em Vendas */
export type SaleServiceItemsSortPayload = {
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a quantidade do Item de Serviço em Venda */
  quantity?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o preço unitário do Item de Serviço em Venda */
  unitPrice?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Status da venda */
export enum SaleStatus {
  /** Convertido */
  Converted = 'CONVERTED',
  /** Descartado */
  Discarded = 'DISCARDED',
  /** Em negociação */
  Ongoing = 'ONGOING',
}

export enum SaleTemperature {
  /** Verde */
  Green = 'GREEN',
  /** Laranja */
  Orange = 'ORANGE',
  /** Vermelho */
  Red = 'RED',
  /** Amarelo */
  Yellow = 'YELLOW',
}

export enum SaleType {
  /** Certificado Digital */
  DigitalCertificate = 'DIGITAL_CERTIFICATE',
  /** Vínculo de Empresa */
  EnterpriseMigration = 'ENTERPRISE_MIGRATION',
  /** Abertura de Empresa */
  EnterpriseOpening = 'ENTERPRISE_OPENING',
  /** Imposto de Renda de Pessoa Física (IRPF) */
  Irpf = 'IRPF',
  /** Outros */
  Other = 'OTHER',
  /** Legalização Premium */
  PremiumLegalization = 'PREMIUM_LEGALIZATION',
  /** Legalização Volume */
  VolumeLegalization = 'VOLUME_LEGALIZATION',
}

/** Payload para filtragem de campos do tipo SaleType */
export type SaleTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<SaleType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<SaleType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<SaleType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<SaleType>>>
}

/** Representacão dos Tipos de Venda categorizado */
export type SaleTypesCategorized = {
  __typename?: 'SaleTypesCategorized'
  /** Contabilidade */
  accounting: Array<SaleType>
  /** Servicos */
  services: Array<SaleType>
}

/** Payload de atualização de uma Venda */
export type SaleUpdatePayload = {
  /** Informações Comerciais da Venda */
  commercialInfo?: InputMaybe<SaleCommercialInfoPayload>
  /** Contato da Venda */
  contactId?: InputMaybe<Scalars['UUID']['input']>
  /** Detalhes da Venda */
  details?: InputMaybe<Scalars['String']['input']>
  /** Informações Empresariais da Venda */
  enterpriseInfo?: InputMaybe<EnterpriseInfoPayload>
  /** Informações Internas da Venda */
  internalInfo?: InputMaybe<SaleInternalInfoPayload>
  /** Campanha de marketing de origem da Venda */
  marketingCampaignId?: InputMaybe<Scalars['UUID']['input']>
  /** Informações Pessoais do Contato da Venda */
  personalInfo?: InputMaybe<SalePersonalInfoPayload>
  /** Prioridade da Venda */
  priority?: InputMaybe<SalePriority>
  /** Responsável pela Venda */
  responsibleId?: InputMaybe<Scalars['UUID']['input']>
  /** Data de início de prestação de serviços */
  servicesStartingAt?: InputMaybe<Scalars['Date']['input']>
  /** Tipo da Venda */
  type?: InputMaybe<SaleType>
}

/** Lista de Anexos na Venda e seus metadados de paginação */
export type SalesAttachmentsPaginatedList = {
  __typename?: 'SalesAttachmentsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<SaleAttachment>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Lista de Comentários na Venda e seus metadados de paginação */
export type SalesCommentsPaginatedList = {
  __typename?: 'SalesCommentsPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<SaleComment>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para filtragem de Vendas */
export type SalesFilterPayload = {
  /** Filtros para o código da Venda */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID do contato da Venda */
  contactId?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de conversão da Venda */
  convertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para a data de descarte da Venda */
  discardedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID da Venda */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de Criação da Venda */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o ID da campanha de marketing de origem da Venda */
  marketingCampaignId?: InputMaybe<UuidFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<SalesFilterPayload>>
  /** Filtros para a prioridade da Venda */
  priority?: InputMaybe<SalePriorityFilterPayload>
  /** Filtros para o ID do responsável pela Venda */
  responsibleId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o tipo da Venda */
  type?: InputMaybe<SaleTypeFilterPayload>
}

/** Lista de Vendas e seus metadados de paginação */
export type SalesPaginatedList = {
  __typename?: 'SalesPaginatedList'
  /** Dados recuperados */
  entries?: Maybe<Array<Sale>>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de Vendas */
export type SalesSortPayload = {
  /** Configuração de ordenação para o código da Venda */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do contato da Venda */
  contactId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de conversão da Venda */
  convertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de descarte da Venda */
  discardedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID da campanha de marketing de origem da Venda */
  marketingCampaignId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a prioridade da Venda */
  priority?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do responsável pela Venda */
  responsibleId?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação de um item de serviço */
export type ServiceItem = {
  __typename?: 'ServiceItem'
  /** Código do item de serviço */
  code: Scalars['String']['output']
  /** Descrição do item de serviço */
  description?: Maybe<Scalars['String']['output']>
  /** Identificador único do item de serviço */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Define se o item é fixo */
  isFixed: Scalars['Boolean']['output']
  /** Nome do item de serviço */
  name: Scalars['String']['output']
  /** Tipo do item de serviço */
  type: ServiceItemType
  /** Preço unitário do item de serviço */
  unitPrice: Scalars['Int']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload de inserção de um novo item de serviço */
export type ServiceItemInsertPayload = {
  /** Nome 'slugificado', único no contexto, do item de serviço */
  code: Scalars['String']['input']
  /** Descrição do item de serviço */
  description: Scalars['String']['input']
  /** Nome amigável do item de serviço */
  name: Scalars['String']['input']
  /** Tipo do item de serviço */
  type: ServiceItemType
  /** Preço unitário do item de serviço */
  unitPrice: Scalars['Int']['input']
}

export enum ServiceItemType {
  /** Geral */
  General = 'GENERAL',
  /** Legalização */
  Legalization = 'LEGALIZATION',
  /** Outro */
  Other = 'OTHER',
}

/** Payload para filtragem de campos do tipo ServiceItemType */
export type ServiceItemTypeFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<ServiceItemType>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<ServiceItemType>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<ServiceItemType>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<ServiceItemType>>>
}

/** Payload de atualização de um item de serviço */
export type ServiceItemUpdatePayload = {
  /** Nome 'slugificado', único no contexto, do item de serviço */
  code?: InputMaybe<Scalars['String']['input']>
  /** Descrição do item de serviço */
  description?: InputMaybe<Scalars['String']['input']>
  /** Nome amigável do item de serviço */
  name?: InputMaybe<Scalars['String']['input']>
  /** Tipo do item de serviço */
  type?: InputMaybe<ServiceItemType>
  /** Preço unitário do item de serviço */
  unitPrice?: InputMaybe<Scalars['Int']['input']>
}

/** Payload para filtragem de itens de serviço */
export type ServiceItemsFilterPayload = {
  /** Filtros para o código do item de serviço */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição do item de serviço */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do item de serviço */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a definição de item de serviço fixo */
  isFixed?: InputMaybe<BooleanFilterPayload>
  /** Filtros para o nome amigável do item de serviço */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<ServiceItemsFilterPayload>>
  /** Filtros para o tipo do item de serviço */
  type?: InputMaybe<ServiceItemTypeFilterPayload>
  /** Filtros para o preço unitário do item de serviço */
  unitPrice?: InputMaybe<IntegerFilterPayload>
}

/** Lista de itens de serviço e seus metadados de paginação */
export type ServiceItemsPaginatedList = {
  __typename?: 'ServiceItemsPaginatedList'
  /** Dados recuperados */
  entries: Array<ServiceItem>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para ordenação de itens de serviço */
export type ServiceItemsSortPayload = {
  /** Configuração de ordenação para o código do item de serviço */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o ID do recurso */
  id?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a definição de item de serviço fixo */
  isFixed?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do item de serviço */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o status do item de serviço */
  type?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o preço unitário do item de serviço */
  unitPrice?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Direções para ordenação */
export enum SortDirection {
  /** Crescente */
  Asc = 'ASC',
  /** Decrescente */
  Desc = 'DESC',
}

/** Payload para filtragem de campos do tipo String */
export type StringFilterPayload = {
  /** Se o campo possui uma substring com valor igual ao enviado */
  contains?: InputMaybe<Scalars['String']['input']>
  /** Se o campo possui sufixo idêntico ao valor enviado */
  endsWith?: InputMaybe<Scalars['String']['input']>
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['String']['input']>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Se o campo não possui uma substring com valor igual ao enviado */
  ncontains?: InputMaybe<Scalars['String']['input']>
  /** Se o campo não possui sufixo idêntico ao valor enviado */
  nendsWith?: InputMaybe<Scalars['String']['input']>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['String']['input']>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  /** Se o campo não possui prefixo idêntico ao valor enviado */
  nstartsWith?: InputMaybe<Scalars['String']['input']>
  /** Se o campo possui prefixo idêntico ao valor enviado */
  startsWith?: InputMaybe<Scalars['String']['input']>
}

/** Informações sobre o Sistema */
export type SystemInfo = {
  __typename?: 'SystemInfo'
  /** SHA256 da versão da API */
  shaVersion: Scalars['String']['output']
  /** Versão da API */
  version: Scalars['String']['output']
}

/** Um registro da configuração de sistema */
export type SystemSetting = {
  __typename?: 'SystemSetting'
  /** Identificador único da configuração */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Chave da configuração */
  key: SystemSettingKey
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Valor da configuração */
  value?: Maybe<Scalars['Json']['output']>
}

export enum SystemSettingKey {
  /** Configurações financeiras */
  Financial = 'FINANCIAL',
  /** Outras configurações */
  Other = 'OTHER',
}

/** Payload de inserção/atualização de uma configuração de sistema */
export type SystemSettingUpsertPayload = {
  /** Chave da configuração */
  key: SystemSettingKey
  /** Valor da configuração */
  value?: InputMaybe<Scalars['Json']['input']>
}

/** Representação de um Regime Tributário na plataforma */
export type TaxRegime = {
  __typename?: 'TaxRegime'
  /** Código do Regime Tributário */
  code: Scalars['String']['output']
  /** Descrição do Regime Tributário */
  description: Scalars['String']['output']
  /** Identificador único do Regime Tributário */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Nome do Regime Tributário */
  name: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
}

/** Payload para filtragem de Regime Tributários */
export type TaxRegimesFilterPayload = {
  /** Filtros para o código do Regime Tributário */
  code?: InputMaybe<StringFilterPayload>
  /** Filtros para a descrição do Regime Tributário */
  description?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do Regime Tributário */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o nome do Regime Tributário */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<TaxRegimesFilterPayload>>
}

/** Lista de Regime Tributários e seus metadados de paginação */
export type TaxRegimesPaginatedList = {
  __typename?: 'TaxRegimesPaginatedList'
  /** Dados recuperados */
  entries: Array<TaxRegime>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Dados da conta de um usuário */
export type UserAccount = {
  __typename?: 'UserAccount'
  /** Data de nascimento do usuário */
  birthdate: Scalars['Date']['output']
  /** Código único do Usuário */
  code: Scalars['Int']['output']
  /** Data de confirmação de email do usuário */
  confirmedAt?: Maybe<Scalars['NaiveDateTime']['output']>
  /** Contato associado ao usuário */
  contact?: Maybe<Contact>
  /** ID do Contato associado ao usuário */
  contactId?: Maybe<Scalars['UUID']['output']>
  /** CPF do usuário */
  cpf: Scalars['CPF']['output']
  /** Email de contato do usuário */
  email: Scalars['String']['output']
  /** Listagem de empresas associadas ao usuário */
  enterprises: Array<EnterpriseUser>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Data de inserção do usuário */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Log de alterações realizadas no usuário */
  log: AuditLogChangesPaginatedList
  /** Nome completo do usuário */
  name: Scalars['String']['output']
  /** Campanha de marketing de origem do Usuário */
  originMarketingCampaign?: Maybe<MarketingCampaign>
  /** ID da Campanha de origem do Usuário */
  originMarketingCampaignId?: Maybe<Scalars['UUID']['output']>
  /** Dados associativos com um parceiro */
  partnerUser?: Maybe<PartnerUser>
  /** Número de contato de telefone do usuário */
  phone: Scalars['Phone']['output']
  /** URLs de caminho da imagem de perfil do usuário */
  picture: UserAccountPicture
  /** RG do usuário */
  rg?: Maybe<Scalars['String']['output']>
  /** Quantidade de autenticações realizadas pelo usuário. Recupera do log de auditoria (não usar demasiadamente) */
  signInEventsAmount: Scalars['Int']['output']
  /** Quantidade de notificações não visualizadas pelo usuário */
  unseenNotificationsAmount: Scalars['Int']['output']
  /** Data de atualização do usuário */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Carteira associada ao usuário */
  wallet?: Maybe<Wallet>
  /** Número de whatsapp do usuário */
  whatsapp: Scalars['Phone']['output']
}

/** Dados da conta de um usuário */
export type UserAccountLogArgs = {
  paginate?: InputMaybe<PaginationPayload>
  where?: InputMaybe<AuditLogChangesFilterPayload>
}

/** Representação de uma imagem de perfil de um usuário */
export type UserAccountPicture = {
  __typename?: 'UserAccountPicture'
  /** Versão de tamanho grande (512x512 px) da imagem do usuário */
  large?: Maybe<Scalars['String']['output']>
  /** Versão de tamanho médio (256x256 px) da imagem do usuário */
  medium?: Maybe<Scalars['String']['output']>
  /** Versão de thumbnail (64x64 px) da imagem do usuário */
  thumb?: Maybe<Scalars['String']['output']>
}

/** Payload de filtragem de usuários */
export type UserAccountsFilterPayload = {
  /** Filtros para o código do Usuário */
  code?: InputMaybe<IntegerFilterPayload>
  /** Filtros para o ID do Contato associado ao usuário */
  contactId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o CPF do usuário */
  cpf?: InputMaybe<StringFilterPayload>
  /** Filtros para o email de contato do usuário */
  email?: InputMaybe<StringFilterPayload>
  /** Filtros para o ID do recurso */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para a data de inserção do recurso */
  insertedAt?: InputMaybe<NaiveDatetimeFilterPayload>
  /** Filtros para o nome do usuário */
  name?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para serem recuperadas */
  or?: InputMaybe<Array<UserAccountsFilterPayload>>
  /** Filtros para o ID da campanha de marketing de origem do Usuário */
  originMarketingCampaignId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o RG do usuário */
  rg?: InputMaybe<StringFilterPayload>
}

/** Payload para ordenação de usuários */
export type UserAccountsSortPayload = {
  /** Configuração de ordenação para o código do Usuário */
  code?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o e-mail do usuário */
  email?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de criação do recurso */
  insertedAt?: InputMaybe<SortDirection>
  /** Configuração de ordenação para o nome do usuário */
  name?: InputMaybe<SortDirection>
  /** Configuração de ordenação para a data de última atualização do recurso */
  updatedAt?: InputMaybe<SortDirection>
}

/** Representação do resultado de uma autenticação de usuário na plataforma */
export type UserAuthResponse = {
  __typename?: 'UserAuthResponse'
  /** JWT de acesso que permite consumir a API pelo cliente (short-lived) */
  accessToken: Scalars['String']['output']
  /** Recurso de usuário associado as credenciais enviadas */
  user: UserAccount
}

/** Um registro de notificação ao Usuário */
export type UserNotification = {
  __typename?: 'UserNotification'
  /** Canais pelo qual a notificação foi disparada */
  channels: Array<Scalars['String']['output']>
  /** Fatura de Assinatura associada à notificação */
  enrollmentInvoice?: Maybe<EnrollmentInvoice>
  /** Id da Fatura de Assinatura associada à notificação */
  enrollmentInvoiceId?: Maybe<Scalars['UUID']['output']>
  /** Empresa associada */
  enterprise?: Maybe<Enterprise>
  /** Arquivo associado à notificação */
  enterpriseArchive?: Maybe<EnterpriseArchive>
  /** ID do Arquivo associado à notificação */
  enterpriseArchiveId?: Maybe<Scalars['UUID']['output']>
  /** Boleto associado à notificação */
  enterpriseBill?: Maybe<EnterpriseBill>
  /** ID do Boleto associado à notificação */
  enterpriseBillId?: Maybe<Scalars['UUID']['output']>
  /** Documento associado à notificação */
  enterpriseDocument?: Maybe<EnterpriseDocument>
  /** ID do Documento associado à notificação */
  enterpriseDocumentId?: Maybe<Scalars['UUID']['output']>
  /** ID da Empresa associada */
  enterpriseId?: Maybe<Scalars['UUID']['output']>
  /** NF associada à notificação */
  enterpriseInvoice?: Maybe<EnterpriseInvoice>
  /** ID da NF associada à notificação */
  enterpriseInvoiceId?: Maybe<Scalars['UUID']['output']>
  /** Solicitação de NF associada à notificação */
  enterpriseInvoiceRequest?: Maybe<EnterpriseInvoiceRequest>
  /** ID da Solicitação de NF associada à notificação */
  enterpriseInvoiceRequestId?: Maybe<Scalars['UUID']['output']>
  /** Onboarding associado à notificação */
  enterpriseOnboarding?: Maybe<EnterpriseOnboarding>
  /** Id do Onboarding associado à notificação */
  enterpriseOnboardingId?: Maybe<Scalars['UUID']['output']>
  /** Relatório associado à notificação */
  enterpriseReport?: Maybe<EnterpriseReport>
  /** ID do Relatório associado à notificação */
  enterpriseReportId?: Maybe<Scalars['UUID']['output']>
  /** Guia de Imposto associada à notificação */
  enterpriseTaxGuide?: Maybe<EnterpriseTaxGuide>
  /** ID da Guia de Imposto associada à notificação */
  enterpriseTaxGuideId?: Maybe<Scalars['UUID']['output']>
  /** Identificador único do recurso */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Declaração de IRPF associada à notificação */
  irpfStatement?: Maybe<IrpfStatement>
  /** ID da declaração de IRPF associada à notificação */
  irpfStatementId?: Maybe<Scalars['UUID']['output']>
  /** Tipo (evento) de notificação */
  kind: UserNotificationKind
  /** Comunicado associado à notificação */
  mailboxMessage?: Maybe<MailboxMessage>
  /** Id do Comunicado associado à notificação */
  mailboxMessageId?: Maybe<Scalars['UUID']['output']>
  /** Pedido associado à notificação */
  order?: Maybe<Order>
  /** Id do Pedido associado à notificação */
  orderId?: Maybe<Scalars['UUID']['output']>
  /** Se a notificação foi visualizada no sistema de Frontend */
  seen: Scalars['Boolean']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado */
  user: UserAccount
  /** ID do Usuário associado */
  userId: Scalars['UUID']['output']
}

export enum UserNotificationKind {
  /** Anúncio de Novidade */
  Announcement = 'ANNOUNCEMENT',
  /** Arquivo da empresa */
  ArchiveRegistered = 'ARCHIVE_REGISTERED',
  /** Boleto com Vencimento próximo */
  BillDueDate = 'BILL_DUE_DATE',
  /** Boleto Registrado */
  BillRegistered = 'BILL_REGISTERED',
  /** Fatura com Vencimento Próximo */
  EnrollmentInvoiceDueDate = 'ENROLLMENT_INVOICE_DUE_DATE',
  /** Lembrete de Pagamento - Fatura em Aberto */
  EnrollmentInvoicePaymentPending = 'ENROLLMENT_INVOICE_PAYMENT_PENDING',
  /** Lembrete de Débito Pendente em Empresa inativa */
  EnterpriseDeactivatedDelinquent = 'ENTERPRISE_DEACTIVATED_DELINQUENT',
  /** Anexo de Onboarding de Empresa Registrado */
  EnterpriseOnboardingAttachmentRegistered = 'ENTERPRISE_ONBOARDING_ATTACHMENT_REGISTERED',
  /** Onboarding de Empresa em nova Etapa */
  EnterpriseOnboardingForwardStep = 'ENTERPRISE_ONBOARDING_FORWARD_STEP',
  /** Onboarding de Empresa Registrado */
  EnterpriseOnboardingRegistered = 'ENTERPRISE_ONBOARDING_REGISTERED',
  /** Onboarding de Empresa Parado */
  EnterpriseOnboardingStopped = 'ENTERPRISE_ONBOARDING_STOPPED',
  /** Onboarding de Empresa em nova Análise */
  EnterpriseOnboardingUpdated = 'ENTERPRISE_ONBOARDING_UPDATED',
  /** Onboarding de Empresa Aguardando Correções */
  EnterpriseOnboardingWaitingPendencies = 'ENTERPRISE_ONBOARDING_WAITING_PENDENCIES',
  /** Aviso de Bloqueio por Débito Pendente */
  EnterpriseWarningDeactivation = 'ENTERPRISE_WARNING_DEACTIVATION',
  /** Nota Fiscal Registrada */
  InvoiceRegistered = 'INVOICE_REGISTERED',
  /** Declaração de Imposto de Renda Finalizada */
  IrpfStatementFinished = 'IRPF_STATEMENT_FINISHED',
  /** Declaração de Imposto de Renda em Análise */
  IrpfStatementRegistered = 'IRPF_STATEMENT_REGISTERED',
  /** Declaração de Imposto de Renda em nova Análise */
  IrpfStatementUpdated = 'IRPF_STATEMENT_UPDATED',
  /** Declaração de Imposto de Renda Aguardando Correções */
  IrpfStatementWaitingPendencies = 'IRPF_STATEMENT_WAITING_PENDENCIES',
  /** Comunicado Registrado */
  MailboxMessageRegistered = 'MAILBOX_MESSAGE_REGISTERED',
  /** Cobrança Pontual Registrada */
  OrderRegistered = 'ORDER_REGISTERED',
  /** Promoção */
  Promotion = 'PROMOTION',
  /** Relatório Registrado */
  ReportRegistered = 'REPORT_REGISTERED',
  /** Alerta de Segurança */
  SecurityAlert = 'SECURITY_ALERT',
  /** Guia de Imposto com Vencimento próximo */
  TaxGuideDueDate = 'TAX_GUIDE_DUE_DATE',
  /** Guia de Imposto Registrada */
  TaxGuideRegistered = 'TAX_GUIDE_REGISTERED',
}

/** Payload para filtragem de notificaçãos */
export type UserNotificationsFilterPayload = {
  /** Filtros para o ID de Empresa da notificação */
  enterpriseId?: InputMaybe<UuidFilterPayload>
  /** Filtros para o ID da notificação */
  id?: InputMaybe<UuidFilterPayload>
  /** Filtros para o tipo de notificação */
  kind?: InputMaybe<StringFilterPayload>
  /** Condições do tipo lógico OR que devem ser verdadeiras para ser recuperadas */
  or?: InputMaybe<Array<UserNotificationsFilterPayload>>
  /** Se a notificação foi ou não visualizada no App */
  seen?: InputMaybe<BooleanFilterPayload>
  /** Filtros para o ID de Usuário da notificação */
  userId?: InputMaybe<UuidFilterPayload>
}

/** Lista de Notificações de Usuários e seus metadados de paginação */
export type UserNotificationsPaginatedList = {
  __typename?: 'UserNotificationsPaginatedList'
  /** Dados recuperados */
  entries: Array<UserNotification>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para cadastro de um usuário */
export type UserSignUpPayload = {
  /** Data de nascimento do usuário */
  birthdate: Scalars['Date']['input']
  /** CPF do usuário */
  cpf: Scalars['CPF']['input']
  /** Email de contato do usuário */
  email: Scalars['String']['input']
  /** Nome completo do usuário */
  name: Scalars['String']['input']
  /** Código da campanha de marketing de origem do Usuário */
  originMarketingCampaignCode?: InputMaybe<Scalars['String']['input']>
  /** Senha de acesso do usuário */
  password: Scalars['String']['input']
  /** Número de contato de telefone do usuário */
  phone: Scalars['Phone']['input']
  /** RG do usuário */
  rg?: InputMaybe<Scalars['String']['input']>
  /** Número de whatsapp do usuário */
  whatsapp: Scalars['Phone']['input']
}

/** Lista de usuários e seus metadados de paginação */
export type UsersPaginatedList = {
  __typename?: 'UsersPaginatedList'
  /** Dados recuperados */
  entries: Array<UserAccount>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload para filtragem de campos do tipo UUID */
export type UuidFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<Scalars['UUID']['input']>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<Scalars['UUID']['input']>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<Scalars['UUID']['input']>>
}

/** Um registro da carteira */
export type Wallet = {
  __typename?: 'Wallet'
  /** Empresa associada à carteira */
  enterprise?: Maybe<Enterprise>
  /** Identificador externo do usuário associado à carteira */
  externalUserId: Scalars['String']['output']
  /** Identificador único da carteira */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Usuário associado à carteira */
  user?: Maybe<UserAccount>
}

/** Um registro de cartão */
export type WalletCard = {
  __typename?: 'WalletCard'
  /** Bandeira do cartão */
  brand: WalletCardBrand
  /** Mês de expiração do cartão */
  expirationMonth: Scalars['Int']['output']
  /** Ano de expiração do cartão */
  expirationYear: Scalars['Int']['output']
  /** Identificador externo do endereço */
  externalAddressId: Scalars['String']['output']
  /** Identificador externo do cartão */
  externalCardId: Scalars['String']['output']
  /** Documento do titular do cartão */
  holderDocument: Scalars['String']['output']
  /** Nome do titular do cartão */
  holderName: Scalars['String']['output']
  /** Identificador único da cartão */
  id: Scalars['UUID']['output']
  /** Timestamp de criação do recurso */
  insertedAt: Scalars['NaiveDateTime']['output']
  /** Indica se o cartão está ativo para recorrência */
  isActive: Scalars['Boolean']['output']
  /** Últimos 4 dígitos do cartão */
  lastFourDigits: Scalars['String']['output']
  /** Timestamp de última atualização do recurso */
  updatedAt: Scalars['NaiveDateTime']['output']
  /** Carteira associada ao cartão */
  wallet: Wallet
}

export enum WalletCardBrand {
  /** Amex */
  Amex = 'AMEX',
  /** Aura */
  Aura = 'AURA',
  /** Diners */
  Diners = 'DINERS',
  /** Discover */
  Discover = 'DISCOVER',
  /** Elo */
  Elo = 'ELO',
  /** Hipercard */
  Hipercard = 'HIPERCARD',
  /** Jcb */
  Jcb = 'JCB',
  /** Mastercard */
  Mastercard = 'MASTERCARD',
  /** Outro */
  Other = 'OTHER',
  /** Visa */
  Visa = 'VISA',
}

/** Payload para filtragem de campos do tipo WalletCardBrand */
export type WalletCardBrandFilterPayload = {
  /** Se o campo possui valor idêntico ao valor enviado */
  eq?: InputMaybe<WalletCardBrand>
  /** Se o campo possui o seu valor na lista enviada */
  in?: InputMaybe<Array<InputMaybe<WalletCardBrand>>>
  /** Se o campo não possui valor idêntico ao valor enviado */
  neq?: InputMaybe<WalletCardBrand>
  /** Se o campo não possui o seu valor na lista enviada */
  nin?: InputMaybe<Array<InputMaybe<WalletCardBrand>>>
}

/** Payload de inserção de um cartão na carteira */
export type WalletCardInsertPayload = {
  /** Dados do endereco de cobrança */
  billingAddress: AddressInsertPayload
  /** Token do cartão de crédito */
  cardToken: Scalars['String']['input']
}

/** Payload de filtro de cartões na carteira */
export type WalletCardsFilterPayload = {
  /** Filtro por bandeira do cartão */
  brand?: InputMaybe<WalletCardBrandFilterPayload>
  /** Filtro por mês de expiração do cartão */
  expirationMonth?: InputMaybe<IntegerFilterPayload>
  /** Filtro por ano de expiração do cartão */
  expirationYear?: InputMaybe<IntegerFilterPayload>
  /** Filtro por identificador externo do endereço */
  externalAddressId?: InputMaybe<StringFilterPayload>
  /** Filtro por identificador externo do cartão */
  externalCardId?: InputMaybe<StringFilterPayload>
  /** Filtro por documento do titular do cartão */
  holderDocument?: InputMaybe<StringFilterPayload>
  /** Filtro por nome do titular do cartão */
  holderName?: InputMaybe<StringFilterPayload>
  /** Filtro por último 4 dígitos do cartão */
  lastFourDigits?: InputMaybe<StringFilterPayload>
}

/** Lista de cartões na carteira e seus metadados de paginação */
export type WalletCardsPaginatedList = {
  __typename?: 'WalletCardsPaginatedList'
  /** Dados recuperados */
  entries: Array<WalletCard>
  /** Metadados de paginação */
  paginationMetadata: PaginationMetadata
}

/** Payload de ordenação de cartões na carteira */
export type WalletCardsSortPayload = {
  /** Ordenação por bandeira do cartão */
  brand?: InputMaybe<SortDirection>
  /** Ordenação por mês de expiração do cartão */
  expirationMonth?: InputMaybe<SortDirection>
  /** Ordenação por ano de expiração do cartão */
  expirationYear?: InputMaybe<SortDirection>
  /** Ordenação por identificador externo do endereço */
  externalAddressId?: InputMaybe<SortDirection>
  /** Ordenação por identificador externo do cartão */
  externalCardId?: InputMaybe<SortDirection>
  /** Ordenação por documento do titular do cartão */
  holderDocument?: InputMaybe<SortDirection>
  /** Ordenação por nome do titular do cartão */
  holderName?: InputMaybe<SortDirection>
  /** Ordenação por último 4 dígitos do cartão */
  lastFourDigits?: InputMaybe<SortDirection>
}

export type RequestContactSaleMutationVariables = Exact<{
  contact: ContactInsertPayload
  marketingCampaignCode?: InputMaybe<Scalars['String']['input']>
  cnpj?: InputMaybe<Scalars['String']['input']>
}>

export type RequestContactSaleMutation = { __typename?: 'RootMutationType'; requestContactSale: string }

export const RequestContactSaleDocument = gql`
  mutation requestContactSale($contact: ContactInsertPayload!, $marketingCampaignCode: String, $cnpj: String) {
    requestContactSale(contact: $contact, marketingCampaignCode: $marketingCampaignCode, cnpj: $cnpj)
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action()

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    requestContactSale(
      variables: RequestContactSaleMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RequestContactSaleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestContactSaleMutation>(RequestContactSaleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'requestContactSale',
        'mutation',
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
