import React from 'react'
import { classNames } from '../services/string'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'

export interface FullCustomerReviewCardProps {
  className?: string
  review: Queries.ReviewInfoFragment
}

const FullCustomerReviewCard = ({ className, review }: FullCustomerReviewCardProps) => {
  const cardImage = getImage(review.avatar?.localFile as ImageDataLike)

  return (
    <div
      className={classNames(
        className,
        'rounded-2xl flex flex-col py-4 px-10 h-full justify-between items-center gap-2 bg-white/50 shadow-[1px_1px_1px_0px_rgba(255,255,255,1)_inset] text-black',
      )}
    >
      <div className='flex flex-col items-center justify-start mb-5'>
        <div className='bg-rainbow p-2 rounded-full mt-2 mb-6'>
          {cardImage && <GatsbyImage className='rounded-full' image={cardImage} alt={`${review?.author}`} />}
        </div>

        <div className='text-center flex flex-col gap-2'>
          <div>
            <p className='font-semibold text-xl'>{review.author}</p>
            <p className='text-sm text-black/70'>{review.position}</p>
          </div>
          <p className='text-sm'>"{review.content}"</p>
        </div>
      </div>
    </div>
  )
}

export default FullCustomerReviewCard
