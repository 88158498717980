import React, { useEffect, useMemo, useState } from 'react'
import { Tab, Transition } from '@headlessui/react'
import type { Property } from 'csstype'

import {
  digitalCertificateBenefits,
  digitalCertificateDescription,
  meiDescription,
  otherDescription,
  planBenefits,
  simplesNacionalDescription,
} from './constants'
import { classNames } from '../../services/string'
import { findContrastColor } from '../../services/color'
import { formatWhatsAppLink } from '../../services/formatter'

import PlanBenefits from './PlanBenefits'
import PlanCard from '../PlanCard'

export enum PlanCategory {
  Service = 'Serviço',
  Commerce = 'Comércio',
}

interface PlanContent extends Queries.PlansContentFragment {
  category?: PlanCategory
  name: string
  description: string
  metaDescription: string
}

export interface CustomPricing extends Queries.PlansContentFragment {
  name: string
  description: string
  metaDescription: string
  category: PlanCategory
}

interface PlansProps {
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
  pricing?: Queries.PricingInfoFragment['pricing']
  customPricingOptions?: CustomPricing[]
  planBenefitsTitle?: string
  customPlanBenefits?: string[]
  contactUrl?: string
  benefitsCardOptions?: {
    wrapped?: boolean
  }
  planCardOptions?: {
    blurred?: boolean
  }
  whatsAppCustomMessageSlug?: string
}

const Plans = ({
  pricing,
  customPricingOptions,
  planBenefitsTitle,
  customPlanBenefits,
  primaryColor,
  secondaryColor,
  contactUrl,
  benefitsCardOptions,
  planCardOptions,
  whatsAppCustomMessageSlug,
}: PlansProps) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [availableOptions, setAvailableOptions] = useState<PlanContent[]>()
  const [digitalCertificateOption, setDigitalCertificateOption] = useState<PlanContent>()
  const [shouldShowTabOptions, setShouldShowTabOptions] = useState<boolean>(false)

  const primaryContrastColor = findContrastColor(primaryColor || 'var(--color-primary-600)')

  useEffect(() => {
    if (customPricingOptions) {
      setAvailableOptions(customPricingOptions)
      return
    }

    const { digitalCertificate, meiService, meiCommerce, simplesNacionalService, simplesNacionalCommerce, otherService, otherCommerce } =
      pricing || {}

    const options: PlanContent[] = [
      ...(meiService
        ? [
            {
              category: PlanCategory.Service,
              metaDescription: 'meiService',
              ...meiDescription,
              ...meiService,
            },
          ]
        : []),
      ...(simplesNacionalService
        ? [
            {
              category: PlanCategory.Service,
              metaDescription: 'simplesNacionalService',
              ...simplesNacionalDescription,
              ...simplesNacionalService,
            },
          ]
        : []),
      ...(otherService
        ? [
            {
              category: PlanCategory.Service,
              metaDescription: 'otherService',
              ...otherDescription,
              ...otherService,
            },
          ]
        : []),
      ...(meiCommerce
        ? [
            {
              category: PlanCategory.Commerce,
              metaDescription: 'meiCommerce',
              ...meiDescription,
              ...meiCommerce,
            },
          ]
        : []),
      ...(simplesNacionalCommerce
        ? [
            {
              category: PlanCategory.Commerce,
              metaDescription: 'simplesNacionalCommerce',
              ...simplesNacionalDescription,
              ...simplesNacionalCommerce,
            },
          ]
        : []),
      ...(otherCommerce
        ? [
            {
              category: PlanCategory.Commerce,
              metaDescription: 'otherCommerce',
              ...otherDescription,
              ...otherCommerce,
            },
          ]
        : []),
    ]
    setAvailableOptions(options)
    setDigitalCertificateOption(
      digitalCertificate
        ? {
            metaDescription: 'digitalCertificate',
            ...digitalCertificateDescription,
            ...digitalCertificate,
          }
        : undefined,
    )

    const availableCategoriesToShow =
      options &&
      options.reduce(
        (prev, { category }) => (category ? (prev.includes(category) ? prev : [...prev, category]) : prev),
        [] as PlanCategory[],
      )
    setShouldShowTabOptions(Boolean(availableCategoriesToShow.length > 1))
    setSelectedTabIndex(Object.values(PlanCategory).indexOf(availableCategoriesToShow[0]))
  }, [pricing])

  const optionsToShow = useMemo(() => {
    const selectedTab = Object.values(PlanCategory)[selectedTabIndex]
    if (!selectedTab) return availableOptions
    return availableOptions?.filter(({ category }) => category === String(selectedTab))
  }, [availableOptions, selectedTabIndex])

  const visibleOptionsAmount = optionsToShow?.length

  return (
    <div className='flex flex-col gap-8 sm:gap-12 lg:gap-16'>
      <div className={classNames('justify-center w-full', shouldShowTabOptions ? 'flex' : 'hidden')}>
        <Tab.Group onChange={(index: number) => setSelectedTabIndex(index)}>
          <Tab.List className='flex space-x-1 rounded-xl p-1 bg-white border-gray-400 border drop-shadow-lg' id='price-tab-group'>
            {Object.values(PlanCategory).map((category, index) => (
              <Tab
                id={`price-tab-${category}`}
                value={category}
                key={category}
                className={({ selected }) =>
                  classNames(
                    'rounded-lg py-2.5 px-1 text-xs font-normal leading-5 w-28',
                    'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
                    selected ? 'shadow' : 'bg-white',
                  )
                }
                style={{
                  backgroundColor: index === selectedTabIndex ? primaryColor || 'var(--color-primary-600)' : 'white',
                  color: index === selectedTabIndex ? primaryContrastColor : 'black',
                }}
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>

      <div key={selectedTabIndex}>
        <Transition
          appear={true}
          show={true}
          enter='transition-opacity easy-linear duration-[300ms]'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          className='flex flex-col gap-8 sm:gap-12 lg:gap-16'
        >
          <div className='grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-8 gap-x-0 gap-y-8 sm:gap-8 w-full'>
            <PlanBenefits
              className={classNames(
                'col-span-1 sm:col-span-2',
                visibleOptionsAmount === 1
                  ? 'sm:col-start-1 xl:col-start-3'
                  : visibleOptionsAmount === 2
                    ? 'sm:col-start-2'
                    : 'sm:col-start-1',
              )}
              title={planBenefitsTitle || 'Incluso nos planos:'}
              benefits={customPlanBenefits || planBenefits}
              wrapped={benefitsCardOptions?.wrapped}
            />
            {optionsToShow?.map(({ id, category, ...rest }) => (
              <PlanCard
                key={id}
                className='col-span-1 sm:col-span-2'
                {...rest}
                contactUrl={contactUrl ? formatWhatsAppLink(contactUrl, { slug: whatsAppCustomMessageSlug }) : ''}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                blurred={planCardOptions?.blurred}
              />
            ))}
          </div>

          {digitalCertificateOption && (
            <div className='grid grid-cols-1 sm:grid-cols-4 xl:grid-cols-8 gap-x-0 gap-y-8 sm:gap-8 w-full'>
              <PlanBenefits
                className='col-span-1 sm:col-span-2 xl:col-start-3'
                title='Certificado Digital:'
                benefits={digitalCertificateBenefits}
                wrapped={benefitsCardOptions?.wrapped}
              />

              <PlanCard
                {...digitalCertificateOption}
                className='col-span-1 sm:col-span-2'
                contactUrl={contactUrl || ''}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                blurred={planCardOptions?.blurred}
              />
            </div>
          )}
        </Transition>
      </div>
    </div>
  )
}

export default Plans
