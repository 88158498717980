import React from 'react'
import type { Property } from 'csstype'

import Disclosure from '../Disclosure'
import { classNames } from '../../services/string'
import { findContrastColor } from '../../services/color'

interface FAQBoxProps {
  questions?: Queries.FAQInfoFragment[]
  extraQuestions?: Queries.FAQInfoFragment[]
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
}

const FAQBox = ({ questions, extraQuestions, primaryColor = '#FFFFFF', secondaryColor }: FAQBoxProps) => {
  const groupedQuestions = [...(questions || []), ...(extraQuestions || [])]
  const contrastColor = findContrastColor(secondaryColor || '#FFFFFF')

  return (
    <div
      className={classNames(
        primaryColor && 'border-t-none border-l-none md:border-t-2 md:border-l-2',
        'flex flex-col gap-2 md:gap-0 md:py-6 md:px-8 md:rounded-2xl md:shadow-lg md:drop-shadow-lg bg-transparent md:bg-white md:bg-opacity-80',
      )}
      style={{
        borderColor: primaryColor ? primaryColor : 'transparent',
      }}
    >
      {groupedQuestions?.map((question, index) => (
        <div key={question?.id}>
          {index !== groupedQuestions.length && index > 0 && <hr className='hidden md:block my-1 border-black/20' />}
          <Disclosure
            className='drop-shadow-lg md:drop-shadow-none'
            title={question?.question || ''}
            secondaryColor={secondaryColor}
            content={
              <section
                style={{ color: contrastColor }}
                className='flex flex-col prose prose-sm prose-li:marker:text-primary-900 prose-hr:border-black'
                dangerouslySetInnerHTML={{
                  __html: question?.answer?.data?.childMarkdownRemark?.html || '',
                }}
              />
            }
          />
        </div>
      ))}
    </div>
  )
}

export default FAQBox
