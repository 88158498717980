import React from 'react'
import type { Property } from 'csstype'

import { findContrastColor } from '../services/color'
import { formatCentsToReais } from '../services/money'
import { classNames } from '../services/string'
import { useAnalytics } from '../hooks'

import Button from './Button'

interface PlanCardProps extends Omit<Queries.PlansContentFragment, 'id'> {
  className?: string
  name: string
  description: string
  metaDescription: string
  contactUrl: string
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
  blurred?: boolean
}

const PlanCard = ({
  className,
  name,
  description,
  metaDescription,
  monthly,
  value,
  valueFrom,
  highlightChipText,
  contactUrl,
  primaryColor,
  secondaryColor,
  blurred = false,
}: PlanCardProps) => {
  const { fireEvent } = useAnalytics()
  const primaryContrastColor = findContrastColor(primaryColor || 'var(--color-primary-600)')
  const secondaryContrastColor = findContrastColor(secondaryColor || 'var(--color-primary-100)')

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = (target: string, description: string) => {
    fireEvent('adsConversion', { component: 'PlanCard', target, description })

    fireEvent('externalClick', {
      component: 'PlanCard',
      target,
      description,
    })
  }

  return (
    <div
      className={classNames(
        className,
        'flex flex-col justify-between gap-4 rounded-xl border-t-2 border-l-2 p-4 shadow-lg drop-shadow-lg',
        blurred && 'backdrop-blur-md',
      )}
      style={{
        backgroundColor: `${secondaryColor || 'var(--color-primary-100)'}${blurred ? '80' : ''}`,
        color: secondaryContrastColor,
        borderColor: primaryColor || 'var(--color-primary-600)',
      }}
    >
      <div className='flex flex-col gap-3'>
        {highlightChipText && (
          <div className='w-full flex justify-end'>
            <div
              className='px-3 py-1 text-sm rounded-full w-fit'
              style={{ backgroundColor: primaryColor || 'var(--color-primary-600)', color: primaryContrastColor }}
            >
              <p className='font-semibold text-sm'>{highlightChipText}</p>
            </div>
          </div>
        )}
        <div>
          <p className='font-semibold' style={{ color: primaryColor || 'var(--color-primary-600)' }}>
            {name}
          </p>
          <p className='text-sm lg:text-xs font-light'>{description}</p>
        </div>
      </div>

      <div className='flex flex-col gap-2 my-2'>
        {Boolean(valueFrom) && (
          <p style={{ color: `${secondaryContrastColor}40` }}>
            <span>R$ </span>
            <span className='line-through text-2xl font-semibold'>{formatCentsToReais(valueFrom || 0)}</span>
            {monthly && <span className='text-sm font-light'>/mês</span>}
          </p>
        )}
        <div>
          <p className='text-[0.7rem] font-light'>À partir de *</p>
          <p className='text-4xl font-semibold'>
            {formatCentsToReais(value || 0)}
            {monthly && <span className='text-sm font-light'>/mês</span>}
          </p>
        </div>
      </div>

      <div>
        <p className='text-right text-[0.7rem] font-light'>* Consulte condições</p>
        <a href={contactUrl} rel='noreferrer noopener' target='_BLANK' onClick={() => fireAnalyticsEvent('linkWhatsapp', metaDescription)}>
          <Button
            className='w-full mt-2'
            colorSchema='styled'
            style={{ backgroundColor: primaryColor || 'var(--color-primary-600)', color: primaryContrastColor }}
          >
            Entre em contato
          </Button>
        </a>
      </div>
    </div>
  )
}

export default PlanCard
