import React from 'react'
import { joinStrings } from '../services/string'

type IconButtonColor = 'primary' | 'secondary' | 'inherit'

type IconButtonSize = 'sm' | 'md'

type IconButtonVariant = 'contained' | 'outlined'

export interface IIConButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  icon: JSX.Element
  size?: IconButtonSize
  variant?: IconButtonVariant
  color?: IconButtonColor
  onClick?: () => unknown
}

const sizes: Record<IconButtonSize, string> = {
  sm: 'px-1 py-1',
  md: 'px-2 py-2',
}

const variants: Record<IconButtonVariant, string> = {
  contained: 'shadow-sm',
  outlined: 'border border-black',
}

const colors: Record<IconButtonColor, string> = {
  primary: 'bg-primary-600 text-black',
  secondary: 'bg-black text-primary-600',
  inherit: 'bg-inherit',
}

const IconButton = ({ icon, className = '', size = 'md', variant = 'contained', color = 'primary', onClick }: IIConButtonProps) => {
  const styles = 'rounded-full font-medium'
  return (
    <button className={joinStrings([className, styles, sizes[size], variants[variant], colors[color]])} onClick={onClick}>
      {icon}
    </button>
  )
}

export default IconButton
