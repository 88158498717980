import { capitalizeFirstLetter } from './string'

interface FormatWhatsAppLinkOptions {
  slug?: string
  customMessage?: string
}

/**
 * Formata um link do WhatsApp de acordo com as opções `options` fornecidas. Particularmente útil em páginas direcionadas, como Landing Pages e páginas de público específico.
 * @param link link do WhatsApp a ser formatado.
 * @param options opções de customização do link.
 * * `slug` identificador inserido no final da mensagem.
 * * `customMessage` mensagem customizada que substitui a mensagem padrão.
 */
export const formatWhatsAppLink = (link: string, options: FormatWhatsAppLinkOptions) => {
  const { slug, customMessage } = options
  if (!slug && !customMessage) return link

  const urlObject = new URL(link)
  urlObject.searchParams.set(
    'text',
    customMessage
      ? customMessage
      : slug
        ? `${urlObject.searchParams.get('text')} (${capitalizeFirstLetter(slug)})`
        : 'Quero saber mais sobre a Hubs Contabilidade',
  )
  return urlObject.toString()
}
