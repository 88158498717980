import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { classNames } from '../../services/string'
import { CommonContactFormProps } from './types'

import Container from '../Container'
import FormFields from './FormFields'

const TraditionalVariant = ({ className, title, description, onSubmit, loading, marketingCampaignCode }: CommonContactFormProps) => {
  return (
    <div className={classNames(className, 'bg-secondary-700 rounded-b-3xl')}>
      <Container className='relative'>
        <div className='flex flex-col md:flex-row lg:flex-col lg:w-full gap-8 py-12 xl:py-20 text-white'>
          <div className='flex flex-col gap-4 text-center md:text-left md:w-1/2'>
            <h2 className='text-primary-600 font-semibold text-2xl'>{title || 'Fale conosco'}</h2>
            <p>
              {description || 'Nosso time de especialistas entrará em contato o mais rápido possível para tirar todas as suas dúvidas!'}
            </p>
          </div>

          <div className='w-full md:w-1/2'>
            <FormFields
              loading={loading}
              onSubmit={onSubmit}
              options={{
                textFieldVariant: 'underline',
                hideTextFieldLabels: true,
                contrastReferenceColor: '#000000',
              }}
              marketingCampaignCode={marketingCampaignCode}
            />
          </div>
        </div>

        <div className='hidden lg:flex justify-center lg:w-1/2'>
          <div className='absolute bottom-0 right-0'>
            <StaticImage
              width={550}
              src='../../images/resources/mailbox.png'
              alt='Figura ilustrativa de uma caixa de correios aberta'
              className='lg:max-w-[450px] xl:max-w-[550px]'
              placeholder='blurred'
              quality={100}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default TraditionalVariant
