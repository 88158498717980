import type { Property } from 'csstype'

/**
 * Calcula a cor de contraste de uma cor fornecida como parâmetro.
 * @param color cor base para o cálculo da cor de contraste. Deve estar no formato hexadecimal (ex.: `#AEAEAE`).
 * @param lightColor cor de contraste claro correspondente à cor fornecida, padrão `#FFFFFF`.
 * @param darkColor cor de contraste escuro correspondente à cor fornecida, padrão `#000000`.
 */
export const findContrastColor = (color: Property.Color, lightColor: string = '#FFFFFF', darkColor: string = '#000000'): string => {
  if (!color.startsWith('#')) return '#000000'

  const cleanColor = color.replace('#', '')
  const [r, g, b] = [0, 2, 4].map((p, idx) => parseInt(cleanColor.slice(p, 2 * (idx + 1)), 16))
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 128 ? darkColor : lightColor
  // if (!color) { return '' }
  // return (parseInt(color.replace('#', ''), 16) > 0xffffff / 2) ? darkColor : lightColor
}
