import React from 'react'
import { graphql } from 'gatsby'
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface PartnerCardProps {
  partner: Queries.PartnerCardFragment
}

const PartnerCard = ({ partner }: PartnerCardProps) => {
  const { name } = partner
  const partnerImage = getImage(partner.logo?.localFile as ImageDataLike)
  return (
    <div className='flex flex-col justify-center items-center gap-2'>
      {partnerImage && <GatsbyImage className='rounded-full' image={partnerImage} alt={`Logo parceiro ${partner.name}`} />}
      <p>{name}</p>
    </div>
  )
}

export const query = graphql`
  fragment PartnerCard on STRAPI_PARTNER {
    id
    name
    logo {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 72, height: 72)
        }
      }
    }
  }
`

export default PartnerCard
