import React from 'react'

interface RainbowChipProps {
  label: string
}

const RainbowChip = ({ label }: RainbowChipProps) => {
  return (
    <span className='bg-rainbow text-white uppercase text-xs py-1.5 font-semibold px-2.5 rounded-full w-fit drop-shadow-xl'>{label}</span>
  )
}

export default RainbowChip
