export const classNames = (...classes: (string | undefined | null | boolean)[]) => {
  return classes.filter(Boolean).join(' ')
}

/**
 * Consolida uma lista de strings recebida como parâmetro em uma única string com itens separados por espaço.
 * @param strings lista de strings a ser consolidada.
 */
export const joinStrings = (strings: string[]) =>
  strings.reduce((previous, current, index) => `${previous}${`${index > 0 ? ` ${current}` : current}`}`, '')

/**
 * Recupera as iniciais de um nome.
 * @param name nome.
 */
export const getInitials = (name: string) => {
  if (!name.includes(' ')) return name[0]

  const splittedName = name.split(' ')
  return `${splittedName[0][0]}${splittedName[splittedName.length - 1][0]}`
}

/**
 * Limita a exibição de uma string.
 * @param value string a ser limitada.
 * @param limit quantidade de caracteres a serem exibidos.
 * @param separator caractere a ser exibido após a string limitada.
 * @param separatorPosition caractere a ser exibido após a string limitada.
 */
export const limitString = (value: string, limit: number, separator = '...', separatorPosition = 'end') => {
  if (value.length <= limit) return value

  if (separatorPosition === 'end') return `${value.substring(0, limit)}${separator}`

  return `${separator}${value.substring(value.length - limit, value.length)}`
}

/**
 * Formata uma label descritiva com o indicador de obrigatoriedade caso a condição seja satisfeita.
 * @param label Label a ser formatada.
 * @param isRequired Condição de obrigatoriedade a ser satisfeita.
 */
export const formatRequiredLabel = (label: string, isRequired: boolean) => `${label}${isRequired ? ' *' : ''}`

/**
 * Extrai os dígitos de uma string formatada em qualquer padrão e retorna o valor em tipo numérico. Considera o caractere vírgula (`,`) como separador de casas decimais.
 * Exemplo:
 * * Entrada: R$ 5.124,32 - `string`
 * * Saída: 5124.32 - `number`
 * @param data string formatada a ser avaliada.
 */
export const extractNumericValue = (data: string) => {
  if (!data) return ''
  const numericString = data.replace(/[^\d,]/g, '')
  return Number(numericString.replace(',', '.'))
}

/**
 * Retorna a `string` fornecida com a primeira letra maiúscula seguida de todas as outras letras minúsculas.
 * @param data `string` a ser convertida.
 */
export const capitalizeFirstLetter = (data: string) => {
  const firstLetter = data.charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = data.slice(1).toLowerCase()

  return firstLetterCap + remainingLetters
}
