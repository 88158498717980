import React, { useEffect, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { MdClose, MdMenu, MdOutlinePerson } from 'react-icons/md'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { getWindow } from '../services/window'
import { classNames } from '../services/string'

import { Container, IconButton, WhatsAppButton } from './'
import { useAnalytics } from '../hooks'

type NavbarItem = {
  label: string
  to: string
  partiallyActive?: boolean
}

interface IAppButtonProps {
  className?: string
  href: string
  onClick?: () => unknown
}

const AppButton = ({ className, href, onClick }: IAppButtonProps) => (
  <a href={href} rel='noreferrer noopener' target='_BLANK' className={className} onClick={onClick}>
    <div className='px-2.5 py-1.5 text-xs shadow-sm bg-black text-white rounded-full'>Área do Cliente</div>
  </a>
)

const options: NavbarItem[] = [
  {
    label: 'Home',
    to: '/',
  },
  {
    label: 'Planos',
    to: '/#planos',
  },
  {
    label: 'Como funciona',
    to: '/como-funciona',
  },
  {
    label: 'Sobre',
    to: '/sobre',
  },
  {
    label: 'Parceiros',
    to: '/parceiros',
  },
  {
    label: 'Blog',
    to: '/blog',
    partiallyActive: true,
  },
  {
    label: 'Contato',
    to: '/contato',
  },
]

const NavBar = () => {
  const { fireEvent } = useAnalytics()
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...NavBarInfo
      }
    }
  `)

  const navBarInfo = strapiGlobal as Queries.NavBarInfoFragment

  const window = getWindow()

  const breakpoints = useBreakpoint()
  const lastScrollPosition = useRef<number>(window?.scrollY || window?.document.documentElement.scrollTop || 0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(true)

  // Mecanismo de ocultação da barra de navegação no scroll down
  useEffect(() => {
    const handleScroll = () => {
      if (!breakpoints.md) return
      const isScrollingTop = (window?.scrollY || 0) > 70 && lastScrollPosition.current < (window?.scrollY || 0)
      if (!isOpen) setIsVisible(!isScrollingTop)
      lastScrollPosition.current = window?.scrollY || 0
    }
    window?.addEventListener('scroll', handleScroll)
    return () => window?.removeEventListener('scroll', handleScroll)
  }, [isOpen, breakpoints.md])

  // Mecanismo de ocultação do menu em caso de redimensionamento da tela
  useEffect(() => {
    if (!breakpoints.md) {
      setIsOpen(false)
      setIsVisible(true)
    }
  }, [breakpoints.md])

  const getNavBarDynamicStyles = () => {
    if (isVisible) return isOpen ? 'h-16' : 'h-16 top-0'
    return 'h-0 -top-24 -z-50 opacity-0'
  }

  const getMenuDynamicStyles = () => (isOpen ? 'h-80 top-0' : 'h-0 -top-96')

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = (target: string) => {
    fireEvent('adsConversion', { component: 'NavBarWhatsappLink', target })

    fireEvent('externalClick', {
      component: 'NavBar',
      target,
    })
  }

  return (
    <nav>
      <WhatsAppButton href={navBarInfo.linkWhatsapp || ''} onClick={() => fireAnalyticsEvent('linkWhatsapp')} />

      <div className={`${getNavBarDynamicStyles()} bg-white top-0 shadow-md fixed w-full transition-all duration-300 z-40`}>
        <Container className='flex h-full items-center justify-between'>
          <IconButton
            aria-label='Botão para abrir e fechar o menu'
            className='border-none lg:hidden'
            icon={isOpen ? <MdClose aria-label='Fechar o Menu' size='1.5rem' /> : <MdMenu aria-label='Abrir o Menu' size='1.5rem' />}
            variant='outlined'
            color='inherit'
            onClick={() => setIsOpen(!isOpen)}
          />

          <Link to='/'>
            <StaticImage
              src='../images/logos/logo-black.png'
              alt='Logo Hubs Contabilidade'
              className='max-w-[100px]'
              placeholder='blurred'
              quality={100}
              width={100}
            />
          </Link>

          <div className='hidden lg:block'>
            {options.map(({ label, to, partiallyActive }, index) => (
              <Link
                partiallyActive={partiallyActive}
                activeClassName='font-bold'
                key={index}
                to={to}
                className='my-2 text-black px-3 py-2 text-xs lg:text-sm'
              >
                {label}
              </Link>
            ))}
          </div>

          <a
            className='block lg:hidden w-fit'
            href={navBarInfo.linkAppWeb || ''}
            rel='noreferrer noopener'
            target='_BLANK'
            onClick={() => fireAnalyticsEvent('linkAppWeb')}
          >
            <div className='flex flex-col justify-center items-center gap-1'>
              <IconButton
                color='secondary'
                size='sm'
                icon={<MdOutlinePerson size='1rem' aria-label='Botão para área de Cliente da Hubs' />}
                aria-label='Botão para acessar a Área de Cliente da Hubs'
              />
              <p className='text-xs'>Cliente</p>
            </div>
          </a>

          <AppButton className='hidden lg:block' href={navBarInfo.linkAppWeb || ''} onClick={() => fireAnalyticsEvent('linkAppWeb')} />
        </Container>
      </div>

      <div
        className={classNames(
          getMenuDynamicStyles(),
          'block lg:hidden mt-16 w-full bg-white fixed z-30 rounded-b-xl transition-all duration-300 shadow-md',
        )}
      >
        <div className='container mx-auto py-4 flex flex-col transition-all'>
          {options.map(({ label, to, partiallyActive }, index) => (
            <Link
              partiallyActive={partiallyActive}
              activeClassName='font-bold'
              key={index}
              to={to}
              onClick={() => setIsOpen(false)}
              className='text-black px-3 py-2 text-sm lg:text-sm'
            >
              {label}
            </Link>
          ))}

          <AppButton className='self-start' href={navBarInfo.linkAppWeb || ''} onClick={() => fireAnalyticsEvent('linkAppWeb')} />
        </div>
      </div>
    </nav>
  )
}

export const query = graphql`
  fragment NavBarInfo on STRAPI_GLOBAL {
    id
    linkWhatsapp
    linkAppWeb
  }
`

export default NavBar
