import React from 'react'
import Container from '../Container'
import ReviewCarousel, { ReviewCarouselProps } from '../ReviewCarousel'
import { classNames } from '../../services/string'

export interface CustomerReviewsProps extends ReviewCarouselProps {
  title?: string
  subtitle?: string
  rounded?: boolean
  reviews: Queries.ReviewInfoFragment[]
}

export const CustomerReviews = ({ title, subtitle, rounded = true, variant = 'always-horizontal', reviews }: CustomerReviewsProps) => {
  return (
    <div className={classNames(rounded && 'rounded-t-3xl', 'bg-gradient-to-r from-gradient-blue to-gradient-fuchsia text-white')}>
      <Container className='flex flex-col gap-8 py-12 xl:py-20'>
        <div className={classNames(variant !== 'always-horizontal' && 'xl:w-1/2', 'flex flex-col gap-4 text-center xl:text-left')}>
          <h3 className='font-semibold text-2xl text-primary-600'>{title || 'Qualidade comprovada'}</h3>
          <p>
            {subtitle ||
              'As melhores decisões e a qualidade do serviço prestado são comprovadas por nossos clientes, o nosso maior patrimônio. Veja alguns comentários:'}
          </p>
        </div>

        <ReviewCarousel reviews={reviews} variant={variant} />
      </Container>
    </div>
  )
}

export default CustomerReviews
