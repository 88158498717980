import React from 'react'
import { Footer, RootBackgroundImage } from '../'

export interface LandingPageLayoutProps {
  children: React.ReactNode
}

export const LandingPageLayout = ({ children }: LandingPageLayoutProps) => {
  return (
    <RootBackgroundImage>
      <div className=''>{children}</div>
      <Footer />
    </RootBackgroundImage>
  )
}

export default LandingPageLayout
