import { Link } from 'gatsby'
import React from 'react'

import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

export interface PaginationItemProps {
  renderItem: () => React.ReactNode
  active?: boolean
  disabled?: boolean
  href: string
  ariaLabel: React.AriaAttributes['aria-label']
}

export const PaginationItem = ({ renderItem, href, ariaLabel, active = false, disabled = false }: PaginationItemProps) => {
  const variant = active ? 'active' : disabled ? 'disabled' : 'default'
  const extraClasses = {
    active: 'bg-primary-600 rounded-full drop-shadow-xl',
    default: '',
    disabled: 'pointer-events-none cursor-not-allowed',
  }[variant]

  return (
    <Link
      aria-label={ariaLabel}
      aria-current={active ? 'page' : undefined}
      to={href}
      className={`flex justify-center items-center w-6 h-6 xs:w-8 xs:h-8 sm:w-10 sm:h-10 text-xs sm:text-sm text-black font-semibold box-shadow-lg transition-colors duration-150 focus:shadow-outline ${extraClasses}`}
    >
      {renderItem()}
    </Link>
  )
}

export default PaginationItem
