import React, { useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { graphql } from 'gatsby'

import { classNames } from '../services/string'

import FullCustomerReviewCard from './FullCustomerReviewCard'
import ShortCustomerReviewCard from './ShortCustomerReviewCard'
import DetailedCustomerReviewCard from './DetailedCustomerReviewCard'
import Carousel from './Carousel'

const carouselResponsiveConstraints = {
  desktop: {
    breakpoint: { min: 1025, max: 10000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

interface IconButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  onClick: () => unknown
}

const IconButton = ({ children, onClick, ...rest }: IconButtonProps) => (
  <button className='bg-white/50 p-3 flex items-center justify-center rounded-full text-black' onClick={onClick} {...rest}>
    {children}
  </button>
)

export interface ReviewCarouselProps {
  reviews: Queries.ReviewInfoFragment[]
  variant?: 'always-horizontal' | 'vertical-on-lg'
}

const ReviewCarousel = ({ reviews, variant = 'vertical-on-lg' }: ReviewCarouselProps) => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState<number>(0)

  const changeCurrentReviewIndex = (direction: 'up' | 'down') => {
    const nodesLength = reviews.length
    if (direction === 'up' && currentReviewIndex === 0) return setCurrentReviewIndex(nodesLength - 1)
    if (direction === 'down' && currentReviewIndex === nodesLength - 1) return setCurrentReviewIndex(0)
    setCurrentReviewIndex(direction === 'up' ? currentReviewIndex - 1 : currentReviewIndex + 1)
  }

  if (!reviews || !reviews?.length) return null
  return (
    <div className='w-full'>
      <div className={variant === 'always-horizontal' ? 'block' : 'xl:hidden'}>
        <Carousel
          showDots
          autoPlay
          infinite
          arrows={false}
          autoPlaySpeed={8000}
          renderDotsOutside
          responsive={carouselResponsiveConstraints}
          dotListClass='static my-4'
        >
          {reviews.map((item) => (
            <FullCustomerReviewCard key={item?.id} className='mx-2' review={item} />
          ))}
        </Carousel>
      </div>

      <div className={classNames(variant === 'vertical-on-lg' ? 'hidden xl:flex gap-8' : 'hidden')}>
        <div className='flex flex-col gap-6 w-1/2'>
          {reviews.map((item, index) => (
            <ShortCustomerReviewCard
              key={item?.id}
              review={item}
              disabled={currentReviewIndex !== index}
              onClick={() => setCurrentReviewIndex(index)}
            />
          ))}
        </div>

        <div className='flex flex-col gap-8 items-center justify-center'>
          <IconButton onClick={() => changeCurrentReviewIndex('up')} aria-label='Subir'>
            <FaChevronUp />
          </IconButton>
          <IconButton onClick={() => changeCurrentReviewIndex('down')} aria-label='Descer'>
            <FaChevronDown />
          </IconButton>
        </div>

        <DetailedCustomerReviewCard className='w-1/2' review={reviews[currentReviewIndex]} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ReviewInfo on STRAPI_REVIEW {
    id
    author
    content
    position
    title
    avatar {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 165, height: 165, transformOptions: { cropFocus: CENTER })
        }
      }
    }
  }
`

export default ReviewCarousel
