import React from 'react'
import { classNames } from '../services/string'
import type { Property } from 'csstype'
import { findContrastColor } from '../services/color'

export type TextFieldVariant = 'underline' | 'filled'

export interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  variant?: TextFieldVariant
  error?: boolean
  helpText?: string
  contrastReferenceColor?: Property.Color
}

const TextField = ({ label, id, variant = 'underline', error, helpText, contrastReferenceColor = '#FFFFFF', ...rest }: ITextFieldProps) => {
  const contrastColor = findContrastColor(contrastReferenceColor)

  const placeholderColorStyle =
    variant === 'filled' ? 'placeholder:text-black' : contrastColor === '#FFFFFF' ? 'placeholder:text-white' : 'placeholder:text-black'
  const inputTextColorStyle = variant === 'filled' ? 'text-black' : contrastColor === '#FFFFFF' ? 'text-white' : 'text-black'
  const labelColorStyle = contrastColor === '#FFFFFF' ? 'text-white' : 'text-black'

  const variantStyles = {
    underline: classNames(
      error && 'border-red-500 text-red-500 placeholder:text-red-500',
      'bg-transparent border-b border-t-transparent border-x-transparent py-2 px-0',
    ),
    filled: classNames(error && 'border border-red-500 text-red-500 placeholder:text-red-500', 'rounded-xl border-none'),
  }[variant]

  return (
    <div className='flex flex-col gap-1'>
      {label && (
        <label className={`text-sm ${labelColorStyle}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        className={classNames(
          variantStyles,
          placeholderColorStyle,
          inputTextColorStyle,
          'appearance-none w-full leading-tight focus:outline-none placeholder:font-thin focus:ring-0',
        )}
        style={{ borderColor: contrastColor }}
        {...rest}
      />
      {error && helpText && <span className='text-red-500 text-xs'>{helpText}</span>}
    </div>
  )
}

export default TextField
