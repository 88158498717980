import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useAnalytics } from '../hooks'

interface AppStoreBadgesProps {
  className?: string
}

const AppStoreBadges = ({ className }: AppStoreBadgesProps) => {
  const { fireEvent } = useAnalytics()
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...AppStoreBadgesInfo
      }
    }
  `)

  const appStoreBadgesInfo = strapiGlobal as Queries.AppStoreBadgesInfoFragment

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = (target: string) => {
    fireEvent('adsConversion', { component: 'AppStoreBadges', target })

    fireEvent('externalClick', {
      component: 'AppStoreBadges',
      target,
    })
  }

  return (
    <div className={className}>
      <a
        href={appStoreBadgesInfo.linkAppPlayStore || '#'}
        rel='noreferrer noopener'
        target='_BLANK'
        className='w-32'
        onClick={() => fireAnalyticsEvent('linkAppPlayStore')}
      >
        <StaticImage
          width={128}
          src='../images/resources/google-play-badge.png'
          alt='Disponível no Google Play'
          placeholder='blurred'
          quality={100}
          objectFit='contain'
        />
      </a>
      <a
        href={appStoreBadgesInfo.linkAppStore || '#'}
        rel='noreferrer noopener'
        target='_BLANK'
        className='w-32'
        onClick={() => fireAnalyticsEvent('linkAppStore')}
      >
        <StaticImage
          width={128}
          src='../images/resources/app-store-badge.png'
          alt='Baixar na App Store'
          placeholder='blurred'
          quality={100}
          objectFit='contain'
        />
      </a>
    </div>
  )
}

export const query = graphql`
  fragment AppStoreBadgesInfo on STRAPI_GLOBAL {
    id
    linkAppStore
    linkAppPlayStore
  }
`

export default AppStoreBadges
