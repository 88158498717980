import React from 'react'
import ContactForm from '../ContactForm'
import { graphql, useStaticQuery } from 'gatsby'

interface ContactProps {
  marketingCampaignCode?: string
}

const Contact = ({ marketingCampaignCode }: ContactProps) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...ContactFormInfo
      }
    }
  `)

  const { contactForm } = strapiGlobal as Queries.ContactFormInfoFragment

  const mappedVariants: Record<string, 'traditional' | 'alternative'> = {
    Tradicional: 'traditional',
    Alternativo: 'alternative',
  }

  if (!contactForm) return null
  return (
    <ContactForm
      variant={mappedVariants[contactForm.variant || ''] || undefined}
      title={contactForm.title || ''}
      description={contactForm.description || ''}
      marketingCampaignCode={marketingCampaignCode}
    />
  )
}

export const query = graphql`
  fragment ContactFormInfo on STRAPI_GLOBAL {
    id
    contactForm {
      id
      title
      description
      variant
    }
  }
`

export default Contact
