import React from 'react'
import Carousel from '../Carousel'
import Container from '../Container'
import { useStaticQuery, graphql } from 'gatsby'
import ClubeHubsOfferCard from '../ClubeHubsOfferCard'

const carouselResponsiveConstraints = {
  desktop: {
    breakpoint: { min: 1025, max: 10000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 769 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
}

const Benefits = () => {
  const strapiClubeHubs = useStaticQuery(graphql`
    query Benefits {
      clubeHubs: allStrapiComponentClubeClubeHubsOffer(filter: { featured: { eq: true } }) {
        nodes {
          offer {
            ...ClubeHubsOfferCard
          }
        }
      }
    }
  `)

  const { clubeHubs } = strapiClubeHubs as Queries.BenefitsQuery

  return (
    <Container className='flex flex-col justify-center items-center gap-4 py-12 text-center'>
      <h3 className='font-semibold text-2xl'>Tenha acesso ao Clube Hubs</h3>
      <p>Exclusivo para clientes, tenha acesso a inúmeras ofertas e promoções dos mais variados parceiros.</p>

      <div className='w-full'>
        <Carousel
          showDots
          autoPlay
          infinite
          arrows={false}
          autoPlaySpeed={5000}
          renderDotsOutside
          responsive={carouselResponsiveConstraints}
          dotListClass='static my-4'
        >
          {clubeHubs.nodes.map((item) => (
            <ClubeHubsOfferCard key={item?.offer?.id} className='mx-2' offer={item?.offer!} hideCallToAction />
          ))}
        </Carousel>
      </div>
    </Container>
  )
}

export default Benefits
