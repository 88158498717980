import React from 'react'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'

import AvailableFormsOfService from '../AvailableFormsOfService'
import Container from '../Container'

interface FormsOfServiceProps {
  image?: IGatsbyImageData
  whatsAppCustomMessageSlug?: string
}

const FormsOfService = ({ image, whatsAppCustomMessageSlug }: FormsOfServiceProps) => {
  return (
    <Container className='flex flex-col md:flex-row md:items-center gap-4 md:gap-8 py-12 xl:py-20 text-center md:text-left'>
      <div className='flex flex-col md:w-1/2'>
        <h3 className='lg:hidden font-semibold text-2xl'>Aqui na Hubs você escolhe como quer ser atendido</h3>

        <h3 className='hidden lg:block'>
          <span className='font-semibold text-2xl'>Aqui na Hubs você escolhe</span>
          <br />
          <span className='lg:text-lg'>como quer ser atendido</span>
        </h3>

        <p className='mt-8 lg:mt-4 mb-4'>Nossa equipe de atendimento ao cliente está disponível para tirar todas as suas dúvidas.</p>

        <AvailableFormsOfService className='w-full lg:w-2/3 mt-6' whatsAppCustomMessageSlug={whatsAppCustomMessageSlug} />
      </div>

      <div className='hidden md:block'>
        {image && (
          <GatsbyImage
            image={image}
            alt='Imagem de um profissional ao lado de um smartphone exibindo o atendimento ao cliente no WhatsApp'
          />
        )}
        {!image && (
          <StaticImage
            width={400}
            src='../../images/resources/whatsapp-1.png'
            alt='Imagem de uma mulher segurando um smartphone ao lado de um smartphone exibindo o atendimento ao cliente no WhatsApp'
            className='max-w-[400px]'
            placeholder='blurred'
            quality={100}
          />
        )}
      </div>
    </Container>
  )
}

export default FormsOfService
