import React, { useState } from 'react'
import { useLocation } from '@reach/router'

import { useRequestContactSale } from '../../hooks'
import { RequestContactSaleMutationVariables } from '../../services/graphql/sdk'
import { ContactFormProps, ContactFormVariables, ContactFormVariant } from './types'

import Alert from '../Alert'
import AlternativeVariant from './AlternativeVariant'
import FeedbackDialog from './FeedbackDialog'
import LandingPageVariant from './LandingPageVariant'
import TraditionalVariant from './TraditionalVariant'
import PartnerVariant from './PartnerVariant'

const ContactForm = ({
  className,
  variant = 'traditional',
  marketingCampaignCode = 'WEBSITE',
  onCompleted,
  onError,
  ...rest
}: ContactFormProps) => {
  const [key, setKey] = useState<number>(0)
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isErrorMessageVisible, setIsErrorMessageVisible] = useState<boolean>(false)
  const location = useLocation()

  const { requestContactSale, loading } = useRequestContactSale()

  const handleSubmit = async ({ name, email, phone, cnpj }: ContactFormVariables) => {
    const variables: RequestContactSaleMutationVariables = {
      cnpj,
      contact: {
        name,
        email,
        phone,
        origin: location.pathname,
      },
      marketingCampaignCode,
    }

    try {
      const response = await requestContactSale(variables)
      if (onCompleted) return onCompleted(response.requestContactSale)
      return setIsDialogOpen(true)
    } catch (error) {
      if (onError) onError(error)
      return setIsErrorMessageVisible(true)
    } finally {
      setKey(key + 1)
    }
  }

  const commonProps = {
    className,
    loading,
    marketingCampaignCode,
    ...rest,
  }

  const mappedComponentsToRender: Record<ContactFormVariant, React.ReactNode> = {
    traditional: <TraditionalVariant key={key} {...commonProps} onSubmit={handleSubmit} />,
    alternative: <AlternativeVariant key={key} {...commonProps} onSubmit={handleSubmit} />,
    'landing-page': <LandingPageVariant key={key} {...commonProps} onSubmit={handleSubmit} />,
    partner: <PartnerVariant key={key} {...commonProps} onSubmit={handleSubmit} />,
  }
  const componentToRender = mappedComponentsToRender[variant]

  return (
    <>
      {componentToRender}

      <Alert
        open={isErrorMessageVisible}
        variant='error'
        onClose={() => setIsErrorMessageVisible(false)}
        title='Houve um problema ao tentar submeter o formulário. Revise as informações e tente novamente.'
      />

      <FeedbackDialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </>
  )
}

export default ContactForm
