import React from 'react'
import Container from '../Container'
import { AppStoreBadges } from '../'
import { StaticImage } from 'gatsby-plugin-image'

const ShortDescription = () => {
  return (
    <Container className='py-16 md:pt-32 flex flex-col lg:flex-row-reverse lg:gap-4 lg:items-center'>
      <div className='flex flex-col gap-8 text-center lg:text-left lg:w-1/2'>
        <h2>
          <span className='font-semibold text-2xl lg:text-2xl'>Um escritório de contabilidade</span>
          <br />
          <span className='lg:text-lg'>na palma da sua mão.</span>
        </h2>

        <p>
          Tenha o controle completo da sua empresa onde e quando você quiser. Além disso, você pode contar com nosso atendimento
          personalizado para auxiliá-lo e esclarecer suas dúvidas.
        </p>

        <AppStoreBadges className='hidden lg:flex w-full gap-4' />
      </div>

      <div className='lg:w-1/2'>
        <div className='flex justify-center items-center my-12'>
          <StaticImage
            width={400}
            src='../../images/resources/desktop-mobile.png'
            alt='Imagem de um computador ao lado de um telefone, ambos exibindo a tela inicial do Sistema Hubs'
            className='w-full'
            placeholder='blurred'
            objectFit='contain'
            quality={100}
          />
        </div>

        <AppStoreBadges className='flex lg:hidden w-full justify-around sm:justify-center sm:gap-4' />
      </div>
    </Container>
  )
}

export default ShortDescription
