import React from 'react'
import { classNames } from '../services/string'
import { MdStar } from 'react-icons/md'
import { FaQuoteLeft } from 'react-icons/fa'

export interface IDetailedCustomerReviewCardProps {
  className?: string
  review: Queries.ReviewInfoFragment
}

const DetailedCustomerReviewCard = ({ className, review }: IDetailedCustomerReviewCardProps) => {
  return (
    <div
      className={classNames(
        className,
        'rounded-2xl flex flex-col p-8 h-auto gap-4 bg-white/50 shadow-[1px_1px_1px_0px_rgba(255,255,255,1)_inset] text-black',
      )}
    >
      <p className='font-medium text-xl'>"{review.title}"</p>

      <div className='flex gap-1 text-primary-600'>
        {[...Array(5)].map((_v, i) => (
          <MdStar key={i} size={35} />
        ))}
      </div>

      <FaQuoteLeft size={42} className='my-4' />

      <p className='text-xl'>"{review.content}"</p>
    </div>
  )
}

export default DetailedCustomerReviewCard
