import React from 'react'
import Container from '../Container'
import { graphql, useStaticQuery } from 'gatsby'
import { ArticleCard } from '../blog'

interface FeaturedArticlesProps {
  title?: string
  subtitle?: string
  articles?: Queries.ArticleCardFragment[]
}

const FeaturedArticles = ({ title, subtitle, articles }: FeaturedArticlesProps) => {
  // Busca os artigos através de uma query estática se nenhum artigo top-level for fornecido como parâmetro
  const fetchedArticles = !articles
    ? useStaticQuery(graphql`
        query {
          allStrapiArticle(limit: 3) {
            nodes {
              ...ArticleCard
            }
          }
        }
      `)
    : undefined

  const articlesToShow = articles ? articles : (fetchedArticles.allStrapiArticle.nodes as Queries.ArticleCardFragment[])

  return (
    <Container className='pt-12 pb-0 flex flex-col gap-8'>
      <div className='flex flex-col gap-4 text-center xl:text-left xl:w-2/3'>
        <h3 className='font-semibold text-2xl text-center md:text-left'>{title || 'Artigos destacados'}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>

      <div className='flex flex-col md:flex-row gap-4 lg:gap-8'>
        {Boolean(!articlesToShow.length) && (
          <h4 className='font-normal text-xl text-center my-4'>Não encontramos nenhum artigo em destaque.</h4>
        )}
        {Boolean(articlesToShow.length) && (
          <div className='flex space-x-8 overflow-x-auto lg:overflow-x-hidden'>
            {articlesToShow.map((article) => (
              <ArticleCard key={article.id} className='flex-shrink-0 max-w-sm lg:flex-shrink mb-0' article={article} />
            ))}
          </div>
        )}
      </div>
    </Container>
  )
}

export default FeaturedArticles
