import React from 'react'

import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import PaginationItem from './PaginationItem'

export interface PaginationProps {
  numPages: number
  currentPage: number
  link: (page: number) => string
}

export const Pagination = ({ numPages, currentPage, link }: PaginationProps) => {
  const navigationProperties = {
    enabled: {
      iconColor: 'var(--color-secondary-800)',
      linkClasses: 'pointer-events-auto cursor-pointer',
    },
    disabled: {
      iconColor: 'var(--color-secondary-400)',
      linkClasses: 'pointer-events-none cursor-not-allowed',
    },
  }

  const generateProperties = (type: 'previous' | 'next') => {
    const enabled = type === 'previous' ? currentPage > 1 : currentPage < numPages
    const href = enabled ? (type === 'previous' ? link(currentPage - 1) : link(currentPage + 1)) : '#'
    const baseKeys = enabled ? navigationProperties.enabled : navigationProperties.disabled

    return {
      ...baseKeys,
      enabled,
      href,
    }
  }

  const previousProperties = generateProperties('previous')
  const nextProperties = generateProperties('next')

  const getCirclePagesTarget = () => {
    if (currentPage === 1) {
      return Array.from({ length: Math.min(3, numPages) }).map((_, i) => i + 1)
    }

    if (currentPage === numPages) {
      return Array.from({ length: Math.min(3, numPages) })
        .map((_, i) => numPages - i)
        .reverse()
    }

    return [currentPage - 1, currentPage, currentPage + 1]
  }
  const circlePages = getCirclePagesTarget().filter((i) => i >= 1 && i <= numPages)

  return (
    <nav role='navigation' aria-label='Pagination Navigation' className='flex w-full justify-center items-center pb-20'>
      <ul className='inline-flex space-x-0.5 sm:space-x-2'>
        <li>
          <PaginationItem
            ariaLabel='Voltar para página anterior'
            href={previousProperties.href}
            disabled={!previousProperties.enabled}
            renderItem={() => (
              <MdChevronLeft aria-label='ícone de voltar' color={previousProperties.iconColor} className='text-[18px] md:text-[28px]' />
            )}
          />
        </li>
        {currentPage > 2 && (
          <>
            <PaginationItem ariaLabel='Ir para a primeira página' renderItem={() => 1} href={link(1)} />
            <li className='text-black flex items-center justify-center w-6 h-6 xs:w-8 xs:h-8 sm:w-10 sm:h-10 text-xs sm:text-sm'>...</li>
          </>
        )}
        {circlePages.map((page) => (
          <li key={`pagina-${page}`}>
            <PaginationItem
              ariaLabel={`Ir para a página ${page}`}
              active={page === currentPage}
              renderItem={() => page}
              href={link(page)}
            />
          </li>
        ))}
        {numPages - currentPage > 1 && (
          <>
            <li className='text-black flex items-center justify-center w-6 h-6 xs:w-8 xs:h-8 sm:w-10 sm:h-10 text-xs sm:text-sm'>...</li>
            <PaginationItem ariaLabel='Ir para a última página' renderItem={() => numPages} href={link(numPages)} />
          </>
        )}
        <li>
          <PaginationItem
            ariaLabel='Avançar para a próxima página'
            href={nextProperties.href}
            disabled={!nextProperties.enabled}
            renderItem={() => (
              <MdChevronRight aria-label='ícone de avançar' color={nextProperties.iconColor} className='text-[18px] md:text-[28px]' />
            )}
          />
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
