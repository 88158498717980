import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { MdBalance, MdGroups, MdPaid } from 'react-icons/md'

import Carousel from '../Carousel'
import Container from '../Container'

export interface FeaturedCardProps {
  title: string
  icon: JSX.Element
  topics: string[]
}

export const FeaturedCard = ({ title, icon, topics }: FeaturedCardProps) => (
  <div className='flex flex-col lg:flex-row gap-4 lg:gap-12 items-center bg-white lg:bg-transparent py-8 lg:py-4 px-12 lg:px-0 mx-2 lg:mx-0 rounded-2xl lg:rounded-none drop-shadow-md lg:drop-shadow-none mb-3 lg:mb-0 h-[17rem] lg:h-auto'>
    <div className='rounded-full shadow-md lg:shadow-xl bg-gradient-to-tr from-emerald-500 via-purple-600 to-primary-500'>
      <div className='flex justify-center items-center w-16 h-16 text-white'>{icon}</div>
    </div>
    <div>
      <p className='font-semibold lg:font-normal text-md lg:text-lg text-center lg:text-left mb-4'>{title}</p>
      <ul className='list-disc font-light text-sm text-gray-800 w-full'>
        {topics.map((topic, index) => (
          <li key={index} className='py-0.5'>
            {`${topic}${index === topics.length - 1 ? '.' : ';'}`}
          </li>
        ))}
      </ul>
    </div>
  </div>
)

const FeaturedServices = () => {
  const featuredCardsContent: FeaturedCardProps[] = [
    {
      title: 'Legalizações',
      icon: <MdBalance size='2.5rem' />,
      topics: ['Abertura de empresa', 'Regularizações', 'Certificados digitais'],
    },
    {
      title: 'Operações',
      icon: <MdPaid size='2.5rem' />,
      topics: ['Atividades contábeis', 'Obrigações com o fisco', 'Otimização tributária'],
    },
    {
      title: 'Pessoas',
      icon: <MdGroups size='2.5rem' />,
      topics: ['Consultoria exclusiva', 'Admissão e demissão', 'Recrutamento e seleção'],
    },
  ]

  const carouselResponsiveConstraints = {
    tablet: {
      breakpoint: { max: 1024, min: 769 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  }

  return (
    <Container className='pt-4 lg:pb-8 flex flex-col lg:flex-row relative'>
      <div className='flex flex-col text-center lg:text-left lg:w-1/2'>
        <h3>
          <span className='font-semibold text-2xl'>O que a Hubs faz</span>
          <br />
          <span className='lg:text-lg'>para ajudar sua empresa?</span>
        </h3>

        <p className='mt-8 lg:mt-4 mb-4'>Fornecemos soluções personalizadas para cada tipo de negócio.</p>
        <p className='font-light mb-4'>
          Solicite emissão de notas fiscais, acesse guias e tenha acesso aos documentos de sua empresa de forma simples e eficiente em nossa
          plataforma digital!
        </p>

        <div className='hidden lg:flex flex-col'>
          {featuredCardsContent.map((props, index) => (
            <FeaturedCard key={index} {...props} />
          ))}
        </div>
      </div>

      <div className='lg:hidden my-4'>
        <Carousel
          showDots
          autoPlay
          infinite
          arrows={false}
          autoPlaySpeed={5000}
          renderDotsOutside
          responsive={carouselResponsiveConstraints}
          dotListClass='static my-4'
        >
          {featuredCardsContent.map((props, index) => (
            <FeaturedCard key={index} {...props} />
          ))}
        </Carousel>
      </div>

      <div className='hidden lg:flex justify-center lg:w-1/2'>
        <div className='absolute bottom-0 right-0'>
          <StaticImage
            width={400}
            src='../../images/resources/holding-coffee.png'
            alt='Imagem de uma mulher surpresa e sorridente segurando um copo de café e um telefone'
            className='max-w-[400px]'
            placeholder='blurred'
            quality={100}
          />
        </div>
      </div>
    </Container>
  )
}

export default FeaturedServices
