import React from 'react'

import { graphql } from 'gatsby'
import type { Property } from 'csstype'

import { FaRegLightbulb, FaRegMoneyBill1, FaClipboardCheck, FaBusinessTime } from 'react-icons/fa6'
import { GiBrazil } from 'react-icons/gi'
import { TfiUnlink } from 'react-icons/tfi'
import { HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'
import { IconBaseProps } from 'react-icons'

import { findContrastColor } from '../services/color'
import { classNames } from '../services/string'
import { FaCreditCard } from 'react-icons/fa'

const commonIconProps: IconBaseProps = {
  size: '2rem',
  display: 'inline',
}

export interface FeaturedCardProps {
  card: Queries.FeaturedCardFragment
  bgColor: string
  borderColor: string
  horizontal?: boolean
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
}

enum AcceptedIcons {
  money = 'money',
  country = 'country',
  unlink = 'unlink',
  chat = 'chat',
  clipboard = 'clipboard',
  businessTime = 'businessTime',
  card = 'card',
}

export const IconMapping: Record<AcceptedIcons, () => React.JSX.Element> = {
  money: () => <FaRegMoneyBill1 {...commonIconProps} />,
  country: () => <GiBrazil {...commonIconProps} />,
  unlink: () => <TfiUnlink {...commonIconProps} />,
  chat: () => <HiOutlineChatBubbleLeftRight {...commonIconProps} />,
  clipboard: () => <FaClipboardCheck {...commonIconProps} />,
  businessTime: () => <FaBusinessTime {...commonIconProps} />,
  card: () => <FaCreditCard {...commonIconProps} />,
}

export const FeaturedCard = ({ card, bgColor, borderColor, horizontal = false, primaryColor, secondaryColor }: FeaturedCardProps) => {
  const icon =
    card.icon && (Object.values(AcceptedIcons) as string[]).includes(card.icon) ? (
      IconMapping[card.icon as AcceptedIcons]()
    ) : (
      <FaRegLightbulb {...commonIconProps} />
    )

  const primaryContrastColor = findContrastColor(primaryColor || 'var(--color-primary-600)')
  const secondaryContrastColor = findContrastColor(secondaryColor || 'var(--color-primary-100)')

  return (
    <article
      style={{ background: bgColor, borderColor, color: secondaryContrastColor }}
      className={classNames(
        'px-4 py-2 rounded-lg border-t-2 border-l-2 w-full',
        'shadow-lg drop-shadow-lg',
        horizontal ? 'flex flex-row' : 'h-full flex grow flex-col justify-start items-center',
      )}
    >
      <div className='my-2'>
        <div
          className='w-16 h-16 rounded-full flex justify-center items-center'
          style={{ background: borderColor, color: primaryContrastColor }}
        >
          {icon}
        </div>
      </div>
      <div className={classNames(horizontal && 'mx-4 flex flex-col gap-1 justify-center', !horizontal && 'text-center')}>
        <p className={classNames('text-lg font-normal', !horizontal && 'my-2')}>{card.title}</p>
        <p className='text-sm font-light'>{card.description}</p>
      </div>
    </article>
  )
}

export const query = graphql`
  fragment FeaturedCard on STRAPI__COMPONENT_SHARED_FEATURED_CARD {
    title
    description
    icon
    variant
  }
`

export default FeaturedCard
