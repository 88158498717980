import React, { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { graphql } from 'gatsby'
import Button from './Button'
import { useAnalytics } from '../hooks'

export interface ClubeHubsOfferRedeemDialogProps {
  onClose?: () => unknown
  redeemDetails: Queries.ClubeHubsRedeemDetailsFragment
}

export const ClubeHubsOfferRedeemDialog = ({ onClose, redeemDetails }: ClubeHubsOfferRedeemDialogProps) => {
  const { fireEvent } = useAnalytics()
  const handleClose = () => {
    if (onClose) onClose()
    return
  }

  const fireAnalyticsEvent = (event: string) => {
    const { coupon, redeemLink, couponDescription, partner } = redeemDetails
    fireEvent(event, {
      coupon,
      couponDescription,
      redeemLink,
      partner: partner?.name,
    })
  }

  // Disparo do evento de abertura do modal de detalhes da oferta
  useEffect(() => {
    fireAnalyticsEvent('clubeHubsDetailsClick')
  }, [])

  return (
    <Dialog as='div' className='relative z-[100]' onClose={handleClose}>
      <Transition.Child
        as={Fragment}
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='fixed inset-0 bg-black/40 backdrop-blur-sm' />
      </Transition.Child>

      <div className='fixed inset-0 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 sm:p-6 text-left align-middle shadow-xl transition-all'>
              <Dialog.Title as='h3' className='text-xl font-medium leading-6 text-black mb-4'>
                Resgatar oferta
              </Dialog.Title>
              <div className='mt-2'>
                <p className='text-sm text-gray-600 mb-2'>
                  Clique no botão abaixo para resgatar a sua oferta. Você será redirecionado ao sistema do parceiro e poderá resgatar o seu
                  benefício.
                </p>

                {redeemDetails.coupon && (
                  <div className='my-4 p-1 sm:p-1.5 bg-rainbow flex items-center justify-center rounded-2xl shadow-md drop-shadow-md'>
                    <div className='p-1 sm:p-6 bg-white rounded-2xl text-center'>
                      <p className='text-sm'>Utilize o cupom:</p>
                      <p className='py-2'>
                        <b className='text-black text-2xl sm:text-3xl'>{redeemDetails.coupon}</b>
                      </p>
                      {redeemDetails.couponDescription && <p className='text-sm pt-2'> {redeemDetails.couponDescription} </p>}
                    </div>
                  </div>
                )}

                <p className='text-sm text-gray-600'>Em caso de dificuldades, entre em contato nos nossos canais oficiais.</p>
              </div>

              <div className='mt-10'>
                <a
                  href={redeemDetails.redeemLink || '#'}
                  rel='noreferrer noopener'
                  target='_blank'
                  // Disparo do evento de resgate da oferta
                  onClick={() => fireAnalyticsEvent('clubeHubsRedeemClick')}
                >
                  <Button className='w-full'>Resgatar</Button>
                </a>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  )
}

export const query = graphql`
  fragment ClubeHubsRedeemDetails on STRAPI_CLUBE_HUBS_OFFER {
    id
    redeemLink
    coupon
    couponDescription
    authRequired
    partner {
      id
      name
    }
  }
`

export default ClubeHubsOfferRedeemDialog
