import React from 'react'
import { joinStrings } from '../services/string'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

type ButtonSize = 'sm' | 'md' | 'lg'
type ButtonColorSchema = 'primary' | 'secondary' | 'styled'

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  size?: ButtonSize
  colorSchema?: 'primary' | 'secondary' | 'styled'
  children: React.ReactNode
  ref?: React.MutableRefObject<any>
  loading?: boolean
}

const sizes: Record<ButtonSize, string> = {
  sm: 'px-2.5 py-1.5 text-xs',
  md: 'px-3.5 py-2 text-sm',
  lg: 'px-4 py-2 text-md ',
}

const colorSchemas: Record<ButtonColorSchema, string> = {
  primary: 'bg-primary-600 text-black hover:bg-yellow-200',
  secondary: 'border border-black bg-transparent hover:bg-black/20 text-black',
  styled: '',
}

const Button = ({ children, className = '', size = 'md', colorSchema = 'primary', ref, loading, ...rest }: IButtonProps) => {
  const ariaStyles = 'focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2'
  const styles = 'rounded-md font-medium drop-shadow-md transition-all duration-200 flex flex-row gap-2 items-center justify-center'

  return (
    <button ref={ref} {...rest} className={joinStrings([className, ariaStyles, styles, sizes[size], colorSchemas[colorSchema]])}>
      {loading && <AiOutlineLoading3Quarters className='animate-spin' />}

      {children}
    </button>
  )
}

export default Button
