export const maskZipCodeNumber = (input: string) => {
  const zipCode = input.replace(/\D/g, '')
  const firstDigits = zipCode.substring(0, 5)
  const lastDigits = zipCode.substring(5, 8)

  return `${firstDigits.length > 0 ? firstDigits : ''}${lastDigits.length > 0 ? `-${lastDigits}` : ''}`
}

export const maskPhoneNumber = (input: string) => {
  const phoneNumber = input.replace(/\D/g, '')
  const isFullNumber = phoneNumber.length >= 11
  const areaCode = phoneNumber.substring(0, 2)
  const middleDigits = phoneNumber.substring(2, isFullNumber ? 7 : 6)
  const lastDigits = phoneNumber.substring(isFullNumber ? 7 : 6, isFullNumber ? 11 : 10)

  return `${areaCode.length > 0 ? `(${areaCode})` : ''}${middleDigits.length > 0 ? ` ${middleDigits}` : ''}${
    lastDigits.length > 0 ? `-${lastDigits}` : ''
  }`
}

export const maskCpfNumber = (input: string) => {
  const cpfNumber = input.replace(/\D/g, '')
  const firstBlock = cpfNumber.substring(0, 3)
  const secondBlock = cpfNumber.substring(3, 6)
  const thirdBlock = cpfNumber.substring(6, 9)
  const fourthBlock = cpfNumber.substring(9, 11)

  return `${firstBlock || ''}${secondBlock.length > 0 ? `.${secondBlock}` : ''}${thirdBlock.length > 0 ? `.${thirdBlock}` : ''}${
    fourthBlock.length > 0 ? `-${fourthBlock}` : ''
  }`
}

export const maskCnpjNumber = (input: string) => {
  const cnpjNumber = input.replace(/\D/g, '')
  const firstBlock = cnpjNumber.substring(0, 2)
  const secondBlock = cnpjNumber.substring(2, 5)
  const thirdBlock = cnpjNumber.substring(5, 8)
  const fourthBlock = cnpjNumber.substring(8, 12)
  const fifthBlock = cnpjNumber.substring(12, 14)

  return `${firstBlock || ''}${secondBlock.length > 0 ? `.${secondBlock}` : ''}${thirdBlock.length > 0 ? `.${thirdBlock}` : ''}${
    fourthBlock.length > 0 ? `/${fourthBlock}` : ''
  }${fifthBlock.length > 0 ? `-${fifthBlock}` : ''}`
}
