import React, { Suspense, lazy } from 'react'
import { WrapPageElementBrowserArgs } from 'gatsby'

import { getWindow } from './src/services/window'

import '@fontsource-variable/montserrat'
import './src/styles/global.css'

// const CookieConsent = lazy(() => import('./src/components/CookieConsent'))
import CookieConsent from './src/components/CookieConsent'

const initGTM = (callTry: number = 0) => {
  const w = getWindow() as any

  if (!w || w.isGTMLoaded) return
  if (callTry >= 10) return console.error('Max tries reached for loading GTM')

  if (!w.dataLayer) {
    console.info('Datalayer not ready...')
    return setTimeout(() => initGTM(callTry + 1), 1000)
  }

  w.isGTMLoaded = true
}

export const onClientEntry = () => {
  const w = getWindow()
  if (!w) return

  w.document.onreadystatechange = () => {
    if (w.document.readyState === 'complete') {
      setTimeout(initGTM, 3500)
    }
  }
}

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return (
    <main id='root'>
      {element}
      {/* <Suspense fallback={<></>}> */}
      <CookieConsent />
      {/* </Suspense> */}
    </main>
  )
}
