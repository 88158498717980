import React from 'react'
import { DotProps, CarouselProps as BaseCarouselProps } from 'react-multi-carousel/lib/types'
import MultiCarousel from 'react-multi-carousel'
import { classNames } from '../services/string'
import 'react-multi-carousel/lib/styles.css'

/** Constante que representa a largura máxima de tamanho da tela para breakpoints cuja largura máxima não esteja claramente definida. */
export const BREAKPOINT_MAX_WIDTH = 1_000_000

interface CustomDotsProps extends DotProps {
  labelled?: boolean
}

const CustomDots = ({ active, onClick, index, labelled }: CustomDotsProps) => {
  const dotClasses = labelled ? 'text-xs font-medium flex justify-center items-center h-8 w-8' : 'w-3 h-3'
  return (
    <li
      className={classNames(dotClasses, 'mx-2 rounded-full bg-primary-700 shadow-lg cursor-pointer', active && 'ring-2 ring-primary-700')}
      onClick={() => onClick?.()}
    >
      {labelled && typeof index === 'number' ? index + 1 : ''}
    </li>
  )
}

interface CarouselProps extends BaseCarouselProps {
  labelledDots?: boolean
}

const Carousel = ({ children, labelledDots = false, ...rest }: CarouselProps) => {
  return (
    <MultiCarousel {...rest} customDot={<CustomDots labelled={labelledDots} />}>
      {children}
    </MultiCarousel>
  )
}

export default Carousel
