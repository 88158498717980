export const isBrowser = (): boolean => typeof window !== 'undefined'

export const getWindow = (): Window | null => {
  if (!isBrowser()) return null
  return window
}

export const getDocument = (): Document | null => {
  if (!isBrowser()) return null
  return document
}
