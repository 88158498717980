const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
})

export const formatCentsToReais = (value: number): string => {
  return currencyFormatter.format(value / 100)
}

/**
 * Converts a currency string formatted as brazilian currency (such as R$ 1.000,00) into its corresponding value in cents.
 * @param currency the value in brazilian currency string format.
 */
export const convertCurrencyToCents = (currency: string): number | undefined => {
  const splittedCurrency = currency.split(' ')
  if (splittedCurrency.length <= 1) return

  const [amount, cents] = (currency.split(' ')[1] || '').replace('.', '').split(',')
  if (!amount || !cents) return
  return Number(amount) * 100 + Number(cents)
}
