import { useState } from 'react'
import { sdk } from '../services/graphql'
import { RequestContactSaleMutationVariables } from '../services/graphql/sdk'

const useRequestContactSale = () => {
  const [loading, setLoading] = useState<boolean>(false)

  const requestContactSale = async (variables: RequestContactSaleMutationVariables) => {
    setLoading(true)
    try {
      const result = await sdk.requestContactSale(variables)
      return result
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return { requestContactSale, loading }
}

export default useRequestContactSale
