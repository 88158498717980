import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Container from '../Container'
import { graphql, useStaticQuery } from 'gatsby'

interface MetricBoxProps {
  className?: string
  title: string
  subtitle: string
}

const MetricBox = ({ className, title, subtitle }: MetricBoxProps) => (
  <div className={className}>
    <div className='flex flex-col gap-1 justify-center items-center'>
      <p className='font-bold text-primary-600 text-2xl'>
        <span className='text-black text-2xl'>+ </span>
        {title}
      </p>
      <p className='text-xs font-medium'>{subtitle}</p>
    </div>
  </div>
)

const CustomerBase = () => {
  const strapiCustomerBaseInfo = useStaticQuery(graphql`
    query CustomerBaseInfo {
      totalPartners: allStrapiPartner {
        totalCount
      }
      totalOffers: allStrapiClubeHubsOffer {
        totalCount
      }
    }
  `)

  const { totalOffers, totalPartners } = strapiCustomerBaseInfo as Queries.CustomerBaseInfoQuery

  return (
    <Container className='flex flex-col gap-12 md:flex-row py-12 xl:py-20'>
      <div className='w-full md:w-1/2 flex flex-col justify-around text-center xl:text-left gap-8 md:gap-0'>
        <div className='flex flex-col gap-4'>
          <h3 className='font-semibold text-2xl'>Nossa base de clientes</h3>
          <p>Nossas parcerias agregam valor aos nossos clientes, e nós proporcionamos uma grande base de usuários aos parceiros.</p>
        </div>
        <div className='bg-soft-rainbow rounded-lg grid grid-cols-2 md:grid-cols-3 md:gap-0 px-4 py-3 justify-items-stretch'>
          <MetricBox className='col-span-2 md:col-span-1' title='R$ 1 bi' subtitle='GMV' />
          <MetricBox className='col-span-1' title={String(totalPartners.totalCount) || '0'} subtitle='Parceiros' />
          <MetricBox className='col-span-1' title={String(totalOffers.totalCount) || '0'} subtitle='Benefícios' />
        </div>
      </div>

      <div className='w-full md:w-1/2 flex justify-center lg:justify-end'>
        <StaticImage
          src='../../images/resources/map.svg'
          alt='Imagem ilustrativa de um mapa do Brasil'
          className='max-w-[440px]'
          height={440}
          placeholder='blurred'
        />
      </div>
    </Container>
  )
}

export default CustomerBase
