import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import AppStoreBadges from '../AppStoreBadges'
import Container from '../Container'
import Button from '../Button'
import { useAnalytics } from '../../hooks'

export interface TalkToSpecProps {
  variant?: 'default' | 'alternative'
}

const TalkToSpec = ({ variant = 'default' }: TalkToSpecProps) => {
  const { fireEvent } = useAnalytics()
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...TalkToSpecInfo
      }
    }
  `)

  const { linkWhatsapp } = strapiGlobal as Queries.TalkToSpecInfoFragment

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = () => {
    fireEvent('externalClick', {
      component: 'TalkToSpec',
      target: 'linkWhatsapp',
    })
  }

  if (variant === 'alternative') {
    return (
      <div className='bg-gradient-to-r from-gradient-blue to-gradient-fuchsia text-white rounded-b-3xl'>
        <Container className='flex flex-col py-12 xl:py-20 text-white justify-center items-center text-center'>
          <h3 className='text-primary-600 font-semibold text-2xl'>Fique tranquilo!</h3>
          <p>Deixe a sua contabilidade com a Hubs.</p>

          <a
            href={linkWhatsapp || ''}
            rel='noreferrer noopener'
            target='_BLANK'
            className='w-full sm:w-fit mt-5'
            onClick={() => fireAnalyticsEvent()}
          >
            <Button className='mt-2 w-full sm:w-fit'>Fale com um especialista</Button>
          </a>
        </Container>
      </div>
    )
  }

  return (
    <div className='bg-gradient-to-r from-gradient-blue to-gradient-fuchsia text-white rounded-b-3xl'>
      <Container className='flex flex-col lg:flex-row gap-8 xl:gap-0 py-12 xl:py-20 text-white'>
        <div className='hidden lg:flex justify-center items-center w-full lg:w-1/2'>
          <StaticImage
            width={500}
            src='../../images/resources/listening-to-music.png'
            alt='Imagem de um homem sentado de pernas cruzadas e olhos fechados ouvindo música em um fone de ouvido'
            className='w-[450px] xl:w-[500px]'
            placeholder='blurred'
            quality={100}
          />
        </div>

        <div className='flex flex-col gap-8 md:gap-16 w-full lg:w-1/2 justify-center'>
          <div className='flex flex-col gap-4 text-center md:text-left'>
            <h3 className='text-primary-600 font-semibold text-2xl'>Fique tranquilo!</h3>
            <p>Deixe a sua contabilidade com a Hubs.</p>

            <p>
              Você pode ter o controle completo da sua empresa, em qualquer momento e lugar que desejar, enquanto desfruta da tranquilidade
              de saber que nosso atendimento personalizado está à sua disposição para ajudá-lo e esclarecer todas as suas dúvidas.
            </p>

            <a
              href={linkWhatsapp || ''}
              rel='noreferrer noopener'
              target='_BLANK'
              className='w-full sm:w-fit'
              onClick={() => fireAnalyticsEvent()}
            >
              <Button className='mt-2 w-full sm:w-fit'>Fale com um especialista</Button>
            </a>
          </div>
          <div className='flex flex-col gap-4 text-center md:text-left'>
            <h3 className='font-semibold text-2xl'>
              <span className='font-semibold text-2xl text-primary-600'>Com nosso aplicativo inteligente</span> você tem tudo que precisa!
            </h3>

            <p>
              Com nosso aplicativo você pode ter tudo em um único lugar para consultar e controlar sua empresa sempre que precisar, na hora
              que você quiser.
            </p>

            <AppStoreBadges className='flex justify-around md:justify-start md:gap-4 mt-4' />
          </div>
        </div>
      </Container>
    </div>
  )
}

export const query = graphql`
  fragment TalkToSpecInfo on STRAPI_GLOBAL {
    id
    linkWhatsapp
  }
`

export default TalkToSpec
