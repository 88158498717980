import React from 'react'
import { MdCheckCircleOutline } from 'react-icons/md'
import { classNames } from '../../services/string'

interface PlanBenefitsProps {
  className?: string
  style?: React.CSSProperties
  title: string
  benefits: string[]
  wrapped?: boolean
}

const PlanBenefits = ({ className, style, wrapped, title, benefits }: PlanBenefitsProps) => {
  return (
    <div style={{ ...style }} className={classNames(className, wrapped ? 'text-black' : 'text-white')}>
      <div className='flex flex-col gap-2'>
        <p className='text-lg text-inherit'>{title}</p>

        <div className={wrapped ? 'bg-white bg-opacity-80 px-4 py-2 rounded-lg shadow-lg drop-shadow-lg' : ''}>
          {benefits.map((benefit, index) => (
            <div key={index} className='flex items-center gap-2 my-1'>
              <MdCheckCircleOutline className='text-green-500' />
              <p className='text-sm leading-6'>{`${benefit}${index === benefits.length - 1 ? '.' : ';'}`}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PlanBenefits
