import React, { useMemo } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import useHasMounted from '../hooks/useHasMounted'

export interface RootBackgroundImageProps {
  children: React.ReactNode
}

export const RootBackgroundImage = ({ children }: RootBackgroundImageProps) => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "home-bg.png" }) {
        childImageSharp {
          fixed(width: 900, quality: 95) {
            src
            srcWebp
          }
        }
      }
    }
  `)

  const hasMounted = useHasMounted()

  const style: React.CSSProperties = useMemo(() => {
    if (hasMounted) {
      const backgroundImage = `image-set(
        "${image.childImageSharp.fixed.srcWebp}" type("image/web"),
        "${image.childImageSharp.fixed.src}" type("image/png")
      )`

      return {
        backgroundImage,
        backgroundRepeat: 'repeat-y',
        backgroundSize: '100% auto',
      }
    }

    return {}
  }, [hasMounted])

  return (
    <article className='overflow-hidden relative' style={style}>
      {children}
    </article>
  )
}

export default RootBackgroundImage
