import React from 'react'
import { classNames } from '../services/string'

export interface HowItWorksCardContent {
  title: string
  description: string
}

export interface HowItWorksCardProps {
  className?: string
  label: string
  content: HowItWorksCardContent
}

const HowItWorksCard = ({ className, label, content }: HowItWorksCardProps) => {
  return (
    <div
      className={classNames(
        className,
        'rounded-2xl flex flex-col py-4 px-10 h-[16rem] md:h-auto gap-2 bg-white/50 shadow-[1px_1px_1px_0px_rgba(255,255,255,1)_inset] text-black',
      )}
    >
      <p className='text-xs uppercase text-secondary-600'>{label}</p>
      <p className='text-sm font-medium'>{content.title}</p>
      <p className='text-sm font-light mt-2'>{content.description}</p>
    </div>
  )
}

export default HowItWorksCard
