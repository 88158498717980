import React from 'react'
import { GatsbyImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import { MdMoney, MdSmartphone, MdSupportAgent, MdVerified } from 'react-icons/md'

import { useAnalytics } from '../../hooks'
import Container from '../Container'
import Button from '../Button'
import { classNames } from '../../services/string'
import AppStoreBadges from '../AppStoreBadges'

const featuredTopics = [
  [
    {
      icon: <MdMoney size={22} />,
      label: 'Planos que cabem no seu bolso',
    },
    {
      icon: <MdVerified size={22} />,
      label: 'Sua empresa 100% regularizada',
    },
  ],
  [
    {
      icon: <MdSmartphone size={22} />,
      label: 'Controle sua empresa pelo aplicativo',
    },
    {
      icon: <MdSupportAgent size={22} />,
      label: 'Atendimento personalizado',
    },
  ],
]
export interface HeroProps {
  image?: IGatsbyImageData
  title?: string | null
  subtitle?: string | null
  description?: string | null
  ctaLabel?: string | null
  hideFeatures?: boolean
  showAppStoreBadges?: boolean
  gradient?: 'default' | 'alternative'
}

const Hero = ({
  image,
  title,
  subtitle,
  description,
  ctaLabel,
  hideFeatures = false,
  showAppStoreBadges = false,
  gradient = 'default',
}: HeroProps) => {
  const { fireEvent } = useAnalytics()

  // Disparo do evento de clique em CTA
  const fireAnalyticsEvent = () => {
    fireEvent('ctaClick', {
      component: 'Hero',
      target: '#planos',
    })
  }

  const buttonLabel = ctaLabel || 'Conheça nossos Planos'

  const gradientStyles = {
    default: 'bg-gradient-to-tr from-gradient-purple from-50% md:from-45% to-primary-700',
    alternative: 'bg-gradient-to-tr from-gradient-blue to-gradient-fuchsia',
  }

  return (
    <section className={classNames('w-full rounded-b-3xl', gradientStyles[gradient])}>
      <Container className='flex flex-col sm:flex-row-reverse md:flex-row py-8 sm:py-12 md:py-16 text-white relative'>
        <div className='flex justify-center sm:absolute sm:bottom-0 sm:right-0'>
          {image && (
            <GatsbyImage
              image={image}
              alt='Imagem de uma pessoa sorridente'
              className='max-w-[400px] md:max-w-[600px] lg:max-w-[650px] xl:max-w-[750px]'
            />
          )}
          {!image && (
            <StaticImage
              breakpoints={[750, 650, 600, 400]}
              width={750}
              src='../../images/resources/hero.png'
              alt='Imagem de um homem sentado em uma almofada enquanto navega em seu celular'
              className='max-w-[400px] md:max-w-[600px] lg:max-w-[650px] xl:max-w-[750px] sm:top-20 md:top-32'
              placeholder='blurred'
              loading='eager'
              quality={80}
            />
          )}
        </div>

        <div
          className={classNames(
            'flex flex-col gap-4 sm:gap-6 text-center sm:text-left md:w-3/4 lg:w-1/2',
            hideFeatures ? 'sm:min-h-[350px] md:min-h-[450px] lg:min-h-[400px]' : '',
          )}
        >
          <h1>
            <span className='text-primary-600 font-semibold text-2xl lg:text-4xl'>{title || 'Contabilidade completa'}</span>
            <br />
            <span className='text-lg lg:text-xl'>{subtitle || 'para pequenas e médias empresas'}</span>
          </h1>

          <h2 className='text-md font-light lg:text-lg'>
            {description || 'Tenha mais tempo para você e para seu negócio, enquanto a Hubs cuida da sua empresa.'}
          </h2>

          {showAppStoreBadges && <AppStoreBadges className='flex justify-around sm:justify-start sm:gap-4 mt-4' />}

          <div className='flex sm:hidden flex-col gap-6'>
            <a href='#planos' onClick={() => fireAnalyticsEvent()}>
              <Button className='font-light w-full'>{buttonLabel}</Button>
            </a>

            {!hideFeatures && (
              <div className='flex flex-wrap'>
                {featuredTopics.map((item, i) => (
                  <div key={i} className='w-1/2'>
                    {item.map(({ icon, label }, j) => (
                      <div
                        key={j}
                        className={`flex flex-col justify-center items-center gap-4 h-28 border-r-2 rounded-r-xl p-2 border-primary-700 bg-white/25 rounded-xl ${
                          i % 2 === 0 ? 'mr-3' : 'ml-3'
                        } ${j % 2 === 0 ? 'mb-5' : 'mt-5'}`}
                      >
                        {icon}
                        <p className='font-light text-sm'>{label}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className='hidden sm:flex flex-col gap-8'>
            {!hideFeatures && (
              <div className='flex flex-wrap gap-4'>
                {featuredTopics.map((item, i) => (
                  <div key={i} className='flex flex-col w-full gap-4'>
                    {item.map(({ icon, label }, j) => (
                      <div key={j} className='flex flex-row items-center md:gap-2 lg:gap-4'>
                        {icon}
                        <p className='font-light text-sm'>{label}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            <a href='#planos' onClick={() => fireAnalyticsEvent()}>
              <Button className='font-light self-start'>{buttonLabel}</Button>
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Hero
