import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'

import Container from '../Container'

enum PartnerRelevance {
  High = 'Alta',
  Medium = 'Média',
  Low = 'Baixa',
}

interface GroupedPartners {
  [PartnerRelevance.High]?: Queries.PartnerInfoFragment[]
  [PartnerRelevance.Medium]?: Queries.PartnerInfoFragment[]
  [PartnerRelevance.Low]?: Queries.PartnerInfoFragment[]
}

interface PartnerCardProps {
  partner: Queries.PartnerInfoFragment
}

const PartnerCard = ({ partner }: PartnerCardProps) => {
  const cardImage = getImage(partner.logo?.localFile as ImageDataLike)

  return (
    <div className='flex flex-col gap-2 items-center'>
      {cardImage && (
        <div className='bg-white drop-shadow-md p-1 rounded-full w-fit'>
          <GatsbyImage className='rounded-full' image={cardImage} alt={`${partner?.name}`} />
        </div>
      )}
      <p className='text-sm'>{partner.name}</p>
    </div>
  )
}

const PartnersFullList = () => {
  const strapiPartners = useStaticQuery(graphql`
    query Partners {
      partners: allStrapiPartner(sort: { name: ASC }) {
        nodes {
          ...PartnerInfo
        }
      }
    }
  `)

  const { partners } = strapiPartners as Queries.PartnersQuery

  const partnersGroupedByRelevance = partners.nodes.reduce((acc, curr) => {
    const relevance = curr.relevance as PartnerRelevance
    return {
      ...acc,
      [relevance]: [...(acc[relevance] || []), curr],
    }
  }, {} as GroupedPartners)

  const lowRelevancePartners = partnersGroupedByRelevance[PartnerRelevance.Low] || []
  const mediumRelevancePartners = partnersGroupedByRelevance[PartnerRelevance.Medium] || []
  const highRelevancePartners = partnersGroupedByRelevance[PartnerRelevance.High] || []

  const fullPartnerList = [highRelevancePartners, mediumRelevancePartners, lowRelevancePartners]

  return (
    <Container className='flex flex-col gap-12 py-12 xl:py-20'>
      <h3 className='font-semibold text-2xl'>Nossos parceiros</h3>

      <div className='flex flex-col gap-0'>
        {fullPartnerList.map((partners, index) => (
          <div key={index}>
            {
              <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-y-12'>
                {partners.map((partner) => (
                  <PartnerCard key={partner.id} partner={partner} />
                ))}
              </div>
            }
            {index < fullPartnerList.length - 1 && <hr className='bg-black border rounded-full border-white my-12' />}
          </div>
        ))}
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment PartnerInfo on STRAPI_PARTNER {
    id
    name
    relevance
    logo {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 100, height: 100, transformOptions: { cropFocus: CENTER })
        }
      }
    }
  }
`

export default PartnersFullList
