import React from 'react'
import { Disclosure as HeadlessDisclosure, Transition } from '@headlessui/react'
import { FaChevronDown } from 'react-icons/fa'
import type { Property } from 'csstype'

import { classNames } from '../services/string'

interface DisclosureProps {
  className?: string
  title: string
  content: React.ReactNode
  primaryColor?: Property.Color
  secondaryColor?: Property.Color
}

const Disclosure = ({ className, title, content, secondaryColor }: DisclosureProps) => {
  return (
    <div className={classNames(className, 'w-full')}>
      <div className='mx-auto w-full rounded-2xl bg-white md:bg-transparent p-4'>
        <HeadlessDisclosure>
          {({ open }) => (
            <>
              <HeadlessDisclosure.Button
                id={`headless-disclosure-${title}`}
                className='flex w-full justify-between items-center gap-4 text-left text-md font-normal'
              >
                {title}
                <div className='w-4 mr-1'>
                  <FaChevronDown className={`transition-all ${open ? 'rotate-180 transform' : ''} h-4 w-4`} />
                </div>
              </HeadlessDisclosure.Button>

              <Transition
                className='overflow-hidden'
                enter='transition-all ease-in-out duration-300'
                enterFrom='transform max-h-0'
                enterTo='transform max-h-[1000px]'
                leave='transition-all ease-in-out duration-300'
                leaveFrom='transform max-h-[1000px]'
                leaveTo='transform max-h-0'
              >
                <HeadlessDisclosure.Panel
                  id='headless-disclosure-panel'
                  style={{ backgroundColor: secondaryColor ? `${secondaryColor}80` : '#00000010' }}
                  className='px-6 py-4 rounded-xl mt-3'
                >
                  {content}
                </HeadlessDisclosure.Panel>
              </Transition>
            </>
          )}
        </HeadlessDisclosure>
      </div>
    </div>
  )
}

export default Disclosure
