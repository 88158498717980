import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

export interface CategoryListProps {
  className?: string
}

export const CategoryList = ({ className }: CategoryListProps) => {
  const { categories } = useStaticQuery<Queries.CategoriesListQuery>(graphql`
    query CategoriesList {
      categories: allStrapiCategory(sort: [{ name: ASC }]) {
        nodes {
          id
          slug
          name
          articles {
            id
          }
        }
      }
    }
  `)

  const categoriesToShow = categories.nodes.filter((c) => c.articles?.length)

  return (
    <nav className={className}>
      <p className='font-semibold text-xl mb-3 text-smoke'>Categorias</p>
      <ul>
        {categoriesToShow.map((c) => (
          <li className='flex w-full' key={c.name}>
            <Link to={`/blog/categoria/${c.slug}`} className='font-light my-2 w-full'>
              {c.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default CategoryList
