import React from 'react'

interface GradientIconProps {
  icon: React.ReactNode
}

const GradientIcon = ({ icon }: GradientIconProps) => {
  return (
    <div className='rounded-full shadow-md bg-gradient-to-tr w-fit from-emerald-500 via-purple-600 to-primary-500'>
      <div className='flex justify-center items-center p-3 text-white'>{icon}</div>
    </div>
  )
}

export default GradientIcon
