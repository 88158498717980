import React from 'react'
import { classNames } from '../services/string'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'

export interface IShortCustomerReviewCardProps {
  className?: string
  review: Queries.ReviewInfoFragment
  disabled?: boolean
  onClick?: () => unknown
}

const ShortCustomerReviewCard = ({ className, review, disabled, onClick }: IShortCustomerReviewCardProps) => {
  const cardImage = getImage(review.avatar?.localFile as ImageDataLike)

  return (
    <div
      className={classNames(
        className,
        onClick && 'cursor-pointer',
        disabled && 'opacity-50 bg-gray-500',
        !disabled && 'bg-white/50',
        'rounded-2xl flex flex-row p-4 h-full gap-8 items-center drop-shadow-2xl',
        'shadow-[1px_1px_1px_0px_rgba(255,255,255,1)_inset] text-black transition-all duration-300 opacity-100',
      )}
      onClick={() => onClick?.()}
    >
      <div className='bg-rainbow p-2 rounded-full'>
        {cardImage && <GatsbyImage className='w-[100px] h-[100px] rounded-full' image={cardImage} alt={`${review?.author}`} />}
      </div>

      <div className='flex flex-col gap-2'>
        <p className='font-medium text-xl'>{review.author}</p>
        <p className='text-sm text-black/70'>{review.position}</p>
      </div>
    </div>
  )
}

export default ShortCustomerReviewCard
