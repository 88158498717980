import React from 'react'
import { graphql } from 'gatsby'
import { ImageDataLike, GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from './Button'
import { classNames } from '../services/string'

export interface ClubeHubsOfferCardProps {
  className?: string
  offer: Queries.ClubeHubsOfferCardFragment
  hideCallToAction?: boolean
  onRedeem?: (id: string) => unknown
}

const ClubeHubsOfferCard = ({ className, offer, hideCallToAction, onRedeem }: ClubeHubsOfferCardProps) => {
  const { id, category, description, shortDescription, partner, customLogo } = offer

  const handleRedeem = () => {
    if (onRedeem) return onRedeem(id)
  }

  const cardImage =
    customLogo && customLogo.localFile
      ? getImage(customLogo.localFile as ImageDataLike)
      : getImage(partner?.logo?.localFile as ImageDataLike)

  return (
    <div
      className={classNames(
        className,
        'rounded-2xl flex flex-col py-5 px-10 h-full justify-between items-center gap-2 bg-white/25 shadow-[1px_1px_1px_0px_rgba(255,255,255,1)_inset]',
      )}
    >
      <section className='flex flex-col items-center justify-start mb-5'>
        <div className='bg-white p-2 rounded-full mt-2 mb-6'>
          {cardImage && <GatsbyImage className='rounded-full' image={cardImage} alt={`Logo parceiro ${partner?.name}`} />}
        </div>
        <span className='bg-rainbow text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full w-auto m-4 drop-shadow-xl'>
          {category}
        </span>
        <p className='font-semibold text-xl text-center'>{partner?.name || 'Parceiro'}</p>
        <p className='font-light text-xs text-center'>{shortDescription}</p>
        <p className='font-light text-sm mt-5 text-center'>{description}</p>
      </section>
      {!hideCallToAction && (
        <Button className='w-full' onClick={handleRedeem}>
          Resgatar
        </Button>
      )}
    </div>
  )
}

export const query = graphql`
  fragment ClubeHubsOfferCard on STRAPI_CLUBE_HUBS_OFFER {
    id
    category
    shortDescription
    description
    partner {
      id
      name
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 165, height: 165, transformOptions: { cropFocus: CENTER })
          }
        }
      }
    }
    customLogo {
      localFile {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 165, height: 165, transformOptions: { cropFocus: CENTER })
        }
      }
    }
  }
`

export default ClubeHubsOfferCard
