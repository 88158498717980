import React, { Fragment } from 'react'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { MdClose } from 'react-icons/md'
import { StaticImage } from 'gatsby-plugin-image'

interface FeedbackDialogProps {
  isOpen: boolean
  onClose: () => unknown
}

const FeedbackDialog = ({ isOpen, onClose }: FeedbackDialogProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog as='div' className='relative z-[100]' onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/40 backdrop-blur-sm' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <HeadlessDialog.Panel className='w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white border-white border-t border-l p-6 text-left align-middle transition-all shadow-lg drop-shadow-lg'>
                <div className='flex justify-end w-full mb-4'>
                  <button
                    className='flex items-center justify-center p-1.5 bg-secondary-800 w-fit text-primary-600 rounded-full cursor-pointer'
                    onClick={() => onClose()}
                    aria-label='Fechar'
                  >
                    <MdClose size='1.2rem' />
                  </button>
                </div>

                <div className='flex flex-col-reverse sm:flex-row gap-0 sm:gap-8 w-full'>
                  <div className='flex justify-center w-full sm:w-2/5 -bottom-6 relative'>
                    <StaticImage
                      width={400}
                      src='../../images/resources/holding-coffee.png'
                      alt='Imagem de uma mulher surpresa e sorridente segurando um copo de café e um telefone'
                      className='max-w-[250px] sm:max-w-[400px]'
                      placeholder='blurred'
                      quality={100}
                    />
                  </div>

                  <div className='w-full sm:w-3/5 flex items-center'>
                    <div className='flex flex-col items-center sm:items-start gap-8 sm:gap-12'>
                      <StaticImage
                        src='../../images/logos/logo-black.png'
                        alt='Logo Hubs Contabilidade'
                        className='max-w-[200px] -left-3'
                        placeholder='blurred'
                        quality={100}
                        width={200}
                      />
                      <div className='flex flex-col gap-2'>
                        <p className='text-2xl font-semibold'>Obrigado pelo interesse!</p>
                        <p className='text-lg text-secondary-700'>Nossa equipe de especialistas entrará em contato com você em breve.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}

export default FeedbackDialog
