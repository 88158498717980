import React from 'react'

import { useStaticQuery, graphql } from 'gatsby'
import { ImageDataLike, getSrc } from 'gatsby-plugin-image'

export interface SeoParams {
  seo?: Partial<Queries.STRAPI__COMPONENT_SHARED_SEO> & { shareImageUrl?: string; structuredData?: object }
}

const Seo = ({ seo = {} }: SeoParams) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        favicon {
          localFile {
            url
          }
        }
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(layout: FIXED, aspectRatio: 1.777777777, width: 1280, placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `)

  const { siteName, defaultSeo, favicon } = strapiGlobal
  const defaultShareImageUrl = getSrc(defaultSeo?.shareImage.localFile as ImageDataLike)

  // Merge default and page-specific SEO values
  const fullSeo = {
    ...defaultSeo,
    ...seo,
    metaTitle: seo.metaTitle || defaultSeo.metaTitle,
    metaDescription: seo.metaDescription || defaultSeo.metaDescription,
    resolvedImageUrl: seo.shareImageUrl || defaultShareImageUrl,
  }

  // Add site name to title
  fullSeo.metaTitle = fullSeo.metaTitle ? `${fullSeo.metaTitle} | ${siteName}` : siteName

  const getMetaTags = () => {
    return [
      fullSeo.metaTitle && { property: 'og:title', content: fullSeo.metaTitle },
      fullSeo.metaTitle && { name: 'twitter:title', content: fullSeo.metaTitle },

      fullSeo.metaDescription && { name: 'description', content: fullSeo.metaDescription },
      fullSeo.metaDescription && { property: 'og:description', content: fullSeo.metaDescription },
      fullSeo.metaDescription && { name: 'twitter:description', content: fullSeo.metaDescription },

      fullSeo.resolvedImageUrl && { name: 'image', content: fullSeo.resolvedImageUrl },
      fullSeo.resolvedImageUrl && { property: 'og:image', content: fullSeo.resolvedImageUrl },
      fullSeo.resolvedImageUrl && { name: 'twitter:image', content: fullSeo.resolvedImageUrl },

      fullSeo.article && { property: 'og:type', content: 'article' },

      { name: 'twitter:card', content: 'summary_large_image' },
    ].filter(Boolean) // Remove falsy values (null or undefined)
  }

  const metaTags = getMetaTags()

  return (
    <>
      <html lang='pt' />
      <title id='pageTitle'>{fullSeo.metaTitle}</title>
      <link rel='icon' href={favicon.localFile.url} />
      {metaTags.map((mt, i) => (
        <meta
          id={mt.name || mt.property}
          key={`mt-${mt.name || mt.property}-${i}`}
          name={mt.name}
          property={mt.property}
          content={mt.content}
        />
      ))}
      {fullSeo.structuredData && <script type='application/ld+json'>{JSON.stringify(fullSeo.structuredData)}</script>}
    </>
  )
}

export default Seo
