import React from 'react'
import BlockRichText from './BlockRichText'
import BlockMedia from './BlockMedia'

const componentsMap: Record<any, any> = {
  STRAPI__COMPONENT_SHARED_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_SHARED_MEDIA: BlockMedia,
}

export interface BlockProps {
  block: any
}

export const Block = ({ block }: BlockProps) => {
  const Component = componentsMap[block.__typename]

  if (!Component) return null

  return <Component data={block} />
}

export interface BlocksRendererProps {
  blocks: readonly any[]
  className?: string
}

export const BlocksRenderer = ({ blocks, className }: BlocksRendererProps) => {
  return (
    <article className={className}>
      <div className='flex flex-col' id='blocks-renderer'>
        {blocks.map((block, index) => (
          <Block key={`${index}${block.__typename}`} block={block} />
        ))}
      </div>
    </article>
  )
}

export default BlocksRenderer
