import React from 'react'
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format'
import TextField, { ITextFieldProps } from './TextField'

interface IBaseMaskTextFieldProps {
  /** Props de customização do comportamento da máscara. */
  NumberFormatBaseProps: NumberFormatBaseProps
  /** Props de customização do componente de entrada de texto `TextField`. */
  TextFieldProps?: ITextFieldProps
}

/** Estende o componente básico de entrada de texto `TextField` para suportar máscaras para padrões numéricos. */
const BaseMaskTextField = ({ NumberFormatBaseProps, TextFieldProps }: IBaseMaskTextFieldProps) => {
  return <NumberFormatBase {...NumberFormatBaseProps} customInput={TextField} {...(TextFieldProps as any)} />
}

export default BaseMaskTextField
