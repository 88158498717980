import React from 'react'

import { classNames } from '../../services/string'
import { CommonContactFormProps } from './types'

import FormFields from './FormFields'

const AlternativeVariant = ({ className, onSubmit, loading, marketingCampaignCode }: CommonContactFormProps) => {
  return (
    <div className={classNames(className, 'bg-gradient-to-r from-gradient-fuchsia/10 to-gradient-blue/10 z-50 p-4 w-full rounded-2xl')}>
      <FormFields
        loading={loading}
        onSubmit={onSubmit}
        options={{
          textFieldVariant: 'filled',
          hideTextFieldLabels: true,
          contrastReferenceColor: '#FFFFFF',
          buttonFullWidth: true,
          buttonLabel: 'Enviar informações',
        }}
        marketingCampaignCode={marketingCampaignCode}
      />
    </div>
  )
}

export default AlternativeVariant
