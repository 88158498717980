import React, { useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'

import { CookieConsentBanner } from './'
import { getWindow } from '../services/window'

const LOCAL_STORAGE_KEY = 'HUBS_COOKIES_STATUS'

export const CookieConsent = () => {
  const w = getWindow()
  const [tagsTriggered, setTagsTriggered] = useState<boolean>(false)
  const [cookiesBannerVisible, setCookiesBannerVisible] = useState<boolean>(false)

  const triggerDatalayerLoad = () => {
    if (!w?.dataLayer) return

    w.dataLayer.push({
      event: 'userPrefUpdate',
      cookiesAccepted: 'true',
    })

    console.log('Tags triggered!')
    setTagsTriggered(true)
  }

  const setCookieStatusStorage = (status: 'accepted' | 'rejected') => {
    w?.localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        status,
        updatedAt: new Date().toISOString(),
      }),
    )
  }

  const handleAccept = () => {
    setCookiesBannerVisible(false)
    setCookieStatusStorage('accepted')
    triggerDatalayerLoad()
  }

  const handleReject = () => {
    setCookiesBannerVisible(false)
    setCookieStatusStorage('rejected')
  }

  useEffect(() => {
    if (tagsTriggered) return
    const { status, updatedAt } = JSON.parse(w?.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}')

    if (status === 'rejected') {
      if (updatedAt && (new Date().valueOf() - new Date(updatedAt).valueOf()) / 3600000 < 24) {
        // Reject the loading of banner or GTM tags when user rejected in the last 24 hours
        return
      }

      // If it's expired or unknown, clear the storage and pop the banner
      w?.localStorage.removeItem(LOCAL_STORAGE_KEY)
    }

    if (status === 'accepted') {
      triggerDatalayerLoad()
      return
    }

    setCookiesBannerVisible(true)
  }, [])

  return (
    <Transition
      appear={true}
      show={cookiesBannerVisible}
      enter='transition-opacity duration-300'
      enterFrom='opacity-0'
      enterTo='opacity-100'
      leave='transition-opacity duration-300'
      leaveFrom='opacity-100'
      leaveTo='opacity-0'
    >
      <CookieConsentBanner onAccept={handleAccept} onReject={handleReject} />
    </Transition>
  )
}

export default CookieConsent
