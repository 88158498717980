import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react'
import { MdClose } from 'react-icons/md'

import { FormOfService } from '.'

interface DetailDialogProps {
  isOpen: boolean
  onClose: () => unknown
  formOfService: FormOfService
}

const DetailDialog = ({ isOpen, onClose, formOfService }: DetailDialogProps) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...DialogInfo
      }
    }
  `)

  const { serviceWorkingDays, serviceWorkingHours } = strapiGlobal as Queries.DialogInfoFragment

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <HeadlessDialog as='div' className='relative z-[10001]' onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black/40 backdrop-blur-sm' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <HeadlessDialog.Panel className='w-full max-w-xl transform overflow-hidden rounded-2xl bg-white border-white border-t border-l p-6 text-left align-middle transition-all shadow-lg drop-shadow-lg'>
                <div className='flex justify-between items-center w-full mb-4'>
                  <p className='font-semibold text-lg'>{formOfService.label}</p>
                  <button
                    className='flex items-center justify-center p-1.5 bg-secondary-800 w-fit text-primary-600 rounded-full cursor-pointer'
                    onClick={() => onClose()}
                    aria-label='Fechar'
                  >
                    <MdClose size='1.2rem' />
                  </button>
                </div>
                <div className='flex flex-col gap-4 mb-4'>
                  <div>
                    <p className='text-md font-light text-black/80'>Hubs Contabilidade</p>
                    <p className='text-2xl font-light'>{formOfService.raw}</p>
                  </div>

                  {serviceWorkingDays && serviceWorkingHours && (
                    <div>
                      <p className='text-md font-light text-black/80'>Horário de atendimento</p>

                      <p>{serviceWorkingDays}</p>
                      <p>{serviceWorkingHours}</p>
                    </div>
                  )}
                </div>
              </HeadlessDialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition>
  )
}

export const query = graphql`
  fragment DialogInfo on STRAPI_GLOBAL {
    id
    serviceWorkingDays
    serviceWorkingHours
  }
`

export default DetailDialog
