import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { classNames } from '../../services/string'
import { CommonContactFormProps } from './types'

import FormFields from './FormFields'

const LandingPageVariant = ({
  className,
  primaryColor,
  secondaryColor = 'var(--color-primary-100)',
  title,
  description,
  loading,
  onSubmit,
  cnpjRequired,
  marketingCampaignCode,
}: CommonContactFormProps) => {
  return (
    <div className={classNames(className, 'flex flex-col md:flex-row gap-8 lg:gap-16 py-12 xl:py-20 text-black')}>
      <div className='flex flex-col gap-4 text-center md:text-left md:w-1/2'>
        <h2 className='font-semibold text-2xl'>{title || 'Fale conosco'}</h2>
        <p>{description || 'Nosso time de especialistas entrará em contato o mais rápido possível para tirar todas as suas dúvidas!'}</p>

        <div className='hidden md:flex items-center justify-center'>
          <StaticImage
            src='../../images/icons/group.svg'
            alt='Ícone de balão de conversa'
            className='mt-4 right-0 max-w-[250px]'
            width={250}
            placeholder='blurred'
          />
        </div>
      </div>

      <div
        className={classNames('px-6 py-6 rounded-xl border-t-2 border-l-2 w-full md:w-1/2 color-black', 'shadow-lg drop-shadow-lg')}
        style={{
          backgroundColor: secondaryColor || 'var(--color-primary-100)',
          borderColor: primaryColor || 'var(--color-primary-700)',
        }}
      >
        <FormFields
          loading={loading}
          onSubmit={onSubmit}
          options={{
            contrastReferenceColor: secondaryColor,
            buttonFullWidth: true,
            buttonLabel: 'Estou interessado',
            buttonColor: primaryColor,
          }}
          cnpjRequired={cnpjRequired}
          marketingCampaignCode={marketingCampaignCode}
        />
      </div>
    </div>
  )
}

export default LandingPageVariant
