import React, { useState } from 'react'
import { Tab, Transition } from '@headlessui/react'
import { graphql, useStaticQuery } from 'gatsby'

import { classNames } from '../../services/string'

import Container from '../Container'
import Carousel, { BREAKPOINT_MAX_WIDTH } from '../Carousel'
import HowItWorksCard, { HowItWorksCardContent } from '../HowItWorksCard'
import Button from '../Button'
import { useAnalytics } from '../../hooks'

enum TabOption {
  Opening = 'Nova empresa',
  Migration = 'Troca de contabilidade',
}

const carouselResponsiveConstraints = {
  all: {
    breakpoint: { max: BREAKPOINT_MAX_WIDTH, min: 0 },
    items: 1,
  },
}

const howItWorksContent: Record<string, HowItWorksCardContent[]> = {
  [TabOption.Opening]: [
    {
      title: 'Baixe o aplicativo Hubs',
      description:
        'Disponível no Google Play e na App Store, você pode baixar o aplicativo a qualquer momento. Para começar, será preciso fazer um cadastro simples e rápido. Viu como é fácil?',
    },
    {
      title: 'Solicite a abertura de sua empresa',
      description:
        'Nosso time de especialistas entrará em contato com você para entender melhor seu modelo de negócios e apresentar as melhores opções disponíveis.',
    },
    {
      title: 'Preencha as informações necessárias',
      description:
        'Aqui na Hubs, você decide quando enviar os dados necessários para a abertura. Acompanhe pelo aplicativo as etapas já concluídas e receba notificações sempre que o processo avançar.',
    },
    {
      title: 'Aproveite a simplicidade',
      description:
        'Com sua empresa aberta, você pode solicitar quantas notas fiscais desejar. Visualize guias de impostos, documentos, conceda permissão a funcionários e faça muito mais, sempre de forma prática e rápida.',
    },
  ],
  [TabOption.Migration]: [
    {
      title: 'Baixe o aplicativo Hubs',
      description:
        'Disponível no Google Play e na App Store, você pode baixar o aplicativo a qualquer momento. Para começar, será preciso fazer um cadastro simples e rápido. Viu como é fácil?',
    },
    {
      title: 'Solicite a migração de sua empresa',
      description:
        'Nosso time de especialistas entrará em contato com você para entender melhor seu modelo de negócios e apresentar as melhores opções disponíveis.',
    },
    {
      title: 'Preencha as informações necessárias',
      description:
        'Aqui na Hubs, você decide quando enviar os dados necessários para a troca de contabilidade. Acompanhe pelo aplicativo as etapas já concluídas e receba notificações sempre que o processo avançar.',
    },
    {
      title: 'Aproveite a simplicidade',
      description:
        'Pronto! Você pode solicitar quantas notas fiscais desejar, visualizar guias de impostos, documentos, além de poder gerenciar todas as suas empresas em um só lugar, sempre de forma prática e rápida.',
    },
  ],
}

const HowItWorks = () => {
  const { fireEvent } = useAnalytics()
  const [selectedTab, setSelectedTab] = useState<TabOption>(TabOption.Opening)

  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...HowItWorksInfo
      }
    }
  `)

  const data = strapiGlobal as Queries.HowItWorksInfoFragment

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = () => {
    fireEvent('externalClick', {
      component: 'HowItWorks',
      target: 'linkWhatsapp',
    })
  }

  return (
    <Container className='flex flex-col gap-8 mb-8'>
      <div className='justify-center w-full flex'>
        <Tab.Group onChange={(index: number) => setSelectedTab(Object.values(TabOption)[index])}>
          <Tab.List className='flex space-x-1 rounded-xl p-1 bg-white border-gray-400 border drop-shadow-lg w-full sm:w-auto'>
            {Object.values(TabOption).map((category) => (
              <Tab
                value={category}
                key={category}
                className={({ selected }) =>
                  classNames(
                    'text-black rounded-lg py-2.5 px-1 text-xs font-normal leading-5 w-1/2 sm:w-48',
                    'ring-white/60 ring-offset-2 focus:outline-none focus:ring-2',
                    selected ? 'shadow bg-primary-600' : 'bg-white',
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </div>

      <div key={selectedTab}>
        <Transition
          appear={true}
          show={true}
          enter='transition-opacity easy-linear duration-[300ms]'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          className='flex flex-col gap-8 sm:gap-12 lg:gap-16'
        >
          <div className='w-full'>
            <Carousel
              showDots
              autoPlay
              infinite
              arrows={false}
              autoPlaySpeed={8000}
              renderDotsOutside
              responsive={carouselResponsiveConstraints}
              dotListClass='static my-4'
              labelledDots
            >
              {howItWorksContent[selectedTab].map((item, index) => (
                <div key={index} className='flex justify-center w-full'>
                  <HowItWorksCard className='w-full mx-2 md:max-w-xl' label={`Passo ${index + 1}`} content={item} />
                </div>
              ))}
            </Carousel>
          </div>
        </Transition>
      </div>

      <div className='flex justify-center w-full'>
        <a
          href={data.linkWhatsapp || ''}
          rel='noreferrer noopener'
          target='_BLANK'
          className='w-full sm:w-fit'
          onClick={() => fireAnalyticsEvent()}
        >
          <Button className='w-full sm:w-fit'>Falar com um especialista</Button>
        </a>
      </div>
    </Container>
  )
}

export const query = graphql`
  fragment HowItWorksInfo on STRAPI_GLOBAL {
    id
    linkWhatsapp
  }
`

export default HowItWorks
