import { Link, graphql } from 'gatsby'
import { GatsbyImage, ImageDataLike, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { classNames } from '../../services/string'

export interface ArticleCardProps {
  className?: string
  article: Queries.ArticleCardFragment
  size?: 'lg' | 'md'
}

export const ArticleCard = ({ className, article, size = 'md' }: ArticleCardProps) => {
  const articleBanner = getImage(article.banner?.localFile as ImageDataLike)
  const containerSizeClasses = {
    lg: 'w-full lg:max-w-[768px]',
    md: 'w-full',
  }[size]

  const cardSectionSizeClasses = {
    lg: 'px-8 py-8 mx-2',
    md: 'px-4 py-4 mx-4',
  }[size]

  const articleTitleSizeClasses = {
    lg: 'text-xl md:text-2xl',
    md: '',
  }[size]

  return (
    <article className={classNames(className, 'flex flex-col justify-start items-center -mb-20 relative', containerSizeClasses)}>
      <Link className='flex' to={`/blog/artigo/${article?.slug}`}>
        {articleBanner && (
          <GatsbyImage className='rounded-xl drop-shadow-xl' image={articleBanner} alt={`Ilustração do Artigo: ${article.title}`} />
        )}
        <span className='bg-primary-600 text-black text-xs font-medium me-2 px-2.5 py-0.5 rounded-full w-auto m-4 absolute drop-shadow-xl'>
          {article.category?.name}
        </span>
      </Link>

      <section className={`bg-white bg-opacity-80 drop-shadow-md rounded-xl relative bottom-20 sm:max-w-[90%] ${cardSectionSizeClasses}`}>
        <Link to={`/blog/artigo/${article?.slug}`} className={`font-semibold text-gray-800 ${articleTitleSizeClasses}`}>
          {article.title}
        </Link>
        <p className='mt-4 font-normal text-smoke'>{article.description}</p>
        <div className='grid grid-cols-2 sm:grid-cols-4 gap-x-5 gap-y-5 place-items-center mt-6'>
          <Link to={`/blog/artigo/${article?.slug}`} className='col-span-2 sm:col-span-1 text-xs sm:text-sm font-semibold text-smoke'>
            + Ler
          </Link>
          <div className='flex flex-col sm:col-span-2'>
            <span className='text-xs font-light text-smoke'>Publicado por:</span>
            <span className='text-xs font-semibold text-smoke'>{article.author?.name}</span>
          </div>
          <span className='text-xs font-semibold text-smoke'>{article.publishedAt}</span>
        </div>
      </section>
    </article>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    description
    publishedAt(formatString: "DD/MM/YYYY", locale: "pt-BR")
    category {
      id
      name
    }
    author {
      id
      name
    }
    banner {
      id
      name
      localFile {
        url
        childImageSharp {
          # 16/9 image ratio width and height
          gatsbyImageData(width: 768, aspectRatio: 1.77777777778)
        }
      }
    }
  }
`

export default ArticleCard
