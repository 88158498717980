import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import { Button, Container } from '../'
import { useAnalytics } from '../../hooks'

interface CommunityButtonProps {
  onClick: () => unknown
}

const CommunityButton = ({ onClick }: CommunityButtonProps) => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query CommunityButton {
      strapiGlobal {
        linkCommunity
      }
    }
  `)

  return (
    <a href={strapiGlobal.linkCommunity || '#'} rel='noreferrer noopener' target='_BLANK' className='w-full sm:w-fit' onClick={onClick}>
      <Button className='mt-2 w-full sm:w-fit'>Acessar a Comunidade Hubs</Button>
    </a>
  )
}

const Community = () => {
  const { fireEvent } = useAnalytics()

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = () => {
    fireEvent('externalClick', {
      component: 'Community',
      target: 'linkCommunity',
    })
  }

  return (
    <Container className='flex flex-col md:flex-row justify-center items-center gap-4 md:gap-12 py-12 text-center md:text-left'>
      <div className='flex flex-col md:w-1/2'>
        <h3 className='font-semibold text-2xl'>Faça parte da comunidade Hubs</h3>
        <p className='mt-8 lg:mt-4 mb-4'>
          A Comunidade Hubs foi feita para compartilhar ideias, tirar dúvidas e conhecer pessoas. Colabore, aprenda e faça conexões
          significativas.
        </p>
        <div className='hidden md:block'>
          <CommunityButton onClick={() => fireAnalyticsEvent()} />
        </div>
      </div>
      <div className='flex justify-center items-center my-4'>
        <StaticImage
          width={384}
          src='../../images/resources/hubs-community.png'
          alt='Imagem de um computador exibindo a página inicial do site da Comunidade Hubs'
          className='w-full'
          placeholder='blurred'
          objectFit='contain'
          quality={100}
        />
      </div>

      <div className='md:hidden w-full sm:w-auto'>
        <CommunityButton onClick={() => fireAnalyticsEvent()} />
      </div>
    </Container>
  )
}

export default Community
