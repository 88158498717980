import { getWindow } from '../services/window'

interface GenericObject {
  [key: string | number]: string | number | string[] | number[] | boolean | boolean[] | GenericObject | GenericObject[] | null | undefined
}

const useAnalytics = () => {
  const fireEvent = (event: string, payload: GenericObject) => {
    const w = getWindow()
    if (!w || !w?.dataLayer) return

    w.dataLayer.push({
      event,
      ...payload,
    })
  }

  return { fireEvent }
}

export default useAnalytics
