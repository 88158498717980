import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export interface BlockMediaProps {
  data: any
}

const BlockMedia = ({ data }: BlockMediaProps) => {
  if (!data || !data.file) return null

  const isVideo = data.file.mime.startsWith('video')
  const image = !isVideo && getImage(data.file.localFile)

  if (!image) return null

  return <section className='py-4'>{image && <GatsbyImage image={image} alt={data.file.alternativeText || ''} />}</section>
}

export default BlockMedia
