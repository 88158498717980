import React from 'react'
import { Container } from '../'

export interface ArticleHeroProps {
  title: string
}

export const ArticleHero = ({ title }: ArticleHeroProps) => {
  return (
    <section className='w-full rounded-b-[40px] py-16 bg-gradient-to-r from-gradient-fuchsia to-gradient-blue'>
      <Container className=''>
        <div className='flex justify-center'>
          <h1 className='text-white text-center font-semibold text-2xl lg:text-3xl max-w-xl'>{title}</h1>
        </div>
      </Container>
    </section>
  )
}

export default ArticleHero
