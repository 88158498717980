import React from 'react'

import { classNames } from '../../services/string'
import { CommonContactFormProps } from './types'

import FormFields from './FormFields'
import Container from '../Container'

const PartnerVariant = ({ title, description, className, onSubmit, loading }: CommonContactFormProps) => {
  return (
    <Container className={(classNames(className), 'flex flex-col md:flex-row gap-8 lg:gap-16 py-12 xl:py-20 text-black')}>
      <div className='flex flex-col gap-4 text-center md:text-left w-full md:w-1/2'>
        <h2 className='font-semibold text-2xl'>{title || 'Fale conosco'}</h2>
        <p>{description || 'Nosso time de especialistas entrará em contato o mais rápido possível para tirar todas as suas dúvidas!'}</p>
      </div>
      <div
        className={classNames(
          className,
          'bg-gradient-to-r from-gradient-fuchsia/10 to-gradient-blue/10 z-50 p-4 w-full md:w-1/2 rounded-2xl',
        )}
      >
        <FormFields
          loading={loading}
          onSubmit={onSubmit}
          options={{
            textFieldVariant: 'filled',
            hideTextFieldLabels: true,
            contrastReferenceColor: '#FFFFFF',
            buttonFullWidth: true,
            buttonLabel: 'Quero ser um parceiro',
          }}
        />
      </div>
    </Container>
  )
}

export default PartnerVariant
