import React from 'react'
import { classNames } from '../services/string'

interface IContainerProps {
  children: React.ReactNode
  className?: string
  id?: string
}

const Container = ({ id, children, className }: IContainerProps) => {
  return (
    <section id={id} className={classNames('mx-10 md:mx-15 lg:container lg:mx-auto', className)}>
      {children}
    </section>
  )
}

export default Container
