import { Link } from 'gatsby'
import React from 'react'

export interface CookiesConentBannerProps {
  onAccept?: () => unknown
  onReject?: () => unknown
}

export const CookieConsentBanner = ({ onAccept, onReject }: CookiesConentBannerProps) => {
  const handleDecline = () => {
    if (onReject) return onReject()
  }

  const handleAccept = () => {
    if (onAccept) return onAccept()
  }

  return (
    <div
      className={`z-[250] shadow-lg max-w-screen-lg mx-auto fixed bg-white inset-x-5 px-5 py-3 bottom-5 rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between`}
    >
      <div className='w-full text-sm font-light'>
        <p>Esse website utiliza cookies para garantir uma melhor experiência.</p>
        <p className='my-2 md:my-0'>
          <Link to='/politica-cookies' className='text-black whitespace-nowrap w-full underline text-sm'>
            Saiba mais
          </Link>
        </p>
      </div>
      <div className='flex gap-4 items-center flex-shrink-0'>
        <button onClick={handleDecline} className='text-sm text-black focus:outline-none hover:underline'>
          Rejeitar
        </button>
        <button
          onClick={handleAccept}
          className='text-sm bg-primary-500 px-5 py-2 text-black rounded-md hover:bg-primary-700 focus:outline-none'
        >
          Aceitar
        </button>
      </div>
    </div>
  )
}

export default CookieConsentBanner
