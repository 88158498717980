import React, { useState } from 'react'
import { MdOutlineEmail, MdOutlinePhone, MdWhatsapp } from 'react-icons/md'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { graphql, useStaticQuery } from 'gatsby'
import type { Property } from 'csstype'

import DetailDialog from './DetailDialog'
import { classNames } from '../../services/string'
import { formatWhatsAppLink } from '../../services/formatter'
import { useAnalytics } from '../../hooks'

interface FormsOfServiceProps {
  className?: string
  primaryColor?: Property.Color
  whatsAppCustomMessageSlug?: string
  whatsAppCustomMessage?: string
}

enum FormOfServiceType {
  Phone = 1,
  WhatsApp,
  Email,
}

export interface FormOfService {
  type: FormOfServiceType
  icon: JSX.Element
  label: string
  href: string | null
  target?: string
  raw?: string | null
  analyticsTarget: string
}

const AvailableFormsOfService = ({ className, primaryColor, whatsAppCustomMessageSlug, whatsAppCustomMessage }: FormsOfServiceProps) => {
  const { fireEvent } = useAnalytics()
  const breakpoints = useBreakpoint()
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        ...AvailableFormsOfserviceInfo
      }
    }
  `)

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [currentFormOfService, setCurrentFormOfService] = useState<FormOfService>()

  const { linkWhatsapp, linkEmail, linkPhone, contactPhone, contactEmail } = strapiGlobal as Queries.AvailableFormsOfserviceInfoFragment
  const availableFormsOfService: FormOfService[] = [
    {
      type: FormOfServiceType.Phone,
      icon: <MdOutlinePhone size='28' />,
      label: 'Telefone',
      href: linkPhone,
      raw: contactPhone,
      target: undefined,
      analyticsTarget: 'linkPhone',
    },
    {
      type: FormOfServiceType.WhatsApp,
      icon: <MdWhatsapp size='28' />,
      label: 'WhatsApp',
      href: formatWhatsAppLink(linkWhatsapp || '', { slug: whatsAppCustomMessageSlug, customMessage: whatsAppCustomMessage }),
      target: '_BLANK',
      analyticsTarget: 'linkWhatsApp',
    },
    {
      type: FormOfServiceType.Email,
      icon: <MdOutlineEmail size='28' />,
      label: 'E-mail',
      href: linkEmail,
      raw: contactEmail,
      target: undefined,
      analyticsTarget: 'linkEmail',
    },
  ]

  // Disparo do evento de clique externo
  const fireAnalyticsEvent = (target: string) => {
    fireEvent('adsConversion', { component: 'AvailableFormsOfService', target })

    fireEvent('externalClick', {
      component: 'AvailableFormsOfService',
      target,
    })
  }

  return (
    <div className={classNames(className, 'grid grid-cols-3 gap-x-4')}>
      {availableFormsOfService.map((formOfService, index) => {
        const { icon, label, href, target, type, analyticsTarget } = formOfService
        return (
          <div key={index} className='rounded-xl w-full shadow-lg drop-shadow-lg bg-white'>
            <a
              rel='noreferrer noopener'
              href={href || ''}
              style={{
                color: primaryColor ? (primaryColor === '#FFFFFF' ? 'var(--text-gray-700)' : primaryColor) : 'var(--text-gray-700)',
              }}
              className='flex flex-col items-center justify-center gap-2 px-4 py-2 w-full'
              target={target}
              onClick={() => {
                fireAnalyticsEvent(analyticsTarget)

                if (!breakpoints.xs && type !== FormOfServiceType.WhatsApp) {
                  setCurrentFormOfService(formOfService)
                  setIsDialogOpen(true)
                  return
                }
              }}
            >
              {icon}
              <p className='text-xs md:text-base'>{label}</p>
            </a>
          </div>
        )
      })}

      {currentFormOfService && (
        <DetailDialog
          formOfService={currentFormOfService}
          isOpen={isDialogOpen}
          onClose={() => {
            setIsDialogOpen(false)
            setCurrentFormOfService(undefined)
          }}
        />
      )}
    </div>
  )
}

export const query = graphql`
  fragment AvailableFormsOfserviceInfo on STRAPI_GLOBAL {
    id
    linkWhatsapp
    linkEmail
    linkPhone
    contactPhone
    contactEmail
  }
`

export default AvailableFormsOfService
