import React from 'react'

export interface BadgeProps {
  text: string
  className?: string
}

export const Badge = ({ text, className }: BadgeProps) => {
  return <div className={`bg-rainbow rounded-full px-4 py-2 text-white uppercase font-bold inline-block ${className}`}>{text}</div>
}

export default Badge
